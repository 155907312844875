import React, { useState, useContext, useRef } from 'react'

import { PresetColorMapContext } from '../../../contexts/PresetColorMapContext'
import useOutsideClick from '../../../hooks/useOutsideClick'
import { loadJson, loadXRayJson } from '../../../utils/imagePresets'
import Dropdown from '../../ui/Dropdown'

/**
 * Dropdown menu for changing the color map of a 3D image.
 *
 * @component
 * @param {Object | null} props.context - main storage for all vtk.js components. If the image was not loaded in the application, than equal to null.
 * @param {string} props.acquisition - acquisition type
 * @returns {JSX.Element} The rendered color map dropdown menu component.
 */
const ColorMap = ({ context, acquisition }) => {
  const [isColorMapOpen, setIsColorMapOpen] = useState(false)

  const colorMapRef = useRef(null)

  const { colorMap, setColorMap, preset, colorMapList } = useContext(
    PresetColorMapContext
  )

  const handleSwitchColorMap = async (e) => {
    if (context.current) {
      setColorMap(e.target.innerText)
      setIsColorMapOpen(false)
      try {
        if (acquisition === 'CTscan') {
          await loadJson(
            `/assets/json/organs/${preset}.json`,
            `/assets/json/luts/${e.target.innerText}.json`,
            context
          )
        } else if (acquisition === 'XRay') {
          await loadXRayJson(
            `/assets/json/organs/${preset}.json`,
            `/assets/json/luts/${e.target.innerText}.json`,
            context
          )
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  useOutsideClick({
    dependency: isColorMapOpen,
    ref: colorMapRef,
    setClosed: setIsColorMapOpen,
  })

  return (
    <Dropdown
      menuRef={colorMapRef}
      caption="Color Map"
      isMenuOpen={isColorMapOpen}
      setIsMenuOpen={setIsColorMapOpen}
      optionsList={colorMapList}
      currentOption={colorMap}
      setNewOption={handleSwitchColorMap}
    />
  )
}

export default ColorMap
