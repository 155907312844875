import React, { useRef, useState, useContext } from 'react'

import { PresetColorMapContext } from '../../../../../contexts/PresetColorMapContext'
import { ActionsTreeContext } from '../../../../../contexts/ActionsTreeContext'

import useOutsideClick from '../../../../../hooks/useOutsideClick'
import Dropdown from '../../../../ui/Dropdown'
import { loadJsonForFunctional } from '../../../../../utils/imagePresets'

/**
 * Dropdown menu for changing the color map of a functional image.
 *
 * @component
 * @param {Object | null} props.context - main storage for all vtk.js components. If the image was not loaded in the application, than equal to null.
 * @returns {JSX.Element} The rendered color map dropdown menu component.
 */
const ColorMapDropdown = ({ context }) => {
  const [isColorMapOpen, setIsColorMapOpen] = useState(false)
  const colorMapRef = useRef()

  const { mainActorData, setMainActorData } = useContext(ActionsTreeContext)
  const { colorMapList } = useContext(PresetColorMapContext)

  const handleSwitchColorMap = async (e) => {
    if (context.current) {
      setMainActorData((prevState) => ({
        ...prevState,
        functionalActor: {
          ...prevState.functionalActor,
          colorMap: e.target.innerText,
        },
      }))
      setIsColorMapOpen(false)
      try {
        await loadJsonForFunctional(
          `/assets/json/luts/${e.target.innerText}.json`,
          context,
          mainActorData.functionalActor.isLogScaleEnabled,
          mainActorData.functionalActor.logRange
        )
      } catch (error) {
        console.error(error)
      }
    }
  }

  useOutsideClick({
    dependency: isColorMapOpen,
    ref: colorMapRef,
    setClosed: setIsColorMapOpen,
  })

  return (
    <div className="w-full">
      <Dropdown
        menuRef={colorMapRef}
        caption="Color Map"
        isMenuOpen={isColorMapOpen}
        setIsMenuOpen={setIsColorMapOpen}
        optionsList={colorMapList}
        currentOption={mainActorData.functionalActor.colorMap}
        setNewOption={handleSwitchColorMap}
      />
    </div>
  )
}

export default ColorMapDropdown
