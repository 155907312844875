import React from 'react'

import clsx from 'clsx'

import ButtonWithTooltip from '../../ui/ButtonWithTooltip'

/**
 * UI Button component for ToolbarFilters
 *
 * @component
 * @param {string} props.caption - button caption
 * @param {string | null} props.activeFilter - variable holds an active filter or null if there are no active filters. Determines whether the button is active
 * @param {function} props.handleClick - function that is triggered by pressing a button
 * @param {boolean} props.isDisabled - state of button availability
 * @param {string} props.activeImageLink - link for active image
 * @param {string} props.inactiveImageLink - link for inactive image
 * @param {string} props.disabledImageLink - link for disabled image
 * @param {string} props.tooltipText - tooltip text
 * @returns {JSX.Element} The rendered button component.
 */
const ToolbarFiltersButton = ({
  caption,
  activeFilter,
  handleClick,
  isDisabled,
  activeImageLink,
  inactiveImageLink,
  disabledImageLink,
  tooltipText,
}) => {
  return (
    <li>
      <ButtonWithTooltip
        tooltipText={tooltipText}
        className={clsx(
          'relative flex flex-col items-center gap-0.5 laptop:gap-1 desktop:gap-2 outline-none',
          {
            'pointer-events-none': isDisabled,
          }
        )}
        onClick={handleClick}
      >
        {isDisabled ? (
          <img
            className="pointer-events-none w-[20px] laptop:w-[25px] desktop:w-auto"
            src={disabledImageLink}
            alt=""
          />
        ) : (
          <img
            className="pointer-events-none w-[20px] laptop:w-[25px] desktop:w-auto"
            src={activeFilter === caption ? activeImageLink : inactiveImageLink}
            alt=""
          />
        )}

        <p
          className={clsx(
            'text-xs laptop:text-sm desktop:text-base leading-tight laptop:leading-snug desktop:leading-normal',
            {
              'text-bright-blue': activeFilter === caption,
              'text-semi-transparent': isDisabled,
            }
          )}
        >
          {caption}
        </p>
      </ButtonWithTooltip>
    </li>
  )
}

export default ToolbarFiltersButton
