import React from 'react'

import Toast from './Toast'

/**
 * Container for toasts
 *
 * @component
 * @param {Array} props.toasts - toasts array
 * @param {Function} props.setToasts - toasts setter
 * @returns {JSX.Element} The toasts container component.
 */
const ToastsContainer = ({ toasts, setToasts }) => {
  const handleCloseToast = (index) => {
    setToasts((prevToasts) => {
      const newToasts = [...prevToasts]
      newToasts.splice(index, 1)
      return newToasts
    })
  }

  return (
    <ul className="absolute flex flex-col gap-2 w-[290px] max-h-[500px] overflow-y-auto top-3 right-3">
      {toasts.map((toast, index) => (
        <Toast
          key={toast}
          handleClose={() => handleCloseToast(index)}
          message={toast}
        />
      ))}
    </ul>
  )
}

export default ToastsContainer
