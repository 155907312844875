import React from 'react'

import ToolbarDiagnosticsButton from '../ui/ToolbarDiagnosticsButton'

const Markers = () => {
  return (
    <ToolbarDiagnosticsButton
      caption="Markers"
      isActive={false}
      isDisabled={true}
      activeImageLink="/assets/images/biomarkers.svg"
      inactiveImageLink="/assets/images/biomarkers.svg"
      disabledImageLink="/assets/images/biomarkers_disabled.svg"
      onClick={() => console.log('markers')}
      tooltipText="TBA"
    />
  )
}

export default Markers
