import React from 'react'

import ActionsTreeItemButton from '../../ui/ActionsTreeItemButton'

/**
 * Button to call a modal window with visual parameters for functional image
 *
 * @component
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered button with modal window component.
 */
const FunctionalVisualParametersButton = ({
  mainActorData,
  setMainActorData,
}) => {
  const handleActive = () => {
    setMainActorData((prevState) => ({
      ...prevState,
      activeModal: null,
      functionalActor: {
        ...prevState.functionalActor,
        isModalActive: !prevState.functionalActor.isModalActive,
      },
    }))
  }

  return (
    <ActionsTreeItemButton
      handleActive={handleActive}
      isActive={mainActorData.functionalActor.isModalActive}
      activeImageLink="/assets/images/parameters_active.svg"
      inactiveImageLink="/assets/images/parameters.svg"
    />
  )
}

export default FunctionalVisualParametersButton
