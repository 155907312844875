import clsx from 'clsx'
import React from 'react'

/**
 * Loader (spinner) component.
 *
 * @component
 * @param {boolean} props.isLoading - state of being loaded
 * @returns {JSX.Element} The rendered loader (spinner) component.
 */
const Loader = ({ isLoading }) => {
  return (
    <img
      className={clsx(
        'absolute bottom-3 left-3 desktop:bottom-7 desktop:left-7 transition-transform duration-500 z-10',
        {
          'translate-x-0': isLoading,
          '-translate-x-[125%]': !isLoading,
        }
      )}
      src="/assets/images/loader.svg"
      alt=""
    />
  )
}

export default Loader
