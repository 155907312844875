import axios from 'axios'

import '@kitware/vtk.js/Rendering/Profiles/All'

import vtkOpenGLRenderWindow from '@kitware/vtk.js/Rendering/OpenGL/RenderWindow'
import vtkRenderWindow from '@kitware/vtk.js/Rendering/Core/RenderWindow'
import vtkVolume from '@kitware/vtk.js/Rendering/Core/Volume'
import vtkVolumeMapper from '@kitware/vtk.js/Rendering/Core/VolumeMapper'
import vtkXMLImageDataReader from '@kitware/vtk.js/IO/XML/XMLImageDataReader'
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction'
import vtkPiecewiseFunction from '@kitware/vtk.js/Common/DataModel/PiecewiseFunction'
import vtkBoundingBox from '@kitware/vtk.js/Common/DataModel/BoundingBox'
import vtkRenderWindowInteractor from '@kitware/vtk.js/Rendering/Core/RenderWindowInteractor'
import vtkRenderer from '@kitware/vtk.js/Rendering/Core/Renderer'
import vtkInteractorStyleTrackballCamera from '@kitware/vtk.js/Interaction/Style/InteractorStyleTrackballCamera'
import vtkOutlineFilter from '@kitware/vtk.js/Filters/General/OutlineFilter'
import vtkInteractorStyleImage from '@kitware/vtk.js/Interaction/Style/InteractorStyleImage'
import vtkImageMapper from '@kitware/vtk.js/Rendering/Core/ImageMapper'
import vtkImageSlice from '@kitware/vtk.js/Rendering/Core/ImageSlice'
import vtkImageReslice from '@kitware/vtk.js/Imaging/Core/ImageReslice'
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor'
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper'
import vtkOrientationMarkerWidget from '@kitware/vtk.js/Interaction/Widgets/OrientationMarkerWidget'
import vtkAxesActor from '@kitware/vtk.js/Rendering/Core/AxesActor'
import vtkAnnotatedCubeActor from '@kitware/vtk.js/Rendering/Core/AnnotatedCubeActor'
import vtkResliceCursorWidget from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget'
import vtkWidgetManager from '@kitware/vtk.js/Widgets/Core/WidgetManager'
import {
  xyzToViewType,
  InteractionMethodsName,
} from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/Constants'
import { SlabMode } from '@kitware/vtk.js/Imaging/Core/ImageReslice/Constants'
import vtkMath from '@kitware/vtk.js/Common/Core/Math'
import vtkImageResliceMapper from '@kitware/vtk.js/Rendering/Core/ImageResliceMapper'
import vtkCylinderSource from '@kitware/vtk.js/Filters/Sources/CylinderSource'
import vtkPlane from '@kitware/vtk.js/Common/DataModel/Plane'
import vtkPlaneWidget from '@kitware/vtk.js/Widgets/Widgets3D/ImplicitPlaneWidget'
import vtkScalarBarActor from '@kitware/vtk.js/Rendering/Core/ScalarBarActor'
import vtkImageStreamline from '@kitware/vtk.js/Filters/General/ImageStreamline'
import * as d3 from 'd3'
import { Representation } from '@kitware/vtk.js/Rendering/Core/Property/Constants'

import {
  loadJson,
  loadJsonForFunctional,
  loadSegJson,
  loadXRayJson,
} from './imagePresets'
import vtkLineSource from '@kitware/vtk.js/Filters/Sources/LineSource'
import vtkSphereSource from '@kitware/vtk.js/Filters/Sources/SphereSource'
import vtkPointSource from '@kitware/vtk.js/Filters/Sources/PointSource'

import vtkXMLPolyDataReader from '@kitware/vtk.js/IO/XML/XMLPolyDataReader'

import vtkPicker from '@kitware/vtk.js/Rendering/Core/Picker'
import {
  getAnnotationFromDB,
  getCircleFromDB,
  getEllipseFromDB,
  getRulerFromDB,
} from './measures'

const segColorDictionary = {
  LULobe: [112 / 255, 33 / 255, 110 / 255],
  LLLobe: [113 / 255, 28 / 255, 187 / 255],
  RULobe: [166 / 255, 189 / 255, 80 / 255],
  RMLobe: [166 / 255, 188 / 255, 134 / 255],
  RLLobe: [165 / 255, 188 / 255, 186 / 255],
  Airways: [91 / 255, 189 / 255, 107 / 255],
  Nodules: [229 / 255, 229 / 255, 253 / 255],
  Emphysema: [97 / 255, 200 / 255, 50 / 255],
  Ventilated: [69 / 255, 149 / 255, 249 / 255],
  Infiltrated: [127 / 255, 99 / 255, 151 / 255],
  Collapsed: [45 / 255, 99 / 255, 81 / 255],
  Vessels: [226 / 255, 56 / 255, 56 / 255],
  PleuralEffusion: [34 / 255, 80 / 255, 137 / 255],
  Covid: [197 / 255, 199 / 255, 56 / 255],
}

const updateReslice = (
  params = {
    resliceCursorWidget: null,
    viewType: null,
    reslice: null,
    actor: null,
    renderer: null,
    resetFocalPoint: false,
    computeFocalPointOffset: false,
    renderWindow: null,
    setSlice: null,
    setMaxSlice: null,
    setCurrentWidgetPosition: null,
  }
) => {
  const {
    resliceCursorWidget,
    viewType,
    reslice,
    actor,
    renderer,
    resetFocalPoint,
    computeFocalPointOffset,
    renderWindow,
    setSlice,
    setMaxSlice,
    setCurrentWidgetPosition,
  } = params

  const modified = resliceCursorWidget.updateReslicePlane(reslice, viewType)
  if (modified) {
    const resliceAxes = reslice.getResliceAxes()
    actor.setUserMatrix(resliceAxes)

    const normal = resliceCursorWidget.getWidgetState().getPlanes()[
      viewType
    ].normal

    const planeExtremities = resliceCursorWidget.getPlaneExtremities(viewType)

    const length = Math.sqrt(
      vtkMath.distance2BetweenPoints(planeExtremities[0], planeExtremities[1])
    )

    const dist = Math.sqrt(
      vtkMath.distance2BetweenPoints(
        planeExtremities[0],
        resliceCursorWidget.getWidgetState().getCenter()
      )
    )

    if (setSlice) {
      setSlice(dist)
    }
    if (setMaxSlice) {
      setMaxSlice(length)
    }

    const currentPosition = vtkMath.multiplyAccumulate(
      planeExtremities[0],
      normal,
      dist,
      []
    )

    setCurrentWidgetPosition(currentPosition)
    resliceCursorWidget.updateCameraPoints(
      renderer,
      viewType,
      resetFocalPoint,
      computeFocalPointOffset
    )
    renderWindow.render()

    return { dist, currentPosition }
  } else {
    resliceCursorWidget.updateCameraPoints(
      renderer,
      viewType,
      resetFocalPoint,
      computeFocalPointOffset
    )
    renderWindow.render()
  }
}

const updateSegReslice = (
  params = {
    resliceCursorWidget: null,
    viewType: null,
    reslice: null,
    actor: null,
    renderer: null,
    resetFocalPoint: false,
    computeFocalPointOffset: false,
    renderWindow: null,
  }
) => {
  const {
    resliceCursorWidget,
    viewType,
    reslice,
    actor,
    renderer,
    resetFocalPoint,
    computeFocalPointOffset,
    renderWindow,
  } = params

  const modified = resliceCursorWidget.updateReslicePlane(reslice, viewType)
  if (modified) {
    const resliceAxes = reslice.getResliceAxes()
    actor.setUserMatrix(resliceAxes)
  }
  resliceCursorWidget.updateCameraPoints(
    renderer,
    viewType,
    resetFocalPoint,
    computeFocalPointOffset
  )
  renderWindow.render()
}

const generateInfo = async (source, setData, patientData, setToasts) => {
  const extent = source.getExtent()
  const bounds = source.getBounds()
  const spacing = source.getSpacing()
  const range = source.getPointData().getScalars().getRange()

  const info = {
    extent: `[ ${extent[0]} : ${extent[1]}, ${extent[2]} : ${extent[3]}, ${extent[4]} : ${extent[5]} ] pixel`,
    bounds: `[ ${bounds[0].toFixed(1)} : ${bounds[1].toFixed(
      1
    )}, ${bounds[2].toFixed(1)} : ${bounds[3].toFixed(1)}, ${bounds[4].toFixed(
      1
    )} : ${bounds[5].toFixed(1)} ] mm`,
    spacing: `( ${spacing[0].toFixed(3)}, ${spacing[1].toFixed(
      3
    )}, ${spacing[2].toFixed(3)} ) mm`,
    range: `
      ${range[0]} : 
        ${range[1]} HU`,
  }
  setData((prevState) => ({
    ...prevState,
    info: {
      ...prevState.info,
      ...info,
    },
  }))

  try {
    setData((prevState) => ({
      ...prevState,
      info: {
        ...prevState.info,
        chart: {
          conditions: { isLoading: true, error: false },
        },
      },
    }))

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/get_image_histogram`,
      {
        ID: patientData.id,
        EID: patientData.eid,
        SID: patientData.sid,
        acquisitiontype: 'CTscan',
      },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    const chartData = response.data
    console.log(chartData)

    const tempFormattedData = []

    for (let i = 0; i < chartData.x.length; i++) {
      tempFormattedData.push({
        x: Math.round(chartData.y[i]),
        y: chartData.x[i],
      })
    }

    setData((prevState) => ({
      ...prevState,
      info: {
        ...prevState.info,
        chart: {
          ...prevState.info?.chart,
          data: tempFormattedData,
          axesNames: { x: chartData.xname, y: chartData.yname },
          conditions: { isLoading: false, error: false },
        },
      },
    }))
  } catch (error) {
    console.error('Error:', error)
    setToasts((prev) => [
      ...prev,
      +process.env.REACT_APP_PROD_MODE_ENABLED
        ? 'An error occured while loading histogram data.'
        : error.response
          ? `Histogram data. Status: ${error.response.status} – ${error.response.statusText}.`
          : `Histogram data. ${error.message}.`,
    ])
    setData((prevState) => ({
      ...prevState,
      info: {
        ...prevState.info,
        chart: {
          ...prevState.info.chart,
          conditions: {
            isLoading: false,
            error: error.response
              ? `Histogram data. Status: ${error.response.status} – ${error.response.statusText}.`
              : `Histogram data. ${error.message}.`,
          },
        },
      },
    }))
  }
}

const generateScalarBar = (label, renderer) => {
  const scalarBarActor = vtkScalarBarActor.newInstance()
  scalarBarActor.setDrawNanAnnotation(false)
  scalarBarActor.setAxisLabel(label)
  scalarBarActor.setAxisTextStyle({
    ...scalarBarActor.getAxisTextStyle(),
    fontFamily: 'sans-serif',
  })
  scalarBarActor.setTickTextStyle({
    ...scalarBarActor.getTickTextStyle(),
    fontFamily: 'sans-serif',
  })

  const generateTicks = () => {
    return (helper) => {
      const lastTickBounds = helper.getLastTickBounds()
      const scale = d3
        .scaleLinear()
        .domain([lastTickBounds[0], lastTickBounds[1]])
        .range([lastTickBounds[0], lastTickBounds[1]])
      const ticks = scale.ticks(5)
      const format = (d) => {
        const dec = d.toString().split('.')[1]
        if (d > 999 || d < -999 || (dec && dec.length > 2)) {
          return d3.format('.1e')(d)
        }
        return d.toString()
      }
      helper.setTicks(ticks)
      helper.setTickStrings(ticks.map(format))
    }
  }

  scalarBarActor.setGenerateTicks(generateTicks())

  renderer.addActor(scalarBarActor)

  return scalarBarActor
}

const renderInitialImageStructure = (
  params = {
    context: null,
    vtkParentContainerRef: null,
    vtkFunctionalContainerRef: null,
    vtkContainerRef: null,
    sliceContainerX: null,
    sliceContainerY: null,
    sliceContainerZ: null,
  }
) => {
  const {
    context,
    vtkParentContainerRef,
    vtkFunctionalContainerRef,
    vtkContainerRef,
    sliceContainerX,
    sliceContainerY,
    sliceContainerZ,
  } = params

  const renderWindow = vtkRenderWindow.newInstance()
  const renderer = vtkRenderer.newInstance({
    background: [30 / 255, 30 / 255, 30 / 255],
  })
  const openglRenderWindow = vtkOpenGLRenderWindow.newInstance()

  renderWindow.addRenderer(renderer)
  renderWindow.addView(openglRenderWindow)

  const { width, height } =
    vtkParentContainerRef.current.getBoundingClientRect()
  openglRenderWindow.setContainer(vtkContainerRef.current)
  openglRenderWindow.setSize(width - 36, height - 36)

  const widgetManager = vtkWidgetManager.newInstance()

  const functionalRenderWindow = vtkRenderWindow.newInstance()
  const functionalRenderer = vtkRenderer.newInstance({
    background: [30 / 255, 30 / 255, 30 / 255],
  })
  const functionalOpenglRenderWindow = vtkOpenGLRenderWindow.newInstance()

  functionalRenderWindow.addRenderer(functionalRenderer)
  functionalRenderWindow.addView(functionalOpenglRenderWindow)

  functionalOpenglRenderWindow.setContainer(vtkFunctionalContainerRef.current)
  functionalOpenglRenderWindow.setSize(width - 36, height - 36)

  const functionalWidgetManager = vtkWidgetManager.newInstance()

  // create planes
  const containerX = sliceContainerX.current
  const containerY = sliceContainerY.current
  const containerZ = sliceContainerZ.current

  const renderWindowX = vtkRenderWindow.newInstance()
  const renderWindowY = vtkRenderWindow.newInstance()
  const renderWindowZ = vtkRenderWindow.newInstance()

  const rendererX = vtkRenderer.newInstance({
    background: [0 / 255, 76 / 255, 0 / 255],
  })
  // rendererX.setBackground(0 / 255, 76 / 255, 0 / 255, 0)
  const rendererY = vtkRenderer.newInstance({
    background: [128 / 255, 128 / 255, 0 / 255],
  })
  const rendererZ = vtkRenderer.newInstance({
    background: [0, 0, 76 / 255],
  })

  const openglRenderWindowX = vtkOpenGLRenderWindow.newInstance()
  const openglRenderWindowY = vtkOpenGLRenderWindow.newInstance()
  const openglRenderWindowZ = vtkOpenGLRenderWindow.newInstance()

  renderWindowX.addRenderer(rendererX)
  renderWindowY.addRenderer(rendererY)
  renderWindowZ.addRenderer(rendererZ)

  renderWindowX.addView(openglRenderWindowX)
  renderWindowY.addView(openglRenderWindowY)
  renderWindowZ.addView(openglRenderWindowZ)

  openglRenderWindowX.setContainer(containerX)
  openglRenderWindowY.setContainer(containerY)
  openglRenderWindowZ.setContainer(containerZ)

  const scissorsWidgetManager = vtkWidgetManager.newInstance()

  context.current = {
    ...context.current,
    openglRenderWindow,
    renderer,
    widgetManager,
    vtkContainerRef,
    renderWindow,
    functionalRenderWindow,
    functionalRenderer,
    functionalOpenglRenderWindow,
    functionalWidgetManager,
    vtkFunctionalContainerRef,
    containerX,
    containerY,
    containerZ,
    renderWindowX,
    renderWindowY,
    renderWindowZ,
    openglRenderWindowX,
    openglRenderWindowY,
    openglRenderWindowZ,
    rendererX,
    rendererY,
    rendererZ,
    scissorsWidgetManager,
  }
}

const render3DImage = (
  params = {
    context: null,
    imageData: null,
    imageName: 'imageName',
    setMainActorData: null,
  }
) => {
  const { context, imageData, imageName, setMainActorData } = params
  if (context.current) {
    const {
      renderWindow,
      renderer,
      openglRenderWindow,
      vtkContainerRef,
      widgetManager,
      scissorsWidgetManager,
    } = context.current

    const interactor = vtkRenderWindowInteractor.newInstance()
    interactor.setView(openglRenderWindow)
    interactor.initialize()
    interactor.bindEvents(vtkContainerRef.current)

    const trackBallInteractorStyle =
      vtkInteractorStyleTrackballCamera.newInstance()

    interactor.setInteractorStyle(trackBallInteractorStyle)

    widgetManager.setRenderer(renderer)
    scissorsWidgetManager.setRenderer(renderer)

    // create axes
    const axes = vtkAnnotatedCubeActor.newInstance()
    axes.setDefaultStyle({
      text: '+X',
      fontStyle: 'bold',
      fontFamily: 'Arial',
      fontColor: 'black',
      fontSizeScale: (res) => res / 2,
      faceColor: '#004c00',
      faceRotation: 0,
      edgeThickness: 0,
      edgeColor: 'black',
      resolution: 400,
    })
    axes.setXMinusFaceProperty({
      text: '-X',
      faceColor: '#004c00',
      faceRotation: 180,
    })
    axes.setYPlusFaceProperty({
      text: '+Y',
      faceColor: '#808000',
    })
    axes.setYMinusFaceProperty({
      text: '-Y',
      faceColor: '#808000',
    })
    axes.setZPlusFaceProperty({
      text: '+Z',
      faceColor: '#00004c',
      fontColor: '#fff',
    })
    axes.setZMinusFaceProperty({
      text: '-Z',
      faceColor: '#00004c',
      fontColor: '#fff',
      faceRotation: 180,
    })

    // create orientation widget
    const orientationWidget = vtkOrientationMarkerWidget.newInstance({
      actor: axes,
      interactor,
    })
    orientationWidget.setEnabled(true)
    orientationWidget.setViewportCorner(
      vtkOrientationMarkerWidget.Corners.BOTTOM_LEFT
    )
    orientationWidget.setViewportSize(0.1)
    orientationWidget.setMinPixelSize(100)
    orientationWidget.setMaxPixelSize(300)

    // create axes orientation widget
    const axesLeft = vtkAxesActor.newInstance()
    const axesOrientationWidget = vtkOrientationMarkerWidget.newInstance({
      actor: axesLeft,
      interactor,
    })
    axesLeft.setXAxisColor([255, 255, 255])
    axesLeft.setYAxisColor([255, 255, 255])
    axesLeft.setZAxisColor([255, 255, 255])
    const config = axesLeft.getConfig()
    config.recenter = false
    axesLeft.setConfig(config)
    axesLeft.update()
    axesOrientationWidget.setEnabled(true)
    axesOrientationWidget.setViewportCorner(
      vtkOrientationMarkerWidget.Corners.BOTTOM_LEFT
    )
    axesOrientationWidget.setViewportSize(0.1)
    axesOrientationWidget.setMinPixelSize(100)
    axesOrientationWidget.setMaxPixelSize(300)

    const actor = vtkVolume.newInstance()
    const mapper = vtkVolumeMapper.newInstance()
    mapper.setSampleDistance(1.1)
    actor.setMapper(mapper)

    // create color and opacity transfer functions
    const ctfun = vtkColorTransferFunction.newInstance()
    const ofun = vtkPiecewiseFunction.newInstance()

    const reader = vtkXMLImageDataReader.newInstance()

    reader.parseAsArrayBuffer(imageData)

    mapper.setInputConnection(reader.getOutputPort())

    const source = reader.getOutputData()

    const sliceMapper = vtkImageResliceMapper.newInstance()
    const slicePlane = vtkPlane.newInstance()
    slicePlane.setNormal(0, 1, 0)
    sliceMapper.setSlicePlane(slicePlane)

    const slicePolyDataSource = vtkCylinderSource.newInstance({
      height: 100,
      radius: 100,
      resolution: 20,
      capping: 1,
      center: [100, 100, 100],
    })

    const sliceActor = vtkImageSlice.newInstance()
    sliceActor.setMapper(sliceMapper)
    sliceActor.getProperty().setColorLevel(982.7)
    sliceActor.getProperty().setColorWindow(4290.5)

    const planeWidget = vtkPlaneWidget.newInstance()
    planeWidget.getWidgetState().setNormal(0, 0, 1)
    planeWidget.setPlaceFactor(1)

    sliceMapper.setInputData(source)
    const bds = source.getBounds()
    const imc = source.getCenter()
    slicePlane.setOrigin(imc)
    slicePolyDataSource.setCenter(imc)
    planeWidget.placeWidget(bds)

    const planeState = planeWidget.getWidgetState()
    planeState.setOrigin(slicePlane.getOrigin())
    planeState.setNormal(slicePlane.getNormal())
    planeState.onModified(() => {
      slicePlane.setOrigin(planeState.getOrigin())
      slicePlane.setNormal(planeState.getNormal())
      slicePolyDataSource.setCenter(planeState.getOrigin())
      slicePolyDataSource.setDirection(planeState.getNormal())
      if (sliceMapper.getSlicePolyData()) {
        slicePolyDataSource.update()
        sliceMapper.setSlicePolyData(slicePolyDataSource.getOutputData())
      }
    })

    // For better looking volume rendering
    // - distance in world coordinates a scalar opacity of 1.0
    actor
      .getProperty()
      .setScalarOpacityUnitDistance(
        0,
        vtkBoundingBox.getDiagonalLength(source.getBounds()) /
          Math.max(...source.getDimensions())
      )

    const outline = vtkOutlineFilter.newInstance()
    outline.setInputData(source)
    const outlineMapper = vtkMapper.newInstance()
    outlineMapper.setInputData(outline.getOutputData())
    const outlineActor = vtkActor.newInstance()
    outlineActor.setMapper(outlineMapper)
    renderer.addActor(outlineActor)

    const imageScalarBarActor = generateScalarBar('', renderer)
    imageScalarBarActor.setVisibility(false)

    renderer.addActor(actor)

    const resliceCursorWidget = vtkResliceCursorWidget.newInstance()

    resliceCursorWidget
      .getWidgetState()
      .getStatesWithLabel('handles')
      .forEach((handle) => handle.setOpacity(0))

    resliceCursorWidget.setImage(source)

    renderer.getActiveCamera().setParallelProjection(false)

    renderer.resetCameraClippingRange()
    renderer.resetCamera()
    renderWindow.render()

    context.current = {
      ...context.current,
      interactor,
      actor,
      ctfun,
      ofun,
      resliceCursorWidget,
      orientationWidget,
      axesOrientationWidget,
      sliceActor,
      planeWidget,
      imageScalarBarActor,
    }

    setMainActorData({
      name: imageName,
      actor,
      isSubMenuOpen: true,
      isActorVisible: true,
      isPlanesVisible: {
        xyz: true,
        x: true,
        y: true,
        z: true,
        rotatable: false,
      },
      activeModal: null,
      reportText: '',
      isCameraParallelProjection: false,
      subActors: [],
      anatomyData: [],
      chartDiagnosticsData: [],
      radiologyMetaData: {},
      hasFunctional: false,
      functionalActor: {
        dataArray: 'ventilation [L/min]',
        colorMap: 'CoolToWarmExtended',
        bds: [],
        spacing: [],
        isModalActive: false,
        isVisible: true,
        isLogScaleEnabled: false,
        logRange: [],
        isPlaneVisible: true,
        isStreamlinesVisible: false,
        streamlinesRepresentation: 'Line',
        isStreamlinesLineVisible: true,
        isStreamlinesSphereVisible: true,
        streamlinesSpherePositionCoef: { x: 0.5, y: 0.5, z: 0.5 },
        streamlinesSpherePoints: 25,
        streamlinesSphereRadius: 30,
        stremalinesSphereMaxRadius: 200,
        streamlinesLinePositionP1: { x: 0, y: 0, z: 0 },
        streamlinesLinePositionP2: { x: 0, y: 0, z: 0 },
      },
    })

    return { source }
  }
}

const render2DPlanes = (
  params = {
    context: null,
    source: null,
    vtkParentContainerRef: null,
    setSpacings: null,
    color: null,
    setColor: null,
    setCoordinates: null,
    setXSlice: null,
    setYSlice: null,
    setZSlice: null,
    setSliderMaxX: null,
    setSliderMaxY: null,
    setSliderMaxZ: null,
    setCurrentWidgetPosition: null,
  }
) => {
  const {
    context,
    source,
    vtkParentContainerRef,
    setSpacings,
    color,
    setColor,
    setCoordinates,
    setXSlice,
    setYSlice,
    setZSlice,
    setSliderMaxX,
    setSliderMaxY,
    setSliderMaxZ,
    setCurrentWidgetPosition,
  } = params
  if (context.current) {
    const {
      renderer,
      renderWindow,
      containerX,
      containerY,
      containerZ,
      renderWindowX,
      renderWindowY,
      renderWindowZ,
      rendererX,
      rendererY,
      rendererZ,
      openglRenderWindowX,
      openglRenderWindowY,
      openglRenderWindowZ,
      resliceCursorWidget,
    } = context.current

    const resliceCursorWidgetManagerX = vtkWidgetManager.newInstance()
    const resliceCursorWidgetManagerY = vtkWidgetManager.newInstance()
    const resliceCursorWidgetManagerZ = vtkWidgetManager.newInstance()

    const spacing = source.getSpacing()

    setSpacings({ X: spacing[0], Y: spacing[1], Z: spacing[2] })

    const { width, height } =
      vtkParentContainerRef.current.getBoundingClientRect()

    openglRenderWindowX.setSize(width - 36, height - 84)
    openglRenderWindowY.setSize(width - 36, height - 84)
    openglRenderWindowZ.setSize(width - 36, height - 84)

    const interactorX = vtkRenderWindowInteractor.newInstance()
    const interactorY = vtkRenderWindowInteractor.newInstance()
    const interactorZ = vtkRenderWindowInteractor.newInstance()

    interactorX.setView(openglRenderWindowX)
    interactorY.setView(openglRenderWindowY)
    interactorZ.setView(openglRenderWindowZ)

    interactorX.initialize()
    interactorY.initialize()
    interactorZ.initialize()

    interactorX.bindEvents(containerX)
    interactorY.bindEvents(containerY)
    interactorZ.bindEvents(containerZ)

    const iStyleX = vtkInteractorStyleImage.newInstance()
    iStyleX.setInteractionMode('IMAGE_SLICING')
    const iStyleY = vtkInteractorStyleImage.newInstance()
    iStyleY.setInteractionMode('IMAGE_SLICING')
    const iStyleZ = vtkInteractorStyleImage.newInstance()
    iStyleZ.setInteractionMode('IMAGE_SLICING')

    interactorX.setInteractorStyle(iStyleX)
    interactorY.setInteractorStyle(iStyleY)
    interactorZ.setInteractorStyle(iStyleZ)

    resliceCursorWidgetManagerX.setRenderer(rendererX)
    resliceCursorWidgetManagerY.setRenderer(rendererY)
    resliceCursorWidgetManagerZ.setRenderer(rendererZ)

    const resliceCursorWidgetInstanceX = resliceCursorWidgetManagerX.addWidget(
      resliceCursorWidget,
      xyzToViewType[0]
    )
    const resliceCursorWidgetInstanceY = resliceCursorWidgetManagerY.addWidget(
      resliceCursorWidget,
      xyzToViewType[1]
    )
    const resliceCursorWidgetInstanceZ = resliceCursorWidgetManagerZ.addWidget(
      resliceCursorWidget,
      xyzToViewType[2]
    )

    const resliceX = vtkImageReslice.newInstance()
    const resliceMapperX = vtkImageMapper.newInstance()
    resliceMapperX.setInputConnection(resliceX.getOutputPort())
    const resliceActorX = vtkImageSlice.newInstance()
    resliceActorX.getProperty().setColorLevel(color.level)
    resliceActorX.getProperty().setColorWindow(color.window)
    resliceActorX.setMapper(resliceMapperX)

    const resliceY = vtkImageReslice.newInstance()
    const resliceMapperY = vtkImageMapper.newInstance()
    resliceMapperY.setInputConnection(resliceY.getOutputPort())
    const resliceActorY = vtkImageSlice.newInstance()
    resliceActorY.getProperty().setColorLevel(color.level)
    resliceActorY.getProperty().setColorWindow(color.window)
    resliceActorY.setMapper(resliceMapperY)

    const resliceZ = vtkImageReslice.newInstance()
    const resliceMapperZ = vtkImageMapper.newInstance()
    resliceMapperZ.setInputConnection(resliceZ.getOutputPort())
    const resliceActorZ = vtkImageSlice.newInstance()
    resliceActorZ.getProperty().setColorLevel(color.level)
    resliceActorZ.getProperty().setColorWindow(color.window)
    resliceActorZ.setMapper(resliceMapperZ)

    resliceX.setInputData(source)
    resliceY.setInputData(source)
    resliceZ.setInputData(source)

    resliceX.setSlabMode(SlabMode.MAX)
    resliceY.setSlabMode(SlabMode.MAX)
    resliceZ.setSlabMode(SlabMode.MAX)

    resliceX.setInterpolationMode(1)
    resliceY.setInterpolationMode(1)
    resliceZ.setInterpolationMode(1)

    resliceX.setSlabNumberOfSlices(1)
    resliceY.setSlabNumberOfSlices(1)
    resliceZ.setSlabNumberOfSlices(1)

    rendererX.addActor(resliceActorX)
    rendererY.addActor(resliceActorY)
    rendererZ.addActor(resliceActorZ)

    // On window interaction read the color/level of current interactor and paste it in the another two.
    interactorX.onAnimation((event) => {
      // console.log(
      //   event.firstRenderer.getActors()[0].getProperty().getColorLevel()
      // )
      const cl = resliceActorX.getProperty().getColorLevel()
      const cw = resliceActorX.getProperty().getColorWindow()
      resliceActorY.getProperty().setColorLevel(cl)
      resliceActorY.getProperty().setColorWindow(cw)
      resliceActorZ.getProperty().setColorLevel(cl)
      resliceActorZ.getProperty().setColorWindow(cw)
      setColor({ level: cl, window: cw })
      renderWindow.render()
      renderWindowY.render()
      renderWindowZ.render()
    })

    interactorY.onAnimation(() => {
      const cl = resliceActorY.getProperty().getColorLevel()
      const cw = resliceActorY.getProperty().getColorWindow()
      resliceActorX.getProperty().setColorLevel(cl)
      resliceActorX.getProperty().setColorWindow(cw)
      resliceActorZ.getProperty().setColorLevel(cl)
      resliceActorZ.getProperty().setColorWindow(cw)
      setColor({ level: cl, window: cw })
      renderWindow.render()
      renderWindowX.render()
      renderWindowZ.render()
    })

    interactorZ.onAnimation(() => {
      const cl = resliceActorZ.getProperty().getColorLevel()
      const cw = resliceActorZ.getProperty().getColorWindow()
      resliceActorX.getProperty().setColorLevel(cl)
      resliceActorX.getProperty().setColorWindow(cw)
      resliceActorY.getProperty().setColorLevel(cl)
      resliceActorY.getProperty().setColorWindow(cw)
      setColor({ level: cl, window: cw })
      renderWindow.render()
      renderWindowX.render()
      renderWindowY.render()
    })

    const slices = { x: 0, y: 0, z: 0 }

    const updateResliceStartInteractionEventX =
      resliceCursorWidgetInstanceX.onStartInteractionEvent(() => {
        slices.x = updateReslice({
          resliceCursorWidget,
          viewType: xyzToViewType[0],
          reslice: resliceX,
          actor: resliceActorX,
          renderer: rendererX,
          resetFocalPoint: false,
          computeFocalPointOffset: true,
          renderWindow,
          setSlice: setXSlice,
          setMaxSlice: setSliderMaxX,
          setCurrentWidgetPosition,
        })?.dist
      })

    const updateResliceInteractionEventX =
      resliceCursorWidgetInstanceX.onInteractionEvent(
        (interactionMethodName) => {
          const canUpdateFocalPoint =
            interactionMethodName === InteractionMethodsName.RotateLine
          const activeViewType = resliceCursorWidget
            .getWidgetState()
            .getActiveViewType()
          const computeFocalPointOffset =
            activeViewType === xyzToViewType[0] || !canUpdateFocalPoint
          slices.x = updateReslice({
            resliceCursorWidget,
            viewType: xyzToViewType[0],
            reslice: resliceX,
            actor: resliceActorX,
            renderer: rendererX,
            resetFocalPoint: false,
            computeFocalPointOffset,
            renderWindow,
            setSlice: setXSlice,
            setMaxSlice: setSliderMaxX,
            setCurrentWidgetPosition,
          })?.dist
        }
      )

    slices.x = updateReslice({
      resliceCursorWidget,
      viewType: xyzToViewType[0],
      reslice: resliceX,
      actor: resliceActorX,
      renderer: rendererX,
      resetFocalPoint: true,
      computeFocalPointOffset: true,
      renderWindow,
      setSlice: setXSlice,
      setMaxSlice: setSliderMaxX,
      setCurrentWidgetPosition,
    })?.dist
    interactorX.render()

    const updateResliceStartInteractionEventY =
      resliceCursorWidgetInstanceY.onStartInteractionEvent(() => {
        slices.y = updateReslice({
          resliceCursorWidget,
          viewType: xyzToViewType[1],
          reslice: resliceY,
          actor: resliceActorY,
          renderer: rendererY,
          resetFocalPoint: false,
          computeFocalPointOffset: true,
          renderWindow,
          setSlice: setYSlice,
          setMaxSlice: setSliderMaxY,
          setCurrentWidgetPosition,
        })?.dist
      })

    const updateResliceInteractionEventY =
      resliceCursorWidgetInstanceY.onInteractionEvent(
        (interactionMethodName) => {
          const canUpdateFocalPoint =
            interactionMethodName === InteractionMethodsName.RotateLine
          const activeViewType = resliceCursorWidget
            .getWidgetState()
            .getActiveViewType()
          const computeFocalPointOffset =
            activeViewType === xyzToViewType[1] || !canUpdateFocalPoint
          slices.y = updateReslice({
            resliceCursorWidget,
            viewType: xyzToViewType[1],
            reslice: resliceY,
            actor: resliceActorY,
            renderer: rendererY,
            resetFocalPoint: false,
            computeFocalPointOffset,
            renderWindow,
            setSlice: setYSlice,
            setMaxSlice: setSliderMaxY,
            setCurrentWidgetPosition,
          })?.dist
        }
      )

    slices.y = updateReslice({
      resliceCursorWidget,
      viewType: xyzToViewType[1],
      reslice: resliceY,
      actor: resliceActorY,
      renderer: rendererY,
      resetFocalPoint: true,
      computeFocalPointOffset: true,
      renderWindow,
      setSlice: setYSlice,
      setMaxSlice: setSliderMaxY,
      setCurrentWidgetPosition,
    })?.dist
    interactorY.render()

    const updateResliceStartInteractionEventZ =
      resliceCursorWidgetInstanceZ.onStartInteractionEvent(() => {
        slices.z = updateReslice({
          resliceCursorWidget,
          viewType: xyzToViewType[2],
          reslice: resliceZ,
          actor: resliceActorZ,
          renderer: rendererZ,
          resetFocalPoint: false,
          computeFocalPointOffset: true,
          renderWindow,
          setSlice: setZSlice,
          setMaxSlice: setSliderMaxZ,
          setCurrentWidgetPosition,
        })?.dist
      })

    const updateResliceInteractionEventZ =
      resliceCursorWidgetInstanceZ.onInteractionEvent(
        (interactionMethodName) => {
          const canUpdateFocalPoint =
            interactionMethodName === InteractionMethodsName.RotateLine
          const activeViewType = resliceCursorWidget
            .getWidgetState()
            .getActiveViewType()
          const computeFocalPointOffset =
            activeViewType === xyzToViewType[2] || !canUpdateFocalPoint
          slices.z = updateReslice({
            resliceCursorWidget,
            viewType: xyzToViewType[2],
            reslice: resliceZ,
            actor: resliceActorZ,
            renderer: rendererZ,
            resetFocalPoint: false,
            computeFocalPointOffset,
            renderWindow,
            setSlice: setZSlice,
            setMaxSlice: setSliderMaxZ,
            setCurrentWidgetPosition,
          })?.dist
        }
      )

    const sliceAndPos = updateReslice({
      resliceCursorWidget,
      viewType: xyzToViewType[2],
      reslice: resliceZ,
      actor: resliceActorZ,
      renderer: rendererZ,
      resetFocalPoint: true,
      computeFocalPointOffset: true,
      renderWindow,
      setSlice: setZSlice,
      setMaxSlice: setSliderMaxZ,
      setCurrentWidgetPosition,
    })
    slices.z = sliceAndPos?.dist
    interactorZ.render()

    const pickerX = vtkPicker.newInstance()
    const pickerY = vtkPicker.newInstance()
    const pickerZ = vtkPicker.newInstance()

    const ori = source.getOrigin()

    const pointerLocationTrackingX = interactorX.onMouseMove((event) => {
      pickerX.pick(
        [event.position.x, event.position.y, event.position.z],
        rendererX
      )
      const pos = pickerX.getPickPosition()

      const i = Math.round(pos[1]) - ori[1]
      const j = Math.round(pos[2]) - ori[2]

      const onImage =
        (Math.round(i / source.getSpacing()[1]) <= source.getExtent()[3] &&
          Math.round(i / source.getSpacing()[1]) >= source.getExtent()[2]) ||
        (Math.round(i / source.getSpacing()[2]) <= source.getExtent()[5] &&
          Math.round(i / source.getSpacing()[2]) >= source.getExtent()[4])

      const contrast = onImage
        ? source.getScalarValueFromWorld(
            [
              ori[0] + slices.x,
              ori[1] +
                Math.round(i / source.getSpacing()[1]) * source.getSpacing()[1],
              ori[2] +
                Math.round(j / source.getSpacing()[2]) * source.getSpacing()[2],
            ],
            0
          )
          ? source.getScalarValueFromWorld(
              [
                ori[0] + slices.x,
                ori[1] +
                  Math.round(i / source.getSpacing()[1]) *
                    source.getSpacing()[1],
                ori[2] +
                  Math.round(j / source.getSpacing()[2]) *
                    source.getSpacing()[2],
              ],
              0
            )
          : 'n/a'
        : 'n/a'

      setCoordinates((prev) => ({
        ...prev,
        x: {
          loc: [
            Math.round(i / source.getSpacing()[1]),
            Math.round(j / source.getSpacing()[2]),
          ],
          pos: [Math.round(pos[0]), Math.round(pos[1]), Math.round(pos[2])],
          onImage,
          contrast,
        },
      }))

      // console.log('test:')
      // console.log(256 * source.getSpacing()[0])
      // console.log(Math.round(i / source.getSpacing()[1]))
      // console.log(Math.round(j / source.getSpacing()[2]))

      // console.log(
      //   source.getScalarValueFromWorld(
      //     [
      //       ori[0] + 256 * source.getSpacing()[0],
      //       ori[1] +
      //         Math.round(i / source.getSpacing()[1]) * source.getSpacing()[1],
      //       ori[2] +
      //         Math.round(j / source.getSpacing()[2]) * source.getSpacing()[2],
      //     ],
      //     0
      //   )
      // )
    })

    const pointerLocationTrackingY = interactorY.onMouseMove((event) => {
      pickerY.pick(
        [event.position.x, event.position.y, event.position.z],
        rendererY
      )
      const pos = pickerY.getPickPosition()

      const i = Math.round(pos[0]) - ori[0]
      const j = Math.round(pos[2]) - ori[2]

      const onImage =
        (Math.round(i / source.getSpacing()[0]) <= source.getExtent()[1] &&
          Math.round(i / source.getSpacing()[0]) >= source.getExtent()[0]) ||
        (Math.round(i / source.getSpacing()[2]) <= source.getExtent()[5] &&
          Math.round(i / source.getSpacing()[2]) >= source.getExtent()[4])

      const contrast = onImage
        ? source.getScalarValueFromWorld(
            [
              ori[0] +
                Math.round(i / source.getSpacing()[0]) * source.getSpacing()[0],
              ori[1] + slices.y,
              ori[2] +
                Math.round(j / source.getSpacing()[2]) * source.getSpacing()[2],
            ],
            0
          )
          ? source.getScalarValueFromWorld(
              [
                ori[0] +
                  Math.round(i / source.getSpacing()[0]) *
                    source.getSpacing()[0],
                ori[1] + slices.y,
                ori[2] +
                  Math.round(j / source.getSpacing()[2]) *
                    source.getSpacing()[2],
              ],
              0
            )
          : 'n/a'
        : 'n/a'

      setCoordinates((prev) => ({
        ...prev,
        y: {
          loc: [
            Math.round(i / source.getSpacing()[0]),
            Math.round(j / source.getSpacing()[2]),
          ],
          pos: [Math.round(pos[0]), Math.round(pos[1]), Math.round(pos[2])],
          onImage,
          contrast,
        },
      }))
    })

    const pointerLocationTrackingZ = interactorZ.onMouseMove((event) => {
      pickerZ.pick(
        [event.position.x, event.position.y, event.position.z],
        rendererZ
      )
      const pos = pickerZ.getPickPosition()

      const i = Math.round(pos[0]) - ori[0]
      const j = Math.round(pos[1]) - ori[1]

      const onImage =
        (Math.round(i / source.getSpacing()[0]) <= source.getExtent()[1] &&
          Math.round(i / source.getSpacing()[0]) >= source.getExtent()[0]) ||
        (Math.round(i / source.getSpacing()[1]) <= source.getExtent()[3] &&
          Math.round(i / source.getSpacing()[1]) >= source.getExtent()[2])

      const contrast = onImage
        ? source.getScalarValueFromWorld(
            [
              ori[0] +
                Math.round(i / source.getSpacing()[0]) * source.getSpacing()[0],
              ori[1] +
                Math.round(j / source.getSpacing()[1]) * source.getSpacing()[1],
              ori[2] + slices.z,
            ],
            0
          )
          ? source.getScalarValueFromWorld(
              [
                ori[0] +
                  Math.round(i / source.getSpacing()[0]) *
                    source.getSpacing()[0],
                ori[1] +
                  Math.round(j / source.getSpacing()[1]) *
                    source.getSpacing()[1],
                ori[2] + slices.z,
              ],
              0
            )
          : 'n/a'
        : 'n/a'

      setCoordinates((prev) => ({
        ...prev,
        z: {
          loc: [
            Math.round(i / source.getSpacing()[0]),
            Math.round(j / source.getSpacing()[1]),
          ],
          pos: [Math.round(pos[0]), Math.round(pos[1]), Math.round(pos[2])],
          onImage,
          contrast,
        },
      }))
    })

    const cameraX = rendererX.getActiveCamera()
    const cameraY = rendererY.getActiveCamera()
    const cameraZ = rendererZ.getActiveCamera()

    cameraX.setParallelProjection(true)
    cameraY.setParallelProjection(true)
    cameraZ.setParallelProjection(true)

    rendererX.resetCamera()
    rendererY.resetCamera()
    rendererZ.resetCamera()

    renderer.addActor(resliceActorX)
    renderer.addActor(resliceActorY)
    renderer.addActor(resliceActorZ)

    const measuresWidgetManagerX = vtkWidgetManager.newInstance()
    const measuresWidgetManagerY = vtkWidgetManager.newInstance()
    const measuresWidgetManagerZ = vtkWidgetManager.newInstance()

    measuresWidgetManagerX.setRenderer(rendererX)
    measuresWidgetManagerY.setRenderer(rendererY)
    measuresWidgetManagerZ.setRenderer(rendererZ)

    renderWindow.render()
    renderWindowX.render()
    renderWindowY.render()
    renderWindowZ.render()

    context.current = {
      ...context.current,
      source,
      resliceActorX,
      resliceActorY,
      resliceActorZ,
      resliceX,
      resliceY,
      resliceZ,
      resliceCursorWidgetInstanceX,
      resliceCursorWidgetInstanceY,
      resliceCursorWidgetInstanceZ,
      interactorX,
      interactorY,
      interactorZ,
      resliceCursorWidgetManagerX,
      resliceCursorWidgetManagerY,
      resliceCursorWidgetManagerZ,
      measuresWidgetManagerX,
      measuresWidgetManagerY,
      measuresWidgetManagerZ,
      updateResliceStartInteractionEventX,
      updateResliceStartInteractionEventY,
      updateResliceStartInteractionEventZ,
      updateResliceInteractionEventX,
      updateResliceInteractionEventY,
      updateResliceInteractionEventZ,
      pointerLocationTrackingX,
      pointerLocationTrackingY,
      pointerLocationTrackingZ,
    }

    return sliceAndPos.currentPosition
  }
}

const getAnatomyList = async (patientData, setToasts) => {
  const anatomyList = []
  if (patientData && !!patientData.anid) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/get_anatomy_list`,
        {
          ID: patientData.id,
          EID: patientData.eid,
          SID: patientData.sid,
          AnID: patientData.anid,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      const data = response.data
      console.log('Response from server:', data)

      anatomyList.push(...data)
    } catch (error) {
      console.error('Error:', error)
      setToasts((prev) => [
        ...prev,
        +process.env.REACT_APP_PROD_MODE_ENABLED
          ? 'An error occured while loading anatomy list.'
          : error.response
            ? `Anatomy list. Status: ${error.response.status} – ${error.response.statusText}.`
            : `Anatomy list. ${error.message}.`,
      ])
    }
  }
  return anatomyList
}

const getDiagnosticsList = async (patientData, setToasts) => {
  const diagnostics = {
    morpho: [],
    functional: [],
  }
  if (patientData && !!patientData.did) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/get_diagnostics_list`,
        {
          ID: patientData.id,
          EID: patientData.eid,
          SID: patientData.sid,
          DID: patientData.did,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      const data = response.data
      console.log('Response from server:', data)

      diagnostics.morpho = data.filter(
        (diagnostic) => diagnostic.diagnostictype === 'morphological'
      )

      diagnostics.functional = data.filter(
        (diagnostic) => diagnostic.diagnostictype === 'functional'
      )
    } catch (error) {
      console.error('Error:', error)
      setToasts((prev) => [
        ...prev,
        +process.env.REACT_APP_PROD_MODE_ENABLED
          ? 'An error occured while loading diagnostics list.'
          : error.response
            ? `Diagnostics list. Status: ${error.response.status} – ${error.response.statusText}.`
            : `Diagnostics list. ${error.message}.`,
      ])
    }

    console.log(diagnostics)
    console.log(diagnostics.morpho.length)
    console.log(!!diagnostics.morpho.length)
    console.log(diagnostics.functional.length)
    console.log(!!diagnostics.functional.length)
  }
  return diagnostics
}

export const renderAnatomy = async (
  params = {
    context: null,
    setMainActorData: null,
    anatomyData: null,
    patientData: null,
    setMessages: null,
    setToasts: null,
  }
) => {
  const {
    context,
    setMainActorData,
    anatomyData,
    patientData,
    setMessages,
    setToasts,
  } = params

  try {
    const { renderer, renderWindow } = context.current

    if (anatomyData?.statistics) {
      setMainActorData((prevState) => ({
        ...prevState,
        anatomyData: [
          ...prevState.anatomyData,
          { name: anatomyData.name, ...anatomyData.statistics },
        ],
      }))

      console.log([{ name: anatomyData.name, ...anatomyData.statistics }])
    }

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/get_anatomy_content`,
      {
        ID: patientData.id,
        EID: patientData.eid,
        SID: patientData.sid,
        AnID: anatomyData.id,
      },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer',
      }
    )

    const vtpData = response.data

    const reader = vtkXMLPolyDataReader.newInstance()
    reader.parseAsArrayBuffer(vtpData)

    const polydata = reader.getOutputData()

    const mapper = vtkMapper.newInstance()

    const scalars = polydata.getPointData().getScalars()

    const segColor = []

    if (segColorDictionary[anatomyData.id]) {
      segColor.push(...segColorDictionary[anatomyData.id])
    } else {
      segColor.push(
        ...[
          Math.floor(Math.random() * 256) / 255,
          Math.floor(Math.random() * 256) / 255,
          Math.floor(Math.random() * 256) / 255,
        ]
      )
    }

    if (scalars) {
      const lookupTable = vtkColorTransferFunction.newInstance()
      lookupTable.addRGBPoint(0, segColor[0], segColor[1], segColor[2])
      mapper.setLookupTable(lookupTable)
      mapper.setScalarRange(0, 2)
      mapper.setColorModeToMapScalars()
      mapper.setScalarModeToUsePointData()
    }

    mapper.setInputConnection(reader.getOutputPort())

    const actor = vtkActor.newInstance()
    actor.getProperty().setOpacity(1.0)

    if (!scalars) {
      actor.getProperty().setColor(segColor[0], segColor[1], segColor[2])
    }

    setMainActorData((prevState) => ({
      ...prevState,
      subActors: [
        ...prevState.subActors,
        {
          name: anatomyData.name,
          type: 'anatomy',
          hasMask: anatomyData.hasMask,
          segColor,
          seg2DLoaded: false,
          actor,
          isActorVisible: true,
        },
      ],
    }))

    setMessages((prev) => [...prev, `Loaded ${anatomyData.name}`])

    actor.setMapper(mapper)
    renderer.addActor(actor)

    renderWindow.render()

    return {
      name: anatomyData.name,
      type: 'anatomy',
      hasMask: anatomyData.hasMask,
      segColor,
      seg2DLoaded: false,
      actor,
      isActorVisible: true,
    }
  } catch (error) {
    console.error('Error:', error)
    setToasts((prev) => [
      ...prev,
      +process.env.REACT_APP_PROD_MODE_ENABLED
        ? `An error occured while loading ${anatomyData.name}.`
        : error.response
          ? `${anatomyData.name}. Status: ${error.response.status} – ${error.response.statusText}.`
          : `${anatomyData.name}. ${error.message}.`,
    ])
  }
}

export const renderDiagnostics = async (
  params = {
    context: null,
    setMainActorData: null,
    diagnosticData: null,
    patientData: null,
    setMessages: null,
    setToasts: null,
  }
) => {
  const {
    context,
    setMainActorData,
    diagnosticData,
    patientData,
    setMessages,
    setToasts,
  } = params

  try {
    const { renderer, renderWindow } = context.current

    if (diagnosticData?.statistics?.lobeoccupancy) {
      const transformedData = Object.entries(
        diagnosticData.statistics.lobeoccupancy
      ).map(([name, value]) => ({ name, value }))

      setMainActorData((prevState) => ({
        ...prevState,
        chartDiagnosticsData: [
          ...prevState.chartDiagnosticsData,
          { name: diagnosticData.name, data: transformedData },
        ],
      }))

      console.log([{ name: diagnosticData.name, data: transformedData }])
    }

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/get_diagnostic_content`,
      {
        ID: patientData.id,
        EID: patientData.eid,
        SID: patientData.sid,
        DID: diagnosticData.id,
      },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer',
      }
    )

    const vtpData = response.data

    const reader = vtkXMLPolyDataReader.newInstance()
    reader.parseAsArrayBuffer(vtpData)

    const polydata = reader.getOutputData()

    const mapper = vtkMapper.newInstance()

    const scalars = polydata.getPointData().getScalars()

    const segColor = []

    if (segColorDictionary[diagnosticData.id]) {
      segColor.push(...segColorDictionary[diagnosticData.id])
    } else {
      segColor.push(
        ...[
          Math.floor(Math.random() * 256) / 255,
          Math.floor(Math.random() * 256) / 255,
          Math.floor(Math.random() * 256) / 255,
        ]
      )
    }

    if (scalars) {
      const lookupTable = vtkColorTransferFunction.newInstance()
      lookupTable.addRGBPoint(0, segColor[0], segColor[1], segColor[2])
      mapper.setLookupTable(lookupTable)
      mapper.setScalarRange(0, 2)
      mapper.setColorModeToMapScalars()
      mapper.setScalarModeToUsePointData()
    }

    mapper.setInputConnection(reader.getOutputPort())

    const actor = vtkActor.newInstance()
    actor.getProperty().setOpacity(1.0)

    if (!scalars) {
      actor.getProperty().setColor(segColor[0], segColor[1], segColor[2])
    }

    setMainActorData((prevState) => ({
      ...prevState,
      subActors: [
        ...prevState.subActors,
        {
          name: diagnosticData.id,
          type: 'diagnostic',
          hasMask: diagnosticData.hasMask,
          segColor,
          seg2DLoaded: false,
          actor,
          isActorVisible: true,
        },
      ],
    }))

    setMessages((prev) => [...prev, `Loaded ${diagnosticData.name}`])

    actor.setMapper(mapper)
    renderer.addActor(actor)

    renderWindow.render()

    return {
      name: diagnosticData.id,
      type: 'diagnostic',
      hasMask: diagnosticData.hasMask,
      segColor,
      seg2DLoaded: false,
      actor,
      isActorVisible: true,
    }
  } catch (error) {
    console.error('Error:', error)
    setToasts((prev) => [
      ...prev,
      +process.env.REACT_APP_PROD_MODE_ENABLED
        ? `An error occured while loading ${diagnosticData.name}.`
        : error.response
          ? `${diagnosticData.name}. Status: ${error.response.status} – ${error.response.statusText}.`
          : `${diagnosticData.name}. ${error.message}.`,
    ])
  }
}

export const renderSegmentation = async (
  params = {
    context: null,
    setMainActorData: null,
    color: null,
    setColor: null,
    patientData: null,
    segData: null,
    setToasts: null,
    isLast: null,
  }
) => {
  const {
    context,
    setMainActorData,
    color,
    setColor,
    patientData,
    segData,
    setToasts,
    isLast,
  } = params
  try {
    const {
      rendererX,
      rendererY,
      rendererZ,
      renderWindow,
      resliceCursorWidget,
      resliceCursorWidgetInstanceX,
      resliceCursorWidgetInstanceY,
      resliceCursorWidgetInstanceZ,
      renderWindowX,
      renderWindowY,
      renderWindowZ,
      resliceActorX,
      resliceActorY,
      resliceActorZ,
      interactorX,
      interactorY,
      interactorZ,
    } = context.current

    // const segResliceCursorWidgetManagerX = vtkWidgetManager.newInstance()
    // const segResliceCursorWidgetManagerY = vtkWidgetManager.newInstance()
    // const segResliceCursorWidgetManagerZ = vtkWidgetManager.newInstance()

    // const segInteractorX = vtkRenderWindowInteractor.newInstance()
    // const segInteractorY = vtkRenderWindowInteractor.newInstance()
    // const segInteractorZ = vtkRenderWindowInteractor.newInstance()

    // interactorX.setView(openglRenderWindowX)
    // interactorY.setView(openglRenderWindowY)
    // interactorZ.setView(openglRenderWindowZ)

    // const response = await axios.get('/assets/stl/LIDC2.vti', {
    //   responseType: 'arraybuffer',
    // })

    console.log(segData)

    const paths = {
      anatomy: {
        true: '/get_anatomy_image_mask_stored',
        false: '/get_anatomy_image_mask',
      },
      diagnostic: {
        true: '/get_diagnostic_image_mask_stored',
        false: '/get_diagnostic_image_mask',
      },
    }

    const path = paths[segData.type]?.[segData.hasMask]

    const response = await axios.post(
      process.env.REACT_APP_SERVER_URL + path,
      {
        ID: patientData.id,
        EID: patientData.eid,
        SID: patientData.sid,
        acquisitiontype: 'CTscan',
        DID: segData.name,
      },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer',
      }
    )

    const segImageData = response.data

    console.log(segImageData)

    const segReader = vtkXMLImageDataReader.newInstance()

    segReader.parseAsArrayBuffer(segImageData)

    const segSource = segReader.getOutputData()

    const segImageRange = segSource.getPointData().getScalars().getRange()

    const segCtfun = vtkColorTransferFunction.newInstance()
    const segOfun = vtkPiecewiseFunction.newInstance()

    console.log(segImageRange)

    const segResliceX = vtkImageReslice.newInstance()
    const segResliceMapperX = vtkImageMapper.newInstance()
    segResliceMapperX.setInputConnection(segResliceX.getOutputPort())
    const segResliceActorX = vtkImageSlice.newInstance()
    segResliceActorX.getProperty().setColorLevel(color.level)
    segResliceActorX.getProperty().setColorWindow(color.window)
    segResliceActorX.setMapper(segResliceMapperX)

    const segResliceY = vtkImageReslice.newInstance()
    const segResliceMapperY = vtkImageMapper.newInstance()
    segResliceMapperY.setInputConnection(segResliceY.getOutputPort())
    const segResliceActorY = vtkImageSlice.newInstance()
    segResliceActorY.getProperty().setColorLevel(color.level)
    segResliceActorY.getProperty().setColorWindow(color.window)
    segResliceActorY.setMapper(segResliceMapperY)

    const segResliceZ = vtkImageReslice.newInstance()
    const segResliceMapperZ = vtkImageMapper.newInstance()
    segResliceMapperZ.setInputConnection(segResliceZ.getOutputPort())
    const segResliceActorZ = vtkImageSlice.newInstance()
    segResliceActorZ.getProperty().setColorLevel(color.level)
    segResliceActorZ.getProperty().setColorWindow(color.window)
    segResliceActorZ.setMapper(segResliceMapperZ)

    console.log(segResliceMapperX)

    segResliceX.setInputData(segSource)
    segResliceY.setInputData(segSource)
    segResliceZ.setInputData(segSource)

    segResliceX.setSlabMode(SlabMode.MAX)
    segResliceY.setSlabMode(SlabMode.MAX)
    segResliceZ.setSlabMode(SlabMode.MAX)

    segResliceX.setInterpolationMode(1)
    segResliceY.setInterpolationMode(1)
    segResliceZ.setInterpolationMode(1)

    segResliceX.setSlabNumberOfSlices(1)
    segResliceY.setSlabNumberOfSlices(1)
    segResliceZ.setSlabNumberOfSlices(1)

    rendererX.addActor(segResliceActorX)
    rendererY.addActor(segResliceActorY)
    rendererZ.addActor(segResliceActorZ)

    console.log(segData.name)
    console.log(isLast)

    if (isLast) {
      interactorX.onAnimation((event) => {
        // console.log(event.firstRenderer.getActors())
        const cl = segResliceActorX.getProperty().getColorLevel()
        const cw = segResliceActorX.getProperty().getColorWindow()

        segResliceActorY.getProperty().setColorLevel(cl)
        segResliceActorY.getProperty().setColorWindow(cw)
        segResliceActorZ.getProperty().setColorLevel(cl)
        segResliceActorZ.getProperty().setColorWindow(cw)
        resliceActorX.getProperty().setColorLevel(cl)
        resliceActorX.getProperty().setColorWindow(cw)
        resliceActorY.getProperty().setColorLevel(cl)
        resliceActorY.getProperty().setColorWindow(cw)
        resliceActorZ.getProperty().setColorLevel(cl)
        resliceActorZ.getProperty().setColorWindow(cw)
        setColor({ level: cl, window: cw })
        renderWindow.render()
        renderWindowY.render()
        renderWindowZ.render()
      })

      interactorY.onAnimation(() => {
        const cl = segResliceActorY.getProperty().getColorLevel()
        const cw = segResliceActorY.getProperty().getColorWindow()

        segResliceActorX.getProperty().setColorLevel(cl)
        segResliceActorX.getProperty().setColorWindow(cw)
        segResliceActorZ.getProperty().setColorLevel(cl)
        segResliceActorZ.getProperty().setColorWindow(cw)
        resliceActorX.getProperty().setColorLevel(cl)
        resliceActorX.getProperty().setColorWindow(cw)
        resliceActorY.getProperty().setColorLevel(cl)
        resliceActorY.getProperty().setColorWindow(cw)
        resliceActorZ.getProperty().setColorLevel(cl)
        resliceActorZ.getProperty().setColorWindow(cw)
        setColor({ level: cl, window: cw })
        renderWindow.render()
        renderWindowX.render()
        renderWindowZ.render()
      })

      interactorZ.onAnimation(() => {
        const cl = segResliceActorZ.getProperty().getColorLevel()
        const cw = segResliceActorZ.getProperty().getColorWindow()

        segResliceActorX.getProperty().setColorLevel(cl)
        segResliceActorX.getProperty().setColorWindow(cw)
        segResliceActorY.getProperty().setColorLevel(cl)
        segResliceActorY.getProperty().setColorWindow(cw)
        resliceActorX.getProperty().setColorLevel(cl)
        resliceActorX.getProperty().setColorWindow(cw)
        resliceActorY.getProperty().setColorLevel(cl)
        resliceActorY.getProperty().setColorWindow(cw)
        resliceActorZ.getProperty().setColorLevel(cl)
        resliceActorZ.getProperty().setColorWindow(cw)
        setColor({ level: cl, window: cw })
        renderWindow.render()
        renderWindowX.render()
        renderWindowY.render()
      })
    }

    const updateResliceStartInteractionEventX =
      resliceCursorWidgetInstanceX.onStartInteractionEvent(() => {
        updateSegReslice({
          resliceCursorWidget,
          viewType: xyzToViewType[0],
          reslice: segResliceX,
          actor: segResliceActorX,
          renderer: rendererX,
          resetFocalPoint: false,
          computeFocalPointOffset: true,
          renderWindow,
        })
      })

    const updateResliceInteractionEventX =
      resliceCursorWidgetInstanceX.onInteractionEvent(
        (interactionMethodName) => {
          const canUpdateFocalPoint =
            interactionMethodName === InteractionMethodsName.RotateLine
          const activeViewType = resliceCursorWidget
            .getWidgetState()
            .getActiveViewType()
          const computeFocalPointOffset =
            activeViewType === xyzToViewType[0] || !canUpdateFocalPoint
          updateSegReslice({
            resliceCursorWidget,
            viewType: xyzToViewType[0],
            reslice: segResliceX,
            actor: segResliceActorX,
            renderer: rendererX,
            resetFocalPoint: false,
            computeFocalPointOffset,
            renderWindow,
          })
        }
      )

    updateSegReslice({
      resliceCursorWidget,
      viewType: xyzToViewType[0],
      reslice: segResliceX,
      actor: segResliceActorX,
      renderer: rendererX,
      resetFocalPoint: true,
      computeFocalPointOffset: true,
      renderWindow,
    })

    const updateResliceStartInteractionEventY =
      resliceCursorWidgetInstanceY.onStartInteractionEvent(() => {
        updateSegReslice({
          resliceCursorWidget,
          viewType: xyzToViewType[1],
          reslice: segResliceY,
          actor: segResliceActorY,
          renderer: rendererY,
          resetFocalPoint: false,
          computeFocalPointOffset: true,
          renderWindow,
        })
      })

    const updateResliceInteractionEventY =
      resliceCursorWidgetInstanceY.onInteractionEvent(
        (interactionMethodName) => {
          const canUpdateFocalPoint =
            interactionMethodName === InteractionMethodsName.RotateLine
          const activeViewType = resliceCursorWidget
            .getWidgetState()
            .getActiveViewType()
          const computeFocalPointOffset =
            activeViewType === xyzToViewType[1] || !canUpdateFocalPoint
          updateSegReslice({
            resliceCursorWidget,
            viewType: xyzToViewType[1],
            reslice: segResliceY,
            actor: segResliceActorY,
            renderer: rendererY,
            resetFocalPoint: false,
            computeFocalPointOffset,
            renderWindow,
          })
        }
      )

    updateSegReslice({
      resliceCursorWidget,
      viewType: xyzToViewType[1],
      reslice: segResliceY,
      actor: segResliceActorY,
      renderer: rendererY,
      resetFocalPoint: true,
      computeFocalPointOffset: true,
      renderWindow,
    })

    const segUpdateResliceStartInteractionEventZ =
      resliceCursorWidgetInstanceZ.onStartInteractionEvent(() => {
        updateSegReslice({
          resliceCursorWidget,
          viewType: xyzToViewType[2],
          reslice: segResliceZ,
          actor: segResliceActorZ,
          renderer: rendererZ,
          resetFocalPoint: false,
          computeFocalPointOffset: true,
          renderWindow,
        })
      })

    const segUpdateResliceInteractionEventZ =
      resliceCursorWidgetInstanceZ.onInteractionEvent(
        (interactionMethodName) => {
          const canUpdateFocalPoint =
            interactionMethodName === InteractionMethodsName.RotateLine
          const activeViewType = resliceCursorWidget
            .getWidgetState()
            .getActiveViewType()
          const computeFocalPointOffset =
            activeViewType === xyzToViewType[2] || !canUpdateFocalPoint
          updateSegReslice({
            resliceCursorWidget,
            viewType: xyzToViewType[2],
            reslice: segResliceZ,
            actor: segResliceActorZ,
            renderer: rendererZ,
            resetFocalPoint: false,
            computeFocalPointOffset,
            renderWindow,
          })
        }
      )

    updateSegReslice({
      resliceCursorWidget,
      viewType: xyzToViewType[2],
      reslice: segResliceZ,
      actor: segResliceActorZ,
      renderer: rendererZ,
      resetFocalPoint: true,
      computeFocalPointOffset: true,
      renderWindow,
    })

    segOfun.addPoint(segImageRange[0], 0)
    segOfun.addPoint(segImageRange[1], 1)

    segCtfun.addRGBPoint(
      0,
      segData.segColor[0],
      segData.segColor[1],
      segData.segColor[2]
    )

    segResliceActorX.getProperty().setRGBTransferFunction(0, segCtfun)
    segResliceActorY.getProperty().setRGBTransferFunction(0, segCtfun)
    segResliceActorZ.getProperty().setRGBTransferFunction(0, segCtfun)
    segResliceActorX.getProperty().setScalarOpacity(0, segOfun)
    segResliceActorY.getProperty().setScalarOpacity(0, segOfun)
    segResliceActorZ.getProperty().setScalarOpacity(0, segOfun)

    if (!segData.isActorVisible) {
      segResliceActorX.setVisibility(false)
      segResliceActorY.setVisibility(false)
      segResliceActorZ.setVisibility(false)
    }

    setMainActorData((prevState) => ({
      ...prevState,
      subActors: prevState.subActors.map((subActor) =>
        subActor.name === segData.name
          ? {
              ...subActor,
              seg2DLoaded: true,
              actor2DX: segResliceActorX,
              actor2DY: segResliceActorY,
              actor2DZ: segResliceActorZ,
            }
          : subActor
      ),
    }))
  } catch (error) {
    console.error('Error:', error)
    setToasts((prev) => [
      ...prev,
      +process.env.REACT_APP_PROD_MODE_ENABLED
        ? `An error occured while loading segmentation.`
        : error.response
          ? `Segmentation. Status: ${error.response.status} – ${error.response.statusText}.`
          : `Segmentation. ${error.message}.`,
    ])
  }
}

export const applyZoomToSlices = (context, vtkParentContainerRef) => {
  // Make slice images to fill remaining space
  const {
    rendererX,
    rendererY,
    rendererZ,
    renderWindowX,
    renderWindowY,
    renderWindowZ,
  } = context.current

  const { width, height } =
    vtkParentContainerRef.current.getBoundingClientRect()

  const cameraX = rendererX.getActiveCamera()
  const cameraY = rendererY.getActiveCamera()
  const cameraZ = rendererZ.getActiveCamera()

  const bounds = rendererX.computeVisiblePropBounds()
  const dim = [
    (bounds[1] - bounds[0]) / 2,
    (bounds[3] - bounds[2]) / 2,
    (bounds[5] - bounds[4]) / 2,
  ]
  const r = width / height

  let x_SliceX = dim[1]
  let y_SliceX = dim[2]

  let x_SliceY = dim[0]
  let y_SliceY = dim[2]

  let x_SliceZ = dim[0]
  let y_SliceZ = dim[1]

  if (r >= x_SliceX / y_SliceX) {
    // use width
    cameraX.setParallelScale(y_SliceX + 1)
  } else {
    // use height
    cameraX.setParallelScale(y_SliceX + 1)
    // cameraX.setParallelScale(x_SliceX / r + 1)
  }

  if (r >= x_SliceY / y_SliceY) {
    // use width
    cameraY.setParallelScale(y_SliceY + 1)
  } else {
    // use height
    cameraY.setParallelScale(x_SliceY / r + 1)
  }

  if (r >= x_SliceZ / y_SliceZ) {
    // use width
    cameraZ.setParallelScale(y_SliceZ + 1)
  } else {
    // use height
    cameraZ.setParallelScale(x_SliceZ / r + 1)
  }

  renderWindowX.render()
  renderWindowY.render()
  renderWindowZ.render()
}

const renderFunctional = async (
  params = {
    context: null,
    setMainActorData: null,
    diagnosticData: null,
    patientData: null,
    setMessages: null,
    setToasts: null,
  }
) => {
  const {
    context,
    setMainActorData,
    diagnosticData,
    patientData,
    setMessages,
    setToasts,
  } = params

  console.log(diagnosticData)

  const {
    interactor,
    renderer,
    renderWindow,
    functionalRenderer,
    functionalRenderWindow,
    functionalOpenglRenderWindow,
    functionalWidgetManager,
    vtkFunctionalContainerRef,
  } = context.current

  const functionalInteractor = vtkRenderWindowInteractor.newInstance()
  functionalInteractor.setView(functionalOpenglRenderWindow)
  functionalInteractor.initialize()
  functionalInteractor.bindEvents(vtkFunctionalContainerRef.current)

  if (diagnosticData.length) {
    try {
      const functionalTrackBallInteractorStyle =
        vtkInteractorStyleTrackballCamera.newInstance()

      functionalInteractor.setInteractorStyle(
        functionalTrackBallInteractorStyle
      )

      functionalWidgetManager.setRenderer(functionalRenderer)

      const functionalActor = vtkVolume.newInstance()
      const functionalMapper = vtkVolumeMapper.newInstance()
      functionalMapper.setSampleDistance(1.1)
      functionalActor.setMapper(functionalMapper)

      const functionalResponse = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/get_functional_as_image`,
        {
          ID: patientData.id,
          EID: patientData.eid,
          SID: patientData.sid,
          DID: diagnosticData[0].id,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'arraybuffer',
        }
      )

      const functionalData = functionalResponse.data

      const functionalReader = vtkXMLImageDataReader.newInstance()

      functionalReader.parseAsArrayBuffer(functionalData)

      functionalMapper.setInputConnection(functionalReader.getOutputPort())

      const functionalSource = functionalReader.getOutputData()

      functionalSource.getPointData().setActiveScalars('ventilation [L/min]')

      const functionalRange = functionalSource
        .getPointData()
        .getScalars()
        .getRange()

      // create color and opacity transfer functions
      const functionalCtfun = vtkColorTransferFunction.newInstance()
      const functionalOfun = vtkPiecewiseFunction.newInstance()

      functionalOfun.removeAllPoints()

      functionalOfun.addPoint(functionalRange[0], 0)
      functionalOfun.addPoint(functionalRange[1], 1)

      functionalActor.getProperty().setScalarOpacity(0, functionalOfun)
      functionalActor.getProperty().setIndependentComponents(false)

      const functionalSliceMapper = vtkImageResliceMapper.newInstance()
      const functionalSlicePlane = vtkPlane.newInstance()
      functionalSlicePlane.setNormal(0, 1, 0)
      functionalSliceMapper.setSlicePlane(functionalSlicePlane)

      const functionalSlicePolyDataSource = vtkCylinderSource.newInstance({
        height: 100,
        radius: 100,
        resolution: 20,
        capping: 1,
        center: [100, 100, 100],
      })

      const functionalSliceActor = vtkImageSlice.newInstance()
      functionalSliceActor.setMapper(functionalSliceMapper)
      functionalSliceActor.getProperty().setColorLevel(0)
      functionalSliceActor.getProperty().setColorWindow(1)

      const functionalPlaneWidget = vtkPlaneWidget.newInstance()
      functionalPlaneWidget.getWidgetState().setNormal(0, 0, 1)
      functionalPlaneWidget.setPlaceFactor(1)

      functionalSliceMapper.setInputData(functionalSource)
      const bds = functionalSource.getBounds()
      const imc = functionalSource.getCenter()
      const spacing = functionalSource.getSpacing()
      functionalSlicePlane.setOrigin(imc)
      functionalSlicePolyDataSource.setCenter(imc)
      functionalPlaneWidget.placeWidget(bds)

      const functionalPlaneState = functionalPlaneWidget.getWidgetState()
      functionalPlaneState.setOrigin(functionalSlicePlane.getOrigin())
      functionalPlaneState.setNormal(functionalSlicePlane.getNormal())
      functionalPlaneState.onModified(() => {
        functionalSlicePlane.setOrigin(functionalPlaneState.getOrigin())
        functionalSlicePlane.setNormal(functionalPlaneState.getNormal())
        functionalSlicePolyDataSource.setCenter(
          functionalPlaneState.getOrigin()
        )
        functionalSlicePolyDataSource.setDirection(
          functionalPlaneState.getNormal()
        )
        if (functionalSliceMapper.getSlicePolyData()) {
          functionalSlicePolyDataSource.update()
          functionalSliceMapper.setSlicePolyData(
            functionalSlicePolyDataSource.getOutputData()
          )
        }
      })

      functionalSliceActor.getProperty().setScalarOpacity(0, functionalOfun)

      functionalRenderer.addActor(functionalSliceActor)
      const w = functionalWidgetManager.addWidget(functionalPlaneWidget)

      const repStyle = {
        active: {
          plane: {
            opacity: 0.0,
            color: [1, 1, 1],
          },
          normal: {
            opacity: 1,
            color: [1, 1, 1],
          },
          origin: {
            opacity: 1,
            color: [1, 1, 1],
          },
        },
        inactive: {
          plane: {
            opacity: 0.0,
            color: [1, 1, 1],
          },
          normal: {
            opacity: 0.5,
            color: [1, 1, 1],
          },
          origin: {
            opacity: 0.5,
            color: [1, 1, 1],
          },
        },
      }

      w.setRepresentationStyle(repStyle)

      // For better looking volume rendering
      // - distance in world coordinates a scalar opacity of 1.0
      functionalActor
        .getProperty()
        .setScalarOpacityUnitDistance(
          0,
          vtkBoundingBox.getDiagonalLength(functionalSource.getBounds()) /
            Math.max(...functionalSource.getDimensions())
        )

      const outline = vtkOutlineFilter.newInstance()
      outline.setInputData(functionalSource)
      const outlineMapper = vtkMapper.newInstance()
      outlineMapper.setInputData(outline.getOutputData())
      const outlineActor = vtkActor.newInstance()
      outlineActor.setMapper(outlineMapper)
      functionalRenderer.addActor(outlineActor)

      const functionalScalarBarActor = generateScalarBar(
        'ventilation [L/min]',
        functionalRenderer
      )

      if (
        functionalSource.getPointData().setActiveVectors('velocity [m/s]') !==
        -1
      ) {
        functionalSource.getPointData().setActiveVectors('velocity [m/s]')

        const lineSource = vtkLineSource.newInstance()

        lineSource.setResolution(50)
        lineSource.setPoint1(bds[0], bds[2], bds[4])
        lineSource.setPoint2(bds[1], bds[3], bds[5])

        const sphereSource = vtkSphereSource.newInstance()

        sphereSource.setThetaResolution(10)
        sphereSource.setPhiResolution(10)
        sphereSource.setRadius(30)
        sphereSource.setCenter(
          0.5 * (bds[0] + bds[1]),
          0.5 * (bds[2] + bds[3]),
          0.5 * (bds[4] + bds[5])
        )

        const pointCloud = vtkPointSource.newInstance()

        pointCloud.setNumberOfPoints(25)
        pointCloud.setCenter(sphereSource.getCenter())
        pointCloud.setRadius(sphereSource.getRadius())

        const sline = vtkImageStreamline.newInstance()

        sline.setIntegrationStep(0.2)
        sline.setInputConnection(functionalReader.getOutputPort())
        sline.setInputConnection(lineSource.getOutputPort(), 1)

        const slineMapper = vtkMapper.newInstance()

        slineMapper.setInputConnection(sline.getOutputPort())

        const slineActor = vtkActor.newInstance()
        slineActor.setMapper(slineMapper)
        slineActor.getProperty().set({
          diffuseColor: [0, 1, 1],
          lineWidth: 2,
        })
        functionalRenderer.addActor(slineActor)

        const slineSeedMapper = vtkMapper.newInstance()
        slineSeedMapper.setInputConnection(lineSource.getOutputPort())

        const slineSeedActor = vtkActor.newInstance()
        slineSeedActor.setMapper(slineSeedMapper)

        slineSeedActor.getProperty().set({
          representation: Representation.SURFACE,
        })

        functionalRenderer.addActor(slineSeedActor)

        const sphereMapper = vtkMapper.newInstance()
        sphereMapper.setInputConnection(sphereSource.getOutputPort())

        const sphereActor = vtkActor.newInstance()
        sphereActor.setMapper(sphereMapper)

        sphereActor.getProperty().set({
          representation: Representation.WIREFRAME,
        })

        functionalRenderer.addActor(sphereActor)

        sphereActor.setVisibility(false)

        slineActor.setVisibility(false)
        slineSeedActor.setVisibility(false)

        const lengths = [bds[1] - bds[0], bds[3] - bds[2], bds[5] - bds[4]]
        const maxLength = Math.max(...lengths)
        const maxRadius = Math.round(maxLength / 2)

        setMainActorData((prevState) => ({
          ...prevState,
          hasFunctional: true,
          functionalActor: {
            ...prevState.functionalActor,
            bds: bds,
            spacing: spacing,
            streamlinesLinePositionP1: { x: bds[0], y: bds[2], z: bds[4] },
            streamlinesLinePositionP2: { x: bds[1], y: bds[3], z: bds[5] },
            stremalinesSphereMaxRadius: maxRadius,
          },
        }))

        context.current = {
          ...context.current,
          lineSource,
          sphereSource,
          pointCloud,
          sline,
          slineMapper,
          slineSeedMapper,
          slineActor,
          sphereActor,
          slineSeedActor,
        }
      } else {
        setMainActorData((prevState) => ({
          ...prevState,
          hasFunctional: true,
          functionalActor: {
            ...prevState.functionalActor,
            isStreamlinesVisible: null,
          },
        }))
      }

      functionalRenderer.addActor(functionalActor)

      const fp = renderer.getActiveCamera().getFocalPoint()
      const p = renderer.getActiveCamera().getPosition()
      const v = renderer.getActiveCamera().getViewUp()
      const cr = renderer.getActiveCamera().getClippingRange()

      functionalRenderer.getActiveCamera().setFocalPoint(fp[0], fp[1], fp[2])
      functionalRenderer.getActiveCamera().setPosition(p[0], p[1], p[2])
      functionalRenderer.getActiveCamera().setViewUp(v[0], v[1], v[2])
      functionalRenderer.getActiveCamera().setClippingRange(cr[0], cr[1])

      interactor.onAnimation(() => {
        const fp = renderer.getActiveCamera().getFocalPoint()
        const p = renderer.getActiveCamera().getPosition()
        const v = renderer.getActiveCamera().getViewUp()
        const cr = renderer.getActiveCamera().getClippingRange()

        functionalRenderer.getActiveCamera().setFocalPoint(fp[0], fp[1], fp[2])
        functionalRenderer.getActiveCamera().setPosition(p[0], p[1], p[2])
        functionalRenderer.getActiveCamera().setViewUp(v[0], v[1], v[2])
        functionalRenderer.getActiveCamera().setClippingRange(cr[0], cr[1])

        functionalRenderWindow.render()
      })

      functionalInteractor.onAnimation(() => {
        const fp = functionalRenderer.getActiveCamera().getFocalPoint()
        const p = functionalRenderer.getActiveCamera().getPosition()
        const v = functionalRenderer.getActiveCamera().getViewUp()
        const cr = functionalRenderer.getActiveCamera().getClippingRange()

        renderer.getActiveCamera().setFocalPoint(fp[0], fp[1], fp[2])
        renderer.getActiveCamera().setPosition(p[0], p[1], p[2])
        renderer.getActiveCamera().setViewUp(v[0], v[1], v[2])
        renderer.getActiveCamera().setClippingRange(cr[0], cr[1])

        renderWindow.render()
      })

      context.current = {
        ...context.current,
        functionalActor,
        functionalSource,
        functionalOfun,
        functionalCtfun,
        functionalPlaneWidget,
        functionalSliceActor,
        functionalReader,
        functionalRenderWindow,
        functionalScalarBarActor,
      }

      await loadJsonForFunctional(
        '/assets/json/luts/CoolToWarmExtended.json',
        context,
        false,
        []
      )

      functionalRenderer.resetCameraClippingRange()
      functionalRenderer.resetCamera()
      functionalRenderWindow.render()

      setMessages((prev) => [...prev, `Loaded ${diagnosticData[0].name}`])
    } catch (error) {
      console.error('Error:', error)
      setToasts((prev) => [
        ...prev,
        +process.env.REACT_APP_PROD_MODE_ENABLED
          ? `An error occured while loading ${diagnosticData[0].name}.`
          : error.response
            ? `${diagnosticData[0].name}. Status: ${error.response.status} – ${error.response.statusText}.`
            : `${diagnosticData[0].name}. ${error.message}.`,
      ])
    }
  }

  context.current = {
    ...context.current,
    functionalInteractor,
  }
}

const getRadiologyMeta = async (patientData, setMainActorData, setToasts) => {
  if (patientData) {
    try {
      const metaResponse = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/get_radiology_meta`,
        {
          ID: patientData.id,
          EID: patientData.eid,
          SID: patientData.sid,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      const metaData = metaResponse.data

      setMainActorData((prevState) => ({
        ...prevState,
        radiologyMetaData: {
          patient: metaData.PatientName
            ? metaData.PatientName.trim()
            : 'No data',
          patientID: metaData.PatientID ? metaData.PatientID.trim() : 'No data',
          patientBirthDate: metaData.PatientBirthDate
            ? metaData.PatientBirthDate.trim().replace(
                /(\d{4})(\d{2})(\d{2})/,
                '$1-$2-$3'
              )
            : 'No data',
          patientSex: metaData.PatientSex
            ? metaData.PatientSex.trim()
            : 'No data',
          patientAge: metaData.PatientAge
            ? metaData.PatientAge.trim().replace(/^0*(\d+)Y$/, '$1Y')
            : 'No data',
          patientPosition: metaData.PatientPosition
            ? metaData.PatientPosition.trim()
            : 'No data',
          manufacturer: metaData.Manufacturer
            ? metaData.Manufacturer.trim()
            : 'No data',
          manufacturerModelName: metaData.ManufacturerModelName
            ? metaData.ManufacturerModelName.trim()
            : 'No data',
          acquisitionDate: metaData.AcquisitionDate
            ? metaData.AcquisitionDate.trim().replace(
                /(\d{4})(\d{2})(\d{2})/,
                '$1-$2-$3'
              )
            : 'No data',
          acquisitionTime: metaData.AcquisitionTime
            ? metaData.AcquisitionTime.trim().replace(
                /(\d{2})(\d{2})(\d{2})\..*/,
                '$1:$2:$3'
              )
            : 'No data',
          modality: metaData.Modality ? metaData.Modality.trim() : 'No data',
          seriesDescription: metaData.SeriesDescription
            ? metaData.SeriesDescription.trim()
            : 'No data',
          sliceThickness: metaData.SliceThickness
            ? parseFloat(metaData.SliceThickness.trim())
            : 'No data',
        },
      }))
    } catch (error) {
      console.error('Error:', error)
      setToasts((prev) => [
        ...prev,
        +process.env.REACT_APP_PROD_MODE_ENABLED
          ? `An error occured while loading metadata.`
          : error.response
            ? `Metadata. Status: ${error.response.status} – ${error.response.statusText}.`
            : `Metadata. ${error.message}.`,
      ])
    }
  }
}

const renderMeasurementsFromDB = (
  context,
  sliceContainer,
  measure,
  measuresData,
  setSelectedWidgetIndex,
  setActiveWindow,
  currentWidgetPosition
) => {
  if (measure.type === 'ruler') {
    getRulerFromDB(
      context,
      sliceContainer,
      measure,
      measuresData,
      setSelectedWidgetIndex,
      setActiveWindow,
      currentWidgetPosition
    )
  } else if (measure.type === 'annotation') {
    getAnnotationFromDB(
      context,
      sliceContainer,
      measure,
      measuresData,
      setSelectedWidgetIndex,
      setActiveWindow,
      currentWidgetPosition
    )
  } else if (measure.type === 'ellipse') {
    getEllipseFromDB(
      context,
      sliceContainer,
      measure,
      measuresData,
      setSelectedWidgetIndex,
      setActiveWindow,
      currentWidgetPosition
    )
  } else if (measure.type === 'circle') {
    getCircleFromDB(
      context,
      sliceContainer,
      measure,
      measuresData,
      setSelectedWidgetIndex,
      setActiveWindow,
      currentWidgetPosition
    )
  }
}

const clearImageData = (context, measuresData, setView) => {
  if (context.current) {
    const {
      renderer,
      rendererX,
      rendererY,
      rendererZ,
      interactor,
      functionalInteractor,
      functionalRenderer,
      interactorX,
      interactorY,
      interactorZ,
      widgetManager,
      functionalWidgetManager,
      updateResliceStartInteractionEventX,
      updateResliceStartInteractionEventY,
      updateResliceStartInteractionEventZ,
      updateResliceInteractionEventX,
      updateResliceInteractionEventY,
      updateResliceInteractionEventZ,
      pointerLocationTrackingX,
      pointerLocationTrackingY,
      pointerLocationTrackingZ,
      measuresWidgetManagerX,
      measuresWidgetManagerY,
      measuresWidgetManagerZ,
      scissorsWidgetManager,
    } = context.current

    setView('Morpho')

    renderer.removeAllVolumes()
    renderer.removeAllActors()
    if (functionalRenderer) {
      functionalRenderer.removeAllVolumes()
      functionalRenderer.removeAllActors()
    }
    rendererX.removeAllActors()
    rendererY.removeAllActors()
    rendererZ.removeAllActors()

    // if (updateResliceStartInteractionEventX && updateResliceStartInteractionEventY && updateResliceStartInteractionEventZ) {
    updateResliceStartInteractionEventX.unsubscribe()
    updateResliceStartInteractionEventY.unsubscribe()
    updateResliceStartInteractionEventZ.unsubscribe()
    updateResliceInteractionEventX.unsubscribe()
    updateResliceInteractionEventY.unsubscribe()
    updateResliceInteractionEventZ.unsubscribe()
    pointerLocationTrackingX.unsubscribe()
    pointerLocationTrackingY.unsubscribe()
    pointerLocationTrackingZ.unsubscribe()
    // }

    // if (interactorX && interactorY && interactorZ) {
    interactor.unbindEvents()
    functionalInteractor.unbindEvents()
    interactorX.unbindEvents()
    interactorY.unbindEvents()
    interactorZ.unbindEvents()
    // }

    widgetManager.removeWidgets()
    if (functionalWidgetManager.getWidgets().length) {
      functionalWidgetManager.removeWidgets()
    }
    // if (scissorsWidgetManager) {
    scissorsWidgetManager.removeWidgets()
    // }

    // if (
    //   measuresWidgetManagerX &&
    //   measuresWidgetManagerY &&
    //   measuresWidgetManagerZ
    // ) {
    measuresWidgetManagerX.removeWidgets()
    measuresWidgetManagerY.removeWidgets()
    measuresWidgetManagerZ.removeWidgets()

    measuresData.current.x.forEach((measure) => {
      measure.node.remove()
    })
    measuresData.current.y.forEach((measure) => {
      measure.node.remove()
    })
    measuresData.current.z.forEach((measure) => {
      measure.node.remove()
    })

    measuresData.current = { x: [], y: [], z: [] }
    // }
  }
}

export const renderImageStructure = async (
  params = {
    context: null,
    imageData: null,
    imageName: 'imageName',
    vtkContainerRef: null,
    vtkFunctionalContainerRef: null,
    vtkParentContainerRef: null,
    sliceContainerX: null,
    sliceContainerY: null,
    sliceContainerZ: null,
    setXSlice: null,
    setYSlice: null,
    setZSlice: null,
    setSliderMaxX: null,
    setSliderMaxY: null,
    setSliderMaxZ: null,
    setCurrentWidgetPosition: null,
    color: null,
    setColor: null,
    setSpacings: null,
    mainActorData: null,
    setMainActorData: null,
    measuresData: null,
    patientData: null,
    setMessages: null,
    setToasts: null,
    setView: null,
    setSelectedWidgetIndex: null,
    setActiveWindow: null,
  }
) => {
  const {
    context,
    imageData,
    imageName,
    vtkContainerRef,
    vtkFunctionalContainerRef,
    vtkParentContainerRef,
    sliceContainerX,
    sliceContainerY,
    sliceContainerZ,
    setXSlice,
    setYSlice,
    setZSlice,
    setSliderMaxX,
    setSliderMaxY,
    setSliderMaxZ,
    setCurrentWidgetPosition,
    color,
    setColor,
    setCoordinates,
    setSpacings,
    measuresData,
    mainActorData,
    setMainActorData,
    patientData,
    setMessages,
    setToasts,
    setView,
    setSelectedWidgetIndex,
    setActiveWindow,
  } = params

  if (!vtkContainerRef.current.children.length) {
    console.log('INITIAL')
    renderInitialImageStructure({
      context,
      vtkParentContainerRef,
      vtkFunctionalContainerRef,
      vtkContainerRef,
      sliceContainerX,
      sliceContainerY,
      sliceContainerZ,
    })
  } else {
    console.log('RE-RENDER')
    clearImageData(context, measuresData, setView)
  }

  const { source } = render3DImage({
    context,
    imageData,
    imageName,
    setMainActorData,
  })

  const currentPosition = render2DPlanes({
    context,
    source,
    vtkParentContainerRef,
    setSpacings,
    color,
    setColor,
    setCoordinates,
    setXSlice,
    setYSlice,
    setZSlice,
    setSliderMaxX,
    setSliderMaxY,
    setSliderMaxZ,
    setCurrentWidgetPosition,
  })

  const presetRes = await loadJson(
    `/assets/json/organs/Lungs.json`,
    `/assets/json/luts/CoolToWarmExtended.json`,
    context
  )

  setMainActorData((prevState) => ({
    ...prevState,
    info: {
      ...prevState.info,
      visualContrast: presetRes.visualContrast,
      presetLevel: presetRes.presetLevel,
      window: presetRes.window,
    },
  }))

  const anatomyList = await getAnatomyList(patientData, setToasts)

  if (anatomyList.length) {
    const anatomyPromises = anatomyList.map((anatomy) =>
      renderAnatomy({
        context,
        setMainActorData,
        color,
        anatomyData: anatomy,
        patientData,
        setMessages,
        setToasts,
      })
    )

    await Promise.all(anatomyPromises)

    // renderSegmentation
    // const segmentationPromises = anatomyList.map((anatomy) =>
    //   renderSegmentation({
    //     context,
    //     color,
    //     patientData,
    //     diagnosticData: anatomy,
    //     setToasts,
    //     type: 'anatomy',
    //   })
    // )
    // await Promise.all(segmentationPromises)
  }

  const diagnostics = await getDiagnosticsList(patientData, setToasts)

  if (diagnostics.morpho.length) {
    const dignoticsPromises = diagnostics.morpho.map((diagnostic) =>
      renderDiagnostics({
        context,
        mainActorData,
        setMainActorData,
        diagnosticData: diagnostic,
        patientData,
        setMessages,
        setToasts,
      })
    )

    await Promise.all(dignoticsPromises)

    // renderSegmentation
    // const segmentationPromises = diagnostics.morpho.map((diagnostic) =>
    //   renderSegmentation({
    //     context,
    //     color,
    //     patientData,
    //     diagnosticData: diagnostic,
    //     setToasts,
    //     type: 'diagnostic',
    //   })
    // )
    // await Promise.all(segmentationPromises)
  }

  // if ([...anatomyList, ...diagnostics.morpho].length) {
  //   const segmentationPromises = mainActorData.subActors.map((subActor) =>
  //     renderSegmentation({
  //       context,
  //       setMainActorData,
  //       color,
  //       patientData,
  //       segData: subActor,
  //       setToasts,
  //     })
  //   )
  //   await Promise.all(segmentationPromises)
  // }

  applyZoomToSlices(context, vtkParentContainerRef)

  await renderFunctional({
    context,
    setMainActorData,
    diagnosticData: diagnostics.functional,
    patientData,
    setMessages,
    setToasts,
  })

  await getRadiologyMeta(patientData, setMainActorData, setToasts)

  await generateInfo(source, setMainActorData, patientData, setToasts)

  const response = await axios.post(
    process.env.REACT_APP_SERVER_URL + '/get_series_measures_list',
    {
      ID: patientData.id,
      EID: patientData.eid,
      AID: patientData.aid,
      SID: patientData.sid,
    },
    {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'json',
    }
  )

  const measuresList = response.data
  console.log(measuresList)

  if (measuresList.length) {
    for (const measure of measuresList) {
      const sliceContainer =
        measure.plane === 'x'
          ? sliceContainerX
          : measure.plane === 'y'
            ? sliceContainerY
            : sliceContainerZ
      renderMeasurementsFromDB(
        context,
        sliceContainer,
        measure,
        measuresData,
        setSelectedWidgetIndex,
        setActiveWindow,
        currentPosition
      )
    }
  }
}

const renderInitialXRayStructure = (
  params = {
    context: null,
    vtkParentContainerRef: null,
    sliceContainerZ: null,
  }
) => {
  const { context, vtkParentContainerRef, sliceContainerZ } = params

  const { width, height } =
    vtkParentContainerRef.current.getBoundingClientRect()
  const containerZ = sliceContainerZ.current
  const renderWindowZ = vtkRenderWindow.newInstance()

  const rendererZ = vtkRenderer.newInstance({
    background: [30 / 255, 30 / 255, 30 / 255],
  })

  const openglRenderWindowZ = vtkOpenGLRenderWindow.newInstance()
  openglRenderWindowZ.setSize(width - 36, height - 36)

  renderWindowZ.addRenderer(rendererZ)

  renderWindowZ.addView(openglRenderWindowZ)

  openglRenderWindowZ.setContainer(containerZ)

  context.current = {
    ...context.current,
    containerZ,
    renderWindow: renderWindowZ,
    renderWindowZ,
    openglRenderWindowZ,
    rendererZ,
  }
}

const renderXRay = (
  params = {
    context: null,
    imageData: null,
    imageName: 'imageName',
    setMainActorData: null,
    setColor: null,
    setCurrentWidgetPosition: null,
  }
) => {
  const {
    context,
    imageData,
    imageName,
    setMainActorData,
    setColor,
    setCurrentWidgetPosition,
  } = params

  const { containerZ, rendererZ, openglRenderWindowZ, renderWindowZ } =
    context.current

  const ctfun = vtkColorTransferFunction.newInstance()

  const reader = vtkXMLImageDataReader.newInstance()

  reader.parseAsArrayBuffer(imageData)

  const source = reader.getOutputData()

  const resliceCursorWidget = vtkResliceCursorWidget.newInstance()

  resliceCursorWidget
    .getWidgetState()
    .getStatesWithLabel('handles')
    .forEach((handle) => handle.setOpacity(0))

  resliceCursorWidget.setImage(source)

  const resliceCursorWidgetManagerZ = vtkWidgetManager.newInstance()

  const interactorZ = vtkRenderWindowInteractor.newInstance()

  interactorZ.setView(openglRenderWindowZ)

  interactorZ.initialize()

  interactorZ.bindEvents(containerZ)

  const iStyleZ = vtkInteractorStyleImage.newInstance()
  iStyleZ.setInteractionMode('IMAGE_SLICING')

  interactorZ.setInteractorStyle(iStyleZ)

  resliceCursorWidgetManagerZ.setRenderer(rendererZ)

  const resliceCursorWidgetInstanceZ = resliceCursorWidgetManagerZ.addWidget(
    resliceCursorWidget,
    xyzToViewType[2]
  )

  const resliceZ = vtkImageReslice.newInstance()
  const resliceMapperZ = vtkImageMapper.newInstance()
  resliceMapperZ.setInputConnection(resliceZ.getOutputPort())
  const resliceActorZ = vtkImageSlice.newInstance()
  const cl = resliceActorZ.getProperty().getColorLevel()
  const cw = resliceActorZ.getProperty().getColorWindow()
  setColor({ level: cl, window: cw })
  resliceActorZ.setMapper(resliceMapperZ)

  resliceZ.setInputData(source)

  resliceZ.setSlabMode(SlabMode.MAX)

  resliceZ.setInterpolationMode(1)

  resliceZ.setSlabNumberOfSlices(1)

  const imageScalarBarActorZ = generateScalarBar('', rendererZ)

  rendererZ.addActor(resliceActorZ)

  interactorZ.onAnimation(() => {
    const cl = resliceActorZ.getProperty().getColorLevel()
    const cw = resliceActorZ.getProperty().getColorWindow()
    setColor({ level: cl, window: cw })
  })

  const currentPosition = updateReslice({
    resliceCursorWidget,
    viewType: xyzToViewType[2],
    reslice: resliceZ,
    actor: resliceActorZ,
    renderer: rendererZ,
    resetFocalPoint: true,
    computeFocalPointOffset: true,
    renderWindow: renderWindowZ,
    setCurrentWidgetPosition,
  }).currentPosition
  interactorZ.render()

  const cameraZ = rendererZ.getActiveCamera()

  cameraZ.setParallelProjection(true)

  const position = cameraZ.getPosition()
  const focalPoint = cameraZ.getFocalPoint()
  const viewUp = cameraZ.getViewUp()

  const newPos = [
    2 * focalPoint[0] - position[0],
    2 * focalPoint[1] - position[1],
    2 * focalPoint[2] - position[2],
  ]

  cameraZ.setPosition(...newPos)
  cameraZ.setViewUp(-viewUp[0], -viewUp[1], -viewUp[2])
  cameraZ.modified()

  rendererZ.resetCamera()

  const measuresWidgetManagerZ = vtkWidgetManager.newInstance()

  measuresWidgetManagerZ.setRenderer(rendererZ)

  renderWindowZ.render()

  context.current = {
    ...context.current,
    ctfun,
    source,
    resliceActorZ,
    resliceZ,
    resliceCursorWidget,
    resliceCursorWidgetInstanceZ,
    interactorZ,
    resliceCursorWidgetManagerZ,
    measuresWidgetManagerZ,
    imageScalarBarActorZ,
  }

  setMainActorData({
    name: imageName,
    actor: resliceActorZ,
    isSubMenuOpen: true,
    isActorVisible: true,
    isPlanesVisible: {
      xyz: true,
      x: true,
      y: true,
      z: true,
      rotatable: false,
    },
    activeModal: null,
    reportText: '',
    isCameraParallelProjection: false,
    subActors: [],
    anatomyData: [],
    chartDiagnosticsData: [],
    radiologyMetaData: {},
    hasFunctional: false,
    functionalActor: {
      dataArray: 'ventilation [L/min]',
      colorMap: 'CoolToWarmExtended',
      bds: [],
      spacing: [],
      isModalActive: false,
      isVisible: true,
      isLogScaleEnabled: false,
      logRange: [],
      isPlaneVisible: true,
      isStreamlinesVisible: false,
      streamlinesRepresentation: 'Line',
      isStreamlinesLineVisible: true,
      isStreamlinesSphereVisible: true,
      streamlinesSpherePositionCoef: { x: 0.5, y: 0.5, z: 0.5 },
      streamlinesSpherePoints: 25,
      streamlinesSphereRadius: 30,
      stremalinesSphereMaxRadius: 200,
      streamlinesLinePositionP1: { x: 0, y: 0, z: 0 },
      streamlinesLinePositionP2: { x: 0, y: 0, z: 0 },
    },
  })

  return currentPosition
}

export const applyZoomToXRay = (context, vtkParentContainerRef) => {
  const { rendererZ, renderWindowZ } = context.current

  const { width, height } =
    vtkParentContainerRef.current.getBoundingClientRect()

  const cameraZ = rendererZ.getActiveCamera()

  const bounds = rendererZ.computeVisiblePropBounds()
  const dim = [
    (bounds[1] - bounds[0]) / 2,
    (bounds[3] - bounds[2]) / 2,
    (bounds[5] - bounds[4]) / 2,
  ]
  const r = width / height

  let x_SliceZ = dim[0]
  let y_SliceZ = dim[1]

  if (r >= x_SliceZ / y_SliceZ) {
    // use width
    cameraZ.setParallelScale(y_SliceZ + 1)
  } else {
    // use height
    cameraZ.setParallelScale(x_SliceZ / r + 1)
  }

  renderWindowZ.render()
}

export const renderXRayStructure = async (
  params = {
    context: null,
    imageData: null,
    imageName: 'imageName',
    vtkParentContainerRef: null,
    sliceContainerZ: null,
    setCurrentWidgetPosition: null,
    color: null,
    setColor: null,
    mainActorData: null,
    setMainActorData: null,
    measuresData: null,
    patientData: null,
    setMessages: null,
    setToasts: null,
    setSelectedWidgetIndex: null,
    setActiveWindow: null,
  }
) => {
  const {
    context,
    imageData,
    imageName,
    vtkParentContainerRef,
    sliceContainerZ,
    setZSlice,
    setCurrentWidgetPosition,
    color,
    setColor,
    measuresData,
    mainActorData,
    setMainActorData,
    patientData,
    setMessages,
    setToasts,
    setSelectedWidgetIndex,
    setActiveWindow,
  } = params

  // if (!sliceContainerZ.current.children.length) {
  console.log('INITIAL')
  renderInitialXRayStructure({
    context,
    vtkParentContainerRef,
    sliceContainerZ,
  })
  // } else {
  //   console.log('RE-RENDER')
  //   clearImageData(context, measuresData, setView)
  // }

  const currentPosition = renderXRay({
    context,
    imageData,
    imageName,
    setMainActorData,
    setColor,
    vtkParentContainerRef,
    setCurrentWidgetPosition,
  })

  await loadXRayJson(
    `/assets/json/organs/Lungs.json`,
    `/assets/json/luts/XRay.json`,
    context
  )

  applyZoomToXRay(context, vtkParentContainerRef)

  // // const presetRes = await loadJson(
  // //   `/assets/json/organs/Lungs.json`,
  // //   `/assets/json/luts/CoolToWarmExtended.json`,
  // //   context
  // // )

  // // setMainActorData((prevState) => ({
  // //   ...prevState,
  // //   info: {
  // //     ...prevState.info,
  // //     visualContrast: presetRes.visualContrast,
  // //     presetLevel: presetRes.presetLevel,
  // //     window: presetRes.window,
  // //   },
  // // }))

  // const anatomyList = await getAnatomyList(patientData, setToasts)

  // if (anatomyList.length) {
  //   const anatomyPromises = anatomyList.map((anatomy) =>
  //     renderAnatomy({
  //       context,
  //       setMainActorData,
  //       color,
  //       anatomyData: anatomy,
  //       patientData,
  //       setMessages,
  //       setToasts,
  //     })
  //   )

  //   await Promise.all(anatomyPromises)

  //   // renderSegmentation
  //   // const segmentationPromises = anatomyList.map((anatomy) =>
  //   //   renderSegmentation({
  //   //     context,
  //   //     color,
  //   //     patientData,
  //   //     diagnosticData: anatomy,
  //   //     setToasts,
  //   //     type: 'anatomy',
  //   //   })
  //   // )
  //   // await Promise.all(segmentationPromises)
  // }

  // const diagnostics = await getDiagnosticsList(patientData, setToasts)

  // if (diagnostics.morpho.length) {
  //   const dignoticsPromises = diagnostics.morpho.map((diagnostic) =>
  //     renderDiagnostics({
  //       context,
  //       mainActorData,
  //       setMainActorData,
  //       diagnosticData: diagnostic,
  //       patientData,
  //       setMessages,
  //       setToasts,
  //     })
  //   )

  //   await Promise.all(dignoticsPromises)

  //   // renderSegmentation
  //   // const segmentationPromises = diagnostics.morpho.map((diagnostic) =>
  //   //   renderSegmentation({
  //   //     context,
  //   //     color,
  //   //     patientData,
  //   //     diagnosticData: diagnostic,
  //   //     setToasts,
  //   //     type: 'diagnostic',
  //   //   })
  //   // )
  //   // await Promise.all(segmentationPromises)
  // }

  // // if ([...anatomyList, ...diagnostics.morpho].length) {
  // //   const segmentationPromises = mainActorData.subActors.map((subActor) =>
  // //     renderSegmentation({
  // //       context,
  // //       setMainActorData,
  // //       color,
  // //       patientData,
  // //       segData: subActor,
  // //       setToasts,
  // //     })
  // //   )
  // //   await Promise.all(segmentationPromises)
  // // }

  // applyZoomToSlices(context, vtkParentContainerRef)

  // await renderFunctional({
  //   context,
  //   setMainActorData,
  //   diagnosticData: diagnostics.functional,
  //   patientData,
  //   setMessages,
  //   setToasts,
  // })

  // await getRadiologyMeta(patientData, setMainActorData, setToasts)

  // await generateInfo(source, setMainActorData, patientData, setToasts)

  const response = await axios.post(
    process.env.REACT_APP_SERVER_URL + '/get_series_measures_list',
    {
      ID: patientData.id,
      EID: patientData.eid,
      AID: patientData.aid,
      SID: patientData.sid,
    },
    {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'json',
    }
  )

  const measuresList = response.data

  if (measuresList.length) {
    for (const measure of measuresList) {
      const sliceContainer = sliceContainerZ
      renderMeasurementsFromDB(
        context,
        sliceContainer,
        measure,
        measuresData,
        setSelectedWidgetIndex,
        setActiveWindow,
        currentPosition
      )
    }
  }
}
