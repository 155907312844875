import React, { useContext } from 'react'

import { PdfContext } from '../../contexts/PdfContext'

import A4Page from './a4Page'

/**
 * PDF report component which consists of one or more A4 sheets.
 *
 * @component
 * @param {Object} props.mainActorData - object with actors data
 * @returns {JSX.Element} The rendered PDF report component.
 */
const PdfReport = ({ mainActorData }) => {
  const { pdfRef } = useContext(PdfContext)

  const splitDataIntoChunks = (array, firstChunkSize, chunkSize) => {
    const result = []
    for (
      let i = 0;
      i < array.length;
      i === 0 ? (i += firstChunkSize) : (i += chunkSize)
    ) {
      result.push(array.slice(i, i === 0 ? i + firstChunkSize : i + chunkSize))
      console.log(result)
    }
    return result
  }

  return (
    <div ref={pdfRef}>
      {mainActorData ? (
        splitDataIntoChunks(
          mainActorData.chartDiagnosticsData,
          mainActorData.anatomyData.length ? 6 - 1 : 6,
          6
        ).length ? (
          splitDataIntoChunks(
            mainActorData.chartDiagnosticsData,
            mainActorData.anatomyData.length ? 6 - 1 : 6,
            6
          ).map((innerArray, index) => (
            <A4Page
              key={index}
              mainActorData={mainActorData}
              isFirstPage={index === 0}
              data={innerArray}
            />
          ))
        ) : (
          <A4Page mainActorData={mainActorData} isFirstPage={true} data={[]} />
        )
      ) : (
        ''
      )}
    </div>
  )
}

export default PdfReport
