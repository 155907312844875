import React from 'react'

import { Scale } from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction/Constants'

import Checkbox from '../../../../ui/Checkbox'
import { loadJsonForFunctional } from '../../../../../utils/imagePresets'

/**
 * Checkbox to control the scale mode
 *
 * @component
 * @param {Object | null} props.context - main storage for all vtk.js components. If the image was not loaded in the application, than equal to null.
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered checkbox component.
 */
const LogScaleButton = ({ context, mainActorData, setMainActorData }) => {
  const handleLogScale = async () => {
    if (context.current) {
      setMainActorData((prevState) => ({
        ...prevState,
        functionalActor: {
          ...prevState.functionalActor,
          isLogScaleEnabled: !mainActorData.functionalActor.isLogScaleEnabled,
        },
      }))

      if (context.current) {
        const {
          functionalWidgetManager,
          functionalRenderer,
          functionalActor,
          functionalSource,
          functionalCtfun,
          functionalOfun,
          functionalPlaneWidget,
          functionalSliceActor,
          functionalScalarBarActor,
        } = context.current
        if (
          functionalSource
            .getPointData()
            .setActiveScalars(
              'log ' + mainActorData.functionalActor.dataArray
            ) !== -1
        ) {
          setMainActorData((prevState) => ({
            ...prevState,
            functionalActor: {
              ...prevState.functionalActor,
              isLogScaleEnabled:
                !mainActorData.functionalActor.isLogScaleEnabled,
            },
          }))
          console.log('yes')
          functionalRenderer.removeActor(functionalSliceActor)
          functionalWidgetManager.removeWidget(functionalPlaneWidget)

          functionalSource
            .getPointData()
            .setActiveScalars(
              mainActorData.functionalActor.isLogScaleEnabled
                ? mainActorData.functionalActor.dataArray
                : 'log ' + mainActorData.functionalActor.dataArray
            )

          const functionalRange = functionalSource
            .getPointData()
            .getScalars()
            .getRange()

          functionalOfun.removeAllPoints()

          const newRange = []

          if (mainActorData.functionalActor.isLogScaleEnabled) {
            functionalOfun.addPoint(functionalRange[0], 0)
            functionalOfun.addPoint(functionalRange[1], 1)
            functionalCtfun.setRange(functionalRange[0], functionalRange[1])
          } else {
            functionalOfun.addPoint(functionalRange[0], 0)
            const midpoint = (functionalRange[0] + functionalRange[1]) / 2
            // functionalOfun.addPoint(0.26094311475753784, 0)
            // functionalOfun.addPoint(midpoint + 2.6, 0)
            // functionalOfun.addPoint(midpoint + 2.61, 0.65)
            functionalOfun.addPoint(
              midpoint +
                Math.abs(functionalRange[1] - functionalRange[0]) * 0.196,
              0
            )
            functionalOfun.addPoint(
              midpoint +
                Math.abs(functionalRange[1] - functionalRange[0]) * 0.1961,
              0.65
            )
            // functionalOfun.addPoint(
            //   midpoint +
            //     Math.abs(functionalRange[1] - functionalRange[0]) * 0.215,
            //   0
            // )
            // functionalOfun.addPoint(
            //   midpoint +
            //     Math.abs(functionalRange[1] - functionalRange[0]) * 0.2151,
            //   0.65
            // )
            functionalOfun.addPoint(functionalRange[1], 1)
            functionalCtfun.setRange(-1, functionalRange[1])
            newRange.push(...functionalCtfun.getRange())
            setMainActorData((prevState) => ({
              ...prevState,
              functionalActor: {
                ...prevState.functionalActor,
                logRange: newRange,
              },
            }))
          }

          functionalScalarBarActor.setAxisLabel(
            mainActorData.functionalActor.isLogScaleEnabled
              ? mainActorData.functionalActor.dataArray
              : 'log ' + mainActorData.functionalActor.dataArray
          )
          functionalScalarBarActor.setScalarsToColors(functionalCtfun)

          functionalActor.getProperty().setScalarOpacity(0, functionalOfun)
          functionalActor.getProperty().setIndependentComponents(false)

          // functionalSliceActor.getProperty().setScalarOpacity(0, functionalOfun)
          // functionalSliceActor
          //   .getProperty()
          //   .setRGBTransferFunction(0, functionalCtfun)

          if (mainActorData.functionalActor.isPlaneVisible) {
            functionalRenderer.addActor(functionalSliceActor)
            const w = functionalWidgetManager.addWidget(functionalPlaneWidget)

            const repStyle = {
              active: {
                plane: {
                  opacity: 0.0,
                  color: [1, 1, 1],
                },
                normal: {
                  opacity: 1,
                  color: [1, 1, 1],
                },
                origin: {
                  opacity: 1,
                  color: [1, 1, 1],
                },
              },
              inactive: {
                plane: {
                  opacity: 0.0,
                  color: [1, 1, 1],
                },
                normal: {
                  opacity: 0.5,
                  color: [1, 1, 1],
                },
                origin: {
                  opacity: 0.5,
                  color: [1, 1, 1],
                },
              },
            }

            w.setRepresentationStyle(repStyle)
          }

          console.log(functionalCtfun.getRange())

          loadJsonForFunctional(
            `/assets/json/luts/${mainActorData.functionalActor.colorMap}.json`,
            context,
            !mainActorData.functionalActor.isLogScaleEnabled,
            newRange
          )
        } else {
          setMainActorData((prevState) => ({
            ...prevState,
            functionalActor: {
              ...prevState.functionalActor,
              isLogScaleEnabled: false,
            },
          }))
          console.log('no')
          functionalSource
            .getPointData()
            .setActiveScalars(mainActorData.functionalActor.dataArray)
        }
      }
    }
  }

  return (
    <Checkbox
      id="logScale"
      isChecked={mainActorData.functionalActor.isLogScaleEnabled}
      setIsChecked={handleLogScale}
    >
      LogScale
    </Checkbox>
  )
}

export default LogScaleButton
