import React from 'react'

import clsx from 'clsx'

/**
 * UI dropdown menu component.
 *
 * @component
 * @param {Object} props.menuRef - dropdown menu ref object
 * @param {string} props.caption - dropdown menu caption
 * @param {boolean} props.isMenuOpen - state that determines whether the menu is open
 * @param {function} props.setIsMenuOpen - function for managing the isMenuOpen state
 * @param {Array} props.optionsList - array list with dropdown options
 * @param {string} props.currentOption - current option.
 * @param {function} props.setNewOption - function for changing the current option
 * @returns {JSX.Element} The rendered view dropdown menu component.
 */
const Dropdown = ({
  menuRef,
  caption,
  isMenuOpen,
  setIsMenuOpen,
  optionsList,
  currentOption,
  setNewOption,
}) => {
  return (
    <div className="relative">
      <div className="text-sm laptop:text-base desktop:text-lg leading-7 laptop:leading-7 desktop:leading-7 text-center font-bold truncate">
        {caption}
      </div>
      <div ref={menuRef}>
        <button
          className="relative py-1.5 pl-[16px] pr-[25px] w-full text-sm desktop:text-base text-left bg-blue mt-[1px]
          rounded-[39px] appearance-none cursor-pointer truncate
          shadow-[4px_4px_10px_0px_#00000040]"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {currentOption}
          <img
            className={clsx(
              'absolute bottom-[11px] desktop:bottom-[13px] right-[12px] cursor-pointer pointer-events-none',
              {
                'rotate-180': isMenuOpen,
              }
            )}
            src="/assets/images/arrow-select.svg"
            alt=""
          />
        </button>
        <ul
          className={clsx(
            'absolute bg-blue w-full max-h-[260px] overflow-y-scroll rounded-md top-[110%] z-10 shadow-[4px_4px_10px_0px_#00000040]',
            {
              hidden: !isMenuOpen,
            }
          )}
          onClick={(e) => e.stopPropagation()}
        >
          {optionsList.map((item, index) => (
            <li
              key={item.text}
              className={clsx(
                'flex items-center text-sm desktop:text-base hover:bg-blue-hover',
                {
                  'rounded-t-md': index === 0,
                  'rounded-b-md': index === optionsList.length - 1,
                }
              )}
            >
              {currentOption === item.text && (
                <img
                  className="left-[1px] absolute pointer-events-none"
                  src="/assets/images/checkmark.svg"
                  alt=""
                />
              )}
              <button
                className={clsx('text-left pl-[16px] pr-[5px] py-1 w-full', {
                  'flex items-center gap-1': item.image,
                })}
                onClick={setNewOption}
              >
                {item.image && (
                  <img
                    src={item.image}
                    className="w-[18px] pointer-events-none"
                    alt=""
                  />
                )}
                <p className="truncate">{item.text}</p>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Dropdown
