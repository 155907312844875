import React, { useRef, useContext } from 'react'
import axios from 'axios'

import { GViewContext } from '../../contexts/GViewContext'
import { MeasuresContext } from '../../contexts/MeasuresContext'
import { PatientContext } from '../../contexts/PatientContext'
import { FileContext } from '../../contexts/FileContext'

import useOutsideClick from '../../hooks/useOutsideClick'

/**
 * A modal window is invoked by right-clicking on any of the three 2D planes, provided that at least one measurement exists on this plane. Currently, its functionality is limited to removing unnecessary measurements. In the future, it also will be used to add text labels to existing measures.
 *
 * @component
 * @param {string} props.plane - name of the plane for which the modal window is invoked (x, y, z)
 * @param {Object} props.measuresWidgetManager - object with current measures (unique for each plane)
 * @returns {JSX.Element} The rendered right button modal window component.
 */
const RightButtonMenu = ({ plane, measuresWidgetManager }) => {
  const {
    isRightButtonMenuVisible,
    setIsRightButtonMenuVisible,
    menuPosition,
  } = useContext(GViewContext)
  const { measuresData, selectedWidgetIndex, setSelectedWidgetIndex } =
    useContext(MeasuresContext)
  const { patientData } = useContext(PatientContext)
  const { setToasts } = useContext(FileContext)

  const menuRef = useRef(null)

  const deleteSelectedWidget = async () => {
    if (selectedWidgetIndex[plane] != null) {
      const measure = measuresData.current[plane][selectedWidgetIndex[plane]]
      console.log(measure)
      if (measure.id) {
        try {
          await axios.post(
            process.env.REACT_APP_SERVER_URL + '/delete_series_measure',
            {
              ID: patientData.id,
              EID: patientData.eid,
              AID: patientData.aid,
              SID: patientData.sid,
              MID: measure.id,
            },
            {
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
              },
              responseType: 'json',
            }
          )
        } catch (error) {
          console.error('Error:', error)
          setToasts((prev) => [
            ...prev,
            +process.env.REACT_APP_PROD_MODE_ENABLED
              ? 'An error occured while deleting a remote measure.'
              : error.response
                ? `Delete remote measure. Status: ${error.response.status} – ${error.response.statusText}.`
                : `Delete remote measure. ${error.message}.`,
          ])
        }
      }

      measure.node.remove()
      measuresWidgetManager.removeWidget(measure.widget)
      const updatedMeasuresData = [...measuresData.current[plane]]
      updatedMeasuresData.splice(selectedWidgetIndex[plane], 1)
      measuresData.current = {
        ...measuresData.current,
        [plane]: updatedMeasuresData,
      }
    }

    setSelectedWidgetIndex({ x: null, y: null, z: null })
    setIsRightButtonMenuVisible({
      x: false,
      y: false,
      z: false,
    })
  }

  // const addLabel = () => {
  //   if (selectedWidgetIndex[plane] != null) {
  //     measuresData.current[plane] = measuresData.current[plane].map(
  //       (measure, i) =>
  //         i === selectedWidgetIndex[plane]
  //           ? { ...measure, ...{ label: `Updated label ${i}` } }
  //           : measure
  //     )
  //   }
  //   setIsRightButtonMenuVisible({
  //     x: false,
  //     y: false,
  //     z: false,
  //   })
  // }

  useOutsideClick({
    dependency: isRightButtonMenuVisible.x,
    ref: menuRef,
    setClosed: setIsRightButtonMenuVisible,
    setter: { x: false, y: false, z: false },
  })
  useOutsideClick({
    dependency: isRightButtonMenuVisible.y,
    ref: menuRef,
    setClosed: setIsRightButtonMenuVisible,
    setter: { x: false, y: false, z: false },
  })
  useOutsideClick({
    dependency: isRightButtonMenuVisible.z,
    ref: menuRef,
    setClosed: setIsRightButtonMenuVisible,
    setter: { x: false, y: false, z: false },
  })

  return (
    <div
      ref={menuRef}
      style={{ left: menuPosition.x, top: menuPosition.y }}
      className="fixed flex flex-col justify-center items-center shadow-[4px_4px_10px_0px_#00000040] rounded-md bg-mid-blue z-10"
    >
      <div className="flex flex-col justify-start whitespace-nowrap">
        <button
          className="text-sm text-left p-2 border-b-[1px] rounded-t-md border-pale-blue hover:bg-blue-hover"
          onClick={deleteSelectedWidget}
        >
          Delete measurement
        </button>
        <button
          className="text-sm text-left p-2 hover:bg-blue-hover rounded-b-md"
          // onClick={addLabel}
        >
          Add label
        </button>
      </div>
    </div>
  )
}

export default RightButtonMenu
