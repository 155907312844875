import React from 'react'

import Checkbox from '../../../../ui/Checkbox'

/**
 * Checkbox to control the visibility and invisibility of streamlines
 *
 * @component
 * @param {Object | null} props.context - main storage for all vtk.js components. If the image was not loaded in the application, than equal to null.
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered checkbox component.
 */
const StreamlinesVisibilityButton = ({
  context,
  mainActorData,
  setMainActorData,
}) => {
  const handleStreamlinesVisibility = () => {
    if (context.current) {
      const {
        slineActor,
        slineSeedActor,
        sphereActor,
        functionalRenderWindow,
      } = context.current
      if (mainActorData.functionalActor.streamlinesRepresentation === 'Line') {
        setMainActorData((prevState) => ({
          ...prevState,
          functionalActor: {
            ...prevState.functionalActor,
            isStreamlinesVisible:
              !mainActorData.functionalActor.isStreamlinesVisible,
            isStreamlinesLineVisible: true,
          },
        }))
        slineActor.setVisibility(
          !mainActorData.functionalActor.isStreamlinesVisible
        )
        slineSeedActor.setVisibility(
          !mainActorData.functionalActor.isStreamlinesVisible
        )
      } else {
        setMainActorData((prevState) => ({
          ...prevState,
          functionalActor: {
            ...prevState.functionalActor,
            isStreamlinesVisible:
              !mainActorData.functionalActor.isStreamlinesVisible,
            isStreamlinesSphereVisible: true,
          },
        }))
        sphereActor.setVisibility(
          !mainActorData.functionalActor.isStreamlinesVisible
        )
        slineActor.setVisibility(
          !mainActorData.functionalActor.isStreamlinesVisible
        )
        slineSeedActor.setVisibility(false)
      }

      functionalRenderWindow.render()
    }
  }

  return (
    <Checkbox
      id="streamlines"
      isChecked={mainActorData.functionalActor.isStreamlinesVisible}
      setIsChecked={handleStreamlinesVisibility}
    >
      Streamlines
    </Checkbox>
  )
}

export default StreamlinesVisibilityButton
