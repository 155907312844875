import React, { useState } from 'react'

import NumberInputWithButtons from '../../../../ui/NumberInputWithButtons'

/**
 * Control panel for streamlines in sphere representation
 *
 * @component
 * @param {Object | null} props.context - main storage for all vtk.js components. If the image was not loaded in the application, than equal to null.
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered control panel component.
 */
const SphereControlPanel = ({ context, mainActorData, setMainActorData }) => {
  const [points, setPoints] = useState(
    mainActorData.functionalActor.streamlinesSpherePoints
  )
  const [radius, setRadius] = useState(
    mainActorData.functionalActor.streamlinesSphereRadius
  )

  const handleSphereXChange = (e) => {
    if (context.current) {
      const {
        sphereSource,
        pointCloud,
        functionalSource,
        functionalRenderWindow,
      } = context.current

      const bds = functionalSource.getBounds()

      setMainActorData((prevState) => ({
        ...prevState,
        functionalActor: {
          ...prevState.functionalActor,
          streamlinesSpherePositionCoef: {
            ...prevState.functionalActor.streamlinesSpherePositionCoef,
            x: +e.target.value,
          },
        },
      }))

      const center = [
        bds[0] + +e.target.value * (bds[1] - bds[0]),
        bds[2] +
          mainActorData.functionalActor.streamlinesSpherePositionCoef.y *
            (bds[3] - bds[2]),
        bds[4] +
          mainActorData.functionalActor.streamlinesSpherePositionCoef.z *
            (bds[5] - bds[4]),
      ]

      sphereSource.setCenter(center[0], center[1], center[2])
      pointCloud.setCenter(center[0], center[1], center[2])

      functionalRenderWindow.render()
    }
  }
  const handleSphereYChange = (e) => {
    if (context.current) {
      const {
        sphereSource,
        pointCloud,
        functionalSource,
        functionalRenderWindow,
      } = context.current

      const bds = functionalSource.getBounds()

      setMainActorData((prevState) => ({
        ...prevState,
        functionalActor: {
          ...prevState.functionalActor,
          streamlinesSpherePositionCoef: {
            ...prevState.functionalActor.streamlinesSpherePositionCoef,
            y: +e.target.value,
          },
        },
      }))

      const center = [
        bds[0] +
          mainActorData.functionalActor.streamlinesSpherePositionCoef.x *
            (bds[1] - bds[0]),
        bds[2] + +e.target.value * (bds[3] - bds[2]),
        bds[4] +
          mainActorData.functionalActor.streamlinesSpherePositionCoef.z *
            (bds[5] - bds[4]),
      ]

      sphereSource.setCenter(center[0], center[1], center[2])
      pointCloud.setCenter(center[0], center[1], center[2])

      functionalRenderWindow.render()
    }
  }
  const handleSphereZChange = (e) => {
    if (context.current) {
      const {
        sphereSource,
        pointCloud,
        functionalSource,
        functionalRenderWindow,
      } = context.current

      const bds = functionalSource.getBounds()

      setMainActorData((prevState) => ({
        ...prevState,
        functionalActor: {
          ...prevState.functionalActor,
          streamlinesSpherePositionCoef: {
            ...prevState.functionalActor.streamlinesSpherePositionCoef,
            z: +e.target.value,
          },
        },
      }))

      const center = [
        bds[0] +
          mainActorData.functionalActor.streamlinesSpherePositionCoef.x *
            (bds[1] - bds[0]),
        bds[2] +
          mainActorData.functionalActor.streamlinesSpherePositionCoef.y *
            (bds[3] - bds[2]),
        bds[4] + +e.target.value * (bds[5] - bds[4]),
      ]

      sphereSource.setCenter(center[0], center[1], center[2])
      pointCloud.setCenter(center[0], center[1], center[2])

      functionalRenderWindow.render()
    }
  }

  const handleSpherePoints = (value) => {
    if (context.current) {
      const { pointCloud, functionalRenderWindow } = context.current
      if (value) {
        console.log(value)
        setMainActorData((prevState) => ({
          ...prevState,
          functionalActor: {
            ...prevState.functionalActor,
            streamlinesSpherePoints: value,
          },
        }))
        pointCloud.setNumberOfPoints(value)
      } else {
        console.log(points)
        setMainActorData((prevState) => ({
          ...prevState,
          functionalActor: {
            ...prevState.functionalActor,
            streamlinesSpherePoints: points,
          },
        }))
        pointCloud.setNumberOfPoints(points)
      }

      functionalRenderWindow.render()
    }
  }
  const handleSphereRadius = (value) => {
    if (context.current) {
      const { sphereSource, pointCloud, functionalRenderWindow } =
        context.current

      if (value) {
        console.log(value)
        setMainActorData((prevState) => ({
          ...prevState,
          functionalActor: {
            ...prevState.functionalActor,
            streamlinesSphereRadius: value,
          },
        }))
        sphereSource.setRadius(value)
        pointCloud.setRadius(value)
      } else {
        console.log(radius)
        setMainActorData((prevState) => ({
          ...prevState,
          functionalActor: {
            ...prevState.functionalActor,
            streamlinesSphereRadius: radius,
          },
        }))
        sphereSource.setRadius(radius)
        pointCloud.setRadius(radius)
      }

      functionalRenderWindow.render()
    }
  }

  return (
    <>
      <div>Center:</div>
      <div className="flex gap-2">
        <input
          className="w-full"
          type="range"
          min="0.1"
          max="0.9"
          step="0.025"
          value={mainActorData.functionalActor.streamlinesSpherePositionCoef.x}
          onChange={(e) => handleSphereXChange(e)}
        />
        <input
          className="w-full"
          type="range"
          min="0.1"
          max="0.9"
          step="0.025"
          value={mainActorData.functionalActor.streamlinesSpherePositionCoef.y}
          onChange={(e) => handleSphereYChange(e)}
        />
        <input
          className="w-full"
          type="range"
          min="0.1"
          max="0.9"
          step="0.025"
          value={mainActorData.functionalActor.streamlinesSpherePositionCoef.z}
          onChange={(e) => handleSphereZChange(e)}
        />
      </div>
      <div className="flex gap-3 mt-1">
        <div className="flex w-[49%] justify-between items-center">
          <div className="">Points:</div>
          <NumberInputWithButtons
            value={points}
            setValue={setPoints}
            min={1}
            max={1000}
            handleUpdate={handleSpherePoints}
          />
        </div>
        <div className="flex w-[49%] justify-between items-center">
          <div className="">Radius:</div>
          <NumberInputWithButtons
            value={radius}
            setValue={setRadius}
            min={1}
            max={mainActorData.functionalActor.stremalinesSphereMaxRadius}
            handleUpdate={handleSphereRadius}
          />
        </div>
      </div>
    </>
  )
}

export default SphereControlPanel
