import clsx from 'clsx'
import React from 'react'

/**
 * Anatomy item
 *
 * @component
 * @param {Array} props.anatomyData - anatomy data array
 * @returns {JSX.Element} The rendered anatomy item component.
 */
const DiagnosticsItemAnatomy = ({ anatomyData }) => {
  return (
    <div className="h-full inset-0 backdrop-blur-lg bg-pale-blue/30 p-1 laptop:p-2 desktop:p-3 rounded-lg shadow-[4px_4px_10px_0px_#00000040]">
      <h3 className="font-bold text-center leading-tight text-sm laptop:text-base desktop:text-lg mb-[5px] desktop:mb-[10px]">
        Anatomy
      </h3>
      <div className="gap-1 h-[calc(100%-22px)] laptop:h-[calc(100%-34px)] desktop:h-[calc(100%-43px)]">
        <div className="w-full h-full">
          <div className="h-full laptop:mt-[10px] desktop:mt-[15px] overflow-y-scroll rounded-lg border border-semi-transparent">
            <table className="max-w-full w-full h-full divide-y divide-semi-transparent">
              <thead className="bg-blue-hover sticky top-0 z-10 after:content-[''] after:block after:w-full after:h-[1px] after:bg-semi-transparent after:absolute after:bottom-0">
                <tr>
                  <th
                    scope="row"
                    className="px-0.5 laptop:px-1 py-1 laptop:py-2 text-center text-xs desktop:text-sm leading-none laptop:leading-tight font-semibold tracking-wider"
                  >
                    Item
                  </th>
                  <th className="px-0.5 laptop:px-1 py-1 laptop:py-2 text-center text-xs desktop:text-sm leading-none laptop:leading-tight font-semibold tracking-wider">
                    Volume
                  </th>
                  <th className="px-0.5 laptop:px-1 py-1 laptop:py-2 text-center text-xs desktop:text-sm leading-none laptop:leading-tight font-semibold tracking-wider">
                    Fraction
                  </th>
                </tr>
              </thead>
              <tbody className="bg-blue divide-y divide-semi-transparent">
                {anatomyData.map((anatomyItem, index) => (
                  <tr
                    key={index}
                    className={clsx('', {
                      'bg-blue-hover': index % 2,
                    })}
                  >
                    <td className="px-0.5 laptop:px-1 py-0.5 laptop:py-1 text-center text-xs desktop:text-sm leading-none laptop:leading-tight font-semibold tracking-wider">
                      {anatomyItem.name}
                    </td>
                    <td className="px-0.5 laptop:px-1 py-0.5 laptop:py-1 text-center text-xs desktop:text-sm leading-none laptop:leading-tight">
                      {anatomyItem.volume ? anatomyItem.volume.toFixed(1) : '–'}
                    </td>
                    <td className="px-0.5 laptop:px-1 py-0.5 laptop:py-1 text-center text-xs desktop:text-sm leading-none laptop:leading-tight">
                      {anatomyItem.volumeFraction
                        ? anatomyItem.volumeFraction.toFixed(1)
                        : '–'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiagnosticsItemAnatomy
