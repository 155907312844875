import React, { useContext, useState } from 'react'

import { TaxonomyContext } from '../contexts/TaxonomyContext'

import DraggableModalSm from './modals/DraggableModalSm'
import ButtonWithTooltip from './ui/ButtonWithTooltip'
import clsx from 'clsx'

/**
 * Toolbar for generics
 *
 * @component
 * @returns {JSX.Element} The rendered toobar for generics component.
 */
const ToolbarGenerics = () => {
  const [isAboutActive, setIsAboutActive] = useState(false)
  const { isTaxonomyActive, setIsTaxonomyActive } = useContext(TaxonomyContext)

  return (
    <ul className="w-[16%] h-full px-[13px] desktop:px-[20px] py-[13px] flex justify-between items-center gap-2 bg-mid-blue rounded-[10px] shadow-[4px_4px_10px_0px_#00000040]">
      <li className="h-full">
        <ButtonWithTooltip
          className="relative h-full flex flex-col items-center gap-px"
          tooltipText="Taxonomy"
          onClick={() => setIsTaxonomyActive(!isTaxonomyActive)}
        >
          <div className="h-1/2 flex items-center">
            <img
              className="w-[20px] laptop:w-auto"
              src={
                isTaxonomyActive
                  ? '/assets/images/taxonomy_active.svg'
                  : '/assets/images/taxonomy.svg'
              }
              alt=""
            />
          </div>
          <p
            className={clsx(
              'max-h-1/2 text-xs laptop:text-sm desktop:text-base leading-[18px] laptop:leading-[18px] desktop:leading-[18px]',
              {
                'text-bright-blue': isTaxonomyActive,
              }
            )}
          >
            Taxo
            <br />
            nomy
          </p>
        </ButtonWithTooltip>
      </li>
      <li className="h-full">
        <ButtonWithTooltip
          className="relative h-full flex flex-col items-center gap-px"
          tooltipText="About"
          onClick={() => setIsAboutActive(!isAboutActive)}
        >
          <div className="h-1/2 flex items-center">
            <img
              className="w-[20px] laptop:w-auto"
              src={
                isAboutActive
                  ? '/assets/images/about_active.svg'
                  : '/assets/images/about.svg'
              }
              alt=""
            />
          </div>
          <p
            className={clsx(
              'max-h-1/2 text-xs laptop:text-sm desktop:text-base leading-[18px] laptop:leading-[18px] desktop:leading-[18px]',
              {
                'text-bright-blue': isAboutActive,
              }
            )}
          >
            About
          </p>
        </ButtonWithTooltip>
        {isAboutActive && (
          <DraggableModalSm handleClose={() => setIsAboutActive(false)}>
            <h2 className="h-[36px] py-2 px-4 leading-tight text-md bg-blue rounded-t-2xl cursor-default shadow-[4px_4px_10px_0px_#00000040]">
              About
            </h2>
            <div className="flex flex-col gap-4 p-4 w-full h-[calc(100%-36px)] text-center">
              <p>WeResp v0.1.0</p>
              <p>CardioRespiratory Diagnostic Aid</p>
              <p>MedLea Srl</p>
              <p>All rights reserved [2024]</p>
              <img
                className="w-[200px] mx-auto my-0"
                src="assets/images/MedLeaLogo.png"
                alt=""
              />
            </div>
          </DraggableModalSm>
        )}
      </li>
      <li className="h-full">
        <ButtonWithTooltip className="relative h-full flex flex-col items-center gap-px pointer-events-none cursor-default">
          <div className="h-1/2 flex items-center">
            <img
              className="w-[20px] laptop:w-auto"
              src="/assets/images/exit_disabled.svg"
              alt=""
            />
          </div>
          <p className="max-h-1/2 text-xs laptop:text-sm desktop:text-base leading-[18px] laptop:leading-[18px] desktop:leading-[18px] text-semi-transparent">
            Exit
          </p>
        </ButtonWithTooltip>
      </li>
    </ul>
  )
}

export default ToolbarGenerics
