import React, { useContext } from 'react'
import axios from 'axios'
import { format } from 'date-fns'

import { FileContext } from '../../../../contexts/FileContext'
import { PatientContext } from '../../../../contexts/PatientContext'

/**
 * Button for lock and unlock a measure in the database
 *
 * @component
 * @param {Object} props.measure - object with data corresponding to the measure
 * @param {number} props.index - index of the measure
 * @param {string} props.plane - string with plane name
 * @param {Object} props.measuresData - object with all measures
 * @param {function} props.setSelectedWidgetIndex - function that a function that resets the selected measure
 * @returns {JSX.Element} The rendered button component.
 */

const LockMeasureButton = ({
  measure,
  plane,
  index,
  measuresData,
  setSelectedWidgetIndex,
}) => {
  const { patientData } = useContext(PatientContext)
  const { setToasts } = useContext(FileContext)

  const handleLock = async () => {
    if (!measuresData.current[plane][index].isLocked) {
      try {
        const updatedDate = format(new Date(), 'dd-MM-yyyy HH:mm:ss')

        const measureData = {
          createdAt: measure.createdAt,
          updatedAt: updatedDate,
          label: measure.label,
          type: measure.type,
          plane,
          slice: measure.slice,
          position: measure.position,
          handle1:
            measure.type === 'ruler' || measure.type === 'annotation'
              ? measure.widget.getState().widgetState.handle1.origin
              : measure.widget.getState().widgetState.point1Handle.origin,
          handle2:
            measure.type === 'ruler' || measure.type === 'annotation'
              ? measure.widget.getState().widgetState.handle2.origin
              : measure.widget.getState().widgetState.point2Handle.origin,
          text: measure.widget.getState().widgetState.text.text,
        }

        const response = await axios.post(
          process.env.REACT_APP_SERVER_URL + '/insert_series_measure',
          {
            ID: patientData.id,
            EID: patientData.eid,
            AID: patientData.aid,
            SID: patientData.sid,
            record: measureData,
            MID: measure.id,
          },
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
            responseType: 'json',
          }
        )

        const data = response.data
        console.log(data)
        console.log(measureData)

        measuresData.current[plane][index].id = data.MID
        measuresData.current[plane][index].updatedAt = updatedDate
      } catch (error) {
        console.error('Error:', error)
        setToasts((prev) => [
          ...prev,
          +process.env.REACT_APP_PROD_MODE_ENABLED
            ? 'An error occured while saving a measure.'
            : error.response
              ? `Save measure. Status: ${error.response.status} – ${error.response.statusText}.`
              : `Save measure. ${error.message}.`,
        ])
      }
    }
    measuresData.current[plane][index].isLocked =
      !measuresData.current[plane][index].isLocked

    measuresData.current[plane][index].widget.setEnabled(
      !measuresData.current[plane][index].isLocked
    )

    setSelectedWidgetIndex({ x: null, y: null, z: null })
  }

  return (
    <button
      className="flex items-center justify-center min-w-[16px] desktop:min-w-[20px]"
      onClick={handleLock}
    >
      <img
        className="h-[16px] desktop:h-[20px]"
        src={
          measure.isLocked
            ? '/assets/images/lock.svg'
            : '/assets/images/unlock.svg'
        }
        alt=""
      />
    </button>
  )
}

export default LockMeasureButton
