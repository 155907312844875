import React, { useContext, useRef, useState } from 'react'

import clsx from 'clsx'

import { ActionsTreeContext } from '../../contexts/ActionsTreeContext'
import { MeasuresContext } from '../../contexts/MeasuresContext'
import { PatientContext } from '../../contexts/PatientContext'

import ActionsTreeMainItem from './ActionsTreeMainItem/ActionsTreeMainItem'
import ActionsTreeSubItem from './ActionsTreeSubItem/ActionsTreeSubItem'
import ActionsTreeFunctionalItem from './ActionsTreeFunctionalItem/ActionsTreeFunctionalItem'
import ActionsTreeMeasureItem from './ActionsTreeMeasureItem/ActionsTreeMeasureItem'

/**
 * For viewing scene components (image and solid data) in the form of a tree structure
 *
 * @component
 * @returns {JSX.Element} The rendered ActionsTree component.
 */
const ActionsTree = () => {
  const { patientData } = useContext(PatientContext)
  const { mainActorData, setMainActorData } = useContext(ActionsTreeContext)
  const { measuresData, setSelectedWidgetIndex } = useContext(MeasuresContext)

  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipPos, setTooltipPos] = useState({ top: 0, left: 0 })
  const [tooltipData, setTooltipData] = useState({
    createdAt: '',
    updatedAt: '',
    plane: '',
    slice: '',
  })
  const timerRef = useRef(null)

  return (
    <section className="w-full max-h-[55%] h-full bg-mid-blue rounded-[10px] shadow-[4px_4px_10px_0px_#00000040]">
      <h3 className="text-base desktop:text-lg text-center font-bold bg-blue rounded-[10px] py-[8px] shadow-[4px_4px_10px_0px_#00000040]">
        Actions
      </h3>
      {mainActorData && (
        <div className="h-[calc(100%-60px)] desktop:h-[calc(100%-64px)] px-[13px] desktop:px-[20px] my-[10px] overflow-y-auto">
          <ActionsTreeMainItem
            mainActorData={mainActorData}
            setMainActorData={setMainActorData}
          />
          <ul
            className={clsx('ml-[13px] desktop:ml-[20px]', {
              hidden: !mainActorData.isSubMenuOpen,
            })}
          >
            {mainActorData.hasFunctional && (
              <ActionsTreeFunctionalItem
                mainActorData={mainActorData}
                setMainActorData={setMainActorData}
              />
            )}
            {mainActorData.subActors &&
              mainActorData.subActors.map((actor, index) => (
                <ActionsTreeSubItem
                  key={actor.name}
                  index={index}
                  subActorData={actor}
                  setMainActorData={setMainActorData}
                />
              ))}
            {measuresData.current.x &&
              measuresData.current.x.map((measure, index) => (
                <ActionsTreeMeasureItem
                  key={index}
                  index={index}
                  measure={measure}
                  plane="x"
                  measuresData={measuresData}
                  setSelectedWidgetIndex={setSelectedWidgetIndex}
                  setShowTooltip={setShowTooltip}
                  timerRef={timerRef}
                  setTooltipPos={setTooltipPos}
                  setTooltipData={setTooltipData}
                />
              ))}
            {measuresData.current.y &&
              measuresData.current.y.map((measure, index) => (
                <ActionsTreeMeasureItem
                  key={index}
                  index={index}
                  measure={measure}
                  plane="y"
                  measuresData={measuresData}
                  setSelectedWidgetIndex={setSelectedWidgetIndex}
                  setShowTooltip={setShowTooltip}
                  timerRef={timerRef}
                  setTooltipPos={setTooltipPos}
                  setTooltipData={setTooltipData}
                />
              ))}
            {measuresData.current.z &&
              measuresData.current.z.map((measure, index) => (
                <ActionsTreeMeasureItem
                  key={index}
                  index={index}
                  measure={measure}
                  plane="z"
                  measuresData={measuresData}
                  setSelectedWidgetIndex={setSelectedWidgetIndex}
                  setShowTooltip={setShowTooltip}
                  timerRef={timerRef}
                  setTooltipPos={setTooltipPos}
                  setTooltipData={setTooltipData}
                />
              ))}
          </ul>
        </div>
      )}
      {measuresData && (
        <div
          className={clsx(
            'absolute  py-0.5 px-1 text-xs laptop:text-sm rounded-md bg-black backdrop-filter backdrop-blur-sm transition-opacity duration-300 whitespace-nowrap pointer-events-none z-10',
            { 'opacity-1': showTooltip, 'opacity-0': !showTooltip }
          )}
          style={{ top: tooltipPos.top, left: tooltipPos.left }}
        >
          <p>Created: {tooltipData.createdAt}</p>
          <p>Updated: {tooltipData.updatedAt}</p>
          {patientData && patientData?.aid === 'CTscan' && (
            <>
              <p>Plane: {tooltipData.plane}</p>
              <p>Slice: {tooltipData.slice}</p>
            </>
          )}
        </div>
      )}
    </section>
  )
}

export default ActionsTree
