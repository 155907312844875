import React, { createContext, useState } from 'react'

export const PresetColorMapContext = createContext(null)

const PresetColorMapContextProvider = ({ children }) => {
  const currentUrl = window.location.search
  const urlParams = new URLSearchParams(currentUrl)
  const cm = urlParams.get('acquisition')
    ? urlParams.get('acquisition') === 'CTscan'
      ? 'CoolToWarmExtended'
      : 'XRay'
    : 'CoolToWarmExtended'

  const [preset, setPreset] = useState('Lungs')
  const [colorMap, setColorMap] = useState(cm)
  const [presetList] = useState([
    { text: 'Lungs' },
    { text: 'Blood' },
    { text: 'Airways' },
    { text: 'Nasal' },
    { text: 'Bone' },
  ])
  const [colorMapList] = useState([
    { text: 'CoolToWarmExtended' },
    { text: 'BlueGreenOrange' },
    { text: 'WarmStep' },
    { text: 'Inferno' },
    { text: 'RainbowUniform' },
    { text: 'Turbo' },
    { text: 'CoolToWarm' },
    { text: 'Jet' },
    { text: 'XRay' },
    { text: 'Plasma' },
    { text: 'XRayInverse' },
    { text: 'WarmToCool' },
    { text: 'ColdAndHot' },
    { text: 'Viridis' },
    { text: 'Magma' },
    { text: 'CitrusStep' },
    { text: 'RainbowDesaturated' },
    { text: 'BlackBody' },
    { text: 'BrOrYI' },
  ])

  return (
    <PresetColorMapContext.Provider
      value={{
        preset,
        setPreset,
        colorMap,
        setColorMap,
        presetList,
        colorMapList,
      }}
    >
      {children}
    </PresetColorMapContext.Provider>
  )
}

export default PresetColorMapContextProvider
