import React, { useState, createContext } from 'react'

export const ActionsTreeContext = createContext(null)

const ActionsTreeContextProvider = ({ children }) => {
  const [mainActorData, setMainActorData] = useState(null)

  return (
    <ActionsTreeContext.Provider
      value={{
        mainActorData,
        setMainActorData,
      }}
    >
      {children}
    </ActionsTreeContext.Provider>
  )
}

export default ActionsTreeContextProvider
