import React, { useContext } from 'react'

import { GViewContext } from '../../../../../contexts/GViewContext'

import DraggableModalSm from '../../../../modals/DraggableModalSm'

import Checkbox from '../../../../ui/Checkbox'

import ResetPlaneButton from './ResetPlaneButton'
/**
 * Modal window with planes parameters
 *
 * @component
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered modal window component.
 */
const PlanesVisibilityModal = ({ mainActorData, setMainActorData }) => {
  const { context, currentWidgetPosition, vtkParentContainerRef } =
    useContext(GViewContext)

  const handlePlanesVisibility = () => {
    if (context.current) {
      const {
        renderer,
        renderWindow,
        resliceActorX,
        resliceActorY,
        resliceActorZ,
      } = context.current
      setMainActorData((prevState) => ({
        ...prevState,
        isPlanesVisible: {
          ...prevState.isPlanesVisible,
          xyz: !mainActorData.isPlanesVisible.xyz,
          x: !mainActorData.isPlanesVisible.xyz,
          y: !mainActorData.isPlanesVisible.xyz,
          z: !mainActorData.isPlanesVisible.xyz,
        },
      }))
      if (mainActorData.isPlanesVisible.xyz) {
        renderer.removeActor(resliceActorX)
        renderer.removeActor(resliceActorY)
        renderer.removeActor(resliceActorZ)
      } else {
        renderer.addActor(resliceActorX)
        renderer.addActor(resliceActorY)
        renderer.addActor(resliceActorZ)
      }
      renderWindow.render()
    }
  }

  const handleXPlaneVisibility = () => {
    if (context.current) {
      const { renderer, renderWindow, resliceActorX } = context.current
      setMainActorData((prevState) => ({
        ...prevState,
        isPlanesVisible: {
          ...prevState.isPlanesVisible,
          x: !mainActorData.isPlanesVisible.x,
        },
      }))
      if (mainActorData.isPlanesVisible.x) {
        renderer.removeActor(resliceActorX)
      } else {
        renderer.addActor(resliceActorX)
      }
      renderWindow.render()
    }
  }

  const handleYPlaneVisibility = () => {
    if (context.current) {
      const { renderer, renderWindow, resliceActorY } = context.current
      setMainActorData((prevState) => ({
        ...prevState,
        isPlanesVisible: {
          ...prevState.isPlanesVisible,
          y: !mainActorData.isPlanesVisible.y,
        },
      }))
      if (mainActorData.isPlanesVisible.y) {
        renderer.removeActor(resliceActorY)
      } else {
        renderer.addActor(resliceActorY)
      }
      renderWindow.render()
    }
  }

  const handleZPlaneVisibility = () => {
    if (context.current) {
      const { renderer, renderWindow, resliceActorZ } = context.current
      setMainActorData((prevState) => ({
        ...prevState,
        isPlanesVisible: {
          ...prevState.isPlanesVisible,
          z: !mainActorData.isPlanesVisible.z,
        },
      }))
      if (mainActorData.isPlanesVisible.z) {
        renderer.removeActor(resliceActorZ)
      } else {
        renderer.addActor(resliceActorZ)
      }
      renderWindow.render()
    }
  }

  const handleRotatablePlaneVisibility = (e) => {
    e.preventDefault()
    if (context.current) {
      const { renderer, renderWindow, sliceActor, planeWidget, widgetManager } =
        context.current
      setMainActorData((prevState) => ({
        ...prevState,
        isPlanesVisible: {
          ...prevState.isPlanesVisible,
          rotatable: !mainActorData.isPlanesVisible.rotatable,
        },
      }))
      if (mainActorData.isPlanesVisible.rotatable) {
        renderer.removeActor(sliceActor)
        widgetManager.removeWidget(planeWidget)
      } else {
        const repStyle = {
          active: {
            plane: {
              opacity: 0.0,
              color: [1, 1, 1],
            },
            normal: {
              opacity: 1,
              color: [1, 1, 1],
            },
            origin: {
              opacity: 1,
              color: [1, 1, 1],
            },
          },
          inactive: {
            plane: {
              opacity: 0.0,
              color: [1, 1, 1],
            },
            normal: {
              opacity: 0.5,
              color: [1, 1, 1],
            },
            origin: {
              opacity: 0.5,
              color: [1, 1, 1],
            },
          },
        }

        renderer.addActor(sliceActor)
        const w = widgetManager.addWidget(planeWidget)
        w.setRepresentationStyle(repStyle)
      }
      renderWindow.render()
    }
  }

  const resetPlanes = (e) => {
    e.preventDefault()
    if (context.current) {
      const {
        resliceCursorWidget,
        source,
        interactor,
        interactorX,
        resliceCursorWidgetInstanceX,
        interactorY,
        resliceCursorWidgetInstanceY,
        interactorZ,
        resliceCursorWidgetInstanceZ,
      } = context.current
      resliceCursorWidget.setCenter(source.getCenter())
      resliceCursorWidgetInstanceX.invokeInteractionEvent()
      interactorX.render()
      resliceCursorWidgetInstanceY.invokeInteractionEvent()
      interactorY.render()
      resliceCursorWidgetInstanceZ.invokeInteractionEvent()
      interactorZ.render()
      interactor.render()
    }
  }

  const resetXPlane = (e) => {
    e.preventDefault()
    if (context.current) {
      const {
        resliceCursorWidget,
        source,
        interactor,
        interactorX,
        resliceCursorWidgetInstanceX,
      } = context.current
      resliceCursorWidget.setCenter([
        source.getCenter()[0],
        currentWidgetPosition[1],
        currentWidgetPosition[2],
      ])
      resliceCursorWidgetInstanceX.invokeInteractionEvent()
      interactorX.render()
      interactor.render()
    }
  }

  const resetYPlane = (e) => {
    e.preventDefault()
    if (context.current) {
      const {
        resliceCursorWidget,
        source,
        interactor,
        interactorY,
        resliceCursorWidgetInstanceY,
      } = context.current
      resliceCursorWidget.setCenter([
        currentWidgetPosition[0],
        source.getCenter()[1],
        currentWidgetPosition[2],
      ])
      resliceCursorWidgetInstanceY.invokeInteractionEvent()
      interactorY.render()
      interactor.render()
    }
  }

  const resetZPlane = (e) => {
    e.preventDefault()
    if (context.current) {
      const {
        resliceCursorWidget,
        source,
        interactor,
        interactorZ,
        resliceCursorWidgetInstanceZ,
      } = context.current
      resliceCursorWidget.setCenter([
        currentWidgetPosition[0],
        currentWidgetPosition[1],
        source.getCenter()[2],
      ])
      resliceCursorWidgetInstanceZ.invokeInteractionEvent()
      interactorZ.render()
      interactor.render()
    }
  }

  const restorePlanesZoom = (e) => {
    e.preventDefault()
    if (context.current) {
      const {
        rendererX,
        rendererY,
        rendererZ,
        renderWindowX,
        renderWindowY,
        renderWindowZ,
      } = context.current

      const { width, height } =
        vtkParentContainerRef.current.getBoundingClientRect()

      const cameraX = rendererX.getActiveCamera()
      const cameraY = rendererY.getActiveCamera()
      const cameraZ = rendererZ.getActiveCamera()

      const bounds = rendererX.computeVisiblePropBounds()
      const dim = [
        (bounds[1] - bounds[0]) / 2,
        (bounds[3] - bounds[2]) / 2,
        (bounds[5] - bounds[4]) / 2,
      ]
      const r = width / height

      let x_SliceX = dim[1]
      let y_SliceX = dim[2]

      let x_SliceY = dim[0]
      let y_SliceY = dim[2]

      let x_SliceZ = dim[0]
      let y_SliceZ = dim[1]

      if (r >= x_SliceX / y_SliceX) {
        // use width
        cameraX.setParallelScale(y_SliceX + 1)
      } else {
        // use height
        cameraX.setParallelScale(y_SliceX + 1)
        // cameraX.setParallelScale(x_SliceX / r + 1)
      }

      if (r >= x_SliceY / y_SliceY) {
        // use width
        cameraY.setParallelScale(y_SliceY + 1)
      } else {
        // use height
        cameraY.setParallelScale(x_SliceY / r + 1)
      }

      if (r >= x_SliceZ / y_SliceZ) {
        // use width
        cameraZ.setParallelScale(y_SliceZ + 1)
      } else {
        // use height
        cameraZ.setParallelScale(x_SliceZ / r + 1)
      }
      renderWindowX.render()
      renderWindowY.render()
      renderWindowZ.render()
    }
  }

  const handleClose = () => {
    setMainActorData((prevState) => ({
      ...prevState,
      activeModal: null,
    }))
  }

  return (
    <DraggableModalSm handleClose={handleClose}>
      <h2 className="h-[36px] py-2 px-4 leading-tight text-md bg-blue rounded-t-2xl cursor-default shadow-[4px_4px_10px_0px_#00000040]">
        Planar cuts: {mainActorData.name}
      </h2>
      <form className="flex p-4 w-full h-[calc(100%-36px)]">
        <div className="flex flex-col gap-2 w-1/2">
          <div className="flex gap-2">
            <ResetPlaneButton resetPlane={(e) => resetPlanes(e)} />
            <Checkbox
              id="xyz"
              isChecked={mainActorData.isPlanesVisible.xyz}
              setIsChecked={() => handlePlanesVisibility()}
            >
              XYZ
            </Checkbox>
          </div>
          <div className="flex gap-2">
            <ResetPlaneButton resetPlane={(e) => resetXPlane(e)} />
            <Checkbox
              id="x"
              isChecked={mainActorData.isPlanesVisible.x}
              setIsChecked={() => handleXPlaneVisibility()}
            >
              X
            </Checkbox>
          </div>
          <div className="flex gap-2">
            <ResetPlaneButton resetPlane={(e) => resetYPlane(e)} />
            <Checkbox
              id="y"
              isChecked={mainActorData.isPlanesVisible.y}
              setIsChecked={() => handleYPlaneVisibility()}
            >
              Y
            </Checkbox>
          </div>
          <div className="flex gap-2">
            <ResetPlaneButton resetPlane={(e) => resetZPlane(e)} />
            <Checkbox
              id="z"
              isChecked={mainActorData.isPlanesVisible.z}
              setIsChecked={() => handleZPlaneVisibility()}
            >
              Z
            </Checkbox>
          </div>
        </div>
        <div className="flex flex-col gap-3 w-1/2">
          <button
            className="w-full p-2 bg-blue transition duration-100 hover:bg-mid-blue-hover rounded-md"
            onClick={restorePlanesZoom}
          >
            Restore zoom
          </button>
          <button
            className="w-full p-2 bg-blue transition duration-100 hover:bg-mid-blue-hover rounded-md"
            onClick={handleRotatablePlaneVisibility}
          >
            {mainActorData.isPlanesVisible.rotatable
              ? 'Hide rotatable plane'
              : 'Show rotatable plane'}
          </button>
        </div>
      </form>
    </DraggableModalSm>
  )
}

export default PlanesVisibilityModal
