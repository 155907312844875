import React, { useState, useRef, useEffect, useContext } from 'react'

import clsx from 'clsx'

import { GViewContext } from '../../contexts/GViewContext'
import { MeasuresContext } from '../../contexts/MeasuresContext'
import { handleAddText, handleDeleteAnnotation } from '../../utils/measures'
import {
  handleFocus,
  handleBlur,
} from '../../utils/interactorHandlersInTextField'

/**
 * After drawing an annotation (measures dropdown), a text modal window appears, allowing the user to enter a label for that annotation. If the user clicks the 'Cancel' button or presses the 'Esc' key, the modal window will close, and the annotation itself will be deleted. Clicking the 'Save' button or pressing the 'Enter' key, if there is text in the input field, will save the text and associate it with the annotation. Otherwise, the 'Save' button will be inactive.
 *
 * @component
 * @param {boolean} props.isAnnotationModalVisible - variable that controls whether the modal window is open
 * @param {function} props.setIsAnnotationModalVisible - function that is responsible for setting values in isAnnotationModalVisible
 * @returns {JSX.Element} The rendered annotation modal window component.
 */
const AnnotationModal = ({
  isAnnotationModalVisible,
  setIsAnnotationModalVisible,
}) => {
  const { context } = useContext(GViewContext)
  const { measuresData, selectedWidgetIndex, setSelectedWidgetIndex } =
    useContext(MeasuresContext)

  const [annotationText, setAnnotationText] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)

  const inputRef = useRef(null)

  const handleAnnotationText = (e) => {
    setAnnotationText(e.target.value)
    if (e.target.value.trim()) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }

  const deleteAnnotationWidget = () => {
    handleBlur(context)
    if (context.current) {
      const {
        measuresWidgetManagerX,
        measuresWidgetManagerY,
        measuresWidgetManagerZ,
      } = context.current
      if (selectedWidgetIndex.x != null) {
        handleDeleteAnnotation({
          measuresWidgetManager: measuresWidgetManagerX,
          measuresData,
          plane: 'x',
          selectedWidgetIndex,
        })
      } else if (selectedWidgetIndex.y != null) {
        handleDeleteAnnotation({
          measuresWidgetManager: measuresWidgetManagerY,
          measuresData,
          plane: 'y',
          selectedWidgetIndex,
        })
      } else if (selectedWidgetIndex.z != null) {
        handleDeleteAnnotation({
          measuresWidgetManager: measuresWidgetManagerZ,
          measuresData,
          plane: 'z',
          selectedWidgetIndex,
        })
      }
      setSelectedWidgetIndex({ x: null, y: null, z: null })
      setIsAnnotationModalVisible(false)
    }
  }

  const addText = (e) => {
    e.preventDefault()
    if (!isDisabled) {
      if (selectedWidgetIndex.x != null) {
        handleAddText({
          measuresData,
          plane: 'x',
          selectedWidgetIndex,
          setSelectedWidgetIndex,
          annotationText,
        })
      } else if (selectedWidgetIndex.y != null) {
        handleAddText({
          measuresData,
          plane: 'y',
          selectedWidgetIndex,
          setSelectedWidgetIndex,
          annotationText,
        })
      } else if (selectedWidgetIndex.z != null) {
        handleAddText({
          measuresData,
          plane: 'z',
          selectedWidgetIndex,
          setSelectedWidgetIndex,
          annotationText,
        })
      }
      handleBlur(context)
      setIsAnnotationModalVisible(false)
    }
  }

  const handleEscapeKeyPress = (e) => {
    switch (e.key) {
      case 'Escape':
        deleteAnnotationWidget()
        break
      default:
        break
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKeyPress)
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
  }, [])

  useEffect(() => {
    if (isAnnotationModalVisible && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus()
      })
    }
  }, [isAnnotationModalVisible])

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-black bg-opacity-20">
      <div className="fixed flex flex-col justify-center items-center p-5 shadow-[0px_0px_14px_12px_#00000040] rounded-md bg-mid-blue z-10">
        <div className="mb-4 text-left text-bright-blue text-2xl ">
          Annotation
        </div>
        <form onSubmit={addText}>
          <div className="flex flex-col">
            <label className="pb-1">Enter your annotation</label>
            <input
              type="text"
              className="bg-black bg-opacity-40 w-[300px] p-2 border-2 border-blue focus:border-pale-blue rounded outline-none"
              ref={inputRef}
              value={annotationText}
              onChange={handleAnnotationText}
              onFocus={() => handleFocus(context)}
              // onBlur={() => handleBlur(context)}
            />
          </div>
          <div className="flex justify-end mt-4 gap-2">
            <button
              type="button"
              className="w-[70px] py-1 bg-pale-blue hover:bg-pale-blue-hover rounded transition duration-300 ease-in-out"
              onClick={deleteAnnotationWidget}
            >
              Cancel
            </button>
            <button
              type="button"
              className={clsx(
                'w-[70px] py-1 bg-blue hover:bg-blue-hover rounded transition duration-300 ease-in-out',
                {
                  'bg-opacity-30 pointer-events-none': isDisabled,
                }
              )}
              onClick={addText}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AnnotationModal
