import {
  init,
  classModule,
  propsModule,
  styleModule,
  eventListenersModule,
  h,
  attributesModule,
} from 'snabbdom'

const patch = init([
  attributesModule,
  classModule,
  propsModule,
  styleModule,
  eventListenersModule,
])

function mountDummySVGContainer(canvas) {
  const container = canvas.parentElement

  const dummy = document.createElement('div')
  container.insertBefore(dummy, canvas.nextSibling)

  const containerStyles = window.getComputedStyle(container)
  if (containerStyles.position === 'static') {
    container.style.position = 'relative'
  }

  return dummy
}

export const VerticalTextAlignment = {
  TOP: 1,
  MIDDLE: 2,
  BOTTOM: 3,
}

export function multiLineTextCalculator(
  nLines,
  fontSize,
  alignment = VerticalTextAlignment.BOTTOM
) {
  const dys = []
  for (let i = 0; i < nLines; i++) {
    switch (alignment) {
      case VerticalTextAlignment.TOP:
        dys.push(fontSize * (i + 1))
        break
      case VerticalTextAlignment.MIDDLE:
        dys.push(-fontSize * (0.5 * nLines - i - 1))
        break
      case VerticalTextAlignment.BOTTOM:
      default:
        dys.push(-fontSize * (nLines - i - 1))
    }
  }
  return dys
}

export function bindSVGRepresentation(
  renderer,
  widgetState,
  { mapState, render }
) {
  const view = renderer.getRenderWindow().getViews()[0]
  const canvas = view.getCanvas()

  const getSize = () => {
    const [width, height] = view.getSize()
    const ratio = window.devicePixelRatio || 1
    return {
      width: width / ratio,
      height: height / ratio,
      viewBox: `0 0 ${width} ${height}`,
    }
  }

  const renderState = (state) => {
    const repData = mapState(state, {
      size: view.getSize(),
    })
    const rendered = render(repData, h)
    return h(
      'svg',
      {
        attrs: getSize(),
        style: {
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          display: 'block',
          'pointer-events': 'none',
        },
      },
      Array.isArray(rendered) ? rendered : [rendered]
    )
  }

  const dummy = mountDummySVGContainer(canvas)
  let vnode = patch(dummy, renderState(widgetState))

  const updateVNode = () => {
    vnode = patch(vnode, renderState(widgetState))
  }

  const stateSub = widgetState.onModified(() => updateVNode())
  const cameraSub = renderer.getActiveCamera().onModified(() => updateVNode())
  const observer = new ResizeObserver(() => updateVNode())
  observer.observe(canvas)

  return () => {
    stateSub.unsubscribe()
    cameraSub.unsubscribe()
    observer.disconnect()
    patch(vnode, h('!'))
    vnode = null
  }
}

function applyDefaultInteractions(userListeners) {
  let locked = false
  return {
    ...userListeners,
    pointerdown(ev) {
      locked = true
      return userListeners?.pointerdown?.(ev)
    },
    pointerenter(ev) {
      if (locked) {
        locked = false
      }
      return userListeners?.pointerenter?.(ev)
    },
    pointerleave(ev) {
      if (!locked) {
        return userListeners?.pointerleave?.(ev)
      }
      return undefined
    },
  }
}

export function makeListenableSVGNode(vnode) {
  vnode.data.style = {
    ...vnode.data.style,
    'pointer-events': 'all',
  }
  vnode.data.on = applyDefaultInteractions(vnode.data.on)
  return vnode
}

export default { bindSVGRepresentation, multiLineTextCalculator }
