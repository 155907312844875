import React, { createContext, useState } from 'react'

export const PatientContext = createContext(null)

const PatientContextProvider = ({ children }) => {
  const [patientData, setPatientData] = useState(null)

  return (
    <PatientContext.Provider
      value={{
        patientData,
        setPatientData,
      }}
    >
      {children}
    </PatientContext.Provider>
  )
}

export default PatientContextProvider
