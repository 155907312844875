import React, { useContext } from 'react'

import { FileContext } from '../../../contexts/FileContext'
import { GViewContext } from '../../../contexts/GViewContext'
import { ViewContext } from '../../../contexts/ViewContext'
import { MeasuresContext } from '../../../contexts/MeasuresContext'
import { ActionsTreeContext } from '../../../contexts/ActionsTreeContext'
import { renderImageStructure } from '../../../utils/imageRendering'
import ButtonWithTooltip from '../../ui/ButtonWithTooltip'

/**
 * Button is intended to go to the DigiScan application, temporarily used to download a file from the database.
 *
 * @component
 * @returns {JSX.Element} The rendered DigiScan button component.
 */
const Digiscan = () => {
  const {
    context,
    vtkContainerRef,
    vtkFunctionalContainerRef,
    vtkParentContainerRef,
    sliceContainerX,
    sliceContainerY,
    sliceContainerZ,
    setXSlice,
    setYSlice,
    setZSlice,
    setSliderMaxX,
    setSliderMaxY,
    setSliderMaxZ,
    setCurrentWidgetPosition,
    color,
    setColor,
    setSpacings,
  } = useContext(GViewContext)
  const { setView } = useContext(ViewContext)

  const { setFileData, setFileName, setIsLoading } = useContext(FileContext)
  const { setMainActorData } = useContext(ActionsTreeContext)
  const { measuresData } = useContext(MeasuresContext)

  const getImage = async () => {
    try {
      setIsLoading(true)

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/get_vti`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({
            ID: '65b17cd6fb50df8ce1e0620e',
            EID: 'WIETvvEhfhiZWZTS',
            // SID: '1.2.840.113619.2.55.3.1158323844.905.1584943151.859.4',
            SID: 'ctscan4',
            acquisitiontype: 'CTscan',
          }),
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      // const data = await response.json()
      console.log(response)
      const data = await response.arrayBuffer()
      console.log('Response from server:', data)
      setFileData(data)
      setFileName('main.vti')
      console.log(data)

      await renderImageStructure({
        context,
        imageData: data,
        imageName: 'main.vti',
        vtkContainerRef,
        vtkFunctionalContainerRef,
        vtkParentContainerRef,
        sliceContainerX,
        sliceContainerY,
        sliceContainerZ,
        setXSlice,
        setYSlice,
        setZSlice,
        setSliderMaxX,
        setSliderMaxY,
        setSliderMaxZ,
        setCurrentWidgetPosition,
        color,
        setColor,
        setSpacings,
        setMainActorData,
        measuresData,
        hasFunctional: true,
        setView,
      })
    } catch (error) {
      console.error('Error:', error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const getImage1 = async () => {
    try {
      setIsLoading(true)

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/get_vti_old`
      )

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      // const data = await response.json()
      console.log(response)
      const data = await response.arrayBuffer()
      console.log('Response from server:', data)
      setFileData(data)
      setFileName('main.vti')
      console.log(data)

      await renderImageStructure({
        context,
        imageData: data,
        imageName: 'main.vti',
        vtkContainerRef,
        vtkFunctionalContainerRef,
        vtkParentContainerRef,
        sliceContainerX,
        sliceContainerY,
        sliceContainerZ,
        setXSlice,
        setYSlice,
        setZSlice,
        setSliderMaxX,
        setSliderMaxY,
        setSliderMaxZ,
        setCurrentWidgetPosition,
        color,
        setColor,
        setSpacings,
        setMainActorData,
        measuresData,
        hasFunctional: true,
        setView,
      })
    } catch (error) {
      console.error('Error:', error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const getImage2 = async () => {
    try {
      setIsLoading(true)

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/get_functional_as_image`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ID: '65b17cd6fb50df8ce1e0620e',
            EID: 'WIETvvEhfhiZWZTS',
            acquisition: 'CTscan',
            SID: '1.2.840.113619.2.55.3.1158323844.905.1584943151.859.4',
            DID: 'ventilation',
            outputfile: '/tmp/functional.vti',
          }),
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      // const data = await response.json()
      console.log(response)
      const data = await response.arrayBuffer()
      console.log('Response from server:', data)
      setFileData(data)
      setFileName('main.vti')
      console.log(data)

      await renderImageStructure({
        context,
        imageData: data,
        imageName: 'main.vti',
        vtkContainerRef,
        vtkFunctionalContainerRef,
        vtkParentContainerRef,
        sliceContainerX,
        sliceContainerY,
        sliceContainerZ,
        setXSlice,
        setYSlice,
        setZSlice,
        setSliderMaxX,
        setSliderMaxY,
        setSliderMaxZ,
        setCurrentWidgetPosition,
        color,
        setColor,
        setSpacings,
        setMainActorData,
        measuresData,
        hasFunctional: false,
        setView,
      })
    } catch (error) {
      console.error('Error:', error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const test = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/get_diagnostics_list?patient=65b17cd6fb50df8ce1e0620e&eventKey=WIETvvEhfhiZWZTS&ctscanKey=ctscan4`,
      {
        method: 'GET',
        headers: {
          // 'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        // body: JSON.stringify({
        //   ID: '65b17cd6fb50df8ce1e0620e',
        //   EID: 'WIETvvEhfhiZWZTS',
        //   SID: '1.2.840.113619.2.55.3.1158323844.905.1584943151.859.4',
        //   acquisitiontype: 'CTscan',
        // }),
      }
    )

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    // const data = await response.json()
    console.log(response)
    const data = await response.json()
    console.log('Response from server:', data)
    // setFileData(data)
    // setFileName('main.vti')
    console.log(data[0])

    // const response1 = await fetch(
    //   `${process.env.REACT_APP_SERVER_URL}/getDignosticContent?patient=65b17cd6fb50df8ce1e0620e&eventKey=WIETvvEhfhiZWZTS&ctscanKey=ctscan4&diagnosticKey=${data[0].id}`,
    //   {
    //     method: 'GET',
    //     headers: {
    //       // 'Content-Type': 'application/json',
    //       'Access-Control-Allow-Origin': '*',
    //     },
    //     // body: JSON.stringify({
    //     //   ID: '65b17cd6fb50df8ce1e0620e',
    //     //   EID: 'WIETvvEhfhiZWZTS',
    //     //   SID: '1.2.840.113619.2.55.3.1158323844.905.1584943151.859.4',
    //     //   acquisitiontype: 'CTscan',
    //     // }),
    //   }
    // )

    // if (!response1.ok) {
    //   throw new Error(`HTTP error! Status: ${response1.status}`)
    // }

    // // const data = await response.json()
    // console.log(response1)
    // const stl = await response1.arrayBuffer()
    // console.log(stl)
  }

  return (
    <>
      <li className="h-full">
        <ButtonWithTooltip
          tooltipText="Show DigiScan"
          className="relative h-full flex flex-col items-center gap-px"
          // onClick={getImage1}
          onClick={() =>
            (window.location.href = process.env.REACT_APP_DIGISCAN_URL)
          }
        >
          <div className="h-1/2 flex items-center">
            <img
              className="w-[20px] laptop:w-auto"
              src="/assets/images/database.svg"
              alt=""
            />
          </div>
          <p className="max-h-1/2 text-xs laptop:text-sm desktop:text-base leading-[18px] laptop:leading-[18px] desktop:leading-[18px]">
            DigiScan
          </p>
        </ButtonWithTooltip>
      </li>
    </>
  )
}

export default Digiscan
