import React, { useState, useContext, useEffect, useRef } from 'react'
import axios from 'axios'
import clsx from 'clsx'

import { PatientContext } from './contexts/PatientContext'
import { FileContext } from './contexts/FileContext'
import { GViewContext } from './contexts/GViewContext'
import { ViewContext } from './contexts/ViewContext'
import { MeasuresContext } from './contexts/MeasuresContext'
import { MessengerContext } from './contexts/MessengerContext'
import { ActionsTreeContext } from './contexts/ActionsTreeContext'

import {
  renderImageStructure,
  renderXRayStructure,
} from './utils/imageRendering'

import Layout from './components/Layout'
import Topside from './components/Topside'
import Bottomside from './components/Bottomside'
import Loader from './components/ui/Loader'

import PlanesVisibilityModal from './components/ActionsTree/ActionsTreeMainItem/ActionsTreeMainItemButtons/PlanesVisibility/PlanesVisibilityModal'
import ReportModal from './components/ActionsTree/ActionsTreeMainItem/ActionsTreeMainItemButtons/Report/ReportModal'
import InfoModal from './components/ActionsTree/ActionsTreeMainItem/ActionsTreeMainItemButtons/Info/InfoModal'
import VisualParametersModal from './components/ActionsTree/ActionsTreeMainItem/ActionsTreeMainItemButtons/VisualParameters/VisualParametersModal'
import FunctionalVisualParametersModal from './components/ActionsTree/ActionsTreeFunctionalItem/ActionsTreeFunctionalItemButtons/FunctionalVisualParametersModal'
import ToastsContainer from './components/ui/ToastsContainer'
import { getEllipseFromDB } from './utils/measures'
import PdfReport from './components/PdfReport/PdfReport'

const App = () => {
  const [isDragging, setIsDragging] = useState(false)
  const effectRan = useRef(false)

  const { setPatientData } = useContext(PatientContext)

  const {
    context,
    vtkContainerRef,
    vtkFunctionalContainerRef,
    vtkParentContainerRef,
    sliceContainerX,
    sliceContainerY,
    sliceContainerZ,
    setXSlice,
    setYSlice,
    setZSlice,
    setSliderMaxX,
    setSliderMaxY,
    setSliderMaxZ,
    currentWidgetPosition,
    setCurrentWidgetPosition,
    color,
    setColor,
    setCoordinates,
    setSpacings,
    setActiveWindow,
  } = useContext(GViewContext)
  const { setView } = useContext(ViewContext)
  const { mainActorData, setMainActorData } = useContext(ActionsTreeContext)
  const { measuresData, setSelectedWidgetIndex } = useContext(MeasuresContext)
  const { setMessages } = useContext(MessengerContext)

  const {
    setFileData,
    setFileName,
    isLoading,
    setIsLoading,
    toasts,
    setToasts,
  } = useContext(FileContext)

  const handleDrop = async (e) => {
    e.preventDefault()
    setIsDragging(false)

    if (e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0]

      if (file.name.split('.').pop().toLowerCase() === 'vti') {
        const reader = new FileReader()
        reader.onload = async function onLoad() {
          setFileData(reader.result)
          await renderImageStructure({
            context,
            imageData: reader.result,
            imageName: file.name,
            vtkContainerRef,
            vtkFunctionalContainerRef,
            vtkParentContainerRef,
            sliceContainerX,
            sliceContainerY,
            sliceContainerZ,
            setXSlice,
            setYSlice,
            setZSlice,
            setSliderMaxX,
            setSliderMaxY,
            setSliderMaxZ,
            setCurrentWidgetPosition,
            color,
            setColor,
            setCoordinates,
            setSpacings,
            mainActorData,
            setMainActorData,
            measuresData,
            patientData: null,
            setMessages,
            setToasts,
            setView,
            setSelectedWidgetIndex,
            setActiveWindow,
            currentWidgetPosition,
          })
        }
        reader.readAsArrayBuffer(file)
      }
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    if (e.dataTransfer.types.includes('Files')) {
      setIsDragging(true)
    }
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsDragging(false)
    }
  }

  const fetchFromStart = async (patientData) => {
    try {
      setIsLoading(true)

      let param = ''

      if (patientData.aid === 'XRay') {
        param = '/get_xray'
      }

      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + param,
        {
          ID: patientData.id,
          EID: patientData.eid,
          SID: patientData.sid,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'arraybuffer',
        }
      )

      const data = response.data
      console.log(data)

      setFileData(data)
      setFileName(patientData.sid)

      if (patientData.aid === 'CTscan') {
        await renderImageStructure({
          context,
          imageData: data,
          imageName: patientData.sid,
          vtkContainerRef,
          vtkFunctionalContainerRef,
          vtkParentContainerRef,
          sliceContainerX,
          sliceContainerY,
          sliceContainerZ,
          setXSlice,
          setYSlice,
          setZSlice,
          setSliderMaxX,
          setSliderMaxY,
          setSliderMaxZ,
          setCurrentWidgetPosition,
          color,
          setColor,
          setCoordinates,
          setSpacings,
          mainActorData,
          setMainActorData,
          measuresData,
          patientData,
          setMessages,
          setToasts,
          setView,
          setSelectedWidgetIndex,
          setActiveWindow,
          currentWidgetPosition,
        })
      } else if (patientData.aid === 'XRay') {
        await renderXRayStructure({
          context,
          imageData: data,
          imageName: patientData.sid,
          vtkParentContainerRef,
          sliceContainerZ,
          setZSlice,
          setCurrentWidgetPosition,
          color,
          setColor,
          mainActorData,
          setMainActorData,
          measuresData,
          patientData,
          setMessages,
          setToasts,
          setSelectedWidgetIndex,
          setActiveWindow,
        })
      }
    } catch (error) {
      console.error('Error:', error)
      setToasts((prev) => [
        ...prev,
        +process.env.REACT_APP_PROD_MODE_ENABLED
          ? 'An error occured while loading patient radiology.'
          : error.response
            ? `Patient radiology. Status: ${error.response.status} – ${error.response.statusText}.`
            : `Patient radiology. ${error.message}.`,
      ])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (effectRan.current === false) {
      const currentUrl = window.location.search
      const urlParams = new URLSearchParams(currentUrl)
      if (
        urlParams.get('patient') &&
        urlParams.get('eventKey') &&
        urlParams.get('acquisition') &&
        urlParams.get('seriesKey')
      ) {
        console.log('patient parameter active')
        const urlParamsData = {
          id: urlParams.get('patient'),
          eid: urlParams.get('eventKey'),
          aid: urlParams.get('acquisition'),
          sid: urlParams.get('seriesKey'),
          anid: urlParams.get('anatomy'),
          did: urlParams.get('diagnostics'),
        }
        HTMLCanvasElement.prototype.getContext = (function (origFn) {
          return function (type, attribs) {
            attribs = attribs || {}
            attribs.preserveDrawingBuffer = true
            return origFn.call(this, type, attribs)
          }
        })(HTMLCanvasElement.prototype.getContext)
        setPatientData(urlParamsData)
        fetchFromStart(urlParamsData)
      } else {
        console.log('no patient')
      }
      return () => {
        effectRan.current = true
      }
    }
  }, [])

  return (
    <div
      className={clsx(
        'relative w-screen h-screen bg-dark-blue overflow-hidden',
        {
          // 'pointer-events-none': isLoading,
        }
      )}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {isDragging && (
        <div className="absolute inset-0 flex justify-center items-center bg-black opacity-50 z-10 pointer-events-none">
          <div className="text-center pointer-events-none">
            <p className="text-3xl mb-4">Drop file here</p>
            <p className="text-lg">(Supported file type: .vti)</p>
          </div>
        </div>
      )}
      <Layout>
        <main className="h-[calc(100svh-64px)] desktop:h-[calc(100svh-88px)] flex flex-col justify-between">
          <Topside />
          <Bottomside />
        </main>
      </Layout>
      {mainActorData && mainActorData.activeModal === 'planes' && (
        <PlanesVisibilityModal
          mainActorData={mainActorData}
          setMainActorData={setMainActorData}
        />
      )}
      {mainActorData && mainActorData.activeModal === 'report' && (
        <ReportModal
          mainActorData={mainActorData}
          setMainActorData={setMainActorData}
        />
      )}
      {mainActorData && mainActorData.activeModal === 'info' && (
        <InfoModal
          mainActorData={mainActorData}
          setMainActorData={setMainActorData}
        />
      )}
      {mainActorData && mainActorData.activeModal === 'parameters' && (
        <VisualParametersModal
          mainActorData={mainActorData}
          setMainActorData={setMainActorData}
        />
      )}
      {mainActorData?.functionalActor &&
        mainActorData.functionalActor.isModalActive && (
          <FunctionalVisualParametersModal
            mainActorData={mainActorData}
            setMainActorData={setMainActorData}
          />
        )}
      <Loader isLoading={isLoading} />
      {toasts.length ? (
        <ToastsContainer toasts={toasts} setToasts={setToasts} />
      ) : (
        ''
      )}
      {mainActorData && <PdfReport mainActorData={mainActorData} />}
    </div>
  )
}

export default App
