import React, { useEffect, useRef, useContext } from 'react'

import { MessengerContext } from '../contexts/MessengerContext'

/**
 * Component for messages
 *
 * @component
 * @returns {JSX.Element} The rendered messenger component.
 */
const WidgetMessenger = () => {
  const messagesRef = useRef(null)

  const { messages, setMessages } = useContext(MessengerContext)

  const scrollToBottom = () => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight
    }
  }

  useEffect(() => {
    window.addEventListener('resize', scrollToBottom)
    scrollToBottom()

    return () => {
      window.removeEventListener('resize', scrollToBottom)
    }
  }, [messages])

  useEffect(() => {
    setMessages([...messages, 'App is running'])
  }, [])

  return (
    <section className="w-full max-h-[calc(45%-15px)] h-full bg-mid-blue rounded-[10px] shadow-[4px_4px_10px_0px_#00000040]">
      <h3 className="text-base desktop:text-lg text-center font-bold bg-blue rounded-[10px] py-[8px] shadow-[4px_4px_10px_0px_#00000040]">
        Info
      </h3>
      <div
        className="h-[calc(100%-64px)] px-[13px] desktop:px-[20px] my-[10px] overflow-y-auto"
        ref={messagesRef}
      >
        {messages &&
          messages.map((message, index) => (
            <p key={index} className="text-sm desktop:text-base font-light">
              {message}
            </p>
          ))}
      </div>
    </section>
  )
}

export default WidgetMessenger
