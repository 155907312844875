import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import './index.css'

import FileContextProvider from './contexts/FileContext'
import ActionsTreeContextProvider from './contexts/ActionsTreeContext'
import TaxonomyContextProvider from './contexts/TaxonomyContext'
import ViewContextProvider from './contexts/ViewContext'
import GViewContextProvider from './contexts/GViewContext'
import MeasuresContextProvider from './contexts/MeasuresContext'
import MessengerContextProvider from './contexts/MessengerContext'
import PatientContextProvider from './contexts/PatientContext'
import PresetColorMapContextProvider from './contexts/PresetColorMapContext'
import PreferencesContextProvider from './contexts/PreferencesContext'
import PdfContextProvider from './contexts/PdfContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <PreferencesContextProvider>
    <PatientContextProvider>
      <GViewContextProvider>
        <FileContextProvider>
          <ActionsTreeContextProvider>
            <TaxonomyContextProvider>
              <ViewContextProvider>
                <PresetColorMapContextProvider>
                  <MeasuresContextProvider>
                    <MessengerContextProvider>
                      <PdfContextProvider>
                        <React.StrictMode>
                          <App />
                        </React.StrictMode>
                      </PdfContextProvider>
                    </MessengerContextProvider>
                  </MeasuresContextProvider>
                </PresetColorMapContextProvider>
              </ViewContextProvider>
            </TaxonomyContextProvider>
          </ActionsTreeContextProvider>
        </FileContextProvider>
      </GViewContextProvider>
    </PatientContextProvider>
  </PreferencesContextProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
