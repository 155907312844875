import React, { useRef, useState, useContext } from 'react'

import useDebounce from '../../hooks/useDebounce'
// import { GViewContext } from '../../contexts/GViewContext'

// import {
//   handleBlur,
//   handleFocus,
// } from '../../utils/interactorHandlersInTextField'

/**
 * UI input with buttons (+/–).
 *
 * @component
 * @param {number} props.value - input value
 * @param {function} props.setValue - function setter for value
 * @param {number} props.min - min input value
 * @param {number} props.max - max input value
 * @param {function} props.handleUpdate - a function with calculations that is activated when the user has set the desired value
 * @returns {JSX.Element} The rendered input component.
 */
const NumberInputWithButtons = ({
  value,
  setValue,
  min,
  max,
  handleUpdate,
}) => {
  const incrementRef = useRef(null)
  const decrementRef = useRef(null)
  const isMouseDown = useRef(false)
  const [isUpdating, setIsUpdating] = useState(false)

  // const { context } = useContext(GViewContext)

  const handleIncrement = () => {
    setValue((prevValue) => Math.min(prevValue + min, max))
  }

  const handleDecrement = () => {
    setValue((prevValue) => Math.max(prevValue - min, min))
  }

  const debouncedHandleUpdate = useDebounce(handleUpdate, setIsUpdating, 800)

  const handleChange = (e) => {
    const newValue = Number(e.target.value)
    setIsUpdating(true)
    if (newValue >= min && newValue <= max) {
      setValue(newValue)
      debouncedHandleUpdate(newValue)
    } else if (newValue < min) {
      setValue(min)
      debouncedHandleUpdate(min)
    } else if (newValue > max) {
      setValue(max)
      debouncedHandleUpdate(max)
    }
  }

  const startIncrement = () => {
    handleIncrement()
    isMouseDown.current = true
    incrementRef.current = setTimeout(() => {
      incrementRef.current = setInterval(() => {
        if (isMouseDown.current) {
          handleIncrement()
        }
      }, 100)
    }, 300)
  }

  const stopIncrement = () => {
    clearTimeout(incrementRef.current)
    clearInterval(incrementRef.current)
    if (isMouseDown.current) {
      setIsUpdating(true)
      debouncedHandleUpdate()
    }
    isMouseDown.current = false
  }

  const startDecrement = () => {
    handleDecrement()
    isMouseDown.current = true
    decrementRef.current = setTimeout(() => {
      decrementRef.current = setInterval(() => {
        if (isMouseDown.current) {
          handleDecrement()
        }
      }, 100)
    }, 300)
  }

  const stopDecrement = () => {
    clearTimeout(decrementRef.current)
    clearInterval(decrementRef.current)
    if (isMouseDown.current) {
      setIsUpdating(true)
      debouncedHandleUpdate()
    }
    isMouseDown.current = false
  }

  return (
    <div className="flex gap-1">
      <button
        className="w-[30px] h-[30px] bg-blue rounded cursor-pointer outline-none focus:ring-blue-600 active:bg-blue-hover disabled:bg-semi-black disabled:text-semi-transparent disabled:cursor-default"
        onMouseDown={startDecrement}
        onMouseUp={stopDecrement}
        onMouseLeave={stopDecrement}
        disabled={value <= min || isUpdating}
      >
        −
      </button>
      <input
        className="bg-black bg-opacity-40 w-[50px] h-[30px] p-1 text-center border-2 border-blue focus:border-pale-blue rounded outline-none"
        type="number"
        value={value}
        onChange={handleChange}
        min={min}
        max={max}
        // onFocus={() => handleFocus(context)}
        // onBlur={() => handleBlur(context)}
      />
      <button
        className="w-[30px] h-[30px] bg-blue rounded cursor-pointer outline-none focus:ring-blue-600 active:bg-blue-hover disabled:bg-semi-black disabled:text-semi-transparent disabled:cursor-default"
        onMouseDown={startIncrement}
        onMouseUp={stopIncrement}
        onMouseLeave={stopIncrement}
        disabled={value >= max || isUpdating}
      >
        +
      </button>
    </div>
  )
}

export default NumberInputWithButtons
