import React, { useContext } from 'react'

import { GViewContext } from '../../contexts/GViewContext'
import DonutChart from '../charts/DonutChart'

/**
 * Diagnostic item for PDF report
 *
 * @component
 * @param {Object} props.diagnostic - diagnostic object
 * @returns {JSX.Element} The rendered diagnostic item component for PDF report.
 */
const DiagnosticsItemPdf = ({ diagnostic }) => {
  const { activeScreen } = useContext(GViewContext)

  return (
    <div className="h-[247px]">
      <h3 className="font-bold text-center w-[70%] leading-tight text-lg text-black mb-[5px]">
        {diagnostic.name}
      </h3>
      <div className="flex h-[calc(100%-20px)] gap-1">
        <DonutChart
          chartData={diagnostic.data}
          renderDependencies={[false, activeScreen]}
          isPDF={true}
        />
        <div className="w-[30%]">
          <div className="h-full border border-black">
            <table className="max-w-full w-full h-full divide-y divide-black">
              <thead className="bg-blue-hover">
                <tr>
                  <th
                    scope="row"
                    className="px-0.5 py-1 text-center text-sm leading-tight font-semibold tracking-wider"
                  >
                    Lobe
                  </th>
                  <th className="px-0.5 py-1 text-center text-sm leading-tight font-semibold tracking-wider">
                    %
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-black">
                {diagnostic.data.map((lobe, index) => (
                  <tr key={index}>
                    <td className="px-0.5 laptop:px-1 py-0.5 laptop:py-1 text-center text-sm leading-tight font-semibold tracking-wider bg-blue-hover">
                      {lobe.name}
                    </td>
                    <td className="px-0.5 laptop:px-1 py-0.5 laptop:py-1 text-center text-sm leading-tight text-black">
                      {lobe.value.toFixed(3)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiagnosticsItemPdf
