import React, { useState, useContext } from 'react'
import axios from 'axios'

import vtkSplineWidget from '@kitware/vtk.js/Widgets/Widgets3D/SplineWidget'
import { ViewTypes } from '@kitware/vtk.js/Widgets/Core/WidgetManager/Constants'

import clsx from 'clsx'

import { PatientContext } from '../../../contexts/PatientContext'
import { GViewContext } from '../../../contexts/GViewContext'
import { ViewContext } from '../../../contexts/ViewContext'
import { ActionsTreeContext } from '../../../contexts/ActionsTreeContext'
import { FileContext } from '../../../contexts/FileContext'
import { MeasuresContext } from '../../../contexts/MeasuresContext'
import { MessengerContext } from '../../../contexts/MessengerContext'

import { renderImageStructure } from '../../../utils/imageRendering'

import DraggableModalSm from '../../modals/DraggableModalSm'

/**
 * Modal window for performing cut operations (digital surgery), among them: drawing the area within which the cut should occur, sending the coordinates of the drawing to the server to obtain and render a carved image, undo/redo buttons for moving between images with cuts.
 *
 * @component
 * @param {string} props.caption - modal caption
 * @param {function} props.setActiveFilter - function that is triggered by pressing a close modal button
 * @returns {JSX.Element} The rendered modal component.
 */
const ScissorsModal = ({ caption, setActiveFilter }) => {
  const [cutMode, setCutMode] = useState(0)
  const [removedCoordinates, setRemovedCoordinates] = useState([])
  const [isUndoActive, setIsUndoActive] = useState(false)
  const [isStartDrawActivated, setIsStartDrawActivated] = useState(false)

  const [previousImage, setPreviousImage] = useState(null)
  const [canceledCut, setCanceledCut] = useState(null)

  const { patientData } = useContext(PatientContext)
  const {
    context,
    setXSlice,
    setYSlice,
    setZSlice,
    vtkContainerRef,
    vtkFunctionalContainerRef,
    vtkParentContainerRef,
    sliceContainerX,
    sliceContainerY,
    sliceContainerZ,
    currentWidgetPosition,
    setCurrentWidgetPosition,
    color,
    setColor,
    setSpacings,
    setSliderMaxX,
    setSliderMaxY,
    setSliderMaxZ,
    setCoordinates,
    setActiveWindow,
  } = useContext(GViewContext)
  const { setView } = useContext(ViewContext)
  const { setMainActorData } = useContext(ActionsTreeContext)
  const { setFileData, fileData, setFileName, setIsLoading, setToasts } =
    useContext(FileContext)
  const { measuresData, setSelectedWidgetIndex } = useContext(MeasuresContext)
  const { setMessages } = useContext(MessengerContext)

  const calculateOrigin = (x, y, z, center, bounds) => {
    const origin = []

    switch (x) {
      case 0:
        origin.push(center[0])
        break
      case 1:
        origin.push(bounds[1])
        break
      case -1:
        origin.push(bounds[0])
        break
      default:
        break
    }

    switch (y) {
      case 0:
        origin.push(center[1])
        break
      case 1:
        origin.push(bounds[3])
        break
      case -1:
        origin.push(bounds[2])
        break
      default:
        break
    }

    switch (z) {
      case 0:
        origin.push(center[2])
        break
      case 1:
        origin.push(bounds[5])
        break
      case -1:
        origin.push(bounds[4])
        break
      default:
        break
    }

    return origin
  }

  const handleDrawSpline = () => {
    if (context.current) {
      const { scissorsWidgetManager, source, renderer } = context.current

      const polygonWidget = vtkSplineWidget.newInstance({
        resetAfterPointPlacement: false,
        resolution: 1,
      })

      const currentHandle = scissorsWidgetManager.addWidget(
        polygonWidget,
        ViewTypes.SLICE
      )

      currentHandle.setOutputBorder(true)

      const cameraNormal = renderer.getActiveCamera().getViewPlaneNormal()
      const roundedCameraNormal = cameraNormal.map((normal) =>
        Math.round(normal)
      )
      const center = source.getCenter()
      const bounds = source.getBounds()

      const origin = calculateOrigin(
        roundedCameraNormal[0],
        roundedCameraNormal[1],
        roundedCameraNormal[2],
        center,
        bounds
      )

      if (origin.length) {
        polygonWidget.getManipulator().setUserOrigin(origin)
      }

      setIsUndoActive(true)
      setIsStartDrawActivated(true)

      // currentHandle.onStartInteractionEvent(() => {
      // setRemovedCoordinates([])

      // console.log('DRAW EVENT END')
      // console.log(polygonWidget.getManipulator().getUserOrigin())
      // console.log(
      //   scissorsWidgetManager
      //     .getWidgets()
      //     [scissorsWidgetManager.getWidgets().length - 1].getWidgetState()
      //     .getState().handleList
      // )
      // })

      scissorsWidgetManager.grabFocus(polygonWidget)
    }
  }

  const undo = () => {
    if (context.current) {
      const { scissorsWidgetManager, renderWindow } = context.current

      const coordinatesList = scissorsWidgetManager
        .getWidgets()
        [scissorsWidgetManager.getWidgets().length - 1].getWidgetState()
        .getState().handleList

      setRemovedCoordinates([
        ...removedCoordinates,
        coordinatesList[coordinatesList.length - 1],
      ])

      scissorsWidgetManager
        .getWidgets()
        [scissorsWidgetManager.getWidgets().length - 1].getWidgetState()
        .removeHandle(coordinatesList.length - 1)

      if (!coordinatesList.length) {
        setIsUndoActive(false)
      }

      renderWindow.render()

      // polygonWidget.getWidgetState().removeHandle(index)
    }
  }
  const redo = () => {
    if (context.current) {
      const { scissorsWidgetManager, renderWindow } = context.current
      const lastWidget = scissorsWidgetManager
        .getWidgets()
        [scissorsWidgetManager.getWidgets().length - 1].getWidgetState()

      // lastWidget.addHandle(
      //   removedCoordinates[removedCoordinates.length - 1].getState()
      // )
      lastWidget.addHandle(removedCoordinates[removedCoordinates.length - 1])

      const updatedRemovedCoordinates = removedCoordinates.slice(0, -1)
      setRemovedCoordinates(updatedRemovedCoordinates)

      renderWindow.render()
    }
  }
  const trash = () => {
    if (context.current) {
      const { scissorsWidgetManager } = context.current
      scissorsWidgetManager.removeWidgets()
      setIsUndoActive(false)
      setIsStartDrawActivated(false)
      setRemovedCoordinates([])
    }
  }

  // const cut = async () => {
  //   if (context.current) {
  //     const { scissorsWidgetManager } = context.current
  //     try {
  //       setIsLoading(true)
  //       const pointsList = scissorsWidgetManager
  //         .getWidgets()
  //         [scissorsWidgetManager.getWidgets().length - 1].getWidgetState()
  //         .getState().handleList

  //       const coordinatesList = []

  //       for (let i = 0; i < pointsList.length; i++) {
  //         coordinatesList.push(pointsList[i].origin)
  //       }

  //       const response = await fetch(
  //         `${process.env.REACT_APP_SERVER_URL}/carve_image`,
  //         {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify({
  //             ID: '65b17cd6fb50df8ce1e0620e',
  //             EID: 'WIETvvEhfhiZWZTS',
  //             acquisitiontype: 'CTscan',
  //             SID: '1.2.840.113619.2.55.3.1158323844.905.1584943151.859.4',
  //             DID: 'LULobe',
  //             coordinates: coordinatesList,
  //             outputfile: '/tmp/carved.vti',
  //             inout: 0,
  //           }),
  //         }
  //       )

  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`)
  //       }

  //       // const data = await response.json()
  //       const data = await response.arrayBuffer()
  //       console.log('Response from server:', data)
  //       setFileData(data)
  //       setFileName('cut.vti')
  //       console.log(data)

  //       setPreviousImage(fileData)

  //       await renderImageStructure({
  //         context,
  //         imageData: data,
  //         imageName: 'cut.vti',
  //         vtkContainerRef,
  //         vtkFunctionalContainerRef,
  //         vtkParentContainerRef,
  //         sliceContainerX,
  //         sliceContainerY,
  //         sliceContainerZ,
  //         setXSlice,
  //         setYSlice,
  //         setZSlice,
  //         setSliderMaxX,
  //         setSliderMaxY,
  //         setSliderMaxZ,
  //         setCurrentWidgetPosition,
  //         color,
  //         setColor,
  //         setSpacings,
  //         setMainActorData,
  //         measuresData,
  //         hasFunctional: false,
  //         setView,
  //       })

  //       setIsStartDrawActivated(false)
  //       setIsUndoActive(false)
  //       setCanceledCut(null)
  //     } catch (error) {
  //       console.error('Error:', error.message)
  //     } finally {
  //       setIsLoading(false)
  //     }
  //   }
  // }

  const cut1 = async () => {
    if (context.current) {
      const { scissorsWidgetManager } = context.current
      try {
        setIsLoading(true)
        const pointsList = scissorsWidgetManager
          .getWidgets()
          [scissorsWidgetManager.getWidgets().length - 1].getWidgetState()
          .getState().handleList

        const coordinatesList = []

        for (let i = 0; i < pointsList.length; i++) {
          coordinatesList.push(pointsList[i].origin)
        }

        console.log('coordinatesList')
        console.log(coordinatesList)

        const blob = new Blob([fileData])
        const file = new File([blob], 'file.vti')

        const formData = new FormData()
        formData.append('file', file, 'file.vti')
        formData.append(
          'data',
          JSON.stringify({
            coordinates: coordinatesList,
            inout: cutMode,
          })
        )

        // console.log(formData.get('file'))
        // console.log(formData.get('coordinates'))

        // console.log(formData.entries())

        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/carve_image1`,
          formData,
          {
            withCredentials: true,
            // headers: {
            //   'Content-Type': 'multipart/form-data',
            // },
            responseType: 'arraybuffer',
          }
        )

        const data = response.data
        // console.log('Response from server:', data)
        setFileData(data)
        setFileName('cut.vti')
        // console.log(data)

        setPreviousImage(fileData)

        await renderImageStructure({
          context,
          imageData: data,
          imageName: 'cut',
          vtkContainerRef,
          vtkFunctionalContainerRef,
          vtkParentContainerRef,
          sliceContainerX,
          sliceContainerY,
          sliceContainerZ,
          setXSlice,
          setYSlice,
          setZSlice,
          setSliderMaxX,
          setSliderMaxY,
          setSliderMaxZ,
          setCurrentWidgetPosition,
          color,
          setColor,
          setCoordinates,
          setSpacings,
          setMainActorData,
          measuresData,
          patientData,
          setMessages,
          setToasts,
          setView,
          setSelectedWidgetIndex,
          setActiveWindow,
          currentWidgetPosition,
        })
        setIsStartDrawActivated(false)
        setIsUndoActive(false)
        setCanceledCut(null)
      } catch (error) {
        setToasts((prev) => [
          ...prev,
          error.response
            ? `Carve image. Status: ${error.response.status} – ${error.response.statusText}.`
            : `Carve image. ${error.message}.`,
        ])
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handlePrevCut = async () => {
    if (context.current) {
      setIsLoading(true)

      setPreviousImage(null)
      setCanceledCut(fileData)
      await renderImageStructure({
        context,
        imageData: previousImage,
        imageName: 'previous',
        vtkContainerRef,
        vtkFunctionalContainerRef,
        vtkParentContainerRef,
        sliceContainerX,
        sliceContainerY,
        sliceContainerZ,
        setXSlice,
        setYSlice,
        setZSlice,
        setSliderMaxX,
        setSliderMaxY,
        setSliderMaxZ,
        setCurrentWidgetPosition,
        color,
        setColor,
        setCoordinates,
        setSpacings,
        setMainActorData,
        measuresData,
        patientData,
        setMessages,
        setToasts,
        setView,
        setSelectedWidgetIndex,
        setActiveWindow,
        currentWidgetPosition,
      })
      setFileData(previousImage)
      setFileName('previous.vti')
      setIsLoading(false)
    }
  }

  const returnToCanceledCut = async () => {
    if (context.current) {
      setIsLoading(true)
      setCanceledCut(null)
      setPreviousImage(fileData)
      await renderImageStructure({
        context,
        imageData: canceledCut,
        imageName: 'canceled',
        vtkContainerRef,
        vtkFunctionalContainerRef,
        vtkParentContainerRef,
        sliceContainerX,
        sliceContainerY,
        sliceContainerZ,
        setXSlice,
        setYSlice,
        setZSlice,
        setSliderMaxX,
        setSliderMaxY,
        setSliderMaxZ,
        setCurrentWidgetPosition,
        color,
        setColor,
        setCoordinates,
        setSpacings,
        setMainActorData,
        measuresData,
        patientData,
        setMessages,
        setToasts,
        setView,
        setSelectedWidgetIndex,
        setActiveWindow,
        currentWidgetPosition,
      })
      setFileData(canceledCut)
      setFileName('canceled.vti')
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    setActiveFilter(null)
  }

  return (
    <DraggableModalSm handleClose={handleClose}>
      <h2 className="h-[36px] py-2 px-4 leading-tight text-md bg-blue rounded-t-2xl shadow-[4px_4px_10px_0px_#00000040]">
        {caption}
      </h2>

      <div className="flex items-center gap-2 p-4 w-full">
        <div className="flex flex-col justify-between gap-3">
          <button
            className={clsx(
              'p-1 border rounded-md transition duration-100 hover:bg-mid-blue-hover',
              {
                'pointer-events-none border-pale-white': isStartDrawActivated,
              }
            )}
            onClick={handleDrawSpline}
          >
            <img
              src={
                isStartDrawActivated
                  ? '/assets/images/scissors-linear_disabled_white.svg'
                  : '/assets/images/scissors-linear_white.svg'
              }
              alt=""
            />
          </button>
          <button className="p-1 border rounded-md transition duration-100 hover:bg-mid-blue-hover border-pale-white">
            <img
              src="/assets/images/scissors-freehand_disabled_white.svg"
              alt=""
            />
          </button>
        </div>
        <div className="flex flex-col justify-between gap-3">
          <div className="flex gap-2 justify-between">
            <button
              className={clsx(
                'p-1 border rounded-md transition duration-100 hover:bg-mid-blue-hover',
                {
                  'border-bright-blue': cutMode === 0,
                }
              )}
              onClick={() => setCutMode(0)}
            >
              <img
                src={
                  cutMode === 0
                    ? '/assets/images/inner_active.svg'
                    : '/assets/images/inner.svg'
                }
                alt=""
              />
            </button>
            <button
              className={clsx(
                'p-1 border rounded-md transition duration-100 hover:bg-mid-blue-hover',
                {
                  'border-bright-blue': cutMode === 1,
                }
              )}
              onClick={() => setCutMode(1)}
            >
              <img
                src={
                  cutMode === 1
                    ? '/assets/images/outer_active.svg'
                    : '/assets/images/outer.svg'
                }
                alt=""
              />
            </button>
          </div>
          <div className="flex gap-2 justify-between">
            <button
              className={clsx(
                'p-1 border rounded-md transition duration-100 hover:bg-mid-blue-hover',
                {
                  'pointer-events-none border-pale-white': !isUndoActive,
                }
              )}
              onClick={undo}
            >
              <img
                src={
                  isUndoActive
                    ? '/assets/images/undo.svg'
                    : '/assets/images/undo_disabled.svg'
                }
                alt=""
              />
            </button>
            <button
              className={clsx(
                'p-1 border rounded-md transition duration-100 hover:bg-mid-blue-hover',
                {
                  'pointer-events-none border-pale-white':
                    !removedCoordinates.length,
                }
              )}
              onClick={redo}
            >
              <img
                src={
                  removedCoordinates.length
                    ? '/assets/images/redo.svg'
                    : '/assets/images/redo_disabled.svg'
                }
                alt=""
              />
            </button>
          </div>
        </div>
        <div className="flex flex-col justify-between pr-6 border-r gap-3">
          <button
            className="p-1 border rounded-md transition duration-100 hover:bg-mid-blue-hover"
            onClick={trash}
          >
            <img src="/assets/images/trash.svg" alt="" />
          </button>
          <button
            className="p-1 border rounded-md transition duration-100 hover:bg-mid-blue-hover"
            onClick={cut1}
          >
            <img src="/assets/images/cut.svg" alt="" />
          </button>
        </div>
        <h3 className="pl-6">Cut:</h3>
        <div className="flex gap-2 pl-2">
          <button
            className={clsx(
              'p-1 border rounded-md transition duration-100 hover:bg-mid-blue-hover',
              {
                'pointer-events-none border-pale-white': !previousImage,
              }
            )}
            onClick={handlePrevCut}
          >
            <img
              src={
                previousImage
                  ? '/assets/images/undo.svg'
                  : '/assets/images/undo_disabled.svg'
              }
              alt=""
            />
          </button>
          <button
            className={clsx(
              'p-1 border rounded-md transition duration-100 hover:bg-mid-blue-hover',
              {
                'pointer-events-none border-pale-white': !canceledCut,
              }
            )}
            onClick={returnToCanceledCut}
          >
            <img
              src={
                canceledCut
                  ? '/assets/images/redo.svg'
                  : '/assets/images/redo_disabled.svg'
              }
              alt=""
            />
          </button>
        </div>
      </div>
    </DraggableModalSm>
  )
}

export default ScissorsModal
