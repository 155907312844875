import React from 'react'

/**
 * UI Button component for ActionsTreeItemButtons
 *
 * @component
 * @param {function} props.handleActive - function that is responsible for opening and closing a modal window
 * @param {boolean} props.isActive - boolean value that determines which image to show (highlighted in color for the active state and a standard white icon if the button is not active)
 * @param {string} props.activeImageLink - link for active image
 * @param {string} props.inactiveImageLink - link for inactive image
 * @returns {JSX.Element} The rendered button component.
 */
const ActionsTreeItemButton = ({
  handleActive,
  isActive,
  activeImageLink,
  inactiveImageLink,
}) => {
  return (
    <button
      className="flex items-center justify-center w-full max-w-[16px] desktop:max-w-[20px]"
      onClick={handleActive}
    >
      <img
        className="h-[16px] desktop:h-[20px]"
        src={isActive ? activeImageLink : inactiveImageLink}
        alt=""
      />
    </button>
  )
}

export default ActionsTreeItemButton
