import React, { useState, useContext, useRef } from 'react'

import { ViewContext } from '../../../contexts/ViewContext'
import { ActionsTreeContext } from '../../../contexts/ActionsTreeContext'
import useOutsideClick from '../../../hooks/useOutsideClick'
import Dropdown from '../../ui/Dropdown'

/**
 * Dropdown menu for changing the view (quantity and order) of the 3D and 2D windows.
 *
 * @component
 * @param {Object | null} props.context - main storage for all vtk.js components. If the image was not loaded in the application, than equal to null.
 * @returns {JSX.Element} The rendered view dropdown menu component.
 */
const ViewPanels = ({ context }) => {
  const [isViewOpen, setIsViewOpen] = useState(false)
  const viewRef = useRef(null)

  const { view, setView } = useContext(ViewContext)
  const { mainActorData } = useContext(ActionsTreeContext)

  const viewList = [
    { text: 'Morpho' },
    { text: 'Morpho-Coronal' },
    { text: 'Morpho-Sagittal' },
    { text: 'Morpho-Axial' },
    { text: 'Four' },
  ]
  const viewListWithFunctional = [
    { text: 'Morpho' },
    { text: 'Functional' },
    { text: 'Morpho-Functional' },
    { text: 'Morpho-Coronal' },
    { text: 'Morpho-Sagittal' },
    { text: 'Morpho-Axial' },
    { text: 'Four' },
    { text: 'Four-Functional' },
  ]

  const handleSwitchView = (e) => {
    if (context.current) {
      setView(e.target.innerText)
      setIsViewOpen(false)
    }
  }

  useOutsideClick({
    dependency: isViewOpen,
    ref: viewRef,
    setClosed: setIsViewOpen,
  })

  return (
    <Dropdown
      menuRef={viewRef}
      caption="View Panels"
      isMenuOpen={isViewOpen}
      setIsMenuOpen={setIsViewOpen}
      optionsList={
        mainActorData && mainActorData.hasFunctional
          ? viewListWithFunctional
          : viewList
      }
      currentOption={view}
      setNewOption={handleSwitchView}
    />
  )
}

export default ViewPanels
