import React from 'react'

const ResetPlaneButton = ({ resetPlane }) => {
  return (
    <button
      className="w-[20px] h-[20px] border-2 border-bright-blue rounded"
      onClick={resetPlane}
    >
      <img src="assets/images/reload.svg" alt="" />
    </button>
  )
}

export default ResetPlaneButton
