import React, { useState, useContext, useEffect } from 'react'

import { GViewContext } from '../../../../../contexts/GViewContext'

import DraggableModalSm from '../../../../modals/DraggableModalSm'

import Checkbox from '../../../../ui/Checkbox'

/**
 * Modal window with visual parameters
 *
 * @component
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered modal window component.
 */
const VisualParametersModal = ({ mainActorData, setMainActorData }) => {
  const [isOrientationWidgetChecked, setIsOrientationWidgetChecked] =
    useState(true)

  const [scalarBarVisibility, setScalarBarVisibility] = useState(false)

  const { context } = useContext(GViewContext)

  const handleCameraParallelProjection = (e) => {
    if (e.target.checked) {
      const { renderer, renderWindow } = context.current
      // setSelectedOption(e.target.value)
      if (e.target.value === 'perspective') {
        setMainActorData((prevState) => ({
          ...prevState,
          isCameraParallelProjection: false,
        }))
        renderer.getActiveCamera().setParallelProjection(false)
      } else if (e.target.value === 'orthogonal') {
        setMainActorData((prevState) => ({
          ...prevState,
          isCameraParallelProjection: true,
        }))
        renderer.getActiveCamera().setParallelProjection(true)
      }
      renderWindow.render()
    }
  }

  const resetMainActorsCamera = (e) => {
    const { renderer, renderWindow } = context.current
    e.preventDefault()
    renderer.resetCamera()
    renderWindow.render()
  }

  const setMainActorsCameraToXY = (e) => {
    e.preventDefault()
    const { renderer, renderWindow } = context.current
    const fp = renderer.getActiveCamera().getFocalPoint()
    const p = renderer.getActiveCamera().getPosition()
    const dist = Math.sqrt(
      (p[0] - fp[0]) ** 2 + (p[1] - fp[1]) ** 2 + (p[2] - fp[2]) ** 2
    )
    renderer.getActiveCamera().setPosition(fp[0], fp[1], fp[2] + dist)
    renderer.getActiveCamera().setViewUp(0, 1, 0)
    renderWindow.render()
  }
  const setMainActorsCameraToYZ = (e) => {
    e.preventDefault()
    const { renderer, renderWindow } = context.current
    const fp = renderer.getActiveCamera().getFocalPoint()
    const p = renderer.getActiveCamera().getPosition()
    const dist = Math.sqrt(
      (p[0] - fp[0]) ** 2 + (p[1] - fp[1]) ** 2 + (p[2] - fp[2]) ** 2
    )
    renderer.getActiveCamera().setPosition(fp[0] + dist, fp[1], fp[2])
    renderer.getActiveCamera().setViewUp(0, 0, 1)
    renderWindow.render()
  }
  const setMainActorsCameraToXZ = (e) => {
    e.preventDefault()
    const { renderer, renderWindow } = context.current
    const fp = renderer.getActiveCamera().getFocalPoint()
    const p = renderer.getActiveCamera().getPosition()
    const dist = Math.sqrt(
      (p[0] - fp[0]) ** 2 + (p[1] - fp[1]) ** 2 + (p[2] - fp[2]) ** 2
    )
    renderer.getActiveCamera().setPosition(fp[0], fp[1] + dist, fp[2])
    renderer.getActiveCamera().setViewUp(1, 0, 0)
    renderWindow.render()
  }

  const handleOrientationWidgetVisibility = () => {
    if (context.current) {
      const { orientationWidget, axesOrientationWidget, renderWindow } =
        context.current
      setIsOrientationWidgetChecked(!isOrientationWidgetChecked)

      orientationWidget.setEnabled(!isOrientationWidgetChecked)
      axesOrientationWidget.setEnabled(!isOrientationWidgetChecked)
      renderWindow.render()
    }
  }

  const handleScalarBarVisibility = () => {
    if (context.current) {
      const { imageScalarBarActor, renderWindow } = context.current
      setScalarBarVisibility(!scalarBarVisibility)

      imageScalarBarActor.setVisibility(!scalarBarVisibility)

      renderWindow.render()
    }
  }

  const handleClose = () => {
    setMainActorData((prevState) => ({
      ...prevState,
      activeModal: null,
    }))
  }

  useEffect(() => {
    if (context.current) {
      const { axesOrientationWidget, imageScalarBarActor } = context.current
      setIsOrientationWidgetChecked(axesOrientationWidget.getEnabled())

      setScalarBarVisibility(imageScalarBarActor.getVisibility())
    }
  }, [])

  return (
    <DraggableModalSm handleClose={handleClose}>
      <h2 className="h-[36px] py-2 px-4 leading-tight text-md bg-blue rounded-t-2xl cursor-default shadow-[4px_4px_10px_0px_#00000040]">
        Visual parameters: {mainActorData.name}
      </h2>
      <form className="flex flex-col gap-4 p-4 w-full h-[calc(100%-36px)]">
        <div className="flex justify-between gap-4">
          <div className="flex flex-col gap-3 w-1/2">
            <div className="flex">
              <input
                type="radio"
                id="perspe"
                className="hidden"
                value="perspective"
                checked={!mainActorData.isCameraParallelProjection}
                onChange={handleCameraParallelProjection}
              />
              <label
                htmlFor="perspe"
                className="flex items-center gap-2 cursor-pointer"
              >
                <img src="/assets/images/perspe.svg" alt="" />
                <p
                  className={
                    !mainActorData.isCameraParallelProjection ? 'underline' : ''
                  }
                >
                  Perspective
                </p>
              </label>
            </div>
            <div className="flex">
              <input
                type="radio"
                id="ortho"
                className="hidden"
                value="orthogonal"
                checked={mainActorData.isCameraParallelProjection}
                onChange={handleCameraParallelProjection}
              />
              <label
                htmlFor="ortho"
                className="flex items-center gap-2 cursor-pointer"
              >
                <img src="/assets/images/ortho.svg" alt="" />
                <p
                  className={
                    mainActorData.isCameraParallelProjection ? 'underline' : ''
                  }
                >
                  Orthogonal
                </p>
              </label>
            </div>
            <button
              className="h-[35px] bg-blue transition duration-100 hover:bg-mid-blue-hover rounded-md"
              onClick={resetMainActorsCamera}
            >
              Reset camera
            </button>
          </div>
          <div className="flex flex-col gap-3 w-1/2">
            <Checkbox
              id="orientationWidget"
              isChecked={isOrientationWidgetChecked}
              setIsChecked={handleOrientationWidgetVisibility}
            >
              <img src="/assets/images/axes.svg" alt="" />
            </Checkbox>
            <div className="flex items-center h-[30px]">
              <input
                type="checkbox"
                id="volBox"
                // checked={false}
                className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
              />
              <label htmlFor="volBox" className="pl-2 block leading-5">
                Volume Box
              </label>
            </div>
            <div className="flex justify-between gap-2">
              <button
                className="h-[35px] w-[50px] bg-blue transition duration-100 hover:bg-mid-blue-hover rounded-md"
                onClick={setMainActorsCameraToXY}
              >
                XY
              </button>
              <button
                className="h-[35px] w-[50px] bg-blue transition duration-100 hover:bg-mid-blue-hover rounded-md"
                onClick={setMainActorsCameraToYZ}
              >
                YZ
              </button>
              <button
                className="h-[35px] w-[50px] bg-blue transition duration-100 hover:bg-mid-blue-hover rounded-md"
                onClick={setMainActorsCameraToXZ}
              >
                XZ
              </button>
            </div>
          </div>
        </div>
        <div>
          <Checkbox
            id="scalarBarMain"
            isChecked={scalarBarVisibility}
            setIsChecked={handleScalarBarVisibility}
          >
            <p>Scalar bar visibility</p>
          </Checkbox>
        </div>
      </form>
    </DraggableModalSm>
  )
}

export default VisualParametersModal
