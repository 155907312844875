import React, { useRef } from 'react'

import Draggable from 'react-draggable'

/**
 * Universal modal window with a small size of 400x410 pixels with ability to change its location on the page using drag and drop effect. Can be used as a template for various purposes.
 *
 * @component
 * @param {boolean} props.handleClose - function for closing the modal window when the "Close" button is clicked
 * @param {ReactNode} props.children - content that should be placed in the body of the modal window
 * @returns {JSX.Element} The rendered annotation modal window component.
 */
const DraggableModalSm = ({ handleClose, children }) => {
  const nodeRef = useRef(null)

  return (
    <Draggable handle="h2" nodeRef={nodeRef}>
      <div
        ref={nodeRef}
        className="absolute w-[400px] h-auto max-h-[410px] left-[40%] top-[41%] flex flex-col justify-center items-center shadow-[0px_0px_14px_12px_#00000040] bg-mid-blue rounded-2xl z-10"
      >
        <button className="absolute top-2 right-3 p-0.5" onClick={handleClose}>
          <img src="/assets/images/close-modal.svg" alt="" />
        </button>
        <div className="w-full h-full">{children}</div>
      </div>
    </Draggable>
  )
}

export default DraggableModalSm
