import React, { useState, createContext } from 'react'

export const TaxonomyContext = createContext(null)

const TaxonomyContextProvider = ({ children }) => {
  const [isTaxonomyActive, setIsTaxonomyActive] = useState(true)

  return (
    <TaxonomyContext.Provider value={{ isTaxonomyActive, setIsTaxonomyActive }}>
      {children}
    </TaxonomyContext.Provider>
  )
}

export default TaxonomyContextProvider
