import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import Menubar from './Menubar/Menubar'

const Layout = ({ children }) => {
  const [windowRatio, setWindowRatio] = useState(
    window.innerWidth / window.innerHeight
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowRatio(window.innerWidth / window.innerHeight)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <Menubar windowRatio={windowRatio} />
      <div
        className={clsx('p-[15px] desktop:p-[27px] my-0 mx-auto', {
          'max-w-[1920px]': windowRatio > 2,
        })}
      >
        {children}
      </div>
    </>
  )
}

export default Layout
