import React, { useState, useContext } from 'react'
import axios from 'axios'
import clsx from 'clsx'

import { FileContext } from '../../../contexts/FileContext'
import { GViewContext } from '../../../contexts/GViewContext'
import { MessengerContext } from '../../../contexts/MessengerContext'
import { PatientContext } from '../../../contexts/PatientContext'
import {
  applyZoomToSlices,
  renderAnatomy,
  renderDiagnostics,
  renderSegmentation,
} from '../../../utils/imageRendering'

/**
 * Modal for lungs diagnostic.
 *
 * @component
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function setter for 3D image
 * @param {function} props.setShowModal - function to set modal visibility
 * @param {function} props.setIsProcessing - function to set state of pipeline processing
 * @returns {JSX.Element} The rendered button component.
 */
const LungsHealthModal = ({
  mainActorData,
  setMainActorData,
  setShowModal,
  setIsProcessing,
}) => {
  const anatomy = [
    'LULobe',
    'LLLobe',
    'RULobe',
    'RMLobe',
    'RLLobe',
    'Airways',
    'Vessels',
  ]

  const diagnostics = [
    'Emphysema',
    'Ventilated',
    'Infiltrated',
    'Collapsed',
    'Pleural Effusion',
    'Nodules',
    'Covid',
  ]

  const [selectedItems, setSelectedItems] = useState(anatomy)

  const { messages, setMessages } = useContext(MessengerContext)
  const { context, color, setColor, vtkParentContainerRef } =
    useContext(GViewContext)
  const { setIsLoading, setToasts } = useContext(FileContext)
  const { patientData } = useContext(PatientContext)

  const toggleItem = (item) => {
    if (!item.includes('Lobe')) {
      setSelectedItems((prevSelectedItems) => {
        if (prevSelectedItems.includes(item)) {
          return prevSelectedItems.filter((i) => i !== item)
        } else {
          return [...prevSelectedItems, item]
        }
      })
    }
  }

  const addAllItems = (items) => {
    const nonLobeItems = items.filter((item) => !item.includes('Lobe'))
    setSelectedItems((prevSelectedItems) => [
      ...new Set([...prevSelectedItems, ...nonLobeItems]),
    ])
  }

  const removeAllItems = (items) => {
    const nonLobeItems = items.filter((item) => !item.includes('Lobe'))
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((item) => !nonLobeItems.includes(item))
    )
  }

  const isItemSelected = (item) => selectedItems.includes(item)

  const isAllSelected = (items) =>
    items.every((item) => selectedItems.includes(item))

  const lungsHealth = async () => {
    try {
      setMessages([...messages, 'Lungs diagnostic started'])
      setIsLoading(true)
      setIsProcessing(true)
      setShowModal(false)

      const pipelineResponse = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/lung_health',
        {
          ID: patientData.id,
          EID: patientData.eid,
          SID: patientData.sid,
          DIDs: selectedItems,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
          // responseType: 'arraybuffer',
        }
      )

      const pipelineList = pipelineResponse.data

      // const pipelineList = [
      //   {
      //     id: 'Infiltrated',
      //     name: 'Infiltrated',
      //     type: 'diagnostic',
      //     hasMask: false,
      //   },
      //   { id: 'Nodules', name: 'Nodules', type: 'diagnostic', hasMask: true },
      //   { id: 'LULobe', name: 'LULobe', type: 'anatomy', hasMask: true },
      //   { id: 'RULobe', name: 'RULobe', type: 'anatomy', hasMask: true },
      // ]

      for (const [index, pipelineItem] of pipelineList.entries()) {
        const foundItem = mainActorData.subActors.find(
          (subActor) => subActor.name === pipelineItem.name
        )

        if (foundItem) {
          console.log(foundItem.hasMask)
          console.log(foundItem.seg2DLoaded)
          if (foundItem.seg2DLoaded) {
            console.log(foundItem.name + ' 3D and 2D loaded')
          } else {
            console.log(foundItem.name + ' Load only 2D')
            await renderSegmentation({
              context,
              setMainActorData,
              color,
              setColor,
              patientData,
              segData: foundItem,
              setToasts,
              isLast: index === pipelineList.length - 1,
            })
          }
        } else {
          console.log(pipelineItem.name + ' Load 3D and 2D')
          if (pipelineItem.type === 'anatomy') {
            const segData = await renderAnatomy({
              context,
              setMainActorData,
              color,
              anatomyData: pipelineItem,
              patientData,
              setMessages,
              setToasts,
            })
            await renderSegmentation({
              context,
              setMainActorData,
              color,
              setColor,
              patientData,
              segData,
              setToasts,
              isLast: index === pipelineList.length - 1,
            })
          } else if (pipelineItem.type === 'diagnostic') {
            const segData = await renderDiagnostics({
              context,
              mainActorData,
              setMainActorData,
              diagnosticData: pipelineItem,
              patientData,
              setMessages,
              setToasts,
            })
            await renderSegmentation({
              context,
              setMainActorData,
              color,
              setColor,
              patientData,
              segData,
              setToasts,
              isLast: index === pipelineList.length - 1,
            })
          }
        }
      }
      applyZoomToSlices(context, vtkParentContainerRef)
    } catch (error) {
      console.error('Error:', error)
      setToasts((prev) => [
        ...prev,
        +process.env.REACT_APP_PROD_MODE_ENABLED
          ? 'An error occured while processing lungs diagnostic.'
          : error.response
            ? `Lungs diagnostic. Status: ${error.response.status} – ${error.response.statusText}.`
            : `Lungs diagnostic. ${error.message}.`,
      ])
    } finally {
      setIsLoading(false)
      setIsProcessing(false)
    }
  }

  return (
    <div className="absolute top-full left-1/2 translate-x-[-50%] h-[280px] w-[280px] mt-2 p-3 bg-mid-blue shadow-[0px_0px_14px_12px_#00000040] rounded-lg z-10">
      <div className="h-[calc(100%-40px)] overflow-y-auto">
        <ul>
          <div className="w-full flex justify-between items-center pl-[6px] mb-1">
            <p className="text-base font-semibold tracking-wide">Anatomy</p>
            <button
              className="text-sm font-light border rounded px-1 tracking-wide active:border-bright-blue active:text-bright-blue"
              onClick={() =>
                isAllSelected(anatomy)
                  ? removeAllItems(anatomy)
                  : addAllItems(anatomy)
              }
            >
              {isAllSelected(anatomy) ? 'Unselect All' : 'Select All'}
            </button>
          </div>
          {anatomy.map((item, index) => {
            const isSelected = isItemSelected(item)
            const isLobe = item.includes('Lobe')
            const isPrevSelected =
              index > 0 && isItemSelected(anatomy[index - 1])
            const isNextSelected =
              index < anatomy.length - 1 && isItemSelected(anatomy[index + 1])

            let className = ''
            if (isSelected) {
              if (!isPrevSelected && !isNextSelected) {
                className += ' rounded-md'
              } else if (isPrevSelected && !isNextSelected) {
                className += ' rounded-b-md'
              } else if (!isPrevSelected && isNextSelected) {
                className += ' rounded-t-md'
              }
            }
            return (
              <li
                className={clsx(className, {
                  'bg-semi-black': isSelected && !isLobe,
                  'bg-semi-transparent/20': isSelected && isLobe,
                })}
                key={item}
              >
                <div className="relative w-full">
                  <input
                    type="checkbox"
                    id={item}
                    checked={isItemSelected(item)}
                    onChange={() => toggleItem(item)}
                    className={clsx('hidden', {
                      'pointer-events-none': isLobe,
                    })}
                  />
                  <label
                    htmlFor={item}
                    className="pl-[26px] py-[5px] pr-[5px] block text-sm font-light leading-none tracking-wide cursor-default"
                  >
                    {item}
                    <span className="absolute top-[calc(50%-7px)] left-[6px] w-[14px] h-[14px] border border-bright-blue rounded" />
                    {isItemSelected(item) && (
                      <img
                        className="absolute top-[calc(50%-5px)] left-[8px] w-[10px] h-[10px]"
                        src="/assets/images/done.svg"
                        alt=""
                      />
                    )}
                  </label>
                </div>
              </li>
            )
          })}
          <div className="w-full flex justify-between items-center pl-[6px] my-1">
            <p className="text-base font-semibold tracking-wide">Diagnostics</p>
            <button
              className="text-sm font-light border rounded px-1 tracking-wide active:border-bright-blue active:text-bright-blue"
              onClick={() =>
                isAllSelected(diagnostics)
                  ? removeAllItems(diagnostics)
                  : addAllItems(diagnostics)
              }
            >
              {isAllSelected(diagnostics) ? 'Unselect All' : 'Select All'}
            </button>
          </div>
          {diagnostics.map((item, index) => {
            const isSelected = isItemSelected(item)
            const isPrevSelected =
              index > 0 && isItemSelected(diagnostics[index - 1])
            const isNextSelected =
              index < diagnostics.length - 1 &&
              isItemSelected(diagnostics[index + 1])

            let className = ''
            if (isSelected) {
              if (!isPrevSelected && !isNextSelected) {
                className += ' rounded-md'
              } else if (isPrevSelected && !isNextSelected) {
                className += ' rounded-b-md'
              } else if (!isPrevSelected && isNextSelected) {
                className += ' rounded-t-md'
              }
            }
            return (
              <li
                className={clsx(className, {
                  'bg-semi-black': isItemSelected(item),
                })}
                key={item}
              >
                <div className="relative w-full">
                  <input
                    type="checkbox"
                    id={item}
                    checked={isItemSelected(item)}
                    onChange={() => toggleItem(item)}
                    className="hidden"
                  />
                  <label
                    htmlFor={item}
                    className="pl-[26px] py-[5px] pr-[5px] block text-sm font-light leading-none tracking-wide cursor-default"
                  >
                    {item}
                    <span className="absolute top-[calc(50%-7px)] left-[6px] w-[14px] h-[14px] border border-bright-blue rounded" />
                    {isItemSelected(item) && (
                      <img
                        className="absolute top-[calc(50%-5px)] left-[8px] w-[10px] h-[10px]"
                        src="/assets/images/done.svg"
                        alt=""
                      />
                    )}
                  </label>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="flex items-center mt-2 gap-2">
        <button
          className="w-full p-1 border border-blue transition duration-100 hover:bg-semi-black rounded-md"
          onClick={() => setShowModal(false)}
        >
          Cancel
        </button>
        <button
          className={clsx('w-full p-1 transition duration-100 rounded-md', {
            'bg-blue hover:bg-mid-blue-hover': selectedItems.length > 0,
            'bg-pale-white text-semi-black pointer-events-none':
              selectedItems.length < 1,
          })}
          onClick={lungsHealth}
        >
          Start
        </button>
      </div>
    </div>
  )
}

export default LungsHealthModal
