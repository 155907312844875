import React, { useContext } from 'react'

import clsx from 'clsx'
import vtkMath from '@kitware/vtk.js/Common/Core/Math'
import { xyzToViewType } from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/Constants'

import useHandleRightButtonMenu from '../../hooks/useHandleRightButtonMenu'

import { GViewContext } from '../../contexts/GViewContext'
import { MeasuresContext } from '../../contexts/MeasuresContext'
import { PdfContext } from '../../contexts/PdfContext'
import RightButtonMenu from '../modals/RightButtonMenu'

/**
 * Сomponent for displaying a section along the Y axis in a 2D window
 *
 * @component
 * @param {string} props.view - parameter that determines which of the 4 windows are shown and which are hidden
 * @returns {JSX.Element} The rendered component with one 2D window.
 */
const WindowSliceY = ({ view }) => {
  const { measuresData, selectedWidgetIndex } = useContext(MeasuresContext)

  const {
    context,
    YSlice,
    setYSlice,
    sliceContainerY,
    activeWindow,
    isRightButtonMenuVisible,
    setIsRightButtonMenuVisible,
    setMenuPosition,
    color,
    coordinates,
    spacings,
    sliderMaxY,
  } = useContext(GViewContext)

  const { isPdfProcessing } = useContext(PdfContext)

  const changeSliderY = (e) => {
    if (context.current) {
      const { resliceCursorWidget, resliceCursorWidgetInstanceY, interactorY } =
        context.current
      setYSlice(Number(e.target.value))

      const normal = resliceCursorWidget.getWidgetState().getPlanes()[
        xyzToViewType[1]
      ].normal
      const planeExtremities = resliceCursorWidget.getPlaneExtremities(
        xyzToViewType[1]
      )
      const newCenter = vtkMath.multiplyAccumulate(
        planeExtremities[0],
        normal,
        Number(e.target.value),
        []
      )

      resliceCursorWidget.setCenter(newCenter)
      // resliceCursorWidgetInstanceY.invokeInternalInteractionEvent()
      resliceCursorWidgetInstanceY.invokeInteractionEvent(
        resliceCursorWidgetInstanceY.getActiveInteraction()
      )
      interactorY.render()
    }
  }

  useHandleRightButtonMenu({
    plane: 'y',
    measuresData,
    sliceContainer: sliceContainerY,
    selectedWidgetIndex,
    setMenuPosition,
    setIsRightButtonMenuVisible,
  })

  return (
    <>
      <div
        className={clsx('relative w-full h-full', {
          hidden:
            view === 'Morpho' ||
            view === 'Functional' ||
            view === 'Morpho-Functional' ||
            view === 'Morpho-Coronal' ||
            view === 'Morpho-Axial',
        })}
      >
        {!isPdfProcessing && (
          <input
            type="range"
            min="0"
            max={Math.floor(sliderMaxY)}
            step="1"
            value={YSlice}
            className="w-full px-[5px]"
            onChange={(e) => changeSliderY(e)}
          />
        )}
        <div
          className={clsx(
            'canvas-rounded relative border-[5px] rounded-[15px]',
            {
              'border-transparent': activeWindow !== 'y',
              'border-blue': activeWindow === 'y',
            }
          )}
          ref={sliceContainerY}
        >
          <div className="absolute left-1/2 translate-x-[-50%] w-[calc(100%-10px)] flex justify-between text-sm laptop:text-base desktop:text-xl font-light tracking-wide">
            <p>Sagittal-XZ plane</p>
            <p>
              {Math.round(YSlice / spacings.Y)}/
              {Math.round(sliderMaxY / spacings.Y)}
            </p>
          </div>
          <div className="absolute bottom-0 right-1/2 translate-x-[50%] w-[calc(100%-10px)] flex flex-col items-end text-sm laptop:text-base desktop:text-xl font-light tracking-wide">
            {coordinates.y.onImage ? (
              <p>
                Loc: ({coordinates.y.loc[0]} {Math.round(YSlice / spacings.Y)}{' '}
                {coordinates.y.loc[1]})
              </p>
            ) : (
              <>
                <p>
                  Pos: ({coordinates.y.pos[0]} {coordinates.y.pos[1]}{' '}
                  {coordinates.y.pos[2]})
                </p>
                <p>Off image</p>
              </>
            )}
            <p>Contrast: {coordinates.y.contrast}</p>
            <p>
              L:{Math.round(color.level)}, W:{Math.round(color.window)}
            </p>
          </div>
        </div>
      </div>
      {isRightButtonMenuVisible.y && (
        <RightButtonMenu
          plane="y"
          measuresWidgetManager={context.current.measuresWidgetManagerY}
        />
      )}
    </>
  )
}

export default WindowSliceY
