import React from 'react'

import Digiscan from './Digiscan/Digiscan'
import FileSave from './FileSave/FileSave'
import FileLoad from './FileLoad/FileLoad'

/**
 * Toolbar enabling file upload, file saving, and navigation to the DigiScan application.
 *
 * @component
 * @returns {JSX.Element} The rendered toolbar for file operations component.
 */
const ToolbarFileOps = () => {
  return (
    <section className="w-[20%] h-full bg-mid-blue rounded-[10px] shadow-[4px_4px_10px_0px_#00000040] px-[13px] desktop:px-[20px] py-[13px]">
      <div className="flex h-full items-center">
        <ul className="flex h-full w-full gap-[20px] justify-between items-center">
          <Digiscan />
          <FileLoad />
          <FileSave />
        </ul>
        {/* <p className="w-[50%] text-center">
          <span className="font-bold">File: </span>
          {fileName}
        </p> */}
      </div>
    </section>
  )
}

export default ToolbarFileOps
