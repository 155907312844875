import React, { useState, useContext, useRef } from 'react'

import { PresetColorMapContext } from '../../../contexts/PresetColorMapContext'
import useOutsideClick from '../../../hooks/useOutsideClick'
import { loadJson } from '../../../utils/imagePresets'
import { ActionsTreeContext } from '../../../contexts/ActionsTreeContext'
import Dropdown from '../../ui/Dropdown'

/**
 * Dropdown menu for changing the preset of a 3D image.
 *
 * @component
 * @param {Object | null} props.context - main storage for all vtk.js components. If the image was not loaded in the application, than equal to null.
 * @returns {JSX.Element} The rendered preset dropdown menu component.
 */
const ImagePreset = ({ context }) => {
  const [isPresetOpen, setIsPresetOpen] = useState(false)

  const presetRef = useRef(null)

  const { preset, setPreset, colorMap, presetList } = useContext(
    PresetColorMapContext
  )
  const { setMainActorData } = useContext(ActionsTreeContext)

  const handleSwitchPreset = async (e) => {
    if (context.current) {
      setPreset(e.target.innerText)
      setIsPresetOpen(false)
      try {
        const res = await loadJson(
          `/assets/json/organs/${e.target.innerText}.json`,
          `/assets/json/luts/${colorMap}.json`,
          context
        )

        setMainActorData((prevState) => ({
          ...prevState,
          info: {
            ...prevState.info,
            visualContrast: res.visualContrast,
            presetLevel: res.presetLevel,
            window: res.window,
          },
        }))
      } catch (error) {
        console.error(error)
      }
    }
  }

  useOutsideClick({
    dependency: isPresetOpen,
    ref: presetRef,
    setClosed: setIsPresetOpen,
  })

  return (
    <Dropdown
      menuRef={presetRef}
      caption="Preset WW/WL"
      isMenuOpen={isPresetOpen}
      setIsMenuOpen={setIsPresetOpen}
      optionsList={presetList}
      currentOption={preset}
      setNewOption={handleSwitchPreset}
    />
  )
}

export default ImagePreset
