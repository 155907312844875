import React, { useContext, useState, useRef } from 'react'

import { EVENT_ABORT } from '@kitware/vtk.js/macros'
import { xyzToViewType } from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/Constants'

import {
  createRulerInstance,
  createAnnotationInstance,
  createEllipseInstance,
  createCircleInstance,
  handleLineWidgetLeave,
  handleEllipseWidgetLeave,
} from '../../../utils/measures'
import { GViewContext } from '../../../contexts/GViewContext'
import { MeasuresContext } from '../../../contexts/MeasuresContext'
import { PatientContext } from '../../../contexts/PatientContext'

import useSetMeasuresVisibility from '../../../hooks/useSetMeasuresVisibility'
import useOutsideClick from '../../../hooks/useOutsideClick'
import useUpdateWidgetColor from '../../../hooks/useUpdateWidgetColor'
import AnnotationModal from '../../modals/AnnotationModal'
import Dropdown from '../../ui/Dropdown'

/**
 * Dropdown menu for changing the current measure mode (ruler, annotation, etc) of the 2D windows.
 *
 * @component
 * @param {Object | null} props.context - main storage for all vtk.js components. If the image was not loaded in the application, than equal to null.
 * @returns {JSX.Element} The rendered measures dropdown menu component.
 */
const Measures = ({ context }) => {
  const [isMeasuresOpen, setIsMeasuresOpen] = useState(false)
  const [currentMeasure, setCurrentMeasure] = useState('Default')

  const [isAnnotationModalVisible, setIsAnnotationModalVisible] =
    useState(false)

  const measuresRef = useRef(null)
  const old = useRef(null)

  const {
    XSlice,
    YSlice,
    ZSlice,
    spacings,
    sliceContainerX,
    sliceContainerY,
    sliceContainerZ,
    setActiveWindow,
    currentWidgetPosition,
  } = useContext(GViewContext)

  const { measuresData, selectedWidgetIndex, setSelectedWidgetIndex } =
    useContext(MeasuresContext)
  const { patientData } = useContext(PatientContext)

  const measuresList = [
    { text: 'Default', image: '/assets/images/default.svg' },
    { text: 'Ruler', image: '/assets/images/ruler.svg' },
    { text: 'Annotation', image: '/assets/images/annotation.svg' },
    { text: 'Ellipse', image: '/assets/images/ellipse.svg' },
    { text: 'Circle', image: '/assets/images/circle.svg' },
  ]

  function setMeasureModeCTscan(newChoice) {
    if (context.current) {
      const {
        source,
        rendererX,
        rendererY,
        rendererZ,
        interactorX,
        interactorY,
        interactorZ,
        measuresWidgetManagerX,
        measuresWidgetManagerY,
        measuresWidgetManagerZ,
        resliceCursorWidget,
      } = context.current
      if (old.current) {
        old.current.onX.unsubscribe()
        old.current.outX.unsubscribe()
        old.current.onY.unsubscribe()
        old.current.outY.unsubscribe()
        old.current.onZ.unsubscribe()
        old.current.outZ.unsubscribe()
      }
      switch (newChoice) {
        case 'Default':
          old.current = {
            onX: interactorX.onMouseEnter(() => EVENT_ABORT),
            outX: interactorX.onMouseLeave(() => EVENT_ABORT),
            onY: interactorY.onMouseEnter(() => EVENT_ABORT),
            outY: interactorY.onMouseEnter(() => EVENT_ABORT),
            onZ: interactorZ.onMouseEnter(() => EVENT_ABORT),
            outZ: interactorZ.onMouseEnter(() => EVENT_ABORT),
          }
          break
        case 'Ruler':
          old.current = {
            onX: interactorX.onMouseEnter(() => {
              createRulerInstance({
                plane: 'x',
                viewType: xyzToViewType[0],
                measuresData,
                measuresWidgetManager: measuresWidgetManagerX,
                renderer: rendererX,
                sliceContainer: sliceContainerX,
                currentWidgetPosition,
                resliceCursorWidget,
                setActiveWindow,
                setSelectedWidgetIndex,
                slice: Math.round(XSlice / spacings.X),
              })

              return EVENT_ABORT
            }),
            outX: interactorX.onMouseLeave(() => {
              handleLineWidgetLeave({
                plane: 'x',
                measuresWidgetManager: measuresWidgetManagerX,
                measuresData,
                setActiveWindow,
                setSelectedWidgetIndex,
              })
            }),
            onY: interactorY.onMouseEnter(() => {
              createRulerInstance({
                plane: 'y',
                viewType: xyzToViewType[1],
                measuresData,
                measuresWidgetManager: measuresWidgetManagerY,
                renderer: rendererY,
                sliceContainer: sliceContainerY,
                currentWidgetPosition,
                resliceCursorWidget,
                setActiveWindow,
                setSelectedWidgetIndex,
                slice: Math.round(YSlice / spacings.Y),
              })

              return EVENT_ABORT
            }),
            outY: interactorY.onMouseLeave(() => {
              handleLineWidgetLeave({
                plane: 'y',
                measuresWidgetManager: measuresWidgetManagerY,
                measuresData,
                setActiveWindow,
                setSelectedWidgetIndex,
              })
            }),
            onZ: interactorZ.onMouseEnter(() => {
              createRulerInstance({
                plane: 'z',
                viewType: xyzToViewType[2],
                measuresData,
                measuresWidgetManager: measuresWidgetManagerZ,
                renderer: rendererZ,
                sliceContainer: sliceContainerZ,
                currentWidgetPosition,
                resliceCursorWidget,
                setActiveWindow,
                setSelectedWidgetIndex,
                slice: Math.round(ZSlice / spacings.Z),
              })

              return EVENT_ABORT
            }),
            outZ: interactorZ.onMouseLeave(() => {
              handleLineWidgetLeave({
                plane: 'z',
                measuresWidgetManager: measuresWidgetManagerZ,
                measuresData,
                setActiveWindow,
                setSelectedWidgetIndex,
              })
            }),
          }
          break
        case 'Annotation':
          old.current = {
            onX: interactorX.onMouseEnter(() => {
              createAnnotationInstance({
                plane: 'x',
                viewType: xyzToViewType[0],
                measuresData,
                measuresWidgetManager: measuresWidgetManagerX,
                renderer: rendererX,
                sliceContainer: sliceContainerX,
                currentWidgetPosition,
                resliceCursorWidget,
                setActiveWindow,
                setSelectedWidgetIndex,
                slice: Math.round(XSlice / spacings.X),
                setIsAnnotationModalVisible,
              })

              return EVENT_ABORT
            }),
            outX: interactorX.onMouseLeave(() => {
              handleLineWidgetLeave({
                plane: 'x',
                measuresWidgetManager: measuresWidgetManagerX,
                measuresData,
                setActiveWindow,
                setSelectedWidgetIndex,
              })
            }),
            onY: interactorY.onMouseEnter(() => {
              createAnnotationInstance({
                plane: 'y',
                viewType: xyzToViewType[1],
                measuresData,
                measuresWidgetManager: measuresWidgetManagerY,
                renderer: rendererY,
                sliceContainer: sliceContainerY,
                currentWidgetPosition,
                resliceCursorWidget,
                setActiveWindow,
                setSelectedWidgetIndex,
                slice: Math.round(YSlice / spacings.Y),
                setIsAnnotationModalVisible,
              })

              return EVENT_ABORT
            }),
            outY: interactorY.onMouseLeave(() => {
              handleLineWidgetLeave({
                plane: 'y',
                measuresWidgetManager: measuresWidgetManagerY,
                measuresData,
                setActiveWindow,
                setSelectedWidgetIndex,
              })
            }),
            onZ: interactorZ.onMouseEnter(() => {
              createAnnotationInstance({
                plane: 'z',
                viewType: xyzToViewType[2],
                measuresData,
                measuresWidgetManager: measuresWidgetManagerZ,
                renderer: rendererZ,
                sliceContainer: sliceContainerZ,
                currentWidgetPosition,
                resliceCursorWidget,
                setActiveWindow,
                setSelectedWidgetIndex,
                slice: Math.round(ZSlice / spacings.Z),
                setIsAnnotationModalVisible,
              })

              return EVENT_ABORT
            }),
            outZ: interactorZ.onMouseLeave(() => {
              handleLineWidgetLeave({
                plane: 'z',
                measuresWidgetManager: measuresWidgetManagerZ,
                measuresData,
                setActiveWindow,
                setSelectedWidgetIndex,
              })
            }),
          }
          break
        case 'Ellipse':
          old.current = {
            onX: interactorX.onMouseEnter(() => {
              createEllipseInstance({
                plane: 'x',
                viewType: xyzToViewType[0],
                measuresData,
                measuresWidgetManager: measuresWidgetManagerX,
                renderer: rendererX,
                sliceContainer: sliceContainerX,
                currentWidgetPosition,
                resliceCursorWidget,
                setActiveWindow,
                setSelectedWidgetIndex,
                slice: Math.round(XSlice / spacings.X),
                source,
              })

              return EVENT_ABORT
            }),
            outX: interactorX.onMouseLeave(() => {
              handleEllipseWidgetLeave({
                plane: 'x',
                measuresWidgetManager: measuresWidgetManagerX,
                measuresData,
                setActiveWindow,
                setSelectedWidgetIndex,
              })
            }),
            onY: interactorY.onMouseEnter(() => {
              createEllipseInstance({
                plane: 'y',
                viewType: xyzToViewType[1],
                measuresData,
                measuresWidgetManager: measuresWidgetManagerY,
                renderer: rendererY,
                sliceContainer: sliceContainerY,
                currentWidgetPosition,
                resliceCursorWidget,
                setActiveWindow,
                setSelectedWidgetIndex,
                slice: Math.round(YSlice / spacings.Y),
                source,
              })

              return EVENT_ABORT
            }),
            outY: interactorY.onMouseLeave(() => {
              handleEllipseWidgetLeave({
                plane: 'y',
                measuresWidgetManager: measuresWidgetManagerY,
                measuresData,
                setActiveWindow,
                setSelectedWidgetIndex,
              })
            }),
            onZ: interactorZ.onMouseEnter(() => {
              createEllipseInstance({
                plane: 'z',
                viewType: xyzToViewType[2],
                measuresData,
                measuresWidgetManager: measuresWidgetManagerZ,
                renderer: rendererZ,
                sliceContainer: sliceContainerZ,
                currentWidgetPosition,
                resliceCursorWidget,
                setActiveWindow,
                setSelectedWidgetIndex,
                slice: Math.round(ZSlice / spacings.Z),
                source,
              })

              return EVENT_ABORT
            }),
            outZ: interactorZ.onMouseLeave(() => {
              handleEllipseWidgetLeave({
                plane: 'z',
                measuresWidgetManager: measuresWidgetManagerZ,
                measuresData,
                setActiveWindow,
                setSelectedWidgetIndex,
              })
            }),
          }
          break
        case 'Circle':
          old.current = {
            onX: interactorX.onMouseEnter(() => {
              createCircleInstance({
                plane: 'x',
                viewType: xyzToViewType[0],
                measuresData,
                measuresWidgetManager: measuresWidgetManagerX,
                renderer: rendererX,
                sliceContainer: sliceContainerX,
                currentWidgetPosition,
                resliceCursorWidget,
                setActiveWindow,
                setSelectedWidgetIndex,
                slice: Math.round(XSlice / spacings.X),
              })

              return EVENT_ABORT
            }),
            outX: interactorX.onMouseLeave(() => {
              handleEllipseWidgetLeave({
                plane: 'x',
                measuresWidgetManager: measuresWidgetManagerX,
                measuresData,
                setActiveWindow,
                setSelectedWidgetIndex,
              })
            }),
            onY: interactorY.onMouseEnter(() => {
              createCircleInstance({
                plane: 'y',
                viewType: xyzToViewType[1],
                measuresData,
                measuresWidgetManager: measuresWidgetManagerY,
                renderer: rendererY,
                sliceContainer: sliceContainerY,
                currentWidgetPosition,
                resliceCursorWidget,
                setActiveWindow,
                setSelectedWidgetIndex,
                slice: Math.round(YSlice / spacings.Y),
              })

              return EVENT_ABORT
            }),
            outY: interactorY.onMouseLeave(() => {
              handleEllipseWidgetLeave({
                plane: 'y',
                measuresWidgetManager: measuresWidgetManagerY,
                measuresData,
                setActiveWindow,
                setSelectedWidgetIndex,
              })
            }),
            onZ: interactorZ.onMouseEnter(() => {
              createCircleInstance({
                plane: 'z',
                viewType: xyzToViewType[2],
                measuresData,
                measuresWidgetManager: measuresWidgetManagerZ,
                renderer: rendererZ,
                sliceContainer: sliceContainerZ,
                currentWidgetPosition,
                resliceCursorWidget,
                setActiveWindow,
                setSelectedWidgetIndex,
                slice: Math.round(ZSlice / spacings.Z),
              })

              return EVENT_ABORT
            }),
            outZ: interactorZ.onMouseLeave(() => {
              handleEllipseWidgetLeave({
                plane: 'z',
                measuresWidgetManager: measuresWidgetManagerZ,
                measuresData,
                setActiveWindow,
                setSelectedWidgetIndex,
              })
            }),
          }
          break
        default:
          break
      }
      setCurrentMeasure(newChoice)
    }
  }
  function setMeasureModeXRay(newChoice) {
    if (context.current) {
      const {
        source,
        rendererZ,
        interactorZ,
        measuresWidgetManagerZ,
        resliceCursorWidget,
      } = context.current
      if (old.current) {
        old.current.onZ.unsubscribe()
        old.current.outZ.unsubscribe()
      }
      switch (newChoice) {
        case 'Default':
          old.current = {
            onZ: interactorZ.onMouseEnter(() => EVENT_ABORT),
            outZ: interactorZ.onMouseEnter(() => EVENT_ABORT),
          }
          break
        case 'Ruler':
          old.current = {
            onZ: interactorZ.onMouseEnter(() => {
              createRulerInstance({
                plane: 'z',
                viewType: xyzToViewType[2],
                measuresData,
                measuresWidgetManager: measuresWidgetManagerZ,
                renderer: rendererZ,
                sliceContainer: sliceContainerZ,
                currentWidgetPosition,
                resliceCursorWidget,
                setActiveWindow,
                setSelectedWidgetIndex,
              })

              return EVENT_ABORT
            }),
            outZ: interactorZ.onMouseLeave(() => {
              handleLineWidgetLeave({
                plane: 'z',
                measuresWidgetManager: measuresWidgetManagerZ,
                measuresData,
                setActiveWindow,
                setSelectedWidgetIndex,
              })
            }),
          }
          break
        case 'Annotation':
          old.current = {
            onZ: interactorZ.onMouseEnter(() => {
              createAnnotationInstance({
                plane: 'z',
                viewType: xyzToViewType[2],
                measuresData,
                measuresWidgetManager: measuresWidgetManagerZ,
                renderer: rendererZ,
                sliceContainer: sliceContainerZ,
                currentWidgetPosition,
                resliceCursorWidget,
                setActiveWindow,
                setSelectedWidgetIndex,
                setIsAnnotationModalVisible,
              })

              return EVENT_ABORT
            }),
            outZ: interactorZ.onMouseLeave(() => {
              handleLineWidgetLeave({
                plane: 'z',
                measuresWidgetManager: measuresWidgetManagerZ,
                measuresData,
                setActiveWindow,
                setSelectedWidgetIndex,
              })
            }),
          }
          break
        case 'Ellipse':
          old.current = {
            onZ: interactorZ.onMouseEnter(() => {
              createEllipseInstance({
                plane: 'z',
                viewType: xyzToViewType[2],
                measuresData,
                measuresWidgetManager: measuresWidgetManagerZ,
                renderer: rendererZ,
                sliceContainer: sliceContainerZ,
                currentWidgetPosition,
                resliceCursorWidget,
                setActiveWindow,
                setSelectedWidgetIndex,
                source,
              })

              return EVENT_ABORT
            }),
            outZ: interactorZ.onMouseLeave(() => {
              handleEllipseWidgetLeave({
                plane: 'z',
                measuresWidgetManager: measuresWidgetManagerZ,
                measuresData,
                setActiveWindow,
                setSelectedWidgetIndex,
              })
            }),
          }
          break
        case 'Circle':
          old.current = {
            onZ: interactorZ.onMouseEnter(() => {
              createCircleInstance({
                plane: 'z',
                viewType: xyzToViewType[2],
                measuresData,
                measuresWidgetManager: measuresWidgetManagerZ,
                renderer: rendererZ,
                sliceContainer: sliceContainerZ,
                currentWidgetPosition,
                resliceCursorWidget,
                setActiveWindow,
                setSelectedWidgetIndex,
              })

              return EVENT_ABORT
            }),
            outZ: interactorZ.onMouseLeave(() => {
              handleEllipseWidgetLeave({
                plane: 'z',
                measuresWidgetManager: measuresWidgetManagerZ,
                measuresData,
                setActiveWindow,
                setSelectedWidgetIndex,
              })
            }),
          }
          break
        default:
          break
      }
      setCurrentMeasure(newChoice)
    }
  }

  const handleSwitchMeasure = (e) => {
    if (patientData && patientData.aid === 'CTscan' && context.current) {
      setMeasureModeCTscan(e.target.innerText)
      setIsMeasuresOpen(false)
    } else if (patientData && patientData.aid === 'XRay' && context.current) {
      setMeasureModeXRay(e.target.innerText)
      setIsMeasuresOpen(false)
    }
  }

  useUpdateWidgetColor({
    plane: 'x',
    measuresData,
    interactor: context.current ? context.current.interactorX : null,
    selectedWidgetIndex,
  })
  useUpdateWidgetColor({
    plane: 'y',
    measuresData,
    interactor: context.current ? context.current.interactorY : null,
    selectedWidgetIndex,
  })
  useUpdateWidgetColor({
    plane: 'z',
    measuresData,
    interactor: context.current ? context.current.interactorZ : null,
    selectedWidgetIndex,
  })

  useSetMeasuresVisibility({
    plane: 'x',
    planeNum: 0,
    measuresData,
    slice: XSlice,
    currentWidgetPosition,
    renderWindow: context.current && context.current?.renderWindowX,
  })
  useSetMeasuresVisibility({
    plane: 'y',
    planeNum: 1,
    measuresData,
    slice: YSlice,
    currentWidgetPosition,
    renderWindow: context.current && context.current?.renderWindowY,
  })
  useSetMeasuresVisibility({
    plane: 'z',
    planeNum: 2,
    measuresData,
    slice: ZSlice,
    currentWidgetPosition,
    renderWindow: context.current && context.current?.renderWindowZ,
  })

  useOutsideClick({
    dependency: isMeasuresOpen,
    ref: measuresRef,
    setClosed: setIsMeasuresOpen,
  })

  return (
    <>
      <Dropdown
        menuRef={measuresRef}
        caption="Measures"
        isMenuOpen={isMeasuresOpen}
        setIsMenuOpen={setIsMeasuresOpen}
        optionsList={measuresList}
        currentOption={currentMeasure}
        setNewOption={handleSwitchMeasure}
      />
      {isAnnotationModalVisible && (
        <AnnotationModal
          isAnnotationModalVisible={isAnnotationModalVisible}
          setIsAnnotationModalVisible={setIsAnnotationModalVisible}
        />
      )}
    </>
  )
}

export default Measures
