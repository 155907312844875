import React, { useContext } from 'react'

import ToolbarFiltersButton from '../ui/ToolbarFiltersButton'
import { ActionsTreeContext } from '../../../contexts/ActionsTreeContext'

/**
 * A button to open the diagnostics page
 *
 * @component
 * @param {string | null} props.activeFilter - variable holds an active filter or null if there are no active filters. Determines whether the button is active.
 * @param {function} props.setActiveScreen – function that sets the corresponding screen if button responsible for it
 * @param {function} props.setActiveFilter - function that is triggered by pressing a button
 * @returns {JSX.Element} The rendered diagnostics button component.
 */
const DiagnosticsButton = ({
  setActiveScreen,
  activeFilter,
  setActiveFilter,
}) => {
  const { mainActorData } = useContext(ActionsTreeContext)

  const handleClick = () => {
    if (activeFilter !== 'Dx') {
      setActiveFilter('Dx')
      setActiveScreen('Dx')
    } else {
      setActiveFilter(null)
      setActiveScreen('GView')
    }
  }

  return (
    <ToolbarFiltersButton
      caption="Dx"
      activeFilter={activeFilter}
      handleClick={handleClick}
      isDisabled={!mainActorData}
      activeImageLink="/assets/images/diagnostic_active.svg"
      inactiveImageLink="/assets/images/diagnostic.svg"
      disabledImageLink="/assets/images/diagnostic_disabled.svg"
      tooltipText="Show diagnostic"
    />
  )
}

export default DiagnosticsButton
