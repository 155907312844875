import React, { useContext } from 'react'

import { PatientContext } from '../../../contexts/PatientContext'
import { ActionsTreeContext } from '../../../contexts/ActionsTreeContext'
import { GViewContext } from '../../../contexts/GViewContext'
import { FileContext } from '../../../contexts/FileContext'

import {
  applyZoomToSlices,
  renderSegmentation,
} from '../../../utils/imageRendering'
import ToolbarFiltersButton from '../ui/ToolbarFiltersButton'

/**
 * A button to load the 2D segmentation masks
 *
 * @component
 * @param {string | null} props.activeFilter - variable holds an active filter or null if there are no active filters. Determines whether the button is active and, accordingly, whether the modal window is invoked.
 * @param {string} props.activeScreen – variable holds an active screen.
 * @returns {JSX.Element} The rendered lung segment button component
 */
const LungSegmentButton = ({ activeFilter, activeScreen }) => {
  const { patientData } = useContext(PatientContext)
  const { mainActorData, setMainActorData } = useContext(ActionsTreeContext)
  const { context, color, setColor, vtkParentContainerRef } =
    useContext(GViewContext)
  const { setIsLoading, setToasts } = useContext(FileContext)

  const handleClick = async () => {
    if (mainActorData.subActors.length) {
      setIsLoading(true)
      // const segmentationPromises = mainActorData.subActors.map(
      //   (subActor, index) =>
      //     renderSegmentation({
      //       context,
      //       setMainActorData,
      //       color,
      //       setColor,
      //       patientData,
      //       segData: subActor,
      //       setToasts,
      //       isLast: index === mainActorData.subActors.length - 1,
      //     })
      // )
      // await Promise.all(segmentationPromises)

      for (const [index, subActor] of mainActorData.subActors.entries()) {
        await renderSegmentation({
          context,
          setMainActorData,
          color,
          setColor,
          patientData,
          segData: subActor,
          setToasts,
          isLast: index === mainActorData.subActors.length - 1,
        })
      }

      applyZoomToSlices(context, vtkParentContainerRef)

      setIsLoading(false)
    }
  }
  return (
    <ToolbarFiltersButton
      caption="Lungs mask"
      activeFilter={activeFilter}
      handleClick={handleClick}
      isDisabled={
        activeScreen !== 'GView' ||
        !mainActorData ||
        mainActorData.subActors.every((subActor) => subActor.seg2DLoaded)
      }
      activeImageLink="/assets/images/lung-segment.svg"
      inactiveImageLink="/assets/images/lung-segment.svg"
      disabledImageLink="/assets/images/lung-segment_disabled.svg"
      tooltipText="Load mask"
    />
  )
}

export default LungSegmentButton
