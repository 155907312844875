import React, { useState, useContext, useRef, useEffect } from 'react'

import {
  handleBlur,
  handleFocus,
} from '../../../../../utils/interactorHandlersInTextField'

import { GViewContext } from '../../../../../contexts/GViewContext'

import DraggableModalMd from '../../../../modals/DraggableModalMd'

/**
 * Modal window with text report
 *
 * @component
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered modal window component.
 */
const ReportModal = ({ mainActorData, setMainActorData }) => {
  const [report, setReport] = useState('')
  const reportRef = useRef(null)

  const { context } = useContext(GViewContext)

  const saveReportChanges = (e) => {
    e.preventDefault()
    setMainActorData((prevState) => ({
      ...prevState,
      reportText: report,
      activeModal: null,
    }))
    setReport('')
  }

  const handleClose = () => {
    setMainActorData((prevState) => ({
      ...prevState,
      activeModal: null,
    }))
  }

  useEffect(() => {
    if (mainActorData.activeModal === 'report') {
      setReport(mainActorData.reportText)
    }
  }, [mainActorData.activeModal])

  return (
    <DraggableModalMd handleClose={handleClose} handleStopDragging={() => {}}>
      <h2 className="h-[36px] py-2 px-4 leading-tight text-md bg-blue rounded-t-2xl cursor-default shadow-[4px_4px_10px_0px_#00000040]">
        Case folder: {mainActorData.name}
      </h2>
      <form className="flex p-4 flex-col w-full h-[calc(100%-36px)]">
        <textarea
          value={report}
          onChange={(e) => setReport(e.target.value)}
          onFocus={() => handleFocus(context)}
          onBlur={() => handleBlur(context)}
          ref={reportRef}
          autoFocus
          className="w-full h-[calc(100%-40px)] border-2 border-blue focus:border-pale-blue rounded-md px-2 py-2 bg-dark-blue outline-none resize-none"
        ></textarea>
        <button
          onClick={saveReportChanges}
          className="h-[33px] mt-[7px] bg-blue transition duration-100 hover:bg-mid-blue-hover rounded-md"
        >
          Save
        </button>
      </form>
    </DraggableModalMd>
  )
}

export default ReportModal
