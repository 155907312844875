import React from 'react'

import ActionsTreeItemButton from '../../../ui/ActionsTreeItemButton'

/**
 * Button to call a modal window with planes parameters
 *
 * @component
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered button with modal window component.
 */
const PlanesVisibilityButton = ({ mainActorData, setMainActorData }) => {
  const handleActive = () => {
    setMainActorData((prevState) => ({
      ...prevState,
      activeModal: mainActorData.activeModal === 'planes' ? null : 'planes',
      functionalActor: {
        ...prevState.functionalActor,
        isModalActive: false,
      },
    }))
  }

  return (
    <ActionsTreeItemButton
      handleActive={handleActive}
      isActive={mainActorData.activeModal === 'planes'}
      activeImageLink="/assets/images/plane_active.svg"
      inactiveImageLink="/assets/images/plane.svg"
    />
  )
}

export default PlanesVisibilityButton
