import React from 'react'

import SubActorVisibilityButton from './ActionsTreeSubItemButtons/SubActorVisibility/SubActorVisibilityButton'
/**
 * Sub item for ActionsTree (mostly solids)
 *
 * @component
 * @param {number} props.index - index of the sub actor in the subActors list
 * @param {Object} props.subActorData - object with data corresponding to the sub items
 * @param {function} props.setMainActorData - function that sets data to the main item object
 * @returns {JSX.Element} The rendered ActionsTreeItem component.
 */
const ActionsTreeSubItem = ({ index, subActorData, setMainActorData }) => {
  return (
    <li className="flex gap-1 desktop:gap-2 w-full align-center justify-between">
      <p className="flex w-[calc(100%-20px)] desktop:w-[calc(100%-29px)] text-sm desktop:text-base truncate">
        <img src="/assets/images/angle.svg" alt=""></img>
        {subActorData.name}
      </p>
      <div className="flex justify-between gap-2">
        <SubActorVisibilityButton
          index={index}
          subActorData={subActorData}
          setMainActorData={setMainActorData}
        />
      </div>
    </li>
  )
}

export default ActionsTreeSubItem
