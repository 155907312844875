import React, { useState, useContext } from 'react'
import axios from 'axios'
import clsx from 'clsx'
import { formatDistanceToNow, parseISO } from 'date-fns'

import DraggableModalMd from '../../modals/DraggableModalMd'
import { FileContext } from '../../../contexts/FileContext'

/**
 * Menubar item (Onshape) component
 *
 * @component
 * @param {boolean} props.isActive - determines whether a menu item is active
 * @param {function} props.onClick - function to close or open current menu item
 * @param {function} props.onMouseOver - function to change active menu item with onMouseOver event
 * @returns {JSX.Element} The rendered menubar item component.
 */
const Onshape = ({ isActive, onClick, onMouseOver }) => {
  const [onshapeDocsList, setOnshapeDocsList] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedDoc, setSelectedDoc] = useState(null)
  const [onshapeTabsList, setOnshapeTabsList] = useState([])
  const [selectedTab, setSelectedTab] = useState(null)
  const [currentScreen, setCurrentScreen] = useState('docs')
  const [isOnshapeLoading, setIsOnshapeLoading] = useState(false)
  const [step, setStep] = useState('')

  const { setToasts } = useContext(FileContext)

  const getOnshapeDocs = async () => {
    try {
      setIsOnshapeLoading(true)
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/onshape_get_docs',
        {
          withCredentials: true,
        }
      )

      console.log(response.data)

      if ('items' in response.data && response.data.items.length) {
        const extractedData = await Promise.all(
          response.data.items.map(async (item) => {
            const thumbnail300x300 = item.thumbnail?.sizes?.find(
              (size) => size.size === '300x300'
            )

            let thumbnailUrl = ''
            if (thumbnail300x300) {
              try {
                const thumbnailResponse = await axios.post(
                  process.env.REACT_APP_SERVER_URL + '/onshape_get_image',
                  {
                    // path: thumbnail300x300.href,
                    d: item.id,
                    w: item.defaultWorkspace.id,
                    // e: item.defaultElementId,
                  },
                  {
                    withCredentials: true,
                    responseType: 'blob',
                  }
                )
                thumbnailUrl = URL.createObjectURL(thumbnailResponse.data)
              } catch (error) {
                console.error('Error:', error)
                setToasts((prev) => [
                  ...prev,
                  +process.env.REACT_APP_PROD_MODE_ENABLED
                    ? 'An error occured while retrieving thumbnail image.'
                    : error.response
                      ? `Thumbnail image. Status: ${error.response.status} – ${error.response.statusText}.`
                      : `Thumbnail image. ${error.message}.`,
                ])
              }
            }

            return {
              createdBy: item.createdBy.name,
              createdAt: item.createdAt,
              name: item.name,
              d: item.id,
              w: item.defaultWorkspace.id,
              thumbnail: thumbnailUrl,
            }
          })
        )
        setOnshapeDocsList(extractedData)
        console.log(extractedData)
      }
      setIsModalOpen(true)
    } catch (error) {
      console.error('Error:', error)
      setToasts((prev) => [
        ...prev,
        +process.env.REACT_APP_PROD_MODE_ENABLED
          ? 'An error occured while retrieving onshape docs.'
          : error.response
            ? `Onshape docs. Status: ${error.response.status} – ${error.response.statusText}.`
            : `Onshape docs. ${error.message}.`,
      ])
    } finally {
      setIsOnshapeLoading(false)
    }
  }

  const getOnshapeTabs = async () => {
    try {
      setIsOnshapeLoading(true)
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/onshape_get_tabs',
        {
          d: selectedDoc.d,
          w: selectedDoc.w,
        },
        {
          withCredentials: true,
        }
      )

      console.log(response.data)

      const extractedData = response.data.map((item) => ({
        name: item.name,
        dataType: item.dataType,
        e: item.id,
      }))
      setOnshapeTabsList(extractedData)
      console.log(extractedData)
      setCurrentScreen('tabs')
    } catch (error) {
      console.error('Error:', error)
      setToasts((prev) => [
        ...prev,
        +process.env.REACT_APP_PROD_MODE_ENABLED
          ? 'An error occured while retrieving onshape tabs.'
          : error.response
            ? `Onshape tabs. Status: ${error.response.status} – ${error.response.statusText}.`
            : `Onshape tabs. ${error.message}.`,
      ])
    } finally {
      setIsOnshapeLoading(false)
    }
  }

  const getStep = async () => {
    try {
      setIsOnshapeLoading(true)
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + '/onshape_get_partstudio',
        {
          d: selectedDoc.d,
          w: selectedDoc.w,
          e: selectedTab.e,
          dataType: selectedTab.dataType,
        },
        {
          withCredentials: true,
          responseType: 'text',
        }
      )
      if (selectedTab.dataType === 'application/step') {
        const blob = new Blob([response.data], {
          type: 'text/plain',
        })

        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          selectedTab.name.toLowerCase().endsWith('.step')
            ? `${selectedTab.name}`
            : `${selectedTab.name}.step`
        )
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
        console.log(blob)
        // setStep(response.data)
      }
    } catch (error) {
      console.error('Error:', error)
      setToasts((prev) => [
        ...prev,
        +process.env.REACT_APP_PROD_MODE_ENABLED
          ? 'An error occured while retrieving file.'
          : error.response
            ? `PartStudio file. Status: ${error.response.status} – ${error.response.statusText}.`
            : `PartStudio file. ${error.message}.`,
      ])
    } finally {
      setIsOnshapeLoading(false)
    }
  }

  const handleDocsDoubleClick = (doc) => {
    setSelectedDoc(doc)
    getOnshapeTabs()
  }
  const handleTabsDoubleClick = async (tab) => {
    setSelectedTab(tab)
    await getStep()
  }

  const goBackToDocs = () => {
    setSelectedTab(null)
    setOnshapeTabsList([])
    setCurrentScreen('docs')
  }

  const handleClose = () => {
    setIsModalOpen(false)
    setOnshapeTabsList([])
    setCurrentScreen('docs')
    setSelectedDoc(null)
    setSelectedTab(null)
  }

  return (
    <>
      <li className="relative" onMouseOver={onMouseOver} onClick={onClick}>
        <button
          className={clsx('h-full px-4 rounded border border-transparent', {
            'bg-blue border-blue': isActive,
          })}
        >
          Onshape
        </button>
        {isActive && (
          <ul className="absolute top-full py-1 px-1 bg-mid-blue/80 backdrop-blur w-[160px] max-h-[260px] overflow-y-scroll border border-semi-transparent rounded z-10">
            <li className="relative py-1 px-3 rounded hover:bg-blue-hover">
              <button className="w-full text-left" onClick={getOnshapeDocs}>
                Open
              </button>
            </li>
          </ul>
        )}
      </li>
      {isModalOpen ? (
        <DraggableModalMd
          handleClose={handleClose}
          handleStopDragging={() => {}}
        >
          <h2 className="h-[36px] py-2 px-4 leading-tight text-md bg-blue rounded-t-2xl cursor-default shadow-[4px_4px_10px_0px_#00000040]">
            Onshape docs
          </h2>
          <div className="p-4 h-[calc(100%-36px)]">
            <ul
              className={clsx(
                'flex flex-col h-[calc(100%-40px)] overflow-y-auto',
                {
                  hidden: currentScreen !== 'docs',
                }
              )}
            >
              {onshapeDocsList.length ? (
                onshapeDocsList.map((doc) => (
                  <li key={doc.d}>
                    <button
                      className={clsx(
                        'flex w-full p-2 rounded cursor-default',
                        {
                          'bg-blue':
                            JSON.stringify(selectedDoc) === JSON.stringify(doc),
                          'pointer-events-none': isOnshapeLoading,
                        }
                      )}
                      onClick={() => setSelectedDoc(doc)}
                      onDoubleClick={() => handleDocsDoubleClick(doc)}
                    >
                      <img
                        className="w-[64px] h-[64px]"
                        src={
                          doc.thumbnail
                            ? doc.thumbnail
                            : '/assets/images/not-found.svg'
                        }
                        alt=""
                      />
                      <div className="flex flex-col items-start ml-4">
                        <h4 className="font-semibold">{doc.name}</h4>
                        <p className="font-light text-sm">
                          <span className="font-normal">Author: </span>
                          {doc.createdBy}
                        </p>
                        <p className="font-light text-sm">
                          <span className="font-normal">Created: </span>
                          {formatDistanceToNow(parseISO(doc.createdAt), {
                            addSuffix: true,
                          })}
                        </p>
                      </div>
                    </button>
                  </li>
                ))
              ) : (
                <p>Empty docs</p>
              )}
            </ul>
            {onshapeTabsList.length ? (
              <div className="h-[calc(100%-40px)]">
                <button
                  className="bg-blue w-[150px] h-[40px] rounded mb-2 hover:bg-blue-hover transition duration-100 active:shadow-[inset_0px_0px_14px_5px_#00000020]"
                  onClick={goBackToDocs}
                >
                  Go Back
                </button>
                <ul
                  className={clsx(
                    'flex flex-col h-[calc(100%-56px)] overflow-y-auto',
                    {
                      hidden: currentScreen !== 'tabs',
                    }
                  )}
                >
                  {onshapeTabsList.map((tab) => (
                    <li key={tab.e}>
                      <button
                        className={clsx(
                          'flex w-full p-2 rounded cursor-default',
                          {
                            'bg-blue':
                              JSON.stringify(selectedTab) ===
                              JSON.stringify(tab),
                            'pointer-events-none': isOnshapeLoading,
                          }
                        )}
                        onClick={() => setSelectedTab(tab)}
                        onDoubleClick={() => handleTabsDoubleClick(tab)}
                      >
                        <div className="flex flex-col items-start ml-0">
                          <h4 className="font-semibold">{tab.name}</h4>
                          <p className="font-light text-sm">
                            <span className="font-normal">Type: </span>
                            {tab.dataType}
                          </p>
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              ''
            )}
            <div className="flex justify-end h-[40px]">
              {currentScreen === 'docs' && (
                <button
                  className={clsx(
                    'bg-blue w-[150px] h-[40px] rounded hover:bg-blue-hover transition duration-100 active:shadow-[inset_0px_0px_14px_5px_#00000020]',
                    {
                      'bg-semi-transparent text-black pointer-events-none':
                        !selectedDoc ||
                        !onshapeDocsList.length ||
                        isOnshapeLoading,
                    }
                  )}
                  onClick={getOnshapeTabs}
                >
                  {!isOnshapeLoading ? (
                    'Load elements'
                  ) : (
                    <img
                      className="w-[150px] h-[40px]"
                      src="/assets/images/button-loader.svg"
                      alt=""
                    />
                  )}
                </button>
              )}
              {currentScreen === 'tabs' && (
                <button
                  className={clsx(
                    'bg-blue w-[150px] h-[40px] rounded hover:bg-blue-hover transition duration-100 active:shadow-[inset_0px_0px_14px_5px_#00000020]',
                    {
                      'bg-semi-transparent text-black pointer-events-none':
                        !selectedTab ||
                        !onshapeTabsList.length ||
                        isOnshapeLoading,
                    }
                  )}
                  onClick={getStep}
                >
                  {!isOnshapeLoading ? (
                    'Save'
                  ) : (
                    <img
                      className="w-[150px] h-[40px]"
                      src="/assets/images/button-loader.svg"
                      alt=""
                    />
                  )}
                </button>
              )}
            </div>
          </div>
        </DraggableModalMd>
      ) : (
        ''
      )}
    </>
  )
}

export default Onshape
