import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

import { ActionsTreeContext } from '../../../contexts/ActionsTreeContext'
import { GViewContext } from '../../../contexts/GViewContext'
import { ViewContext } from '../../../contexts/ViewContext'
import { TaxonomyContext } from '../../../contexts/TaxonomyContext'
import { PdfContext } from '../../../contexts/PdfContext'
import { PatientContext } from '../../../contexts/PatientContext'

/**
 * Menubar item (File) component
 *
 * @component
 * @param {boolean} props.isActive - determines whether a menu item is active
 * @param {function} props.onClick - function to close or open current menu item
 * @param {function} props.onMouseOver - function to change active menu item with onMouseOver event
 * @returns {JSX.Element} The rendered menubar item component.
 */
const File = ({ isActive, onClick, onMouseOver }) => {
  const [isSubmenuActive, setSubmenuActive] = useState(false)

  const { mainActorData } = useContext(ActionsTreeContext)
  const { setActiveScreen, vtkParentContainerRef } = useContext(GViewContext)
  const { patientData } = useContext(PatientContext)
  const { setIsTaxonomyActive } = useContext(TaxonomyContext)
  const { setView } = useContext(ViewContext)
  const { pdfRef, isPdfProcessing, setIsPdfProcessing } = useContext(PdfContext)

  useEffect(() => {
    const generatePdf = async () => {
      if (isPdfProcessing) {
        const pdf = new jsPDF('p', 'mm', 'a4')
        const pageHeight = pdf.internal.pageSize.getHeight()
        const pageWidth = pdf.internal.pageSize.getWidth()
        let currentYOffset = 0

        const addContentToPdf = async (content) => {
          const canvas = await html2canvas(content)
          const imgData = canvas.toDataURL('image/png')
          const imgProps = pdf.getImageProperties(imgData)
          const pdfWidth = pageWidth
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

          if (currentYOffset + pdfHeight > pageHeight) {
            pdf.addPage()
            currentYOffset = 0
          }
          pdf.addImage(imgData, 'PNG', 0, currentYOffset, pdfWidth, pdfHeight)
          currentYOffset += pdfHeight
        }

        const childDivs = pdfRef.current.children
        for (const div of childDivs) {
          await addContentToPdf(div)
        }

        await addContentToPdf(vtkParentContainerRef.current)

        const pdfOutput = pdf.output('blob')
        const pdfUrl = URL.createObjectURL(pdfOutput)
        window.open(pdfUrl, '_blank')
        setIsPdfProcessing(false)
      }
    }

    generatePdf()
  }, [isPdfProcessing])

  const handleCreatePdf = () => {
    setSubmenuActive(false)
    if (patientData && patientData.aid === 'CTscan') {
      setView('Four')
      setActiveScreen('GView')
      setIsTaxonomyActive(true)
    }
    setIsPdfProcessing(true)
  }

  return (
    <li className="relative" onMouseOver={onMouseOver} onClick={onClick}>
      <button
        className={clsx('h-full px-4 rounded border border-transparent', {
          'bg-blue border-blue': isActive,
        })}
      >
        File
      </button>
      {isActive && (
        <ul className="absolute top-full bg-mid-blue/80 py-1 backdrop-blur w-[160px] max-h-[260px] border border-semi-transparent rounded z-10">
          <li
            className="relative px-1"
            onMouseEnter={() => setSubmenuActive(true)}
            onMouseLeave={() => setSubmenuActive(false)}
          >
            <div className="flex justify-between items-center w-full text-left py-1 pl-3 pr-1 rounded hover:bg-blue-hover">
              <p className="pointer-events-none">PDF Report</p>
              <img
                className="pointer-events-none"
                src="/assets/images/menubar-arrow.svg"
                alt=""
              />
            </div>
            {isSubmenuActive && (
              <ul className="absolute left-full top-[-5px] mt-0 py-1 px-1 bg-mid-blue w-[160px] max-h-[260px] overflow-y-scroll border border-semi-transparent rounded z-20">
                <li>
                  <button
                    onClick={handleCreatePdf}
                    className={clsx(
                      'w-full text-left py-1 px-3 rounded hover:bg-blue-hover',
                      {
                        // 'text-semi-transparent pointer-events-none':
                        //   mainActorData &&
                        //   !mainActorData.chartDiagnosticsData.length,
                      }
                    )}
                  >
                    Create
                  </button>
                </li>
              </ul>
            )}
          </li>
        </ul>
      )}
    </li>
  )
}

export default File
