import React, { useContext } from 'react'

import { applyZoomToXRay } from '../../../../../utils/imageRendering'
import { GViewContext } from '../../../../../contexts/GViewContext'

/**
 * Button to scale xray slice
 *
 * @component
 * @returns {JSX.Element} The rendered xray scale button.
 */
const ScaleButton = () => {
  const { context, vtkParentContainerRef } = useContext(GViewContext)
  return (
    <button
      className="flex items-center justify-center w-full max-w-[16px] desktop:max-w-[20px]"
      onClick={() => applyZoomToXRay(context, vtkParentContainerRef)}
    >
      <img
        className="h-[16px] desktop:h-auto"
        src="/assets/images/scale.svg"
        alt=""
      />
    </button>
  )
}

export default ScaleButton
