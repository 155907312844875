import React, { useContext } from 'react'

import ActionsTreeItemButton from '../../../ui/ActionsTreeItemButton'

import { GViewContext } from '../../../../../contexts/GViewContext'
/**
 * Button to control the visibility and invisibility of solids
 *
 * @component
 * @param {number} props.index - index of the sub actor in the subActors list
 * @param {Object} props.subActorData - object with data corresponding to the sub items
 * @param {function} props.setMainActorData - function that sets data to the main item object
 * @returns {JSX.Element} The rendered button component.
 */
const SubActorVisibilityButton = ({
  index,
  subActorData,
  setMainActorData,
}) => {
  const { context } = useContext(GViewContext)

  const handleActive = () => {
    setMainActorData((prevState) => ({
      ...prevState,
      subActors: prevState.subActors.map((subActor, id) =>
        id === index
          ? { ...subActor, isActorVisible: !subActor.isActorVisible }
          : subActor
      ),
    }))
    subActorData.actor.setVisibility(!subActorData.isActorVisible)
    // console.log(subActorData.actor2DX)
    context.current.renderWindow.render()
    if (subActorData.seg2DLoaded) {
      subActorData.actor2DX.setVisibility(!subActorData.isActorVisible)
      subActorData.actor2DY.setVisibility(!subActorData.isActorVisible)
      subActorData.actor2DZ.setVisibility(!subActorData.isActorVisible)
      context.current.renderWindowX.render()
      context.current.renderWindowY.render()
      context.current.renderWindowZ.render()
    }
  }
  return (
    <ActionsTreeItemButton
      handleActive={handleActive}
      isActive={subActorData.isActorVisible}
      activeImageLink="/assets/images/eye.svg"
      inactiveImageLink="/assets/images/eye-closed.svg"
    />
  )
}

export default SubActorVisibilityButton
