import React, { useContext, useState } from 'react'

import clsx from 'clsx'

import { GViewContext } from '../../contexts/GViewContext'

import ScissorsButton from './Scissors/ScissorsButton'
import DiagnosticsButton from './Diagnostics/DiagnosticsButton'
import LungSegmentButton from './LungSegment/LungSegmentButton'

/**
 * Toolbar for filters. At the time of writing the documentation, only the “Scissors” button is partially implemented.
 *
 * @component
 * @returns {JSX.Element} The rendered toolbar for filters component.
 */
const ToolbarFilters = ({ isTaxonomyActive }) => {
  const [activeFilter, setActiveFilter] = useState(null)
  const { activeScreen, setActiveScreen } = useContext(GViewContext)

  return (
    <div
      className={clsx('h-full', {
        'w-[5%]': isTaxonomyActive,
        'w-[4%]': !isTaxonomyActive,
      })}
    >
      <ul className="h-full py-[25px] pl-4 flex flex-col gap-2 justify-between items-center">
        <DiagnosticsButton
          activeScreen={activeScreen}
          setActiveScreen={setActiveScreen}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />
        <ScissorsButton
          activeScreen={activeScreen}
          setActiveScreen={setActiveScreen}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />
        {/* <li>
          <button className="flex flex-col items-center gap-0.5 laptop:gap-1 desktop:gap-2 pointer-events-none">
            <img
              className="w-[20px] laptop:w-[25px] desktop:w-auto"
              src="/assets/images/lung-segment_disabled.svg"
              alt=""
            />
            <p className="text-xs laptop:text-sm desktop:text-base leading-tight laptop:leading-snug desktop:leading-normal text-semi-transparent">
              Lung segment
            </p>
          </button>
        </li> */}
        <LungSegmentButton
          activeScreen={activeScreen}
          setActiveScreen={setActiveScreen}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />
        <li>
          <button className="flex flex-col items-center gap-0.5 laptop:gap-1 desktop:gap-2 pointer-events-none">
            <img
              className="w-[20px] laptop:w-[25px] desktop:w-auto"
              src="/assets/images/filters_disabled.svg"
              alt=""
            />
            <p className="text-xs laptop:text-sm desktop:text-base leading-tight laptop:leading-snug desktop:leading-normal text-semi-transparent">
              Image filters
            </p>
          </button>
        </li>
        <li>
          <button className="flex flex-col items-center gap-0.5 laptop:gap-1 desktop:gap-2 pointer-events-none">
            <img
              className="w-[20px] laptop:w-[25px] desktop:w-auto"
              src="/assets/images/cardio-segment_disabled.svg"
              alt=""
            />
            <p className="text-xs laptop:text-sm desktop:text-base leading-tight laptop:leading-snug desktop:leading-normal text-semi-transparent">
              Cardio segment
            </p>
          </button>
        </li>
        <li>
          <button className="flex flex-col items-center gap-0.5 laptop:gap-1 desktop:gap-2 pointer-events-none">
            <img
              className="w-[20px] laptop:w-[25px] desktop:w-auto"
              src="/assets/images/to-solid_disabled.svg"
              alt=""
            />
            <p className="text-xs laptop:text-sm desktop:text-base leading-tight laptop:leading-snug desktop:leading-normal text-semi-transparent">
              Image To Solid
            </p>
          </button>
        </li>
        <li>
          <button className="flex flex-col items-center gap-0.5 laptop:gap-1 desktop:gap-2 pointer-events-none">
            <img
              className="w-[20px] laptop:w-[25px] desktop:w-auto"
              src="/assets/images/centerlines_disabled.svg"
              alt=""
            />
            <p className="text-xs laptop:text-sm desktop:text-base leading-tight laptop:leading-snug desktop:leading-normal text-semi-transparent">
              Center Lines
            </p>
          </button>
        </li>
      </ul>
    </div>
  )
}

export default ToolbarFilters
