import React, { createContext, useState, useRef } from 'react'

export const GViewContext = createContext(null)

const GViewContextProvider = ({ children }) => {
  const context = useRef(null)
  const [XSlice, setXSlice] = useState(1)
  const [YSlice, setYSlice] = useState(0)
  const [ZSlice, setZSlice] = useState(0)

  const [spacings, setSpacings] = useState({
    X: 0,
    Y: 0,
    Z: 0,
  })

  const [sliderMaxX, setSliderMaxX] = useState(0)
  const [sliderMaxY, setSliderMaxY] = useState(0)
  const [sliderMaxZ, setSliderMaxZ] = useState(0)

  const [color, setColor] = useState({ level: 982.7, window: 4290.5 })

  const [coordinates, setCoordinates] = useState({
    x: { loc: [0, 0], pos: [0, 0, 0], onImage: true, contrast: 0 },
    y: { loc: [0, 0], pos: [0, 0, 0], onImage: true, contrast: 0 },
    z: { loc: [0, 0], pos: [0, 0, 0], onImage: true, contrast: 0 },
  })

  const [activeWindow, setActiveWindow] = useState(null)
  const [currentWidgetPosition, setCurrentWidgetPosition] = useState([])

  const [isRightButtonMenuVisible, setIsRightButtonMenuVisible] = useState({
    x: false,
    y: false,
    z: false,
  })

  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 })

  const vtkParentContainerRef = useRef(null)
  const vtkContainerRef = useRef(null)
  const vtkFunctionalContainerRef = useRef(null)
  const sliceContainerX = useRef(null)
  const sliceContainerY = useRef(null)
  const sliceContainerZ = useRef(null)

  const [activeScreen, setActiveScreen] = useState('GView')

  return (
    <GViewContext.Provider
      value={{
        context,
        XSlice,
        setXSlice,
        YSlice,
        setYSlice,
        ZSlice,
        setZSlice,
        vtkParentContainerRef,
        vtkContainerRef,
        vtkFunctionalContainerRef,
        sliceContainerX,
        sliceContainerY,
        sliceContainerZ,
        color,
        setColor,
        coordinates,
        setCoordinates,
        activeWindow,
        setActiveWindow,
        currentWidgetPosition,
        setCurrentWidgetPosition,
        isRightButtonMenuVisible,
        setIsRightButtonMenuVisible,
        menuPosition,
        setMenuPosition,
        spacings,
        setSpacings,
        sliderMaxX,
        setSliderMaxX,
        sliderMaxY,
        setSliderMaxY,
        sliderMaxZ,
        setSliderMaxZ,
        activeScreen,
        setActiveScreen,
      }}
    >
      {children}
    </GViewContext.Provider>
  )
}

export default GViewContextProvider
