import React, { useContext, useState } from 'react'

import { PatientContext } from '../../../contexts/PatientContext'
import { ActionsTreeContext } from '../../../contexts/ActionsTreeContext'
import ToolbarDiagnosticsButton from '../ui/ToolbarDiagnosticsButton'

import LungsHealthModal from './LungsHealthModal'

/**
 * Button for lungs diagnostic.
 *
 * @component
 * @returns {JSX.Element} The rendered lungs health button component.
 */
const LungsHealth = () => {
  const { mainActorData, setMainActorData } = useContext(ActionsTreeContext)
  const { patientData } = useContext(PatientContext)

  const [isProcessing, setIsProcessing] = useState(false)
  const [showModal, setShowModal] = useState(false)

  return (
    <div className="relative h-full">
      <ToolbarDiagnosticsButton
        caption="Lungs Health"
        isActive={showModal}
        isDisabled={
          !mainActorData ||
          isProcessing ||
          (patientData && patientData.aid !== 'CTscan')
        }
        activeImageLink="/assets/images/lungs_active.svg"
        inactiveImageLink="/assets/images/lungs.svg"
        disabledImageLink="/assets/images/lungs_disabled.svg"
        handleClick={() => setShowModal((prev) => !prev)}
        tooltipText="Run sequence of pulmonary diagnostics"
      />
      {showModal && (
        <LungsHealthModal
          mainActorData={mainActorData}
          setMainActorData={setMainActorData}
          setShowModal={setShowModal}
          setIsProcessing={setIsProcessing}
        />
      )}
    </div>
  )
}

export default LungsHealth
