import React from 'react'

import LungsHealth from './LungsHealth/LungsHealth'
import Markers from './BioMarkers/Markers'
import ROIManager from './ROIManager/ROIManager'

/**
 * At the time of documenting, there exists one active button (LungsHealth) which, when clicked, loads pre-prepared solid data into the 3D window and populates the ActionTree component with subActors elements.
 *
 * @component
 * @returns {JSX.Element} The rendered toolbar diagnostics component.
 */
const ToolbarDiagnostics = () => {
  return (
    <ul className="w-[calc(21%-15px)] h-full px-[13px] desktop:px-[20px] py-[13px] flex justify-between items-center gap-2 bg-mid-blue rounded-[10px] shadow-[4px_4px_10px_0px_#00000040]">
      <LungsHealth />
      <Markers />
      <ROIManager />
    </ul>
  )
}

export default ToolbarDiagnostics
