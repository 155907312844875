import { useEffect } from 'react'

const useHandleRightButtonMenu = (
  params = {
    plane: null,
    measuresData: null,
    sliceContainer: null,
    selectedWidgetIndex: null,
    setMenuPosition: null,
    setIsRightButtonMenuVisible: null,
  }
) => {
  const {
    plane,
    measuresData,
    sliceContainer,
    selectedWidgetIndex,
    setMenuPosition,
    setIsRightButtonMenuVisible,
  } = params

  useEffect(() => {
    const handleRightButtonMenu = (e) => {
      if (
        sliceContainer.current &&
        sliceContainer.current.contains(e.target) &&
        measuresData.current[plane].length &&
        selectedWidgetIndex[plane] !== null
      ) {
        e.preventDefault()
        setMenuPosition({
          x: Math.round(e.clientX),
          y: Math.round(e.clientY),
        })
        switch (plane) {
          case 'x':
            setIsRightButtonMenuVisible({
              x: true,
              y: false,
              z: false,
            })
            break
          case 'y':
            setIsRightButtonMenuVisible({
              x: false,
              y: true,
              z: false,
            })
            break
          case 'z':
            setIsRightButtonMenuVisible({
              x: false,
              y: false,
              z: true,
            })
            break
          default:
            break
        }
      }
    }

    document.addEventListener('contextmenu', handleRightButtonMenu)

    return () => {
      document.removeEventListener('contextmenu', handleRightButtonMenu)
    }
  }, [selectedWidgetIndex[plane]])
}

export default useHandleRightButtonMenu
