import clsx from 'clsx'
import React, { useState, useRef } from 'react'

/**
 * UI button with tooltip component.
 *
 * @component
 * @param {string} props.tooltipText - text for tooltip
 * @param {string} props.className - styling for button
 * @param {function} props.onClick - onClick function for button
 * @param {ReactNode} props.children - button content (filling)
 * @returns {JSX.Element} The rendered radio button component.
 */
const ButtonWithTooltip = ({ tooltipText, className, onClick, children }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const timerRef = useRef(null)

  const handleMouseEnter = () => {
    timerRef.current = setTimeout(() => {
      setShowTooltip(true)
    }, 800)
  }

  const handleMouseLeave = () => {
    clearTimeout(timerRef.current)
    setShowTooltip(false)
  }

  return (
    <button
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={className}
      onClick={onClick}
    >
      {children}
      {tooltipText && (
        <div
          className={clsx(
            'absolute bottom-[100%] py-0.5 px-1 text-xs laptop:text-sm rounded-md bg-semi-black backdrop-filter backdrop-blur-sm transition-opacity duration-300 whitespace-nowrap pointer-events-none z-10',
            { 'opacity-1': showTooltip, 'opacity-0': !showTooltip }
          )}
        >
          {tooltipText}
        </div>
      )}
    </button>
  )
}

export default ButtonWithTooltip
