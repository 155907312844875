import React, { useContext } from 'react'

import clsx from 'clsx'
import vtkMath from '@kitware/vtk.js/Common/Core/Math'
import { xyzToViewType } from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/Constants'

import useHandleRightButtonMenu from '../../hooks/useHandleRightButtonMenu'

import { GViewContext } from '../../contexts/GViewContext'
import { MeasuresContext } from '../../contexts/MeasuresContext'
import { PdfContext } from '../../contexts/PdfContext'
import RightButtonMenu from '../modals/RightButtonMenu'

/**
 * Сomponent for displaying a section along the X axis in a 2D window
 *
 * @component
 * @param {string} props.view - parameter that determines which of the 4 windows are shown and which are hidden
 * @returns {JSX.Element} The rendered component with one 2D window.
 */
const WindowSliceX = ({ view }) => {
  const { measuresData, selectedWidgetIndex } = useContext(MeasuresContext)

  const {
    context,
    XSlice,
    setXSlice,
    sliceContainerX,
    activeWindow,
    isRightButtonMenuVisible,
    setIsRightButtonMenuVisible,
    setMenuPosition,
    color,
    coordinates,
    spacings,
    sliderMaxX,
  } = useContext(GViewContext)

  const { isPdfProcessing } = useContext(PdfContext)

  const changeSliderX = (e) => {
    if (context.current) {
      const { resliceCursorWidget, resliceCursorWidgetInstanceX, interactorX } =
        context.current
      setXSlice(Number(e.target.value))
      const normal = resliceCursorWidget.getWidgetState().getPlanes()[
        xyzToViewType[0]
      ].normal
      const planeExtremities = resliceCursorWidget.getPlaneExtremities(
        xyzToViewType[0]
      )
      const newCenter = vtkMath.multiplyAccumulate(
        planeExtremities[0],
        normal,
        Number(e.target.value),
        []
      )

      resliceCursorWidget.setCenter(newCenter)
      // resliceCursorWidgetInstanceX.invokeInternalInteractionEvent()
      resliceCursorWidgetInstanceX.invokeInteractionEvent(
        resliceCursorWidgetInstanceX.getActiveInteraction()
      )

      interactorX.render()
    }
  }

  useHandleRightButtonMenu({
    plane: 'x',
    measuresData,
    sliceContainer: sliceContainerX,
    selectedWidgetIndex,
    setMenuPosition,
    setIsRightButtonMenuVisible,
  })

  return (
    <>
      <div
        className={clsx('relative w-full h-full', {
          hidden:
            view === 'Morpho' ||
            view === 'Functional' ||
            view === 'Morpho-Functional' ||
            view === 'Morpho-Sagittal' ||
            view === 'Morpho-Axial',
        })}
      >
        {!isPdfProcessing && (
          <input
            type="range"
            min="0"
            max={Math.floor(sliderMaxX)}
            step="1"
            value={XSlice}
            className="w-full px-[5px]"
            onChange={(e) => changeSliderX(e)}
          />
        )}
        <div
          className={clsx(
            'canvas-rounded relative border-[5px] rounded-[15px]',
            {
              'border-transparent': activeWindow !== 'x',
              'border-blue': activeWindow === 'x',
            }
          )}
          ref={sliceContainerX}
        >
          <div className="absolute left-1/2 translate-x-[-50%] w-[calc(100%-10px)] flex justify-between text-sm laptop:text-base desktop:text-xl font-light tracking-wide">
            <p>Coronal-YZ plane</p>
            <p>
              {Math.round(XSlice / spacings.X)}/
              {Math.round(sliderMaxX / spacings.X)}
            </p>
          </div>
          <div className="absolute bottom-0 right-1/2 translate-x-[50%] w-[calc(100%-10px)] flex flex-col items-end text-sm laptop:text-base desktop:text-xl font-light tracking-wide">
            {coordinates.x.onImage ? (
              <p>
                Loc: ({Math.round(XSlice / spacings.X)} {coordinates.x.loc[0]}{' '}
                {coordinates.x.loc[1]})
              </p>
            ) : (
              <>
                <p>
                  Pos: ({coordinates.x.pos[0]} {coordinates.x.pos[1]}{' '}
                  {coordinates.x.pos[2]})
                </p>
                <p>Off image</p>
              </>
            )}
            <p>Contrast: {coordinates.x.contrast}</p>
            <p>
              L:{Math.round(color.level)}, W:{Math.round(color.window)}
            </p>
          </div>
        </div>
      </div>
      {isRightButtonMenuVisible.x && (
        <RightButtonMenu
          plane="x"
          measuresWidgetManager={context.current.measuresWidgetManagerX}
        />
      )}
    </>
  )
}

export default WindowSliceX
