import React from 'react'

/**
 * UI checkbox component.
 *
 * @component
 * @param {string} props.id - id for input and htmlFor for label
 * @param {boolean} props.isChecked - state of being checked
 * @param {function} props.setIsChecked - isChecked state setter
 * @param {ReactNode} props.children - caption for checkbox (text or image)
 * @returns {JSX.Element} The rendered checkbox component.
 */
const Checkbox = ({ id, isChecked, setIsChecked, children }) => {
  return (
    <div className="relative max-w-max">
      <input
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={setIsChecked}
        className="hidden"
      />
      <label
        htmlFor={id}
        className="pl-7 block text-md leading-tight cursor-pointer"
      >
        {children}
        <span className="absolute top-[calc(50%-10px)] left-0 w-[20px] h-[20px] border-2 border-bright-blue rounded" />
        {isChecked && (
          <img
            className="absolute top-[calc(50%-8px)] left-[2px]"
            src="/assets/images/done.svg"
            alt=""
          />
        )}
      </label>
    </div>
  )
}

export default Checkbox
