import React, { useState, useContext } from 'react'

import clsx from 'clsx'

import { ViewContext } from '../../contexts/ViewContext'
import { GViewContext } from '../../contexts/GViewContext'
import { PatientContext } from '../../contexts/PatientContext'
import { PdfContext } from '../../contexts/PdfContext'

import useChangeCanvasSize from '../../hooks/useChangeCanvasSize'

import WindowSliceX from './WindowSliceX'
import WindowSliceY from './WindowSliceY'
import WindowSliceZ from './WindowSliceZ'

/**
 * Module for displaying and interacting with 3D/2D images
 *
 * @component
 * @param {boolean} props.isTaxonomyActive - boolean variable that determines whether the GView component is shown across the entire width of the container or not
 * @param {boolean} props.isVisible - boolean variable that determines whether the GView component is visible or not
 * @returns {JSX.Element} The rendered component with four windows - 1 for 3D and 3 for 2D.
 */
const GView = ({ isTaxonomyActive, isVisible }) => {
  const {
    context,
    vtkContainerRef,
    vtkFunctionalContainerRef,
    vtkParentContainerRef,
  } = useContext(GViewContext)
  const { isPdfProcessing } = useContext(PdfContext)

  const { view } = useContext(ViewContext)
  const { patientData } = useContext(PatientContext)

  useChangeCanvasSize({
    patientData,
    context,
    vtkParentContainerRef,
    isTaxonomyActive,
  })

  return (
    <div
      className={clsx('', {
        'p-[13px]': !isPdfProcessing,
        'px-[35px] py-[25px]': isPdfProcessing,
        hidden: !isVisible,
        'grid grid-cols-2 gap-1':
          view === 'Four' ||
          view === 'Morpho-Coronal' ||
          view === 'Morpho-Sagittal' ||
          view === 'Morpho-Axial' ||
          view === 'Four-Functional',
        'w-[95%]': isTaxonomyActive,
        'w-[96%]': !isTaxonomyActive,
      })}
      ref={vtkParentContainerRef}
    >
      <div
        className={clsx('p-[5px]', {
          'grid grid-cols-2 gap-[10px]':
            view === 'Morpho-Functional' || view === 'Four-Functional',
          hidden: patientData && patientData.aid !== 'CTscan',
        })}
      >
        <div
          className={clsx('w-full h-full canvas-rounded', {
            hidden: view === 'Functional',
          })}
          ref={vtkContainerRef}
        ></div>
        <div
          className={clsx('w-full h-full canvas-rounded', {
            hidden:
              view === 'Morpho' ||
              view === 'Four' ||
              view === 'Morpho-Coronal' ||
              view === 'Morpho-Sagittal' ||
              view === 'Morpho-Axial',
          })}
          ref={vtkFunctionalContainerRef}
        ></div>
      </div>
      <WindowSliceX view={view} />
      <WindowSliceY view={view} />
      <WindowSliceZ view={view} patientData={patientData} />
    </div>
  )
}

export default GView
