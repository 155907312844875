import { useCallback, useRef } from 'react'

const useDebounce = (callback, setIsUpdating, delay) => {
  const timer = useRef(null)

  const debouncedCallback = useCallback(
    (arg) => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        callback(arg)
        setIsUpdating(false)
        console.log('callback update')
      }, delay)
    },
    [callback, delay]
  )

  return debouncedCallback
}

export default useDebounce
