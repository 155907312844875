import React, { useContext } from 'react'
import clsx from 'clsx'

import { GViewContext } from '../../contexts/GViewContext'
import { PatientContext } from '../../contexts/PatientContext'

import ViewPanels from './ViewPanels/ViewPanels'
import ImagePreset from './Preset/ImagePreset'
import ColorMap from './ColorMap/ColorMap'
import MIP from './MIP/MIP'
import Measures from './Measures/Measures'

/**
 * Toolbar for GView. Allows user to change the window display mode, presets and colors for 3D images, MIP and also contains a menu for creating measurements.
 *
 * @component
 * @returns {JSX.Element} The rendered toolbar for GView component.
 */
const ToolbarGView = () => {
  const { context } = useContext(GViewContext)
  const { patientData } = useContext(PatientContext)

  return (
    <ul
      className={clsx(
        'w-[calc(63%-15px)] h-full px-[13px] desktop:px-[20px] py-[13px] grid items-center gap-4 bg-mid-blue rounded-[10px] shadow-[4px_4px_10px_0px_#00000040]',
        {
          'grid-cols-5':
            (patientData && patientData.aid === 'CTscan') || !patientData,
          'grid-cols-2': patientData && patientData.aid === 'XRay',
        }
      )}
    >
      {!patientData || (patientData && patientData.aid === 'CTscan') ? (
        <ViewPanels context={context} />
      ) : (
        ''
      )}
      {!patientData || (patientData && patientData.aid === 'CTscan') ? (
        <ImagePreset context={context} />
      ) : (
        ''
      )}
      <ColorMap
        context={context}
        acquisition={patientData && patientData.aid}
      />
      {!patientData || (patientData && patientData.aid === 'CTscan') ? (
        <MIP context={context} />
      ) : (
        ''
      )}
      <Measures context={context} />
    </ul>
  )
}

export default ToolbarGView
