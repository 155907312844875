import { useEffect, useContext } from 'react'

import { ViewContext } from '../contexts/ViewContext'
import { GViewContext } from '../contexts/GViewContext'
import { PdfContext } from '../contexts/PdfContext'

import useScreenSize from './useScreenSize'

const useChangeCanvasSize = (
  params = {
    patientData: { aid: 'CTscan' },
    context: null,
    vtkParentContainerRef: null,
    isTaxonomyActive: null,
  }
) => {
  const screenSize = useScreenSize()
  const { patientData, context, vtkParentContainerRef, isTaxonomyActive } =
    params

  const { activeScreen } = useContext(GViewContext)
  const { view } = useContext(ViewContext)
  const { isPdfProcessing } = useContext(PdfContext)

  useEffect(() => {
    if (patientData && patientData.aid) {
      if (context.current && activeScreen === 'GView') {
        const { width, height } =
          vtkParentContainerRef.current.getBoundingClientRect()

        let updatedMainHeight = 0
        let updated2DHeight = 0

        if (view === 'Morpho' || view === 'Functional') {
          updatedMainHeight = height - 37
          updated2DHeight = height - 88
        } else if (view === 'Morpho-Functional' && !isTaxonomyActive) {
          updatedMainHeight = height * 2 - 60
          updated2DHeight = height - 88
        } else if (view === 'Morpho-Functional' && isTaxonomyActive) {
          updatedMainHeight = height * 2 - 64
          updated2DHeight = height - 88
        } else if (view === 'Four' && !isTaxonomyActive) {
          updatedMainHeight = height - 39
          updated2DHeight = height - 88
        } else if (view === 'Four' && isTaxonomyActive) {
          updatedMainHeight = isPdfProcessing ? height - 28 : height - 43
          updated2DHeight = isPdfProcessing ? height - 28 : height - 92
        } else if (view === 'Four-Functional' && !isTaxonomyActive) {
          updatedMainHeight = height * 2 - 49
          updated2DHeight = height - 88
        } else if (view === 'Four-Functional' && isTaxonomyActive) {
          updatedMainHeight = height * 2 - 64
          updated2DHeight = height - 92
        } else if (!isTaxonomyActive) {
          updatedMainHeight = height * 2 - 60
          updated2DHeight = height * 2 - 108
        } else if (isTaxonomyActive) {
          updatedMainHeight = height * 2 - 66
          updated2DHeight = height * 2 - 114
        }

        context.current.openglRenderWindow &&
          context.current.openglRenderWindow.setSize(
            width - 36,
            updatedMainHeight
          )
        context.current.openglRenderWindowX &&
          context.current.openglRenderWindowX.setSize(
            width - 36,
            updated2DHeight
          )
        context.current.openglRenderWindowY &&
          context.current.openglRenderWindowY.setSize(
            width - 36,
            updated2DHeight
          )
        if (patientData.aid === 'CTscan') {
          context.current.openglRenderWindowZ.setSize(
            width - 36,
            updated2DHeight
          )
        } else if (patientData.aid === 'XRay') {
          context.current.openglRenderWindowZ.setSize(
            width - 36,
            updatedMainHeight
          )
        }
        if (context.current.functionalOpenglRenderWindow) {
          context.current.functionalOpenglRenderWindow.setSize(
            width - 36,
            updatedMainHeight
          )
          context.current.functionalRenderWindow.render()
        }
        context.current.renderWindow && context.current.renderWindow.render()
        context.current.renderWindowX && context.current.renderWindowX.render()
        context.current.renderWindowY && context.current.renderWindowY.render()
        context.current.renderWindowZ.render()
      }
    }
  }, [
    screenSize.height,
    screenSize.width,
    isTaxonomyActive,
    view,
    activeScreen,
    isPdfProcessing,
  ])
}

export default useChangeCanvasSize
