import React from 'react'
import clsx from 'clsx'

import ButtonWithTooltip from '../../ui/ButtonWithTooltip'

/**
 * UI Button component for ToolbarDiagnostics
 *
 * @component
 * @param {string} props.caption - button caption
 * @param {boolean} props.isActive - variable that determines whether the button is active
 * @param {boolean} props.isDisabled - variable that determines whether the button is disabled
 * @param {string} props.activeImageLink - link for active image
 * @param {string} props.inactiveImageLink - link for inactive image
 * @param {string} props.disabledImageLink - link for disabled image
 * @param {function} props.handleClick - function that is triggered by pressing a button
 * @param {string} props.tooltipText - tooltip text
 * @returns {JSX.Element} The rendered button component.
 */
const ToolbarDiagnosticsButton = ({
  caption,
  isActive,
  isDisabled,
  activeImageLink,
  inactiveImageLink,
  disabledImageLink,
  handleClick,
  tooltipText,
}) => {
  return (
    <li className="h-full">
      <ButtonWithTooltip
        tooltipText={tooltipText}
        className={clsx('relative h-full flex flex-col items-center gap-px', {
          'pointer-events-none cursor-default': isDisabled,
        })}
        onClick={handleClick}
      >
        <div className="h-1/2 flex items-center">
          {isDisabled ? (
            <img
              className="pointer-events-none w-[20px] laptop:w-[25px] desktop:w-auto"
              src={disabledImageLink}
              alt=""
            />
          ) : (
            <img
              className="pointer-events-none w-[20px] laptop:w-[25px] desktop:w-auto"
              src={isActive ? activeImageLink : inactiveImageLink}
              alt=""
            />
          )}
        </div>
        <p
          className={clsx(
            'max-h-1/2 text-xs laptop:text-sm desktop:text-base leading-[18px] laptop:leading-[18px] desktop:leading-[18px]',
            {
              'text-bright-blue': isActive,
              'text-semi-transparent': isDisabled,
            }
          )}
        >
          {caption}
        </p>
      </ButtonWithTooltip>
    </li>
  )
}

export default ToolbarDiagnosticsButton
