import React, { useContext, useRef, useState } from 'react'

import { GViewContext } from '../../../contexts/GViewContext'
import {
  handleFocus,
  handleBlur,
} from '../../../utils/interactorHandlersInTextField'

import DeleteMeasureButton from './ActionsTreeMeasureItemButtons/DeleteMeasureButton'
import LockMeasureButton from './ActionsTreeMeasureItemButtons/LockMeasureButton'

/**
 * Measure item for ActionsTree
 *
 * @component
 * @param {number} props.index - index of the measure in the measures list
 * @param {Object} props.measure - object with data corresponding to the measure
 * @param {string} props.plane - plane index
 * @param {Object} props.measuresData - object with data corresponding to the measures
 * @param {function} props.setSelectedWidgetIndex - function that defines the index of the selected measure
 * @param {function} props.setShowTooltip - function to set tooltip visibility
 * @param {Object} props.timerRef - tooltip timer
 * @param {function} props.setTooltipPos - function to set tooltip position
 * @param {function} props.setTooltipData - function to set tooltip data
 * @returns {JSX.Element} The rendered ActionsTreeItem component.
 */
const ActionsTreeMeasureItem = ({
  index,
  measure,
  plane,
  measuresData,
  setSelectedWidgetIndex,
  setShowTooltip,
  timerRef,
  setTooltipPos,
  setTooltipData,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [currentText, setCurrentText] = useState('')
  const listItemRef = useRef(null)

  const { context } = useContext(GViewContext)

  const handleMouseEnter = () => {
    timerRef.current = setTimeout(() => {
      if (listItemRef.current) {
        const rect = listItemRef.current.getBoundingClientRect()
        setTooltipPos({
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX + rect.width / 2,
        })
        let plane = ''
        switch (measure.plane) {
          case 'x':
            plane = 'Coronal-YZ'
            break
          case 'y':
            plane = 'Sagittal-XZ'
            break
          case 'z':
            plane = 'Axial-XY'
            break

          default:
            break
        }
        setTooltipData({
          createdAt: measure.createdAt,
          updatedAt: measure.updatedAt,
          plane: plane,
          slice: measure.slice,
        })
        setShowTooltip(true)
      }
    }, 800)
  }

  const handleMouseLeave = () => {
    clearTimeout(timerRef.current)
    setShowTooltip(false)
  }

  const handleDoubleClick = () => {
    setShowTooltip(false)
    setIsEditing(true)
    setCurrentText(measure.label)
  }

  const goToSlice = () => {
    const {
      resliceCursorWidget,
      resliceCursorWidgetInstanceX,
      resliceCursorWidgetInstanceY,
      resliceCursorWidgetInstanceZ,
    } = context.current
    const center = resliceCursorWidget.getWidgetState().getCenter()

    switch (plane) {
      case 'x':
        resliceCursorWidget.setCenter([
          measure.position[0],
          center[1],
          center[2],
        ])

        resliceCursorWidgetInstanceX.invokeInteractionEvent(
          resliceCursorWidgetInstanceX.getActiveInteraction()
        )
        break
      case 'y':
        resliceCursorWidget.setCenter([
          center[0],
          measure.position[1],
          center[2],
        ])

        resliceCursorWidgetInstanceY.invokeInteractionEvent(
          resliceCursorWidgetInstanceY.getActiveInteraction()
        )
        break
      case 'z':
        resliceCursorWidget.setCenter([
          center[0],
          center[1],
          measure.position[2],
        ])

        resliceCursorWidgetInstanceZ.invokeInteractionEvent(
          resliceCursorWidgetInstanceZ.getActiveInteraction()
        )
        break
      default:
        break
    }
  }

  const handleChange = (e) => {
    setCurrentText(e.target.value)
  }

  const handleBlurAndSave = () => {
    saveText()
    handleBlur(context)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      saveText()
      handleBlur(context)
    }
  }

  const saveText = () => {
    measuresData.current[plane][index].label = currentText.trim()
    if (measure.type === 'annotation') {
      measuresData.current[plane][index].widget.setText(currentText.trim())
    }
    setIsEditing(false)
  }

  return (
    <li className="flex w-full align-center gap-1 desktop:gap-2 justify-between">
      <div className="w-[calc(100%-40px)] desktop:w-[calc(100%-56px)] flex grow text-sm desktop:text-base truncate">
        <img src="/assets/images/angle.svg" alt=""></img>
        <div
          className="w-full text-left cursor-default truncate"
          onClick={goToSlice}
          onDoubleClick={handleDoubleClick}
        >
          {isEditing ? (
            <input
              type="text"
              className="w-full text-sm desktop:text-base bg-mid-blue outline-none"
              value={currentText}
              onChange={handleChange}
              onBlur={handleBlurAndSave}
              onKeyDown={handleKeyDown}
              onFocus={() => handleFocus(context)}
              autoFocus
            />
          ) : (
            <>
              <span
                ref={listItemRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {measure.label}
              </span>
              {measure.type === 'ruler' || measure.type === 'circle' ? (
                <span className="font-light text-xs">
                  {' '}
                  ({measure.widget.getState().widgetState.text.text})
                </span>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex justify-between gap-1 desktop:gap-2">
        <LockMeasureButton
          measure={measure}
          plane={plane}
          index={index}
          measuresData={measuresData}
          setSelectedWidgetIndex={setSelectedWidgetIndex}
        />
        <DeleteMeasureButton
          context={context}
          measure={measure}
          plane={plane}
          index={index}
          measuresData={measuresData}
          setSelectedWidgetIndex={setSelectedWidgetIndex}
        />
      </div>
    </li>
  )
}

export default ActionsTreeMeasureItem
