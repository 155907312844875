import clsx from 'clsx'
import React from 'react'

/**
 * UI radio button component.
 *
 * @component
 * @param {string} props.id - id for input and htmlFor for label
 * @param {boolean} props.isChecked - state of being checked
 * @param {function} props.setIsChecked - isChecked state setter
 * @param {boolean} props.isDisabled - state of being disabled
 * @param {ReactNode} props.children - caption for checkbox (text or image)
 * @returns {JSX.Element} The rendered radio button component.
 */
const Radio = ({
  id,
  value,
  isChecked,
  setIsChecked,
  isDisabled,
  children,
}) => {
  return (
    <div className="relative max-w-max">
      <input
        type="radio"
        id={id}
        value={value}
        checked={isChecked}
        onChange={setIsChecked}
        className="hidden"
      />
      <label
        htmlFor={id}
        className={clsx('pl-7 block text-md leading-tight cursor-pointer', {
          'text-pale-white pointer-events-none': isDisabled,
        })}
      >
        {children}
        <span
          className={clsx(
            'absolute top-[calc(50%-10px)] left-0 w-[20px] h-[20px] border-2 border-bright-blue rounded-full',
            {
              'border-pale-white pointer-events-none': isDisabled,
            }
          )}
        />
        {isChecked && (
          <div
            className={clsx(
              'absolute top-[calc(50%-5px)] left-[5px] w-[10px] h-[10px] rounded-full bg-bright-blue',
              {
                'bg-pale-white pointer-events-none': isDisabled,
              }
            )}
          ></div>
        )}
      </label>
    </div>
  )
}

export default Radio
