import React from 'react'

import Checkbox from '../../../../ui/Checkbox'

/**
 * Checkbox to control the visibility and invisibility of functional image
 *
 * @component
 * @param {Object | null} props.context - main storage for all vtk.js components. If the image was not loaded in the application, than equal to null.
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered checkbox component.
 */
const FunctionalActorVisibilityButton = ({
  context,
  mainActorData,
  setMainActorData,
}) => {
  const handleVisible = () => {
    if (context.current) {
      setMainActorData((prevState) => ({
        ...prevState,
        functionalActor: {
          ...prevState.functionalActor,
          isVisible: !mainActorData.functionalActor.isVisible,
        },
      }))
      context.current.functionalActor.setVisibility(
        !mainActorData.functionalActor.isVisible
      )
      context.current.functionalRenderWindow.render()
    }
  }
  return (
    <Checkbox
      id="functionalActor"
      isChecked={mainActorData.functionalActor.isVisible}
      setIsChecked={handleVisible}
    >
      Solid Structure
    </Checkbox>
  )
}

export default FunctionalActorVisibilityButton
