import React from 'react'

import Checkbox from '../../../../ui/Checkbox'

/**
 * Checkbox to control the visibility and invisibility of functional slice
 *
 * @component
 * @param {Object | null} props.context - main storage for all vtk.js components. If the image was not loaded in the application, than equal to null.
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered checkbox component.
 */
const SliceVisibilityButton = ({
  context,
  mainActorData,
  setMainActorData,
}) => {
  const handlePlaneVisibility = () => {
    if (context.current) {
      const {
        functionalRenderer,
        functionalRenderWindow,
        functionalSliceActor,
        functionalPlaneWidget,
        functionalWidgetManager,
      } = context.current
      setMainActorData((prevState) => ({
        ...prevState,
        functionalActor: {
          ...prevState.functionalActor,
          isPlaneVisible: !mainActorData.functionalActor.isPlaneVisible,
        },
      }))
      if (mainActorData.functionalActor.isPlaneVisible) {
        functionalRenderer.removeActor(functionalSliceActor)
        functionalWidgetManager.removeWidget(functionalPlaneWidget)
      } else {
        const repStyle = {
          active: {
            plane: {
              opacity: 0.0,
              color: [1, 1, 1],
            },
            normal: {
              opacity: 1,
              color: [1, 1, 1],
            },
            origin: {
              opacity: 1,
              color: [1, 1, 1],
            },
          },
          inactive: {
            plane: {
              opacity: 0.0,
              color: [1, 1, 1],
            },
            normal: {
              opacity: 0.5,
              color: [1, 1, 1],
            },
            origin: {
              opacity: 0.5,
              color: [1, 1, 1],
            },
          },
        }
        functionalRenderer.addActor(functionalSliceActor)
        const w = functionalWidgetManager.addWidget(functionalPlaneWidget)
        w.setRepresentationStyle(repStyle)
      }
      functionalRenderWindow.render()
    }
  }

  return (
    <Checkbox
      id="functionalPlane"
      isChecked={mainActorData.functionalActor.isPlaneVisible}
      setIsChecked={handlePlaneVisibility}
    >
      Slice Plane
    </Checkbox>
  )
}

export default SliceVisibilityButton
