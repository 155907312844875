export const handleFocus = (context) => {
  if (context.current) {
    console.log(context)
    const { interactor, interactorX, interactorY, interactorZ } =
      context.current

    // if (context.current.functionalInteractor) {
    //   context.current.functionalInteractor.disable()
    // }

    interactor && interactor.disable()
    interactorX && interactorX.disable()
    interactorY && interactorY.disable()
    interactorZ && interactorZ.disable()
  }
}
export const handleBlur = (context) => {
  if (context.current) {
    const { interactor, interactorX, interactorY, interactorZ } =
      context.current

    // if (context.current.functionalInteractor) {
    //   context.current.functionalInteractor.enable()
    //   context.current.functionalRenderWindow.render()
    // }

    interactor && interactor.enable()
    interactorX && interactorX.enable()
    interactorY && interactorY.enable()
    interactorZ && interactorZ.enable()
  }
}
