import { format } from 'date-fns'

import vtkLineWidget from '@kitware/vtk.js/Widgets/Widgets3D/LineWidget'
import vtkEllipseWidget from '@kitware/vtk.js/Widgets/Widgets3D/EllipseWidget'
import vtkSphere from '@kitware/vtk.js/Common/DataModel/Sphere'
import vtkInteractorObserver from '@kitware/vtk.js/Rendering/Core/InteractorObserver'
import { ViewTypes } from '@kitware/vtk.js/Widgets/Core/WidgetManager/Constants'
import LineConstants from '@kitware/vtk.js/Widgets/Widgets3D/LineWidget/Constants'
import { xyzToViewType } from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/Constants'
import DeepEqual from 'deep-equal'
import { vec3 } from 'gl-matrix'
import {
  BehaviorCategory,
  ShapeBehavior,
  TextPosition,
} from '@kitware/vtk.js/Widgets/Widgets3D/ShapeWidget/Constants'

import {
  bindSVGRepresentation,
  multiLineTextCalculator,
  VerticalTextAlignment,
} from '../utils/SVGHelpers'

function setupSVG(w, renderer, options) {
  bindSVGRepresentation(renderer, w.getWidgetState(), {
    mapState(widgetState, { size }) {
      const textState = widgetState.getText()
      const text = textState.getText()
      const origin = textState.getOrigin()
      if (origin && textState.getVisible()) {
        const coords = vtkInteractorObserver.computeWorldToDisplay(
          renderer,
          ...origin
        )
        const position = [coords[0], size[1] - coords[1]]
        return {
          text,
          position,
        }
      }
      return null
    },
    render(data, h) {
      if (data) {
        const currentUrl = window.location.search
        const urlParams = new URLSearchParams(currentUrl)
        const aid = urlParams.get('acquisition')
        const lines = data.text.split('\n')
        const fontSize = aid === 'XRay' ? 15 : 28
        const dys = multiLineTextCalculator(
          lines.length,
          fontSize,
          VerticalTextAlignment.MIDDLE
        )
        return lines.map((line, index) =>
          h(
            'text',
            {
              key: index,
              attrs: {
                x: data.position[0],
                y: data.position[1],
                dx: 12,
                dy: options ? dys[index] : -12,
                fill: 'white',
                'font-size': fontSize,
                ...options?.textProps,
              },
            },
            line
          )
        )
      }
      return []
    },
  })
}

function getLastSvgNode(parentRef) {
  const parentElement = parentRef.current
  if (parentElement) {
    const children = parentElement.children
    let item = null
    for (let i = 0; i < children.length; i++) {
      if (children[i].tagName.toLowerCase() === 'svg') {
        item = children[i]
        break
      }
    }
    return item
  }
}

export const getRulerFromDB = (
  context,
  sliceContainer,
  measureEntityDB,
  measuresData,
  setSelectedWidgetIndex,
  setActiveWindow,
  currentWidgetPosition
) => {
  let index = null

  const {
    resliceCursorWidget,
    measuresWidgetManagerX,
    rendererX,
    renderWindowX,
    measuresWidgetManagerY,
    rendererY,
    renderWindowY,
    measuresWidgetManagerZ,
    rendererZ,
    renderWindowZ,
  } = context.current

  const planeContext = {
    measuresWidgetManager: null,
    renderer: null,
    renderWindow: null,
    xyzToViewType: null,
  }

  switch (measureEntityDB.plane) {
    case 'x':
      planeContext.measuresWidgetManager = measuresWidgetManagerX
      planeContext.renderer = rendererX
      planeContext.renderWindow = renderWindowX
      planeContext.xyzToViewType = 0
      break
    case 'y':
      planeContext.measuresWidgetManager = measuresWidgetManagerY
      planeContext.renderer = rendererY
      planeContext.renderWindow = renderWindowY
      planeContext.xyzToViewType = 1
      break
    case 'z':
      planeContext.measuresWidgetManager = measuresWidgetManagerZ
      planeContext.renderer = rendererZ
      planeContext.renderWindow = renderWindowZ
      planeContext.xyzToViewType = 2
      break
    default:
      break
  }

  const lineWidget = vtkLineWidget.newInstance()

  const currentHandle = planeContext.measuresWidgetManager.addWidget(
    lineWidget,
    ViewTypes.SLICE
  )

  currentHandle.setUseActiveColor(false)

  const lineWidgetState = currentHandle.getWidgetState()

  lineWidgetState.setLineThickness(1.5)
  lineWidgetState.getHandle1().setScale1(22)
  lineWidgetState.getHandle1().setColor(0.2)
  lineWidgetState.getHandle1().setShape(LineConstants.ShapeType.VIEWFINDER)
  lineWidgetState.getHandle2().setScale1(22)
  lineWidgetState.getHandle2().setColor(0.2)
  lineWidgetState.getHandle2().setShape(LineConstants.ShapeType.VIEWFINDER)
  lineWidgetState.getMoveHandle().setVisible(false)
  lineWidgetState.getPositionOnLine().setPosOnLine(1)
  lineWidgetState
    .getHandle1()
    .setOrigin(
      measureEntityDB.handle1[0],
      measureEntityDB.handle1[1],
      measureEntityDB.handle1[2]
    )
  lineWidgetState
    .getHandle2()
    .setOrigin(
      measureEntityDB.handle2[0],
      measureEntityDB.handle2[1],
      measureEntityDB.handle2[2]
    )
  currentHandle.setText(lineWidget.getDistance().toFixed(2) + ' mm')
  lineWidgetState
    .getText()
    .setOrigin(
      measureEntityDB.handle2[0],
      measureEntityDB.handle2[1],
      measureEntityDB.handle2[2]
    )
  currentHandle.updateHandleVisibility(0)
  currentHandle.updateHandleVisibility(1)
  currentHandle.setEnabled(false)
  setupSVG(lineWidget, planeContext.renderer)
  getLastSvgNode(sliceContainer)

  const newMeasure = {
    id: measureEntityDB.id,
    createdAt: measureEntityDB.createdAt,
    updatedAt: measureEntityDB.updatedAt,
    type: measureEntityDB.type,
    label: measureEntityDB.label,
    plane: measureEntityDB.plane,
    slice: measureEntityDB.slice,
    isLocked: true,
    node: getLastSvgNode(sliceContainer),
    widget:
      planeContext.measuresWidgetManager.getWidgets()[
        planeContext.measuresWidgetManager.getWidgets().length - 1
      ],
    position: measureEntityDB.position,
  }

  if (
    Math.floor(measureEntityDB.position[planeContext.xyzToViewType]) !==
    Math.floor(currentWidgetPosition[planeContext.xyzToViewType])
  ) {
    newMeasure.widget.setVisibility(false)
    newMeasure.node.style.display = 'none'
  }

  lineWidget
    .getManipulator()
    // .setUserOrigin(resliceCursorWidget.getState().widgetState.center)
    .setUserOrigin(measureEntityDB.position)

  lineWidget
    .getManipulator()
    .setUserNormal(
      resliceCursorWidget.getWidgetState().getPlanes()[
        xyzToViewType[planeContext.xyzToViewType]
      ].normal
    )

  currentHandle.onStartInteractionEvent(() => {
    handleStartInteractionEvent({
      index,
      plane: measureEntityDB.plane,
      measuresData,
      measuresWidgetManager: planeContext.measuresWidgetManager,
      currentHandle,
      setActiveWindow,
      setSelectedWidgetIndex,
    })
  })

  measuresData.current = {
    ...measuresData.current,
    [measureEntityDB.plane]: [
      ...measuresData.current[measureEntityDB.plane],
      newMeasure,
    ],
  }

  currentHandle.onInteractionEvent(() => {
    handleRulerInteractionEvent({
      currentHandle,
      measureWidget: lineWidget,
    })
  })

  console.log(planeContext.renderWindow)
  console.log(planeContext.renderer)

  planeContext.renderWindow.render()
  planeContext.renderWindow.modified()
  planeContext.renderer.modified()
}

export const getAnnotationFromDB = (
  context,
  sliceContainer,
  measureEntityDB,
  measuresData,
  setSelectedWidgetIndex,
  setActiveWindow,
  currentWidgetPosition
) => {
  let index = null

  const {
    resliceCursorWidget,
    measuresWidgetManagerX,
    rendererX,
    renderWindowX,
    measuresWidgetManagerY,
    rendererY,
    renderWindowY,
    measuresWidgetManagerZ,
    rendererZ,
    renderWindowZ,
  } = context.current

  const planeContext = {
    measuresWidgetManager: null,
    renderer: null,
    renderWindow: null,
    xyzToViewType: null,
  }

  switch (measureEntityDB.plane) {
    case 'x':
      planeContext.measuresWidgetManager = measuresWidgetManagerX
      planeContext.renderer = rendererX
      planeContext.renderWindow = renderWindowX
      planeContext.xyzToViewType = 0
      break
    case 'y':
      planeContext.measuresWidgetManager = measuresWidgetManagerY
      planeContext.renderer = rendererY
      planeContext.renderWindow = renderWindowY
      planeContext.xyzToViewType = 1
      break
    case 'z':
      planeContext.measuresWidgetManager = measuresWidgetManagerZ
      planeContext.renderer = rendererZ
      planeContext.renderWindow = renderWindowZ
      planeContext.xyzToViewType = 2
      break
    default:
      break
  }

  const lineWidget = vtkLineWidget.newInstance()

  const currentHandle = planeContext.measuresWidgetManager.addWidget(
    lineWidget,
    ViewTypes.SLICE
  )

  currentHandle.setUseActiveColor(false)

  const lineWidgetState = currentHandle.getWidgetState()

  lineWidgetState.setLineThickness(1.5)
  lineWidgetState.getHandle1().setScale1(20)
  lineWidgetState.getHandle1().setColor(0.2)
  lineWidgetState.getHandle1().setShape(LineConstants.ShapeType.ARROWHEAD4)
  lineWidgetState.getHandle2().setScale1(14)
  lineWidgetState.getHandle2().setColor(0.2)
  lineWidgetState.getHandle2().setShape(LineConstants.ShapeType.DISK)
  lineWidgetState.getMoveHandle().setScale1(0)
  lineWidgetState.getMoveHandle().setVisible(false)
  lineWidgetState.getMoveHandle().setScale1(20)
  lineWidgetState.getPositionOnLine().setPosOnLine(1)
  lineWidgetState
    .getHandle1()
    .setOrigin(
      measureEntityDB.handle1[0],
      measureEntityDB.handle1[1],
      measureEntityDB.handle1[2]
    )
  lineWidgetState
    .getHandle2()
    .setOrigin(
      measureEntityDB.handle2[0],
      measureEntityDB.handle2[1],
      measureEntityDB.handle2[2]
    )
  currentHandle.setText(measureEntityDB.label)
  lineWidgetState
    .getText()
    .setOrigin(
      measureEntityDB.handle2[0],
      measureEntityDB.handle2[1],
      measureEntityDB.handle2[2]
    )
  currentHandle.updateHandleVisibility(0)
  currentHandle.updateHandleVisibility(1)
  currentHandle.updateHandleOrientations()
  currentHandle.setEnabled(false)
  setupSVG(lineWidget, planeContext.renderer)
  getLastSvgNode(sliceContainer)

  const newMeasure = {
    id: measureEntityDB.id,
    createdAt: measureEntityDB.createdAt,
    updatedAt: measureEntityDB.updatedAt,
    type: measureEntityDB.type,
    label: measureEntityDB.label,
    plane: measureEntityDB.plane,
    slice: measureEntityDB.slice,
    isLocked: true,
    node: getLastSvgNode(sliceContainer),
    widget:
      planeContext.measuresWidgetManager.getWidgets()[
        planeContext.measuresWidgetManager.getWidgets().length - 1
      ],
    position: measureEntityDB.position,
  }

  if (
    Math.floor(measureEntityDB.position[planeContext.xyzToViewType]) !==
    Math.floor(currentWidgetPosition[planeContext.xyzToViewType])
  ) {
    newMeasure.widget.setVisibility(false)
    newMeasure.node.style.display = 'none'
  }

  lineWidget
    .getManipulator()
    // .setUserOrigin(resliceCursorWidget.getState().widgetState.center)
    .setUserOrigin(measureEntityDB.position)

  lineWidget
    .getManipulator()
    .setUserNormal(
      resliceCursorWidget.getWidgetState().getPlanes()[
        xyzToViewType[planeContext.xyzToViewType]
      ].normal
    )

  currentHandle.onStartInteractionEvent(() => {
    handleStartInteractionEvent({
      index,
      plane: measureEntityDB.plane,
      measuresData,
      measuresWidgetManager: planeContext.measuresWidgetManager,
      currentHandle,
      setActiveWindow,
      setSelectedWidgetIndex,
    })
  })

  measuresData.current = {
    ...measuresData.current,
    [measureEntityDB.plane]: [
      ...measuresData.current[measureEntityDB.plane],
      newMeasure,
    ],
  }

  planeContext.renderWindow.render()
}

export const getEllipseFromDB = (
  context,
  sliceContainer,
  measureEntityDB,
  measuresData,
  setSelectedWidgetIndex,
  setActiveWindow,
  currentWidgetPosition
) => {
  let index = null

  const {
    resliceCursorWidget,
    measuresWidgetManagerX,
    rendererX,
    renderWindowX,
    measuresWidgetManagerY,
    rendererY,
    renderWindowY,
    measuresWidgetManagerZ,
    rendererZ,
    renderWindowZ,
    source,
  } = context.current

  const planeContext = {
    measuresWidgetManager: null,
    renderer: null,
    renderWindow: null,
    xyzToViewType: null,
  }

  switch (measureEntityDB.plane) {
    case 'x':
      planeContext.measuresWidgetManager = measuresWidgetManagerX
      planeContext.renderer = rendererX
      planeContext.renderWindow = renderWindowX
      planeContext.xyzToViewType = 0
      break
    case 'y':
      planeContext.measuresWidgetManager = measuresWidgetManagerY
      planeContext.renderer = rendererY
      planeContext.renderWindow = renderWindowY
      planeContext.xyzToViewType = 1
      break
    case 'z':
      planeContext.measuresWidgetManager = measuresWidgetManagerZ
      planeContext.renderer = rendererZ
      planeContext.renderWindow = renderWindowZ
      planeContext.xyzToViewType = 2
      break
    default:
      break
  }

  const ellipseWidget = vtkEllipseWidget.newInstance({
    modifierBehavior: {
      None: {
        [BehaviorCategory.PLACEMENT]:
          ShapeBehavior[BehaviorCategory.PLACEMENT].CLICK_AND_DRAG,
        [BehaviorCategory.POINTS]:
          ShapeBehavior[BehaviorCategory.POINTS].CORNER_TO_CORNER,
        [BehaviorCategory.RATIO]: ShapeBehavior[BehaviorCategory.RATIO].FREE,
      },
    },
  })

  const currentHandle = planeContext.measuresWidgetManager.addWidget(
    ellipseWidget,
    ViewTypes.SLICE
  )

  currentHandle.getRepresentations()[1].setDrawBorder(true)
  currentHandle.getRepresentations()[1].setDrawFace(false)
  currentHandle.getRepresentations()[1].setOpacity(1)
  currentHandle.setUseActiveColor(false)
  currentHandle.setEnabled(false)

  // const widgetVisibility =
  //   !measuresWidgetManager.getWidgets()[widgetIndex].getPoint1() ||
  //   widget.getWidgetState().getPoint1Handle().getOrigin()[i] === slicePos[i];
  // return widget.setVisibility(widgetVisibility);

  // document.querySelector('.place').addEventListener('click', () => {
  //   if (activeWidget !== 'rectangleWidget') {
  //     const widget = widgets[activeWidget];
  //     const widgetIndex = activeWidget === 'ellipseWidget' ? 1 : 2;
  //     const handle =
  //       activeWidget === 'ellipseWidget'
  //         ? scene.ellipseHandle
  //         : scene.circleHandle;
  //     const coord1 = [0, 0, 0];
  //     const coord2 = [100, 100, 100];
  //     const slicePos = image.imageMapper.getSlice();
  //     const axis = image.imageMapper.getSlicingMode() % 3;
  //     coord1[axis] = slicePos;
  //     coord2[axis] = slicePos;
  //     handle.grabFocus();
  //     handle.placePoint1(coord1);
  //     handle.placePoint2(coord2);
  //     // Place circle
  //     handle.setCorners(coord1, coord2);
  //     // Recompute text position
  //     handle.invokeInteractionEvent();
  //     handle.loseFocus();
  //     updateWidgetVisibility(widget, coord1, axis, widgetIndex);
  //     scene.renderWindow.render();
  //   }
  // });

  const ellipseWidgetState = currentHandle.getWidgetState()

  ellipseWidgetState.getPoint1Handle().setColor(0.2)
  ellipseWidgetState.getPoint2Handle().setColor(0.2)
  ellipseWidgetState.getEllipseHandle().setColor(0.2)
  ellipseWidgetState
    .getPoint1Handle()
    .setOrigin(
      measureEntityDB.handle1[0],
      measureEntityDB.handle1[1],
      measureEntityDB.handle1[2]
    )
  ellipseWidgetState
    .getPoint2Handle()
    .setOrigin(
      measureEntityDB.handle2[0],
      measureEntityDB.handle2[1],
      measureEntityDB.handle2[2]
    )

  const worldBounds = currentHandle.getBounds()
  console.log(worldBounds)
  console.log(
    source.computeHistogram(worldBounds, vtkSphere.isPointIn3DEllipse)
  )
  const { average, minimum, maximum } = source.computeHistogram(
    worldBounds,
    vtkSphere.isPointIn3DEllipse
  )

  const text = `average: ${average.toFixed(
    0
  )} \nmin: ${minimum} \nmax: ${maximum} `
  currentHandle.setText(text)
  ellipseWidgetState
    .getText()
    .setOrigin(
      measureEntityDB.handle2[0],
      measureEntityDB.handle2[1],
      measureEntityDB.handle2[2]
    )
  currentHandle.grabFocus()
  currentHandle.placePoint1(measureEntityDB.handle1)
  currentHandle.placePoint2(measureEntityDB.handle2)
  currentHandle.setCorners(measureEntityDB.handle1, measureEntityDB.handle2)
  currentHandle.invokeInteractionEvent()
  currentHandle.loseFocus()
  // currentHandle.updateRepresentationForRender()
  // currentHandle.updateHandleVisibility(1)
  setupSVG(ellipseWidget, planeContext.renderer, {
    textProps: {
      'text-anchor': 'middle',
    },
  })

  const newMeasure = {
    id: measureEntityDB.id,
    createdAt: measureEntityDB.createdAt,
    updatedAt: measureEntityDB.updatedAt,
    type: measureEntityDB.type,
    label: measureEntityDB.label,
    plane: measureEntityDB.plane,
    slice: measureEntityDB.slice,
    isLocked: true,
    node: getLastSvgNode(sliceContainer),
    widget:
      planeContext.measuresWidgetManager.getWidgets()[
        planeContext.measuresWidgetManager.getWidgets().length - 1
      ],
    position: measureEntityDB.position,
  }

  if (
    Math.floor(measureEntityDB.position[planeContext.xyzToViewType]) !==
    Math.floor(currentWidgetPosition[planeContext.xyzToViewType])
  ) {
    newMeasure.widget.setVisibility(false)
    newMeasure.node.style.display = 'none'
  }

  ellipseWidget
    .getManipulator()
    // .setUserOrigin(resliceCursorWidget.getState().widgetState.center)
    .setUserOrigin(measureEntityDB.position)

  ellipseWidget
    .getManipulator()
    .setUserNormal(
      resliceCursorWidget.getWidgetState().getPlanes()[
        xyzToViewType[planeContext.xyzToViewType]
      ].normal
    )

  currentHandle.onStartInteractionEvent(() => {
    handleStartInteractionEvent({
      index,
      plane: measureEntityDB.plane,
      measuresData,
      measuresWidgetManager: planeContext.measuresWidgetManager,
      currentHandle,
      setActiveWindow,
      setSelectedWidgetIndex,
    })
  })

  measuresData.current = {
    ...measuresData.current,
    [measureEntityDB.plane]: [
      ...measuresData.current[measureEntityDB.plane],
      newMeasure,
    ],
  }

  currentHandle.onInteractionEvent(() => {
    handleEllipseInteractionEvent({
      currentHandle,
      source,
    })
  })

  planeContext.renderWindow.render()
}

export const getCircleFromDB = (
  context,
  sliceContainer,
  measureEntityDB,
  measuresData,
  setSelectedWidgetIndex,
  setActiveWindow,
  currentWidgetPosition
) => {
  let index = null

  const {
    resliceCursorWidget,
    measuresWidgetManagerX,
    rendererX,
    renderWindowX,
    measuresWidgetManagerY,
    rendererY,
    renderWindowY,
    measuresWidgetManagerZ,
    rendererZ,
    renderWindowZ,
    source,
  } = context.current

  const planeContext = {
    measuresWidgetManager: null,
    renderer: null,
    renderWindow: null,
    xyzToViewType: null,
  }

  switch (measureEntityDB.plane) {
    case 'x':
      planeContext.measuresWidgetManager = measuresWidgetManagerX
      planeContext.renderer = rendererX
      planeContext.renderWindow = renderWindowX
      planeContext.xyzToViewType = 0
      break
    case 'y':
      planeContext.measuresWidgetManager = measuresWidgetManagerY
      planeContext.renderer = rendererY
      planeContext.renderWindow = renderWindowY
      planeContext.xyzToViewType = 1
      break
    case 'z':
      planeContext.measuresWidgetManager = measuresWidgetManagerZ
      planeContext.renderer = rendererZ
      planeContext.renderWindow = renderWindowZ
      planeContext.xyzToViewType = 2
      break
    default:
      break
  }

  const circleWidget = vtkEllipseWidget.newInstance({
    modifierBehavior: {
      None: {
        [BehaviorCategory.PLACEMENT]:
          ShapeBehavior[BehaviorCategory.PLACEMENT].CLICK_AND_DRAG,
        [BehaviorCategory.POINTS]:
          ShapeBehavior[BehaviorCategory.POINTS].RADIUS,
        [BehaviorCategory.RATIO]: ShapeBehavior[BehaviorCategory.RATIO].FREE,
      },
    },
  })

  const currentHandle = planeContext.measuresWidgetManager.addWidget(
    circleWidget,
    ViewTypes.SLICE
  )

  currentHandle.getRepresentations()[1].setDrawBorder(true)
  currentHandle.getRepresentations()[1].setDrawFace(false)
  currentHandle.getRepresentations()[1].setOpacity(1)
  currentHandle.setUseActiveColor(false)
  currentHandle.setEnabled(false)

  const circleWidgetState = currentHandle.getWidgetState()

  circleWidgetState.getPoint1Handle().setColor(0.2)
  circleWidgetState.getPoint2Handle().setColor(0.2)
  circleWidgetState.getEllipseHandle().setColor(0.2)
  circleWidgetState
    .getPoint1Handle()
    .setOrigin(
      measureEntityDB.handle1[0],
      measureEntityDB.handle1[1],
      measureEntityDB.handle1[2]
    )
  circleWidgetState
    .getPoint2Handle()
    .setOrigin(
      measureEntityDB.handle2[0],
      measureEntityDB.handle2[1],
      measureEntityDB.handle2[2]
    )

  // currentHandle.setText(circleWidget.getDistance().toFixed(2) + ' mm')
  // const worldBounds = currentHandle.getBounds()
  // const text = `radius: ${(
  //   vec3.distance(
  //     [worldBounds[0], worldBounds[2], worldBounds[4]],
  //     [worldBounds[1], worldBounds[3], worldBounds[5]]
  //   ) / 2
  // ).toFixed(2)}`
  // currentHandle.setText(measureEntityDB.text)
  // circleWidgetState
  //   .getText()
  //   .setOrigin(
  //     measureEntityDB.handle2[0],
  //     measureEntityDB.handle2[1],
  //     measureEntityDB.handle2[2]
  //   )

  // const text = `average: ${average.toFixed(
  //   0
  // )} \nmin: ${minimum} \nmax: ${maximum} `
  // currentHandle.setText(text)
  // ellipseWidgetState
  //   .getText()
  //   .setOrigin(
  //     measureEntityDB.handle2[0],
  //     measureEntityDB.handle2[1],
  //     measureEntityDB.handle2[2]
  //   )

  // console.log(currentHandle)
  // console.log(currentHandle.get().manipulator)
  // console.log(currentHandle.getState())
  currentHandle.grabFocus()
  // planeContext.measuresWidgetManager.grabFocus(circleWidget)
  circleWidget
    .getManipulator()
    // .setUserOrigin(resliceCursorWidget.getState().widgetState.center)
    .setUserOrigin(measureEntityDB.position)

  // console.log(measureEntityDB.plane)
  // console.log(planeContext.xyzToViewType)
  // console.log(
  //   resliceCursorWidget.getWidgetState().getPlanes()[
  //     xyzToViewType[planeContext.xyzToViewType]
  //   ].normal
  // )

  circleWidget
    .getManipulator()
    .setUserNormal(
      resliceCursorWidget.getWidgetState().getPlanes()[
        xyzToViewType[planeContext.xyzToViewType]
      ].normal
    )

  // console.log(
  //   currentHandle
  //     .get()
  //     .widgetState.get()
  //     .ellipseHandle.set({
  //       ...currentHandle.get().widgetState.get().ellipseHandle.get(),
  //       direction: resliceCursorWidget.getWidgetState().getPlanes()[
  //         xyzToViewType[planeContext.xyzToViewType]
  //       ].normal,
  //     })
  // )
  // console.log(currentHandle.get().widgetState.get().ellipseHandle.get())
  // console.log(
  //   currentHandle
  //     .get()
  //     .widgetState.get()
  //     .ellipseHandle.setOrigin(measureEntityDB.position)
  // )

  // currentHandle.setWidgetState({
  //   ...currentHandle.getWidgetState(),
  //   ellipseHandle: {
  //     ...currentHandle.getWidgetState(),
  //     direction: resliceCursorWidget.getWidgetState().getPlanes()[
  //       xyzToViewType[planeContext.xyzToViewType]
  //     ].normal,
  //   },
  // })

  currentHandle.placePoint1(measureEntityDB.handle1)
  currentHandle.placePoint2(measureEntityDB.handle2)
  // currentHandle.setPoints(measureEntityDB.handle1, measureEntityDB.handle2)
  currentHandle.setCorners(measureEntityDB.handle1, measureEntityDB.handle2)
  currentHandle.setText(measureEntityDB.text)
  setupSVG(circleWidget, planeContext.renderer)
  currentHandle.get().manipulator.setUserOrigin(measureEntityDB.position)

  currentHandle
    .get()
    .manipulator.setUserNormal(
      resliceCursorWidget.getWidgetState().getPlanes()[
        xyzToViewType[planeContext.xyzToViewType]
      ].normal
    )
  currentHandle.get().manipulator.setWidgetOrigin(measureEntityDB.position)

  currentHandle
    .get()
    .manipulator.setWidgetNormal(
      resliceCursorWidget.getWidgetState().getPlanes()[
        xyzToViewType[planeContext.xyzToViewType]
      ].normal
    )

  // console.log('ORIGIN')
  // console.log(currentHandle.get().manipulator.getUserOrigin())

  // console.log('NORMAL')
  // console.log(circleWidget.getManipulator().getUserNormal())
  // console.log('==========================')
  currentHandle.invokeInteractionEvent()
  // planeContext.measuresWidgetManager.loseFocus(circleWidget)
  currentHandle.loseFocus()
  // currentHandle.updateRepresentationForRender()
  // currentHandle.updateHandleVisibility(1)

  const newMeasure = {
    id: measureEntityDB.id,
    createdAt: measureEntityDB.createdAt,
    updatedAt: measureEntityDB.updatedAt,
    type: measureEntityDB.type,
    label: measureEntityDB.label,
    plane: measureEntityDB.plane,
    slice: measureEntityDB.slice,
    isLocked: true,
    node: getLastSvgNode(sliceContainer),
    widget:
      planeContext.measuresWidgetManager.getWidgets()[
        planeContext.measuresWidgetManager.getWidgets().length - 1
      ],
    position: measureEntityDB.position,
  }

  if (
    Math.floor(measureEntityDB.position[planeContext.xyzToViewType]) !==
    Math.floor(currentWidgetPosition[planeContext.xyzToViewType])
  ) {
    newMeasure.widget.setVisibility(false)
    newMeasure.node.style.display = 'none'
  }

  currentHandle.onStartInteractionEvent(() => {
    handleStartInteractionEvent({
      index,
      plane: measureEntityDB.plane,
      measuresData,
      measuresWidgetManager: planeContext.measuresWidgetManager,
      currentHandle,
      setActiveWindow,
      setSelectedWidgetIndex,
    })
  })

  measuresData.current = {
    ...measuresData.current,
    [measureEntityDB.plane]: [
      ...measuresData.current[measureEntityDB.plane],
      newMeasure,
    ],
  }

  currentHandle.onInteractionEvent(() => {
    handleCircleInteractionEvent(currentHandle)
  })

  planeContext.renderWindow.render()
}

export const createRulerInstance = (
  params = {
    plane: null,
    viewType: null,
    measuresData: null,
    measuresWidgetManager: null,
    renderer: null,
    sliceContainer: null,
    currentWidgetPosition: null,
    resliceCursorWidget: null,
    setActiveWindow: null,
    setSelectedWidgetIndex: null,
    slice: null,
  }
) => {
  let index = null

  const {
    plane,
    viewType,
    measuresData,
    measuresWidgetManager,
    renderer,
    sliceContainer,
    currentWidgetPosition,
    resliceCursorWidget,
    setActiveWindow,
    setSelectedWidgetIndex,
    slice,
  } = params

  const lineWidget = vtkLineWidget.newInstance()

  const currentHandle = measuresWidgetManager.addWidget(
    lineWidget,
    ViewTypes.SLICE
  )

  const lineWidgetState = currentHandle.getWidgetState()

  lineWidgetState.setLineThickness(1.5)
  lineWidgetState.getHandle1().setScale1(22)
  lineWidgetState.getHandle1().setShape(LineConstants.ShapeType.VIEWFINDER)
  lineWidgetState.getHandle2().setScale1(22)
  lineWidgetState.getMoveHandle().setVisible(false)
  lineWidgetState.getHandle2().setShape(LineConstants.ShapeType.VIEWFINDER)
  lineWidgetState.getPositionOnLine().setPosOnLine(1)
  setupSVG(lineWidget, renderer)
  getLastSvgNode(sliceContainer)

  const newMeasure = {
    id: null,
    createdAt: format(new Date(), 'dd-MM-yyyy HH:mm:ss'),
    updatedAt: format(new Date(), 'dd-MM-yyyy HH:mm:ss'),
    type: 'ruler',
    label: `Ruler ${
      measuresData.current.x.length +
      measuresData.current.y.length +
      measuresData.current.z.length +
      1
    }`,
    plane,
    slice,
    isLocked: false,
    node: getLastSvgNode(sliceContainer),
    widget:
      measuresWidgetManager.getWidgets()[
        measuresWidgetManager.getWidgets().length - 1
      ],
    position: currentWidgetPosition,
  }

  handleEnter({
    index,
    plane,
    viewType,
    measuresData,
    newMeasure,
    measureWidget: lineWidget,
    measuresWidgetManager,
    currentWidgetPosition,
    resliceCursorWidget,
    setActiveWindow,
    setSelectedWidgetIndex,
    currentHandle,
  })

  currentHandle.onInteractionEvent(() => {
    handleRulerInteractionEvent({
      currentHandle,
      measureWidget: lineWidget,
    })
  })
}

export const createAnnotationInstance = (
  params = {
    plane: null,
    viewType: null,
    measuresData: null,
    measuresWidgetManager: null,
    renderer: null,
    sliceContainer: null,
    currentWidgetPosition: null,
    resliceCursorWidget: null,
    setActiveWindow: null,
    setSelectedWidgetIndex: null,
    slice: null,
    setIsAnnotationModalVisible: null,
  }
) => {
  let index = null

  const {
    plane,
    viewType,
    measuresData,
    measuresWidgetManager,
    renderer,
    sliceContainer,
    currentWidgetPosition,
    resliceCursorWidget,
    setActiveWindow,
    setSelectedWidgetIndex,
    setIsAnnotationModalVisible,
    slice,
  } = params

  const lineWidget = vtkLineWidget.newInstance()

  const currentHandle = measuresWidgetManager.addWidget(
    lineWidget,
    ViewTypes.SLICE
  )

  const lineWidgetState = currentHandle.getWidgetState()

  lineWidgetState.setLineThickness(1.5)
  lineWidgetState.getHandle1().setScale1(20)
  lineWidgetState.getHandle1().setShape(LineConstants.ShapeType.ARROWHEAD4)
  lineWidgetState.getHandle2().setScale1(14)
  lineWidgetState.getMoveHandle().setScale1(0)
  lineWidgetState.getMoveHandle().setVisible(false)
  lineWidgetState.getHandle2().setShape(LineConstants.ShapeType.DISK)
  lineWidgetState.getMoveHandle().setScale1(20)
  lineWidgetState.getPositionOnLine().setPosOnLine(1)
  setupSVG(lineWidget, renderer)

  const newMeasure = {
    id: null,
    createdAt: format(new Date(), 'dd-MM-yyyy HH:mm:ss'),
    updatedAt: format(new Date(), 'dd-MM-yyyy HH:mm:ss'),
    type: 'annotation',
    label: `Annotation ${
      measuresData.current.x.length +
      measuresData.current.y.length +
      measuresData.current.z.length +
      1
    }`,
    plane,
    slice,
    isLocked: false,
    node: getLastSvgNode(sliceContainer),
    widget:
      measuresWidgetManager.getWidgets()[
        measuresWidgetManager.getWidgets().length - 1
      ],
    position: currentWidgetPosition,
  }

  handleEnter({
    index,
    plane,
    viewType,
    measuresData,
    newMeasure,
    measureWidget: lineWidget,
    measuresWidgetManager,
    currentWidgetPosition,
    resliceCursorWidget,
    setActiveWindow,
    setSelectedWidgetIndex,
    currentHandle,
  })

  currentHandle.onEndInteractionEvent(() => {
    if (
      !DeepEqual(
        currentHandle.getState().widgetState.handle1.origin,
        currentHandle.getState().widgetState.handle2.origin
      ) &&
      currentHandle.getState().widgetState.handle1.origin &&
      currentHandle.getState().widgetState.handle2.origin &&
      !currentHandle.getState().widgetState.text.text
    ) {
      setIsAnnotationModalVisible(true)
    }
  })
}

export const createEllipseInstance = (
  params = {
    plane: null,
    viewType: null,
    measuresData: null,
    measuresWidgetManager: null,
    renderer: null,
    sliceContainer: null,
    currentWidgetPosition: null,
    resliceCursorWidget: null,
    setActiveWindow: null,
    setSelectedWidgetIndex: null,
    slice: null,
    source: null,
  }
) => {
  let index = null

  const {
    plane,
    viewType,
    measuresData,
    measuresWidgetManager,
    renderer,
    sliceContainer,
    currentWidgetPosition,
    resliceCursorWidget,
    setActiveWindow,
    setSelectedWidgetIndex,
    slice,
    source,
  } = params

  const ellipseWidget = vtkEllipseWidget.newInstance({
    modifierBehavior: {
      None: {
        [BehaviorCategory.PLACEMENT]:
          ShapeBehavior[BehaviorCategory.PLACEMENT].CLICK_AND_DRAG,
        [BehaviorCategory.POINTS]:
          ShapeBehavior[BehaviorCategory.POINTS].CORNER_TO_CORNER,
        [BehaviorCategory.RATIO]: ShapeBehavior[BehaviorCategory.RATIO].FREE,
      },
    },
  })

  const currentHandle = measuresWidgetManager.addWidget(
    ellipseWidget,
    ViewTypes.SLICE
  )

  currentHandle.getRepresentations()[1].setDrawBorder(true)
  currentHandle.getRepresentations()[1].setDrawFace(false)
  currentHandle.getRepresentations()[1].setOpacity(1)

  setupSVG(ellipseWidget, renderer, {
    textProps: {
      'text-anchor': 'middle',
    },
  })

  const newMeasure = {
    id: null,
    createdAt: format(new Date(), 'dd-MM-yyyy HH:mm:ss'),
    updatedAt: format(new Date(), 'dd-MM-yyyy HH:mm:ss'),
    type: 'ellipse',
    label: `Ellipse ${
      measuresData.current.x.length +
      measuresData.current.y.length +
      measuresData.current.z.length +
      1
    }`,
    plane,
    slice,
    isLocked: false,
    node: getLastSvgNode(sliceContainer),
    widget:
      measuresWidgetManager.getWidgets()[
        measuresWidgetManager.getWidgets().length - 1
      ],
    position: currentWidgetPosition,
  }

  handleEnter({
    index,
    plane,
    viewType,
    measuresData,
    newMeasure,
    measureWidget: ellipseWidget,
    measuresWidgetManager,
    currentWidgetPosition,
    resliceCursorWidget,
    setActiveWindow,
    setSelectedWidgetIndex,
    currentHandle,
  })

  currentHandle.onInteractionEvent(() => {
    handleEllipseInteractionEvent({
      currentHandle,
      source,
    })
  })
}

export const createCircleInstance = (
  params = {
    plane: null,
    viewType: null,
    measuresData: null,
    measuresWidgetManager: null,
    renderer: null,
    sliceContainer: null,
    currentWidgetPosition: null,
    resliceCursorWidget: null,
    setActiveWindow: null,
    setSelectedWidgetIndex: null,
    slice: null,
  }
) => {
  let index = null

  const {
    plane,
    viewType,
    measuresData,
    measuresWidgetManager,
    renderer,
    sliceContainer,
    currentWidgetPosition,
    resliceCursorWidget,
    setActiveWindow,
    setSelectedWidgetIndex,
    slice,
  } = params

  const circleWidget = vtkEllipseWidget.newInstance({
    modifierBehavior: {
      None: {
        [BehaviorCategory.PLACEMENT]:
          ShapeBehavior[BehaviorCategory.PLACEMENT].CLICK_AND_DRAG,
        [BehaviorCategory.POINTS]:
          ShapeBehavior[BehaviorCategory.POINTS].RADIUS,
        [BehaviorCategory.RATIO]: ShapeBehavior[BehaviorCategory.RATIO].FREE,
      },
    },
  })

  const currentHandle = measuresWidgetManager.addWidget(
    circleWidget,
    ViewTypes.SLICE
  )

  currentHandle.getRepresentations()[1].setDrawBorder(true)
  currentHandle.getRepresentations()[1].setDrawFace(false)
  currentHandle.getRepresentations()[1].setOpacity(1)

  setupSVG(circleWidget, renderer)

  const newMeasure = {
    id: null,
    createdAt: format(new Date(), 'dd-MM-yyyy HH:mm:ss'),
    updatedAt: format(new Date(), 'dd-MM-yyyy HH:mm:ss'),
    type: 'circle',
    label: `Circle ${
      measuresData.current.x.length +
      measuresData.current.y.length +
      measuresData.current.z.length +
      1
    }`,
    plane,
    slice,
    isLocked: false,
    node: getLastSvgNode(sliceContainer),
    widget:
      measuresWidgetManager.getWidgets()[
        measuresWidgetManager.getWidgets().length - 1
      ],
    position: currentWidgetPosition,
  }

  handleEnter({
    index,
    plane,
    viewType,
    measuresData,
    newMeasure,
    measureWidget: circleWidget,
    measuresWidgetManager,
    currentWidgetPosition,
    resliceCursorWidget,
    setActiveWindow,
    setSelectedWidgetIndex,
    currentHandle,
  })

  currentHandle.onInteractionEvent(() => {
    handleCircleInteractionEvent(currentHandle)
  })
}

const handleEnter = (
  params = {
    index: null,
    plane: null,
    viewType: null,
    measuresData: null,
    newMeasure: null,
    measureWidget: null,
    measuresWidgetManager: null,
    currentWidgetPosition: null,
    resliceCursorWidget: null,
    setActiveWindow: null,
    setSelectedWidgetIndex: null,
    currentHandle: null,
  }
) => {
  let {
    index,
    plane,
    viewType,
    measuresData,
    newMeasure,
    measureWidget,
    measuresWidgetManager,
    currentWidgetPosition,
    resliceCursorWidget,
    setActiveWindow,
    setSelectedWidgetIndex,
    currentHandle,
  } = params

  measuresWidgetManager.grabFocus(measureWidget)

  measureWidget
    .getManipulator()
    // .setUserOrigin(resliceCursorWidget.getState().widgetState.center)
    .setUserOrigin(resliceCursorWidget.getWidgetState().getCenter())

  measureWidget
    .getManipulator()
    .setUserNormal(
      resliceCursorWidget.getWidgetState().getPlanes()[viewType].normal
    )

  setActiveWindow(plane)

  switch (plane) {
    case 'x':
      setSelectedWidgetIndex({
        x: measuresWidgetManager.getWidgets().length - 1,
        y: null,
        z: null,
      })
      break
    case 'y':
      setSelectedWidgetIndex({
        x: null,
        y: measuresWidgetManager.getWidgets().length - 1,
        z: null,
      })
      break
    case 'z':
      setSelectedWidgetIndex({
        x: null,
        y: null,
        z: measuresWidgetManager.getWidgets().length - 1,
      })
      break
    default:
      break
  }

  measuresData.current = {
    ...measuresData.current,
    [plane]: [...measuresData.current[plane], newMeasure],
  }

  console.log(newMeasure)

  currentHandle.onStartInteractionEvent(() => {
    handleStartInteractionEvent({
      index,
      plane,
      measuresData,
      measuresWidgetManager,
      currentHandle,
      setActiveWindow,
      setSelectedWidgetIndex,
    })
  })
}

export const handleLineWidgetLeave = (
  params = {
    plane: null,
    measuresData: null,
    measuresWidgetManager: null,
    setActiveWindow: null,
    setSelectedWidgetIndex: null,
  }
) => {
  const {
    plane,
    measuresData,
    measuresWidgetManager,
    setActiveWindow,
    setSelectedWidgetIndex,
  } = params

  setActiveWindow(null)

  if (measuresWidgetManager.getWidgets().length) {
    if (
      !measuresWidgetManager
        .getWidgets()
        [measuresWidgetManager.getWidgets().length - 1].getState().widgetState
        .handle2.origin ||
      DeepEqual(
        measuresWidgetManager
          .getWidgets()
          [measuresWidgetManager.getWidgets().length - 1].getState().widgetState
          .handle1.origin,
        measuresWidgetManager
          .getWidgets()
          [measuresWidgetManager.getWidgets().length - 1].getState().widgetState
          .handle2.origin
      )
    ) {
      measuresWidgetManager.removeWidget(
        measuresWidgetManager.getWidgets()[
          measuresData.current[plane].length - 1
        ]
      )
      measuresData.current[plane][
        measuresData.current[plane].length - 1
      ].node.remove()
      const updatedMeasuresData = [...measuresData.current[plane]]
      updatedMeasuresData.splice(measuresData.current[plane].length - 1, 1)
      setSelectedWidgetIndex({
        x: null,
        y: null,
        z: null,
      })

      measuresData.current = {
        ...measuresData.current,
        [plane]: updatedMeasuresData,
      }
    }
  }
}

export const handleEllipseWidgetLeave = (
  params = {
    plane: null,
    measuresData: null,
    measuresWidgetManager: null,
    setActiveWindow: null,
    setSelectedWidgetIndex: null,
  }
) => {
  const {
    plane,
    measuresData,
    measuresWidgetManager,
    setActiveWindow,
    setSelectedWidgetIndex,
  } = params

  setActiveWindow(null)
  if (measuresWidgetManager.getWidgets().length) {
    if (
      !measuresWidgetManager
        .getWidgets()
        [measuresWidgetManager.getWidgets().length - 1].getState().widgetState
        .point2Handle.origin ||
      DeepEqual(
        measuresWidgetManager
          .getWidgets()
          [measuresWidgetManager.getWidgets().length - 1].getState().widgetState
          .point1Handle.origin,
        measuresWidgetManager
          .getWidgets()
          [measuresWidgetManager.getWidgets().length - 1].getState().widgetState
          .point2Handle.origin
      )
    ) {
      measuresWidgetManager.removeWidget(
        measuresWidgetManager.getWidgets()[
          measuresData.current[plane].length - 1
        ]
      )
      measuresData.current[plane][
        measuresData.current[plane].length - 1
      ].node.remove()
      const updatedMeasuresData = [...measuresData.current[plane]]
      updatedMeasuresData.splice(measuresData.current[plane].length - 1, 1)
      setSelectedWidgetIndex({
        x: null,
        y: null,
        z: null,
      })
      measuresData.current = {
        ...measuresData.current,
        [plane]: updatedMeasuresData,
      }
    }
  }
}

const handleStartInteractionEvent = (
  params = {
    index: null,
    plane: null,
    measuresData: null,
    measuresWidgetManager: null,
    currentHandle: null,
    setActiveWindow: null,
    setSelectedWidgetIndex: null,
  }
) => {
  let {
    index,
    plane,
    measuresData,
    measuresWidgetManager,
    currentHandle,
    setActiveWindow,
    setSelectedWidgetIndex,
  } = params

  index = measuresWidgetManager.getWidgets().findIndex((cwidget) => {
    if (DeepEqual(currentHandle.getWidgetState(), cwidget.getWidgetState()))
      return 1
    return 0
  })

  switch (plane) {
    case 'x':
      setSelectedWidgetIndex({ x: index, y: null, z: null })
      break
    case 'y':
      setSelectedWidgetIndex({ x: null, y: index, z: null })
      break
    case 'z':
      setSelectedWidgetIndex({ x: null, y: null, z: index })
      break
    default:
      break
  }

  if (
    measuresData.current[plane][measuresWidgetManager.getWidgets().length - 1]
      .type === 'ruler' ||
    measuresData.current[plane][measuresWidgetManager.getWidgets().length - 1]
      .type === 'annotation'
  ) {
    if (
      !DeepEqual(
        measuresWidgetManager
          .getWidgets()
          [measuresWidgetManager.getWidgets().length - 1].getState().widgetState
          .handle1.origin,
        measuresWidgetManager
          .getWidgets()
          [measuresWidgetManager.getWidgets().length - 1].getState().widgetState
          .handle2.origin
      )
    ) {
      setActiveWindow(null)
    }
  } else if (
    measuresData.current[plane][measuresWidgetManager.getWidgets().length - 1]
      .type === 'ellipse' ||
    measuresData.current[plane][measuresWidgetManager.getWidgets().length - 1]
      .type === 'circle'
  ) {
    if (
      !DeepEqual(
        measuresWidgetManager
          .getWidgets()
          [measuresWidgetManager.getWidgets().length - 1].getState().widgetState
          .point1Handle.origin,
        measuresWidgetManager
          .getWidgets()
          [measuresWidgetManager.getWidgets().length - 1].getState().widgetState
          .point2Handle.origin
      )
    ) {
      setActiveWindow(null)
    }
  }
}

const handleRulerInteractionEvent = (
  params = { currentHandle: null, measureWidget: null }
) => {
  const { currentHandle, measureWidget } = params
  currentHandle.setText(measureWidget.getDistance().toFixed(2) + ' mm')
}

const handleEllipseInteractionEvent = (
  params = { currentHandle: null, source: null }
) => {
  const { currentHandle, source } = params
  const worldBounds = currentHandle.getBounds()
  const { average, minimum, maximum } = source.computeHistogram(
    worldBounds,
    vtkSphere.isPointIn3DEllipse
  )
  // console.log(worldBounds)
  // console.log(
  //   source.computeHistogram(worldBounds, vtkSphere.isPointIn3DEllipse)
  // )

  const text = `average: ${average.toFixed(
    0
  )} \nmin: ${minimum} \nmax: ${maximum} `
  currentHandle.setText(text)
}

const handleCircleInteractionEvent = (currentHandle) => {
  const worldBounds = currentHandle.getBounds()

  const text = `radius: ${(
    vec3.distance(
      [worldBounds[0], worldBounds[2], worldBounds[4]],
      [worldBounds[1], worldBounds[3], worldBounds[5]]
    ) / 2
  ).toFixed(2)}`
  currentHandle.setText(text)
}

export const handleAddText = (
  params = {
    measuresData: null,
    plane: null,
    selectedWidgetIndex: null,
    setSelectedWidgetIndex: null,
    annotationText: '',
  }
) => {
  const {
    measuresData,
    plane,
    selectedWidgetIndex,
    setSelectedWidgetIndex,
    annotationText,
  } = params
  if (
    measuresData.current[plane][selectedWidgetIndex[plane]].type ===
    'annotation'
  ) {
    measuresData.current[plane][selectedWidgetIndex[plane]].widget.setText(
      annotationText.trim()
    )
    measuresData.current[plane][selectedWidgetIndex[plane]].label =
      annotationText.trim()
    switch (plane) {
      case 'x':
        setSelectedWidgetIndex({
          x: selectedWidgetIndex[plane],
          y: null,
          z: null,
        })
        break
      case 'y':
        setSelectedWidgetIndex({
          x: null,
          y: selectedWidgetIndex[plane],
          z: null,
        })
        break
      case 'z':
        setSelectedWidgetIndex({
          x: null,
          y: null,
          z: selectedWidgetIndex[plane],
        })
        break
      default:
        break
    }
  }
}

export const handleDeleteAnnotation = (
  params = {
    measuresWidgetManager: null,
    measuresData: null,
    plane: null,
    selectedWidgetIndex: null,
  }
) => {
  const { measuresWidgetManager, measuresData, plane, selectedWidgetIndex } =
    params
  if (
    measuresData.current[plane][selectedWidgetIndex[plane]].type ===
    'annotation'
  ) {
    measuresData.current[plane][selectedWidgetIndex[plane]].node.remove()
    measuresWidgetManager.removeWidget(
      measuresData.current[plane][selectedWidgetIndex[plane]].widget
    )
    const updatedMeasuresData = [...measuresData.current[plane]]
    updatedMeasuresData.splice(selectedWidgetIndex[plane], 1)
    measuresData.current = {
      ...measuresData.current,
      [plane]: updatedMeasuresData,
    }
  }
}
