import React, { useRef, useState } from 'react'
import clsx from 'clsx'

import useOutsideClick from '../../hooks/useOutsideClick'

import Application from './Application/Application'
import File from './File/File'
import Database from './Database/Database'
import Help from './Help/Help'
import Onshape from './Onshape/Onshape'

/**
 * Menubar component
 *
 * @component
 * @param {number} props.windowRatio - width/height ratio to determine block size
 * @returns {JSX.Element} The rendered menubar component.
 */
const Menubar = ({ windowRatio }) => {
  const [activeItem, setActiveItem] = useState(null)
  const navbarRef = useRef(null)

  const handleItemClick = (item, e) => {
    if (
      e.target.closest('li') &&
      e.target.closest('li').querySelector(':scope > button') &&
      e.target.tagName !== 'UL'
    ) {
      setActiveItem(activeItem === item ? null : item)
    }
  }

  const handleMouseOver = (item) => {
    if (activeItem !== null) {
      setActiveItem(item)
    }
  }
  useOutsideClick({
    dependency: activeItem,
    ref: navbarRef,
    setClosed: setActiveItem,
    setter: null,
  })

  return (
    <header className="h-[34px] border-b border-b-blue flex justify-start items-center">
      <nav
        className={clsx(
          'w-full h-full px-[15px] desktop:px-[27px] my-0 mx-auto',
          {
            'max-w-[1920px]': windowRatio > 2,
          }
        )}
      >
        <ul
          ref={navbarRef}
          className="h-full max-w-max flex text-sm desktop:text-base font-normal"
        >
          <Application
            isActive={activeItem === 'application'}
            onClick={(e) => handleItemClick('application', e)}
            onMouseOver={() => handleMouseOver('application')}
          />
          <File
            isActive={activeItem === 'file'}
            onClick={(e) => handleItemClick('file', e)}
            onMouseOver={() => handleMouseOver('file')}
          />
          <Database
            isActive={activeItem === 'database'}
            onClick={(e) => handleItemClick('database', e)}
            onMouseOver={() => handleMouseOver('database')}
          />
          <Help
            isActive={activeItem === 'help'}
            onClick={(e) => handleItemClick('help', e)}
            onMouseOver={() => handleMouseOver('help')}
          />
          <Onshape
            isActive={activeItem === 'onshape'}
            onClick={(e) => handleItemClick('onshape', e)}
            onMouseOver={() => handleMouseOver('onshape')}
          />
        </ul>
      </nav>
    </header>
  )
}

export default Menubar
