import axios from 'axios'

import {
  ColorSpace,
  Scale,
} from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction/Constants'

export const loadJson = async (presetPath, colorPath, context) => {
  if (context.current) {
    const {
      actor,
      ctfun,
      ofun,
      imageScalarBarActor,
      renderWindow,
      resliceActorX,
      resliceActorY,
      resliceActorZ,
      renderWindowX,
      renderWindowY,
      renderWindowZ,
    } = context.current

    const presetResponse = await axios.get(presetPath)
    const presetData = presetResponse.data

    const points = presetData[0].Points

    ofun.removeAllPoints()

    for (let i = 0; i < points.length; i += 2) {
      const x = points[i]
      const y = points[i + 1]
      ofun.addPoint(x, y)
    }

    const colorResponse = await axios.get(colorPath)
    const colorData = colorResponse.data

    const colorRgbPoints = colorData[0].RGBPoints

    const pp = stretchColorMap(colorRgbPoints, points)

    ctfun.removeAllPoints()

    for (let i = 0; i < pp.length; i += 4) {
      const x = pp[i]
      const r = pp[i + 1]
      const g = pp[i + 2]
      const b = pp[i + 3]
      ctfun.addRGBPoint(x, r, g, b)
    }

    ctfun.setColorSpace(ColorSpace.DIVERGING)

    actor.getProperty().setRGBTransferFunction(0, ctfun)

    resliceActorX.getProperty().setRGBTransferFunction(0, ctfun)
    resliceActorY.getProperty().setRGBTransferFunction(0, ctfun)
    resliceActorZ.getProperty().setRGBTransferFunction(0, ctfun)
    // resliceActorX.getProperty().setRGBTransferFunction(0, ofun)

    actor.getProperty().setScalarOpacity(0, ofun)
    actor.getProperty().setInterpolationTypeToLinear()
    actor.getProperty().setShade(false)
    actor.getProperty().setAmbient(0.5)
    actor.getProperty().setDiffuse(0.3)
    actor.getProperty().setSpecular(0.3)

    imageScalarBarActor.setScalarsToColors(ctfun)

    renderWindow.render()
    renderWindowX.render()
    renderWindowY.render()
    renderWindowZ.render()

    return generateVisualContrastAndPresetLevel(points)
  }
}

export const loadXRayJson = async (presetPath, colorPath, context) => {
  if (context.current) {
    console.log(presetPath)
    console.log(colorPath)
    const { ctfun, renderWindow, resliceActorZ, imageScalarBarActorZ } =
      context.current

    const presetResponse = await axios.get(presetPath)
    const presetData = presetResponse.data

    const points = presetData[0].Points

    const colorResponse = await axios.get(colorPath)
    const colorData = colorResponse.data

    const colorRgbPoints = colorData[0].RGBPoints

    const pp = stretchColorMap(colorRgbPoints, points)

    ctfun.removeAllPoints()

    for (let i = 0; i < pp.length; i += 4) {
      const x = pp[i]
      const r = pp[i + 1]
      const g = pp[i + 2]
      const b = pp[i + 3]
      ctfun.addRGBPoint(x, r, g, b)
    }

    ctfun.setColorSpace(ColorSpace.DIVERGING)

    resliceActorZ.getProperty().setRGBTransferFunction(0, ctfun)
    resliceActorZ.getProperty().setInterpolationTypeToLinear()
    resliceActorZ.getProperty().setAmbient(0.5)
    resliceActorZ.getProperty().setDiffuse(0.3)

    imageScalarBarActorZ.setScalarsToColors(ctfun)

    renderWindow.render()

    return generateVisualContrastAndPresetLevel(points)
  }
}

export const loadJsonForFunctional = async (
  colorPath,
  context,
  isLogScaleEnabled,
  range
) => {
  if (context.current) {
    const {
      source,
      functionalActor,
      functionalCtfun,
      functionalOfun,
      functionalMapper,
      resliceActorX,
      functionalSource,
      functionalScalarBarActor,
      functionalSliceActor,
      functionalRenderWindow,
    } = context.current

    // console.log('source')

    // console.log(source.getOrigin())

    // console.log('funcitonal source')

    // console.log(functionalSource.getOrigin())

    // functionalCtfun.setScale(Scale.LOG10)
    // console.log(functionalCtfun.getScale())

    const functionalRange = functionalSource
      .getPointData()
      .getScalars()
      .getRange()

    const presetResponse = await axios.get('/assets/json/organs/Nasal.json')
    const presetData = presetResponse.data

    const points = presetData[0].Points

    const colorResponse = await axios.get(colorPath)
    const colorData = colorResponse.data

    const colorRgbPoints = colorData[0].RGBPoints

    const pp = stretchColorMap(colorRgbPoints, points)

    functionalCtfun.removeAllPoints()

    for (let i = 0; i < pp.length; i += 4) {
      // console.log('x')
      const x = pp[i]
      // console.log(x)
      const r = pp[i + 1]
      const g = pp[i + 2]
      const b = pp[i + 3]
      functionalCtfun.addRGBPoint(x, r, g, b)
    }

    // functionalCtfun.setColorSpace(ColorSpace.DIVERGING)

    // if (isLogScaleEnabled) {
    //   // functionalCtfun.setColorSpace(ColorSpace.HSV)
    //   functionalCtfun.setScale(Scale.LOG10)
    // } else {
    //   // functionalCtfun.setColorSpace(ColorSpace.LAB)
    //   functionalCtfun.setScale(Scale.LINEAR)
    // }

    if (isLogScaleEnabled && range.length) {
      functionalCtfun.setRange(range[0], range[1])
      // functionalCtfun.setRange(-2.35, functionalRange[1])
    } else {
      functionalCtfun.setRange(functionalRange[0], functionalRange[1])
    }

    // const ctRange = functionalCtfun.getRange()

    // console.log(pp)
    // const cwidth = 1024
    // const colorTable = new Float32Array(cwidth * 3)
    // console.log(functionalCtfun)
    // console.log(colorTable)
    // functionalCtfun.getTable(0, 139, 140, pp)
    // console.log(colorTable)
    // console.log(functionalCtfun.usingLogScale())

    // console.log(functionalActor.getProperty())

    functionalActor.getProperty().setRGBTransferFunction(0, functionalCtfun)
    functionalSliceActor
      .getProperty()
      .setRGBTransferFunction(0, functionalCtfun)

    if (context.current.slineActor) {
      context.current.slineActor
        .getProperty()
        .setDiffuseColor([pp[0], pp[1], pp[2]])
    }

    functionalScalarBarActor.setScalarsToColors(functionalCtfun)

    // console.log(resliceActorX.getProperty())

    // resliceActorX.getProperty.setRGBTransferFunction(0, functionalCtfun)

    functionalRenderWindow.render()

    return functionalCtfun
  }
}

const splitArrayIntoChunks = (array, chunkSize) => {
  const result = []
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }
  return result
}

const stretchColorMap = (rgbaPointsCM, opacityPointsO) => {
  let minHU = +1e6
  let maxHU = -1e6

  const updatedOpacityPointsO = splitArrayIntoChunks(opacityPointsO, 4)
  const updatedRgbaPointsCM = splitArrayIntoChunks(rgbaPointsCM, 4)

  for (let p of updatedOpacityPointsO) {
    if (p[1] > 1e-2) {
      minHU = p[0]
      break
    }
  }

  for (let p of updatedOpacityPointsO.reverse()) {
    if (p[1] > 1e-2) {
      maxHU = p[0]
      break
    }
  }

  let pp = []
  for (let p of updatedRgbaPointsCM) {
    pp.push(minHU + p[0] * (maxHU - minHU), p[1], p[2], p[3])
  }

  return pp
}

const generateVisualContrastAndPresetLevel = (points) => {
  const pointsGroups = splitArrayIntoChunks(points, 4)
  const min = pointsGroups[0][0]
  const max = pointsGroups[pointsGroups.length - 1][0]

  const visualContrast = `${min} : ${max} HU`
  const presetLevel = `${Math.floor(0.5 * (min + max))} HU`
  const window = `${Math.floor(0.5 * (max - min))} HU`

  return { visualContrast, presetLevel, window }
}
