import React, { useContext } from 'react'
import clsx from 'clsx'

import { ActionsTreeContext } from '../../contexts/ActionsTreeContext'

import DiagnosticsItem from './DiagnosticsItem'
import DiagnosticsItemAnatomy from './DiagnosticsItemAnatomy'

/**
 * Diagnostics page
 *
 * @component
 * @param {boolean} props.isTaxonomyActive - determine whether the taxonomy is open or not
 * @returns {JSX.Element} The rendered diagnostics page component.
 */
const Diagnostics = ({ isTaxonomyActive }) => {
  const { mainActorData } = useContext(ActionsTreeContext)

  return (
    <div
      className={clsx(
        'flex flex-col gap-2 laptop:gap-3 desktop:gap-4 h-full p-[18px]',
        {
          'w-[95%]': isTaxonomyActive,
          'w-[96%]': !isTaxonomyActive,
        }
      )}
    >
      <div className="flex flex-col h-[18%] inset-0 backdrop-blur-lg bg-pale-blue/30 p-1 laptop:p-2 desktop:p-3 rounded-lg shadow-[4px_4px_10px_0px_#00000040]">
        <div
          className={clsx(
            'grid grid-flow-col grid-cols-4 grid-rows-2 gap-2 items-center',
            {
              'h-1/2 pb-1 laptop:pb-2 desktop:pb-3':
                mainActorData.chartDiagnosticsData.length,
              'h-full': !mainActorData.chartDiagnosticsData.length,
            }
          )}
        >
          <div className="flex gap-1 text-xs laptop:text-sm desktop:text-base leading-none font-light">
            <span className="font-bold tracking-wide">Patient: </span>
            {mainActorData.radiologyMetaData.patient}
          </div>
          <div className="flex gap-1 text-xs laptop:text-sm desktop:text-base leading-none font-light">
            <span className="font-bold tracking-wide">ID: </span>
            {mainActorData.radiologyMetaData.patientID}
          </div>
          <div className="flex gap-1 text-xs laptop:text-sm desktop:text-base leading-none font-light">
            <span className="font-bold tracking-wide">Age: </span>
            {mainActorData.radiologyMetaData.patientAge}
          </div>
          <div className="flex gap-1 text-xs laptop:text-sm desktop:text-base leading-none font-light">
            <span className="font-bold tracking-wide">DOB: </span>
            {mainActorData.radiologyMetaData.patientBirthDate}
          </div>
          <div className="flex gap-1 text-xs laptop:text-sm desktop:text-base leading-none font-light">
            <span className="font-bold tracking-wide">Sex: </span>
            {mainActorData.radiologyMetaData.patientSex}
          </div>
          <div className="flex gap-1 text-xs laptop:text-sm desktop:text-base leading-none font-light">
            <span className="font-bold tracking-wide">Position: </span>
            {mainActorData.radiologyMetaData.patientPosition}
          </div>
          <div className="flex gap-1 text-xs laptop:text-sm desktop:text-base leading-none font-light">
            <span className="font-bold tracking-wide">Acquisition date:</span>
            {mainActorData.radiologyMetaData.acquisitionDate}
          </div>
          <div className="flex gap-1 text-xs laptop:text-sm desktop:text-base leading-none font-light">
            <span className="font-bold tracking-wide">Acquisition time:</span>
            {mainActorData.radiologyMetaData.acquisitionTime}
          </div>
        </div>
        {mainActorData.chartDiagnosticsData.length ? (
          <div className="grid grid-flow-col grid-cols-4 grid-rows-2 gap-2 items-center h-1/2 pt-1 laptop:pt-2 desktop:pt-3 border-t border-t-semi-transparent">
            <div className="flex gap-1 text-xs laptop:text-sm desktop:text-base leading-none font-light truncate w-full">
              <span className="font-bold tracking-wide">Manufacturer: </span>
              {mainActorData.radiologyMetaData.manufacturer}
            </div>
            <div className="flex gap-1 text-xs laptop:text-sm desktop:text-base leading-none font-light">
              <span className="font-bold tracking-wide">Model: </span>
              {mainActorData.radiologyMetaData.manufacturerModelName}
            </div>
            <div className="flex gap-1 text-xs laptop:text-sm desktop:text-base leading-none font-light">
              <span className="font-bold tracking-wide">Series: </span>
              {mainActorData.radiologyMetaData.seriesDescription}
            </div>
            <div className="flex gap-1 text-xs laptop:text-sm desktop:text-base leading-none font-light">
              <span className="font-bold tracking-wide">Slice Thickness: </span>
              {mainActorData.radiologyMetaData.sliceThickness}
            </div>
            <div className="flex gap-1 text-xs laptop:text-sm desktop:text-base leading-none font-light">
              <span className="font-bold tracking-wide">Modality: </span>
              {mainActorData.radiologyMetaData.modality}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {mainActorData.chartDiagnosticsData.length ||
      mainActorData.anatomyData.length ? (
        <div
          className={clsx('h-[calc(82%-16px)]', {
            'grid grid-cols-2 grid-rows-1 gap-2 laptop:gap-3 desktop:gap-4':
              mainActorData.chartDiagnosticsData.length +
                (mainActorData.anatomyData.length > 0 ? 1 : 0) ===
              2,
            'grid grid-cols-3 grid-rows-1 gap-2 laptop:gap-3 desktop:gap-4':
              mainActorData.chartDiagnosticsData.length +
                (mainActorData.anatomyData.length > 0 ? 1 : 0) ===
              3,
            'grid grid-cols-2 grid-rows-2 gap-2 laptop:gap-3 desktop:gap-4':
              mainActorData.chartDiagnosticsData.length +
                (mainActorData.anatomyData.length > 0 ? 1 : 0) ===
              4,
            'grid grid-cols-3 grid-rows-2 gap-2 laptop:gap-3 desktop:gap-4':
              mainActorData.chartDiagnosticsData.length +
                (mainActorData.anatomyData.length > 0 ? 1 : 0) >
              4,
            'grid grid-cols-4 grid-rows-2 gap-2 laptop:gap-3 desktop:gap-4':
              mainActorData.chartDiagnosticsData.length +
                (mainActorData.anatomyData.length > 0 ? 1 : 0) >
              6,
            'grid grid-cols-5 grid-rows-2 gap-2 laptop:gap-3 desktop:gap-4':
              mainActorData.chartDiagnosticsData.length +
                (mainActorData.anatomyData.length > 0 ? 1 : 0) >
              8,
          })}
        >
          {mainActorData.anatomyData.length > 0 && (
            <DiagnosticsItemAnatomy anatomyData={mainActorData.anatomyData} />
          )}
          {mainActorData.chartDiagnosticsData &&
            mainActorData.chartDiagnosticsData.map((diagnostic, index) => (
              <DiagnosticsItem
                key={index}
                diagnostic={diagnostic}
                isTaxonomyActive={isTaxonomyActive}
              />
            ))}
        </div>
      ) : (
        <div className="h-[calc(82%-16px)] flex items-center justify-center inset-0 backdrop-blur-lg bg-pale-blue/30 p-1 laptop:p-2 desktop:p-3 rounded-lg shadow-[4px_4px_10px_0px_#00000040]">
          <span className="text-2xl">No diagnostics</span>
        </div>
      )}
    </div>
  )
}

export default Diagnostics
