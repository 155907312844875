import React, { useState, useRef, useContext } from 'react'

import { ActionsTreeContext } from '../../../../../contexts/ActionsTreeContext'

import Dropdown from '../../../../ui/Dropdown'
import useOutsideClick from '../../../../../hooks/useOutsideClick'
import { PreferencesContext } from '../../../../../contexts/PreferencesContext'

/**
 * Dropdown menu for changing the data array of a functional image.
 *
 * @component
 * @param {Object | null} props.context - main storage for all vtk.js components. If the image was not loaded in the application, than equal to null.
 * @returns {JSX.Element} The rendered data array dropdown menu component.
 */
const DataArrayDropdown = ({ context }) => {
  const [isDataArrayOpen, setIsDataArrayOpen] = useState(false)
  const dataArrayRef = useRef()
  const dataArrayListFull = [
    { text: 'ventilation [L/min]' },
    { text: 'average pressure [Pa]' },
    { text: 'average velocity [m/s]' },
    { text: 'density [Kg/m3]' },
    { text: 'node_value' },
    { text: 'nodetag' },
    { text: 'pressure [Pa]' },
    { text: 'simerr_type' },
    { text: 'velocity [m/s]' },
    { text: 'ventilation-perfusion ratio' },
    { text: 'vorticity [Hz]' },
    { text: 'vtkValidPointMask' },
  ]

  const dataArrayListShort = [
    { text: 'ventilation [L/min]' },
    { text: 'velocity [m/s]' },
    { text: 'density [Kg/m3]' },
  ]

  const { mainActorData, setMainActorData } = useContext(ActionsTreeContext)
  const { isDataArrayFullList } = useContext(PreferencesContext)

  const handleSwitchDataArray = (e) => {
    e.preventDefault()
    if (context.current) {
      const {
        functionalWidgetManager,
        functionalRenderer,
        functionalActor,
        functionalSource,
        functionalCtfun,
        functionalOfun,
        functionalPlaneWidget,
        functionalSliceActor,
        functionalRenderWindow,
        functionalScalarBarActor,
      } = context.current
      setMainActorData((prevState) => ({
        ...prevState,
        functionalActor: {
          ...prevState.functionalActor,
          dataArray: e.target.innerText,
          isLogScaleEnabled: false,
        },
      }))
      setIsDataArrayOpen(false)

      functionalRenderer.removeActor(functionalSliceActor)
      functionalWidgetManager.removeWidget(functionalPlaneWidget)

      functionalSource.getPointData().setActiveScalars(e.target.innerText)

      const functionalRange = functionalSource
        .getPointData()
        .getScalars()
        .getRange()

      functionalOfun.removeAllPoints()

      switch (e.target.innerText) {
        case 'average pressure [Pa]':
          functionalOfun.addPoint(functionalRange[0], 1)
          functionalOfun.addPoint(functionalRange[1], 0)
          break
        case 'nodetag':
          functionalOfun.addPoint(functionalRange[0], 0)
          functionalOfun.addPoint(0.00312504, 0)
          functionalOfun.addPoint(functionalRange[1], 1)
          break
        case 'pressure [Pa]':
          functionalOfun.addPoint(functionalRange[0], 1)
          functionalOfun.addPoint(-0.032967, 0)
          functionalOfun.addPoint(functionalRange[1], 0)
          break
        default:
          functionalOfun.addPoint(functionalRange[0], 0)
          functionalOfun.addPoint(functionalRange[1], 1)
          break
      }

      functionalCtfun.setRange(functionalRange[0], functionalRange[1])
      functionalScalarBarActor.setAxisLabel(e.target.innerText)
      functionalScalarBarActor.setScalarsToColors(functionalCtfun)

      functionalActor.getProperty().setScalarOpacity(0, functionalOfun)
      functionalActor.getProperty().setIndependentComponents(false)

      functionalSliceActor.getProperty().setScalarOpacity(0, functionalOfun)

      if (mainActorData.functionalActor.isPlaneVisible) {
        functionalRenderer.addActor(functionalSliceActor)
        const w = functionalWidgetManager.addWidget(functionalPlaneWidget)

        const repStyle = {
          active: {
            plane: {
              opacity: 0.0,
              color: [1, 1, 1],
            },
            normal: {
              opacity: 1,
              color: [1, 1, 1],
            },
            origin: {
              opacity: 1,
              color: [1, 1, 1],
            },
          },
          inactive: {
            plane: {
              opacity: 0.0,
              color: [1, 1, 1],
            },
            normal: {
              opacity: 0.5,
              color: [1, 1, 1],
            },
            origin: {
              opacity: 0.5,
              color: [1, 1, 1],
            },
          },
        }

        w.setRepresentationStyle(repStyle)
      }

      functionalRenderWindow.render()
    }
  }

  useOutsideClick({
    dependency: isDataArrayOpen,
    ref: dataArrayRef,
    setClosed: setIsDataArrayOpen,
  })

  return (
    <div className="w-full">
      <Dropdown
        menuRef={dataArrayRef}
        caption="Data Array"
        isMenuOpen={isDataArrayOpen}
        setIsMenuOpen={setIsDataArrayOpen}
        optionsList={
          isDataArrayFullList ? dataArrayListFull : dataArrayListShort
        }
        currentOption={mainActorData.functionalActor.dataArray}
        setNewOption={handleSwitchDataArray}
      />
    </div>
  )
}

export default DataArrayDropdown
