import React, { useState, createContext, useRef } from 'react'

export const PdfContext = createContext(null)

const PdfContextProvider = ({ children }) => {
  const [isPdfProcessing, setIsPdfProcessing] = useState(false)
  const pdfRef = useRef(null)

  return (
    <PdfContext.Provider
      value={{
        isPdfProcessing,
        setIsPdfProcessing,
        pdfRef,
      }}
    >
      {children}
    </PdfContext.Provider>
  )
}

export default PdfContextProvider
