import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'

import DraggableModalSm from '../../modals/DraggableModalSm'
import Checkbox from '../../ui/Checkbox'
import { PreferencesContext } from '../../../contexts/PreferencesContext'

/**
 * Menubar item (Application) component
 *
 * @component
 * @param {boolean} props.isActive - determines whether a menu item is active
 * @param {function} props.onClick - function to close or open current menu item
 * @param {function} props.onMouseOver - function to change active menu item with onMouseOver event
 * @returns {JSX.Element} The rendered menubar item component.
 */
const Application = ({ isActive, onClick, onMouseOver }) => {
  const [isPreferencesOpen, setIsPreferencesOpen] = useState(false)

  const handlePreferencesClick = () => {
    setIsPreferencesOpen(true)
  }

  const { isDataArrayFullList, setIsDataArrayFullList } =
    useContext(PreferencesContext)

  const handleIsDataArrayFullList = () => {
    const newChoise = !isDataArrayFullList
    setIsDataArrayFullList(newChoise)
    localStorage.setItem('isDataArrayFullList', JSON.stringify(newChoise))
  }

  useEffect(() => {
    if (!localStorage.getItem('isDataArrayFullList')) {
      localStorage.setItem('isDataArrayFullList', JSON.stringify(false))
    }
  }, [])

  return (
    <>
      <li className="relative" onMouseOver={onMouseOver} onClick={onClick}>
        <button
          className={clsx('h-full px-4 rounded border border-transparent', {
            'bg-blue border-blue': isActive,
          })}
        >
          Application
        </button>
        {isActive && (
          <ul className="absolute top-full py-1 px-1 bg-mid-blue/80 backdrop-blur w-[160px] max-h-[260px] overflow-y-scroll border border-semi-transparent rounded z-10">
            <li className="relative py-1 px-3 rounded hover:bg-blue-hover">
              <button
                className="w-full text-left"
                onClick={handlePreferencesClick}
              >
                Preferences
              </button>
            </li>
          </ul>
        )}
      </li>
      {isPreferencesOpen && (
        <DraggableModalSm handleClose={() => setIsPreferencesOpen(false)}>
          <h2 className="h-[36px] py-2 px-4 leading-tight text-md bg-blue rounded-t-2xl cursor-default shadow-[4px_4px_10px_0px_#00000040]">
            Preferences
          </h2>
          <div className="flex flex-col gap-4 p-4 w-full h-[calc(100%-36px)]">
            <Checkbox
              id="dataArrayFullList"
              isChecked={isDataArrayFullList}
              setIsChecked={handleIsDataArrayFullList}
            >
              DataArray full list
            </Checkbox>
          </div>
        </DraggableModalSm>
      )}
    </>
  )
}

export default Application
