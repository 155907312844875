import React, { useState, useRef, createContext } from 'react'

export const MeasuresContext = createContext(null)

const MeasuresContextProvider = ({ children }) => {
  const [selectedWidgetIndex, setSelectedWidgetIndex] = useState({
    x: null,
    y: null,
    z: null,
  })

  const measuresData = useRef({
    x: [],
    y: [],
    z: [],
  })

  return (
    <MeasuresContext.Provider
      value={{
        measuresData,
        selectedWidgetIndex,
        setSelectedWidgetIndex,
      }}
    >
      {children}
    </MeasuresContext.Provider>
  )
}

export default MeasuresContextProvider
