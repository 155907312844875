import React, { useContext } from 'react'

import clsx from 'clsx'

import { TaxonomyContext } from '../contexts/TaxonomyContext'
import { GViewContext } from '../contexts/GViewContext'

import WidgetMessenger from './WidgetMessenger'
import ActionsTree from './ActionsTree/ActionsTree'
import ToolbarFilters from './ToolbarFilters/ToolbarFilters'
import GView from './GView/GView'
import Diagnostics from './Diagnostics/Diagnostics'

const Bottomside = () => {
  const { isTaxonomyActive } = useContext(TaxonomyContext)
  const { activeScreen } = useContext(GViewContext)

  return (
    <div className="h-[calc(100%-105px)] desktop:h-[calc(100%-115px)] flex justify-between">
      <div
        className={clsx('w-[20%] h-full flex flex-col justify-between', {
          hidden: !isTaxonomyActive,
        })}
      >
        <ActionsTree />
        <WidgetMessenger />
      </div>
      <section
        className={clsx(
          'h-full flex bg-mid-blue rounded-[10px] shadow-[4px_4px_10px_0px_#00000040]',
          {
            'w-full': !isTaxonomyActive,
            'w-[calc(80%-15px)]': isTaxonomyActive,
          }
        )}
      >
        <ToolbarFilters isTaxonomyActive={isTaxonomyActive} />

        {activeScreen === 'Dx' && (
          <Diagnostics isTaxonomyActive={isTaxonomyActive} />
        )}
        <GView
          isTaxonomyActive={isTaxonomyActive}
          isVisible={activeScreen === 'GView'}
        />
      </section>
    </div>
  )
}

export default Bottomside
