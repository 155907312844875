import React from 'react'

/**
 * Toast component
 *
 * @component
 * @param {string} props.message - message for toast
 * @param {Function} props.handleClose - function to close the toast
 * @returns {JSX.Element} The toast component.
 */
const Toast = ({ message, handleClose }) => {
  return (
    <li className="relative w-[290px] max-h-[82px] backdrop-blur-lg bg-pale-blue/60 border-2 border-blue rounded-lg animate-slide overflow-hidden">
      <button
        className="w-[290px] max-h-[82px] flex gap-2 p-2 text-left overflow-x-hidden overflow-y-scroll"
        onClick={handleClose}
      >
        <img
          src="/assets/images/error.svg"
          className="fixed top-1/2 translate-y-[-50%] w-[24px] h-[24px]"
          alt=""
        />
        <p className="pl-[32px] w-[calc(290px-16px)] font-light leading-snug">
          {message}
        </p>
        {/* <button className="absolute top-1 right-1 p-0.5" onClick={handleClose}>
        <img src="/assets/images/close-modal.svg" alt="" />
      </button> */}
      </button>
    </li>
  )
}

export default Toast
