import React from 'react'

import ActionsTreeItemButton from '../../../ui/ActionsTreeItemButton'

/**
 * Button to call a modal window with information about the 3D image
 *
 * @component
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered button with modal window component.
 */
const InfoButton = ({ mainActorData, setMainActorData }) => {
  const handleActive = () => {
    setMainActorData((prevState) => ({
      ...prevState,
      activeModal: mainActorData.activeModal === 'info' ? null : 'info',
      functionalActor: {
        ...prevState.functionalActor,
        isModalActive: false,
      },
    }))
  }
  return (
    <ActionsTreeItemButton
      handleActive={handleActive}
      isActive={mainActorData.activeModal === 'info'}
      activeImageLink="/assets/images/info_active.svg"
      inactiveImageLink="/assets/images/info.svg"
    />
  )
}

export default InfoButton
