import React from 'react'

import FunctionalVisualParametersButton from './ActionsTreeFunctionalItemButtons/FunctionalVisualParametersButton'

/**
 * Functional item for ActionsTree
 *
 * @component
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered ActionsTreeItem component.
 */
const ActionsTreeFunctionalItem = ({ mainActorData, setMainActorData }) => {
  return (
    <li className="flex gap-1 desktop:gap-2 w-full align-center justify-between">
      <p className="flex w-[calc(100%-20px)] desktop:w-[calc(100%-28px)] text-sm desktop:text-base truncate">
        <img src="/assets/images/angle.svg" alt=""></img>
        Ventilation
      </p>
      <div className="flex justify-between gap-2">
        <FunctionalVisualParametersButton
          mainActorData={mainActorData}
          setMainActorData={setMainActorData}
        />
      </div>
    </li>
  )
}

export default ActionsTreeFunctionalItem
