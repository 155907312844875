import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import clsx from 'clsx'

/**
 * Donut chart component
 *
 * @component
 * @param {Array} props.chartData - data for chart
 * @param {Array} props.renderDependencies - defines dependencies that will re-render the graph
 * @param {boolean} props.isPDF - determines whether the chart is in the interface or in the PDF report
 * @returns {JSX.Element} The rendered donut chart component.
 */
const DonutChart = ({ chartData, renderDependencies, isPDF }) => {
  const chartRef = useRef(null)

  const initializePie = () => {
    const radius =
      Math.min(chartRef.current.offsetWidth, chartRef.current.offsetHeight) / 2

    const arc = d3
      .arc()
      .innerRadius(radius * 0.52)
      .outerRadius(radius * 0.97)
      .cornerRadius(5)

    const pie = d3
      .pie()
      .padAngle(0.03)
      .sort(null)
      .value((d) => d.value)

    const color = d3
      .scaleOrdinal()
      .domain(chartData.map((d) => d.name))
      .range(d3.schemeDark2)

    const totalValue = d3.sum(chartData, (d) => d.value)
    const dataWithPercentages = chartData.map((d) => ({
      ...d,
      percentage: (d.value / totalValue) * 100,
    }))

    return { radius, arc, pie, color, dataWithPercentages }
  }

  const initializeSVG = (radius, arc, pie, color, dataWithPercentages) => {
    const svg = d3
      .select(chartRef.current)
      .append('svg')
      .attr('width', chartRef.current.offsetWidth)
      .attr('height', chartRef.current.offsetHeight)
      .attr(
        'style',
        'max-width: 100%; height: auto; color: #000; margin: 0 auto'
      )

    const g = svg
      .append('g')
      .attr(
        'transform',
        `translate(${chartRef.current.offsetWidth / 2}, ${
          chartRef.current.offsetHeight / 2
        })`
      )

    g.selectAll()
      .data(pie(dataWithPercentages))
      .join('path')
      .attr('fill', (d) => color(d.data.name))
      .attr('d', arc)
      .style('opacity', 0.8)
      .append('title')
      .text((d) => `${d.data.name}: ${Math.round(d.data.percentage)}%`)

    g.selectAll('text')
      .data(pie(dataWithPercentages))
      .join('text')
      .attr('transform', (d) => `translate(${arc.centroid(d)})`)
      .style('text-anchor', 'middle')
      .attr('fill', isPDF ? 'black' : 'white') // Set text color to white for visibility
      .attr('font-size', radius * 0.12) // Set font size
      .each(function (d) {
        const text = d3.select(this)
        text.append('tspan').attr('font-weight', 'bold').text(d.data.name)

        text
          .append('tspan')
          .attr('font-weight', 'lighter')
          .text(` ${Math.round(d.data.percentage)}%`)
      })

    return svg
  }

  const renderChart = () => {
    if (chartRef.current) {
      if (chartRef.current.firstChild) {
        chartRef.current.removeChild(chartRef.current.firstChild)
      }
      const { radius, arc, pie, color, dataWithPercentages } = initializePie()
      initializeSVG(radius, arc, pie, color, dataWithPercentages)
    }
  }

  window.addEventListener('resize', renderChart)

  useEffect(renderChart, [...renderDependencies])

  return (
    <div
      className={clsx('', {
        'w-full h-[calc(100%-51px)] desktop:h-[calc(100%-59px)]': !isPDF,
        'h-full w-[70%]': isPDF,
      })}
      // {
      //   'grid grid-cols-[calc(65%-4px)_calc(35%-4px)] desktop:grid-cols-[calc(65%-8px)_calc(35%-8px)] items-center gap-2 desktop:gap-4 ':
      //     numberOfCharts < 8,
      //   'flex flex-col': numberOfCharts >= 8,
      // }
      // )}
    >
      <div ref={chartRef} className="w-full h-full" />
      {/* <div
        className={clsx('flex  w-full', {
          'flex-col': numberOfCharts < 8,
          'flex-wrap justify-evenly': numberOfCharts >= 8,
        })}
      >
        {chartData.map((d, index) => (
          <div
            key={index}
            className={clsx('flex items-center gap-1', {
              'w-full': numberOfCharts < 8,
            })}
          >
            <div
              className="w-2 h-2 desktop:w-3 desktop:h-3 rounded-full"
              style={{
                backgroundColor: d3
                  .scaleOrdinal()
                  .domain(chartData.map((d) => d.name))
                  .range(d3.schemeDark2)(d.name),
              }}
            ></div>
            <div className="text-[10px] desktop:text-base">{d.name}</div>
            <div className="font-light text-[10px] desktop:text-base">
              {Math.round((d.value / d3.sum(chartData, (d) => d.value)) * 100)}%
            </div>
          </div>
        ))}
      </div> */}
    </div>
  )
}

export default DonutChart
