import React from 'react'

import ToolbarDiagnosticsButton from '../ui/ToolbarDiagnosticsButton'

const ROIManager = () => {
  return (
    <ToolbarDiagnosticsButton
      caption="ROI Manager"
      isActive={false}
      isDisabled={true}
      activeImageLink="/assets/images/roi.svg"
      inactiveImageLink="/assets/images/roi.svg"
      disabledImageLink="/assets/images/roi_disabled.svg"
      onClick={() => console.log('roi')}
      tooltipText="TBA"
    />
  )
}

export default ROIManager
