import React, { useContext } from 'react'
import axios from 'axios'
import clsx from 'clsx'

import { FileContext } from '../../../../contexts/FileContext'
import { PatientContext } from '../../../../contexts/PatientContext'

/**
 * Button for deleting a measure from the database (and also locally)
 *
 * @component
 * @param {Object} props.context - main obect with vtk.js elements
 * @param {Object} props.measure - object with data corresponding to the measure
 * @param {number} props.index - index of the measure
 * @param {string} props.plane - string with plane name
 * @param {Object} props.measuresData - object with all measures
 * @param {function} props.setSelectedWidgetIndex - function that a function that resets the selected measure
 * @returns {JSX.Element} The rendered button component.
 */
const DeleteMeasureButton = ({
  context,
  measure,
  index,
  plane,
  measuresData,
  setSelectedWidgetIndex,
}) => {
  const { patientData } = useContext(PatientContext)
  const { setToasts } = useContext(FileContext)

  const handleDelete = async () => {
    measuresData.current[plane][index].node.remove()
    switch (plane) {
      case 'x':
        console.log(context.current.measuresWidgetManagerX)
        context.current.measuresWidgetManagerX.removeWidget(
          measuresData.current[plane][index].widget
        )
        break
      case 'y':
        context.current.measuresWidgetManagerY.removeWidget(
          measuresData.current[plane][index].widget
        )
        break
      case 'z':
        context.current.measuresWidgetManagerZ.removeWidget(
          measuresData.current[plane][index].widget
        )
        break
      default:
        break
    }
    const updatedMeasuresData = [...measuresData.current[plane]]
    updatedMeasuresData.splice(index, 1)
    measuresData.current = {
      ...measuresData.current,
      [plane]: updatedMeasuresData,
    }

    if (measure.id) {
      try {
        await axios.post(
          process.env.REACT_APP_SERVER_URL + '/delete_series_measure',
          {
            ID: patientData.id,
            EID: patientData.eid,
            AID: patientData.aid,
            SID: patientData.sid,
            MID: measure.id,
          },
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
            responseType: 'json',
          }
        )
      } catch (error) {
        console.error('Error:', error)
        setToasts((prev) => [
          ...prev,
          +process.env.REACT_APP_PROD_MODE_ENABLED
            ? 'An error occured while deleting a remote measure.'
            : error.response
              ? `Delete remote measure. Status: ${error.response.status} – ${error.response.statusText}.`
              : `Delete remote measure. ${error.message}.`,
        ])
      }
    }

    setSelectedWidgetIndex({ x: null, y: null, z: null })
  }

  return (
    <button
      className={clsx(
        'flex items-center justify-center min-w-[16px] desktop:min-w-[20px]',
        {
          'pointer-events-none': measure.isLocked,
        }
      )}
      onClick={handleDelete}
    >
      <img
        className="h-[16px] desktop:h-[20px]"
        src={
          measure.isLocked
            ? '/assets/images/trash_disabled.svg'
            : '/assets/images/trash.svg'
        }
        alt=""
      />
    </button>
  )
}

export default DeleteMeasureButton
