import React, { useContext } from 'react'
import clsx from 'clsx'

import { PatientContext } from '../../../contexts/PatientContext'

import PlanesVisibilityButton from './ActionsTreeMainItemButtons/PlanesVisibility/PlanesVisibilityButton'
import ReportButton from './ActionsTreeMainItemButtons/Report/ReportButton'
import InfoButton from './ActionsTreeMainItemButtons/Info/InfoButton'
import VisualParametersButton from './ActionsTreeMainItemButtons/VisualParameters/VisualParametersButton'
import MainActorVisibilityButton from './ActionsTreeMainItemButtons/MainActorVisibility/MainActorVisibilityButton'
import ScaleButton from './ActionsTreeMainItemButtons/Scale/ScaleButton'

/**
 * Main item for ActionsTree (3D image)
 *
 * @component
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered ActionsTreeItem component.
 */
const ActionsTreeMainItem = ({ mainActorData, setMainActorData }) => {
  const { patientData } = useContext(PatientContext)

  const handleOpenSubMenu = () => {
    setMainActorData((prevState) => ({
      ...prevState,
      isSubMenuOpen: !mainActorData.isSubMenuOpen,
    }))
  }

  return (
    <li className="flex gap-1 desktop:gap-2 w-full align-center justify-between">
      <button
        className="flex items-center gap-1 grow w-[calc(100%-100px)] desktop:w-[calc(100%-144px)] text-sm desktop:text-base"
        onClick={handleOpenSubMenu}
      >
        <img
          className={clsx('w-[8px] desktop:w-auto', {
            'rotate-90': mainActorData.isSubMenuOpen,
          })}
          src="/assets/images/arrow-white.svg"
          alt=""
        />
        <p className="w-full text-left truncate">{mainActorData.name}</p>
      </button>
      <div className="flex justify-end gap-1 desktop:gap-2">
        {patientData && patientData.aid === 'CTscan' && (
          <MainActorVisibilityButton
            mainActorData={mainActorData}
            setMainActorData={setMainActorData}
          />
        )}
        {patientData && patientData.aid === 'CTscan' && (
          <PlanesVisibilityButton
            mainActorData={mainActorData}
            setMainActorData={setMainActorData}
          />
        )}
        {patientData && patientData.aid === 'XRay' && <ScaleButton />}
        <ReportButton
          mainActorData={mainActorData}
          setMainActorData={setMainActorData}
        />
        {patientData && patientData.aid === 'CTscan' && (
          <InfoButton
            mainActorData={mainActorData}
            setMainActorData={setMainActorData}
          />
        )}
        {patientData && patientData.aid === 'CTscan' && (
          <VisualParametersButton
            mainActorData={mainActorData}
            setMainActorData={setMainActorData}
          />
        )}
      </div>
    </li>
  )
}

export default ActionsTreeMainItem
