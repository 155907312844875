import { useContext, useEffect } from 'react'

import { GViewContext } from '../contexts/GViewContext'

const useSetMeasuresVisibility = (
  params = {
    plane: null,
    planeNum: null,
    measuresData: null,
    slice: null,
    currentWidgetPosition: null,
  }
) => {
  const {
    plane,
    planeNum,
    measuresData,
    slice,
    currentWidgetPosition,
    renderWindow,
  } = params

  const { context } = useContext(GViewContext)

  useEffect(() => {
    if (context.current) {
      let measures = measuresData.current[plane]
      if (measures.length) {
        for (let i = 0; i < measures.length; i++) {
          if (
            Math.floor(measures[i].position[planeNum]) ===
            Math.floor(currentWidgetPosition[planeNum])
          ) {
            measures[i].widget.setVisibility(true)
            measures[i].node.style.display = 'block'
          } else {
            if (
              measures[i].widget.getVisibility !== false &&
              measures[i].node.style.display !== 'none'
            ) {
              measures[i].widget.setVisibility(false)
              measures[i].node.style.display = 'none'
            }
          }
        }
        if (renderWindow) {
          renderWindow.render()
        }
      }
    }
  }, [slice])
}

export default useSetMeasuresVisibility
