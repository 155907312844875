import { useEffect } from 'react'

const useUpdateWidgetColor = (
  params = {
    plane: null,
    measuresData: null,
    interactor: null,
    selectedWidgetIndex: null,
  }
) => {
  const { plane, measuresData, interactor, selectedWidgetIndex } = params

  useEffect(() => {
    if (measuresData.current[plane].length && interactor) {
      for (let i = 0; i < measuresData.current[plane].length; i++) {
        if (
          measuresData.current[plane][i].type === 'ruler' ||
          measuresData.current[plane][i].type === 'annotation'
        ) {
          if (i !== selectedWidgetIndex[plane]) {
            measuresData.current[plane][i].widget
              .getWidgetState()
              .getHandle1()
              .setColor(0.2)
            measuresData.current[plane][i].widget
              .getWidgetState()
              .getHandle2()
              .setColor(0.2)
            measuresData.current[plane][i].widget.setUseActiveColor(false)
          } else {
            measuresData.current[plane][i].widget
              .getWidgetState()
              .getHandle1()
              .setColor(0.5)
            measuresData.current[plane][i].widget
              .getWidgetState()
              .getHandle2()
              .setColor(0.5)
            measuresData.current[plane][i].widget.setUseActiveColor(false)
          }
        } else if (
          measuresData.current[plane][i].type === 'ellipse' ||
          measuresData.current[plane][i].type === 'circle'
        ) {
          console.log(
            measuresData.current[plane][i].widget
              .getWidgetState()
              .getEllipseHandle()
          )
          if (i !== selectedWidgetIndex[plane]) {
            measuresData.current[plane][i].widget
              .getWidgetState()
              .getPoint1Handle()
              .setColor(0.2)
            measuresData.current[plane][i].widget
              .getWidgetState()
              .getPoint2Handle()
              .setColor(0.2)
            measuresData.current[plane][i].widget
              .getWidgetState()
              .getEllipseHandle()
              .setColor(0.2)
            measuresData.current[plane][i].widget.setUseActiveColor(false)
          } else {
            measuresData.current[plane][i].widget
              .getWidgetState()
              .getPoint1Handle()
              .setColor(0.5)
            measuresData.current[plane][i].widget
              .getWidgetState()
              .getPoint2Handle()
              .setColor(0.5)
            measuresData.current[plane][i].widget
              .getWidgetState()
              .getEllipseHandle()
              .setColor(0.5)
            measuresData.current[plane][i].widget.setUseActiveColor(false)
          }
        }
        interactor.render()
      }
    }
  }, [selectedWidgetIndex[plane]])
}

export default useUpdateWidgetColor
