import React, { useContext, useState } from 'react'

import { GViewContext } from '../../../../contexts/GViewContext'

import DraggableModalSm from '../../../modals/DraggableModalSm'
import Checkbox from '../../../ui/Checkbox'

import ColorMapDropdown from './ColorMap/ColorMapDropdown'
import DataArrayDropdown from './DataArray/DataArrayDropdown'
import FunctionalActorVisibilityButton from './FunctionalActorVisibility/FunctionalActorVisibilityButton'
import SliceVisibilityButton from './SliceVisibility/SliceVisibilityButton'
import StreamlinesVisibilityButton from './StreamlinesVisibility/StreamlinesVisibilityButton'
import StreamLinesRepresentationRadios from './StreamlinesRepresentation/StreamLinesRepresentationRadios'
import SphereControlPanel from './StreamlinesControlPanels/SphereControlPanel'
import LineControlPanel from './StreamlinesControlPanels/LineControlPanel'
import LogScaleButton from './LogScale/LogScaleButton'

/**
 * Modal window with visual parameters for functional image
 *
 * @component
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered modal window component.
 */
const FunctionalVisualParametersModal = ({
  mainActorData,
  setMainActorData,
}) => {
  const { context } = useContext(GViewContext)

  const handleClose = () => {
    setMainActorData((prevState) => ({
      ...prevState,
      functionalActor: {
        ...prevState.functionalActor,
        isModalActive: false,
      },
    }))
  }

  const handleLineVisibility = () => {
    if (context.current) {
      const {
        slineActor,
        slineSeedActor,
        sphereActor,
        functionalRenderWindow,
      } = context.current

      setMainActorData((prevState) => ({
        ...prevState,
        functionalActor: {
          ...prevState.functionalActor,
          isStreamlinesLineVisible:
            !mainActorData.functionalActor.isStreamlinesLineVisible,
        },
      }))

      // if (
      //   mainActorData.functionalActor.streamlinesRepresentation === 'Sphere'
      // ) {
      //   sphereActor.setVisibility(!isSeedsVisible)
      // } else {
      //   slineSeedActor.setVisibility(!isSeedsVisible)
      // }

      slineSeedActor.setVisibility(
        !mainActorData.functionalActor.isStreamlinesLineVisible
      )
      functionalRenderWindow.render()
    }
  }
  const handleSphereVisibility = () => {
    if (context.current) {
      const {
        slineActor,
        slineSeedActor,
        sphereActor,
        functionalRenderWindow,
      } = context.current

      setMainActorData((prevState) => ({
        ...prevState,
        functionalActor: {
          ...prevState.functionalActor,
          isStreamlinesSphereVisible:
            !mainActorData.functionalActor.isStreamlinesSphereVisible,
        },
      }))

      // if (
      //   mainActorData.functionalActor.streamlinesRepresentation === 'Sphere'
      // ) {
      //   sphereActor.setVisibility(!isSeedsVisible)
      // } else {
      //   slineSeedActor.setVisibility(!isSeedsVisible)
      // }

      sphereActor.setVisibility(
        !mainActorData.functionalActor.isStreamlinesSphereVisible
      )
      functionalRenderWindow.render()
    }
  }

  return (
    <DraggableModalSm handleClose={handleClose}>
      <h2 className="h-[36px] py-2 px-4 leading-tight text-md bg-blue rounded-t-2xl cursor-default shadow-[4px_4px_10px_0px_#00000040]">
        Visual parameters: {mainActorData.name}
      </h2>
      <div className="flex flex-col gap-4 p-4 w-full h-[calc(100%-36px)]">
        <div className="w-full grid grid-cols-2 gap-4">
          <DataArrayDropdown context={context} />
          <ColorMapDropdown context={context} />
        </div>
        <LogScaleButton
          context={context}
          mainActorData={mainActorData}
          setMainActorData={setMainActorData}
        />
        <FunctionalActorVisibilityButton
          context={context}
          mainActorData={mainActorData}
          setMainActorData={setMainActorData}
        />
        <SliceVisibilityButton
          context={context}
          mainActorData={mainActorData}
          setMainActorData={setMainActorData}
        />

        {mainActorData.functionalActor.isStreamlinesVisible !== null && (
          <>
            <div className="flex gap-3">
              <StreamlinesVisibilityButton
                context={context}
                mainActorData={mainActorData}
                setMainActorData={setMainActorData}
              />
              <StreamLinesRepresentationRadios context={context} />
              {mainActorData.functionalActor.isStreamlinesVisible &&
                mainActorData.functionalActor.streamlinesRepresentation ===
                  'Line' && (
                  <Checkbox
                    id="lineVisible"
                    isChecked={
                      mainActorData.functionalActor.isStreamlinesLineVisible
                    }
                    setIsChecked={handleLineVisibility}
                  >
                    Visible
                  </Checkbox>
                )}
              {mainActorData.functionalActor.isStreamlinesVisible &&
                mainActorData.functionalActor.streamlinesRepresentation ===
                  'Sphere' && (
                  <Checkbox
                    id="sphereVisible"
                    isChecked={
                      mainActorData.functionalActor.isStreamlinesSphereVisible
                    }
                    setIsChecked={handleSphereVisibility}
                  >
                    Visible
                  </Checkbox>
                )}
            </div>
            {mainActorData.functionalActor.isStreamlinesVisible &&
              mainActorData.functionalActor.streamlinesRepresentation ===
                'Sphere' && (
                <SphereControlPanel
                  context={context}
                  mainActorData={mainActorData}
                  setMainActorData={setMainActorData}
                />
              )}
            {mainActorData.functionalActor.isStreamlinesVisible &&
              mainActorData.functionalActor.streamlinesRepresentation ===
                'Line' && (
                <LineControlPanel
                  context={context}
                  mainActorData={mainActorData}
                  setMainActorData={setMainActorData}
                />
              )}
          </>
        )}
      </div>
    </DraggableModalSm>
  )
}

export default FunctionalVisualParametersModal
