import React, { useRef } from 'react'
import clsx from 'clsx'

import DiagnosticsItemAnatomyPdf from './DiagnosticsItemAnatomyPdf'
import DiagnosticsItemPdf from './DiagnosticItemPdf'

/**
 * Component with the dimensions of an A4 sheet, a PDF report is compiled from it.
 *
 * @component
 * @param {Object} props.mainActorData - object with actors data
 * @param {boolean} props.isFirstPage - if the first page of report, then a table of contents will be added
 * @param {Array} props.data - diagnostics data divided into chunks to fit on an A4 sheet
 * @returns {JSX.Element} The rendered a4 sheet component for PDF report.
 */
const A4Page = ({ mainActorData, isFirstPage, data }) => {
  const a4PageRef = useRef(null)

  return (
    <div className="w-[794px] max-h-[1122px] h-full overflow-hidden">
      <div
        ref={a4PageRef}
        className="flex flex-col w-full h-full px-[35px] pt-[30px] pb-[35px] bg-white"
      >
        {isFirstPage && (
          <div className="flex flex-col text-black bg-white">
            <h1 className="text-3xl text-center mb-[25px]">Report</h1>
            <div
              className={clsx(
                'grid grid-flow-col grid-cols-3 grid-rows-3 gap-2 items-center pb-3 border-b-2'
              )}
            >
              <div className="flex gap-1 font-light text-base">
                <span className="font-bold tracking-wide">Patient: </span>
                {mainActorData.radiologyMetaData.patient}
              </div>
              <div className="flex gap-1 font-light text-base">
                <span className="font-bold tracking-wide">ID: </span>
                {mainActorData.radiologyMetaData.patientID}
              </div>
              <div className="flex gap-1 font-light text-base">
                <span className="font-bold tracking-wide">Age: </span>
                {mainActorData.radiologyMetaData.patientAge}
              </div>
              <div className="flex gap-1 font-light text-base">
                <span className="font-bold tracking-wide">DOB: </span>
                {mainActorData.radiologyMetaData.patientBirthDate}
              </div>
              <div className="flex gap-1 font-light text-base">
                <span className="font-bold tracking-wide">Sex: </span>
                {mainActorData.radiologyMetaData.patientSex}
              </div>
              <div className="flex gap-1 font-light text-base">
                <span className="font-bold tracking-wide">Position: </span>
                {mainActorData.radiologyMetaData.patientPosition}
              </div>
              <div className="flex gap-1 font-light text-base">
                <span className="font-bold tracking-wide">
                  Acquisition date:
                </span>
                {mainActorData.radiologyMetaData.acquisitionDate}
              </div>
              <div className="flex gap-1 font-light text-base">
                <span className="font-bold tracking-wide">
                  Acquisition time:
                </span>
                {mainActorData.radiologyMetaData.acquisitionTime}
              </div>
            </div>
            {mainActorData.chartDiagnosticsData.length ? (
              <div className="grid grid-flow-col gap-2 items-center pt-3 pb-3 border-b-2 grid-cols-3 grid-rows-2 border-t border-t-semi-transparent">
                <div className="flex gap-1 font-light text-base">
                  <span className="font-bold tracking-wide">Mfr: </span>
                  {mainActorData.radiologyMetaData.manufacturer}
                </div>
                <div className="flex gap-1 font-light text-base">
                  <span className="font-bold tracking-wide">Model: </span>
                  {mainActorData.radiologyMetaData.manufacturerModelName}
                </div>
                <div className="flex gap-1 font-light text-base">
                  <span className="font-bold tracking-wide">Series: </span>
                  {mainActorData.radiologyMetaData.seriesDescription}
                </div>
                <div className="flex gap-1 font-light text-base">
                  <span className="font-bold tracking-wide">
                    Slice Thickness:{' '}
                  </span>
                  {mainActorData.radiologyMetaData.sliceThickness}
                </div>
                <div className="flex gap-1 font-light text-base">
                  <span className="font-bold tracking-wide">Modality: </span>
                  {mainActorData.radiologyMetaData.modality}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
        {mainActorData.anatomyData.length || data.length ? (
          <div className="grid grid-cols-2 grid-rows-3 gap-4 bg-white h-[calc(100%-246px)]">
            {isFirstPage && mainActorData.anatomyData.length > 0 && (
              <DiagnosticsItemAnatomyPdf
                anatomyData={mainActorData.anatomyData}
              />
            )}

            {data.length &&
              data.map((diagnostic, index) => (
                <DiagnosticsItemPdf key={index} diagnostic={diagnostic} />
              ))}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default A4Page
