import React, { useContext, useState } from 'react'
import { Representation } from '@kitware/vtk.js/Rendering/Core/Property/Constants'
import vtkImageStreamline from '@kitware/vtk.js/Filters/General/ImageStreamline'
import vtkSeedWidget from '@kitware/vtk.js/Widgets/Widgets3D/SeedWidget'
import vtkAbstractWidget from '@kitware/vtk.js/Widgets/Core/AbstractWidget'
import { ActionsTreeContext } from '../../../../../contexts/ActionsTreeContext'

import Radio from '../../../../ui/Radio'

/**
 * Dropdown menu for changing the streamlines representation (line or sphere) of a functional image.
 *
 * @component
 * @param {Object | null} props.context - main storage for all vtk.js components. If the image was not loaded in the application, than equal to null.
 * @returns {JSX.Element} The rendered data array dropdown menu component.
 */
const StreamLinesRepresentationRadios = ({ context }) => {
  const { mainActorData, setMainActorData } = useContext(ActionsTreeContext)

  const handleSwitchRepresentation = (e) => {
    e.preventDefault()
    if (context.current) {
      const {
        lineSource,
        pointCloud,
        slineSeedMapper,
        slineSeedActor,
        slineMapper,
        functionalReader,
        functionalRenderWindow,
        sphereActor,
      } = context.current

      const sline = vtkImageStreamline.newInstance()
      sline.setIntegrationStep(0.2)
      sline.setInputConnection(functionalReader.getOutputPort())

      if (e.target.value === 'Line') {
        setMainActorData((prevState) => ({
          ...prevState,
          functionalActor: {
            ...prevState.functionalActor,
            streamlinesRepresentation: e.target.value,
            isStreamlinesLineVisible: true,
            isStreamlinesSphereVisible: false,
          },
        }))

        sline.setInputConnection(lineSource.getOutputPort(), 1)

        sphereActor.setVisibility(false)
        slineSeedActor.setVisibility(true)

        slineMapper.setInputConnection(sline.getOutputPort())

        slineSeedMapper.setInputConnection(lineSource.getOutputPort())

        slineSeedActor.getProperty().set({
          representation: Representation.SURFACE,
        })
      } else {
        setMainActorData((prevState) => ({
          ...prevState,
          functionalActor: {
            ...prevState.functionalActor,
            streamlinesRepresentation: e.target.value,
            isStreamlinesLineVisible: false,
            isStreamlinesSphereVisible: true,
          },
        }))

        sline.setInputConnection(pointCloud.getOutputPort(), 1)

        slineMapper.setInputConnection(sline.getOutputPort())

        slineSeedMapper.setInputConnection(pointCloud.getOutputPort())

        slineSeedActor.setVisibility(false)

        sphereActor.setVisibility(true)

        slineSeedActor.getProperty().set({
          representation: Representation.POINTS,
        })
      }

      context.current = {
        ...context.current,
        sline,
      }
      functionalRenderWindow.render()
    }
  }

  return (
    <div className="flex gap-3">
      <Radio
        id="line"
        value="Line"
        isChecked={
          mainActorData.functionalActor.streamlinesRepresentation === 'Line'
        }
        setIsChecked={handleSwitchRepresentation}
        isDisabled={!mainActorData.functionalActor.isStreamlinesVisible}
      >
        Line
      </Radio>
      <Radio
        id="sphere"
        value="Sphere"
        isChecked={
          mainActorData.functionalActor.streamlinesRepresentation === 'Sphere'
        }
        setIsChecked={handleSwitchRepresentation}
        isDisabled={!mainActorData.functionalActor.isStreamlinesVisible}
      >
        Sphere
      </Radio>
    </div>
  )
}

export default StreamLinesRepresentationRadios
