import React, { createContext, useState } from 'react'

export const ViewContext = createContext(null)

const ViewContextProvider = ({ children }) => {
  const [view, setView] = useState('Morpho')

  return (
    <ViewContext.Provider value={{ view, setView }}>
      {children}
    </ViewContext.Provider>
  )
}

export default ViewContextProvider
