import React, { useState, createContext } from 'react'

export const PreferencesContext = createContext(null)

const PreferencesContextProvider = ({ children }) => {
  const [isDataArrayFullList, setIsDataArrayFullList] = useState(
    JSON.parse(localStorage.getItem('isDataArrayFullList')) || false
  )

  return (
    <PreferencesContext.Provider
      value={{ isDataArrayFullList, setIsDataArrayFullList }}
    >
      {children}
    </PreferencesContext.Provider>
  )
}

export default PreferencesContextProvider
