import React from 'react'

import ToolbarFileOps from './ToolbarFileOps/ToolbarFileOps'
import ToolbarGView from './ToolbarGView/ToolbarGView'
import ToolbarDiagnostics from './ToolbarDiagnostics/ToolbarDiagnostics'
import ToolbarGenerics from './ToolbarGenerics'

const Topside = () => {
  return (
    <div className="h-[90px] desktop:h-[100px] flex justify-between">
      <ToolbarFileOps />
      <section className="w-[calc(80%-15px)] h-full flex justify-between">
        <ToolbarGView />
        <ToolbarDiagnostics />
        <ToolbarGenerics />
      </section>
    </div>
  )
}

export default Topside
