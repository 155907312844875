import React, { createContext, useState } from 'react'

export const MessengerContext = createContext(null)

const MessengerContextProvider = ({ children }) => {
  const [messages, setMessages] = useState([])

  return (
    <MessengerContext.Provider value={{ messages, setMessages }}>
      {children}
    </MessengerContext.Provider>
  )
}

export default MessengerContextProvider
