import React, { useContext } from 'react'

import ActionsTreeItemButton from '../../../ui/ActionsTreeItemButton'

import { GViewContext } from '../../../../../contexts/GViewContext'

/**
 * Button to control the visibility and invisibility of 3D image
 *
 * @component
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered button component.
 */
const MainActorVisibilityButton = ({ mainActorData, setMainActorData }) => {
  const { context } = useContext(GViewContext)

  const handleActive = () => {
    setMainActorData((prevState) => ({
      ...prevState,
      isActorVisible: !mainActorData.isActorVisible,
    }))
    mainActorData.actor.setVisibility(!mainActorData.isActorVisible)
    context.current.renderWindow.render()
  }
  return (
    <ActionsTreeItemButton
      handleActive={handleActive}
      isActive={mainActorData.isActorVisible}
      activeImageLink="/assets/images/eye.svg"
      inactiveImageLink="/assets/images/eye-closed.svg"
    />
  )
}

export default MainActorVisibilityButton
