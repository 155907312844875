import { useEffect } from 'react'

const useOutsideClick = (
  params = { dependency: null, ref: null, setClosed: null, setter: false }
) => {
  const { dependency, ref, setClosed, setter } = params

  const handleOutsideClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setClosed(setter)
    }
  }

  useEffect(() => {
    if (dependency) {
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [dependency])
}

export default useOutsideClick
