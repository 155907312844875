import React, { useContext } from 'react'

import DonutChart from '../charts/DonutChart'
import { GViewContext } from '../../contexts/GViewContext'

/**
 * Diagnostic item
 *
 * @component
 * @param {Object} props.diagnostic - diagnostic object
 * @param {boolean} props.isTaxonomyActive - determine whether the taxonomy is open or not
 * @returns {JSX.Element} The rendered diagnostic item component.
 */
const DiagnosticsItem = ({ diagnostic, isTaxonomyActive }) => {
  const { activeScreen } = useContext(GViewContext)

  return (
    <div className="h-full inset-0 backdrop-blur-lg bg-pale-blue/30 p-1 laptop:p-2 desktop:p-3 rounded-lg shadow-[4px_4px_10px_0px_#00000040]">
      <h3 className="font-bold text-center leading-tight text-sm laptop:text-base desktop:text-lg mb-[5px] desktop:mb-[10px]">
        {diagnostic.name}
      </h3>
      <div className="gap-1 h-[calc(100%-11px)] laptop:h-[calc(100%-37px)] desktop:h-[calc(100%-53px)]">
        <DonutChart
          chartData={diagnostic.data}
          renderDependencies={[isTaxonomyActive, activeScreen]}
          isPDF={false}
        />
        <div className="w-full">
          <div className="mt-[5px] laptop:mt-[10px] desktop:mt-[15px] overflow-y-scroll rounded-lg border border-semi-transparent">
            <table className="max-w-full w-full divide-y divide-semi-transparent">
              <thead className="bg-blue-hover">
                <tr>
                  <th
                    scope="row"
                    className="px-0.5 laptop:px-1 py-0.5 laptop:py-1 text-center text-xs desktop:text-sm leading-none laptop:leading-tight font-semibold tracking-wider"
                  >
                    Lobe
                  </th>
                  {diagnostic.data.map((lobe, index) => (
                    <td
                      key={index}
                      className="px-0.5 laptop:px-1 py-0.5 laptop:py-1 text-center text-xs desktop:text-sm leading-none laptop:leading-tight"
                    >
                      {lobe.name}
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-blue divide-y divide-semi-transparent">
                <tr>
                  <td className="px-0.5 laptop:px-1 py-0.5 laptop:py-1 text-center text-xs desktop:text-sm leading-none laptop:leading-tight font-semibold tracking-wider">
                    %
                  </td>
                  {diagnostic.data.map((lobe, index) => (
                    <td
                      key={index}
                      className="px-0.5 laptop:px-1 py-0.5 laptop:py-1 text-center text-xs desktop:text-sm leading-none laptop:leading-tight"
                    >
                      {lobe.value.toFixed(3)}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiagnosticsItem
