import React from 'react'
import clsx from 'clsx'

/**
 * Menubar item (Database) component
 *
 * @component
 * @param {boolean} props.isActive - determines whether a menu item is active
 * @param {function} props.onClick - function to close or open current menu item
 * @param {function} props.onMouseOver - function to change active menu item with onMouseOver event
 * @returns {JSX.Element} The rendered menubar item component.
 */
const Database = ({ isActive, onClick, onMouseOver }) => {
  return (
    <li className="relative" onMouseOver={onMouseOver} onClick={onClick}>
      <button
        className={clsx('h-full px-4 rounded border border-transparent', {
          'bg-blue border-blue': isActive,
        })}
      >
        Database
      </button>
      {isActive && (
        <ul className="absolute top-full py-1 px-1 bg-mid-blue/80 backdrop-blur w-[160px] max-h-[260px] overflow-y-scroll border border-semi-transparent rounded z-10">
          <li className="relative py-1 px-3 rounded hover:bg-blue-hover">
            <button className="w-full text-left">DB1</button>
          </li>
          <li className="relative py-1 px-3 rounded hover:bg-blue-hover">
            <button className="w-full text-left">DB2</button>
          </li>
          <li className="relative py-1 px-3 rounded hover:bg-blue-hover">
            <button className="w-full text-left">DB3</button>
          </li>
        </ul>
      )}
    </li>
  )
}

export default Database
