import React from 'react'

/**
 * Control panel for streamlines in line representation
 *
 * @component
 * @param {Object | null} props.context - main storage for all vtk.js components. If the image was not loaded in the application, than equal to null.
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered control panel component.
 */
const LineControlPanel = ({ context, mainActorData, setMainActorData }) => {
  const handleLineP1XChange = (e) => {
    if (context.current) {
      const { lineSource, functionalRenderWindow } = context.current

      setMainActorData((prevState) => ({
        ...prevState,
        functionalActor: {
          ...prevState.functionalActor,
          streamlinesLinePositionP1: {
            ...prevState.functionalActor.streamlinesLinePositionP1,
            x: +e.target.value,
          },
        },
      }))

      lineSource.setPoint1(
        +e.target.value,
        mainActorData.functionalActor.streamlinesLinePositionP1.y,
        mainActorData.functionalActor.streamlinesLinePositionP1.z
      )

      functionalRenderWindow.render()
    }
  }
  const handleLineP1YChange = (e) => {
    if (context.current) {
      const { lineSource, functionalRenderWindow } = context.current

      setMainActorData((prevState) => ({
        ...prevState,
        functionalActor: {
          ...prevState.functionalActor,
          streamlinesLinePositionP1: {
            ...prevState.functionalActor.streamlinesLinePositionP1,
            y: +e.target.value,
          },
        },
      }))

      lineSource.setPoint1(
        mainActorData.functionalActor.streamlinesLinePositionP1.x,
        +e.target.value,
        mainActorData.functionalActor.streamlinesLinePositionP1.z
      )

      functionalRenderWindow.render()
    }
  }
  const handleLineP1ZChange = (e) => {
    if (context.current) {
      const { lineSource, functionalRenderWindow } = context.current

      setMainActorData((prevState) => ({
        ...prevState,
        functionalActor: {
          ...prevState.functionalActor,
          streamlinesLinePositionP1: {
            ...prevState.functionalActor.streamlinesLinePositionP1,
            z: +e.target.value,
          },
        },
      }))

      lineSource.setPoint1(
        mainActorData.functionalActor.streamlinesLinePositionP1.x,
        mainActorData.functionalActor.streamlinesLinePositionP1.y,
        +e.target.value
      )

      functionalRenderWindow.render()
    }
  }
  const handleLineP2XChange = (e) => {
    if (context.current) {
      const { lineSource, functionalRenderWindow } = context.current

      setMainActorData((prevState) => ({
        ...prevState,
        functionalActor: {
          ...prevState.functionalActor,
          streamlinesLinePositionP2: {
            ...prevState.functionalActor.streamlinesLinePositionP2,
            x: +e.target.value,
          },
        },
      }))

      lineSource.setPoint2(
        +e.target.value,
        mainActorData.functionalActor.streamlinesLinePositionP2.y,
        mainActorData.functionalActor.streamlinesLinePositionP2.z
      )

      functionalRenderWindow.render()
    }
  }
  const handleLineP2YChange = (e) => {
    if (context.current) {
      const { lineSource, functionalRenderWindow } = context.current

      setMainActorData((prevState) => ({
        ...prevState,
        functionalActor: {
          ...prevState.functionalActor,
          streamlinesLinePositionP2: {
            ...prevState.functionalActor.streamlinesLinePositionP2,
            y: +e.target.value,
          },
        },
      }))

      lineSource.setPoint2(
        mainActorData.functionalActor.streamlinesLinePositionP2.x,
        +e.target.value,
        mainActorData.functionalActor.streamlinesLinePositionP2.z
      )

      functionalRenderWindow.render()
    }
  }
  const handleLineP2ZChange = (e) => {
    if (context.current) {
      const { lineSource, functionalRenderWindow } = context.current

      setMainActorData((prevState) => ({
        ...prevState,
        functionalActor: {
          ...prevState.functionalActor,
          streamlinesLinePositionP2: {
            ...prevState.functionalActor.streamlinesLinePositionP2,
            z: +e.target.value,
          },
        },
      }))

      lineSource.setPoint2(
        mainActorData.functionalActor.streamlinesLinePositionP2.x,
        mainActorData.functionalActor.streamlinesLinePositionP2.y,
        +e.target.value
      )

      functionalRenderWindow.render()
    }
  }

  return (
    <>
      <div>Point1:</div>
      <div className="flex gap-2">
        <input
          className="w-full"
          type="range"
          min={mainActorData.functionalActor.bds[0]}
          max={mainActorData.functionalActor.bds[1]}
          step={mainActorData.functionalActor.spacing[0]}
          value={mainActorData.functionalActor.streamlinesLinePositionP1.x}
          onChange={(e) => handleLineP1XChange(e)}
        />
        <input
          className="w-full"
          type="range"
          min={mainActorData.functionalActor.bds[2]}
          max={mainActorData.functionalActor.bds[3]}
          step={mainActorData.functionalActor.spacing[1]}
          value={mainActorData.functionalActor.streamlinesLinePositionP1.y}
          onChange={(e) => handleLineP1YChange(e)}
        />
        <input
          className="w-full"
          type="range"
          min={mainActorData.functionalActor.bds[4]}
          max={mainActorData.functionalActor.bds[5]}
          step={mainActorData.functionalActor.spacing[2]}
          value={mainActorData.functionalActor.streamlinesLinePositionP1.z}
          onChange={(e) => handleLineP1ZChange(e)}
        />
      </div>
      <div>Point2:</div>
      <div className="flex gap-2">
        <input
          className="w-full"
          type="range"
          min={mainActorData.functionalActor.bds[0]}
          max={mainActorData.functionalActor.bds[1]}
          step={mainActorData.functionalActor.spacing[0]}
          value={mainActorData.functionalActor.streamlinesLinePositionP2.x}
          onChange={(e) => handleLineP2XChange(e)}
        />
        <input
          className="w-full"
          type="range"
          min={mainActorData.functionalActor.bds[2]}
          max={mainActorData.functionalActor.bds[3]}
          step={mainActorData.functionalActor.spacing[1]}
          value={mainActorData.functionalActor.streamlinesLinePositionP2.y}
          onChange={(e) => handleLineP2YChange(e)}
        />
        <input
          className="w-full"
          type="range"
          min={mainActorData.functionalActor.bds[4]}
          max={mainActorData.functionalActor.bds[5]}
          step={mainActorData.functionalActor.spacing[2]}
          value={mainActorData.functionalActor.streamlinesLinePositionP2.z}
          onChange={(e) => handleLineP2ZChange(e)}
        />
      </div>
    </>
  )
}

export default LineControlPanel
