import React, { useContext } from 'react'

import clsx from 'clsx'
import vtkMath from '@kitware/vtk.js/Common/Core/Math'
import { xyzToViewType } from '@kitware/vtk.js/Widgets/Widgets3D/ResliceCursorWidget/Constants'

import useHandleRightButtonMenu from '../../hooks/useHandleRightButtonMenu'

import { GViewContext } from '../../contexts/GViewContext'
import { MeasuresContext } from '../../contexts/MeasuresContext'
import RightButtonMenu from '../modals/RightButtonMenu'
import { PdfContext } from '../../contexts/PdfContext'

/**
 * Сomponent for displaying a section along the Z axis in a 2D window
 *
 * @component
 * @param {string} props.view - parameter that determines which of the 4 windows are shown and which are hidden
 * @param {string} props.patientData - used when XRay is loaded
 * @returns {JSX.Element} The rendered component with one 2D window.
 */
const WindowSliceZ = ({ view, patientData }) => {
  const { measuresData, selectedWidgetIndex } = useContext(MeasuresContext)

  const {
    context,
    ZSlice,
    setZSlice,
    sliceContainerZ,
    activeWindow,
    isRightButtonMenuVisible,
    setIsRightButtonMenuVisible,
    setMenuPosition,
    color,
    coordinates,
    spacings,
    sliderMaxZ,
  } = useContext(GViewContext)

  const { isPdfProcessing } = useContext(PdfContext)

  const changeSliderZ = (e) => {
    if (context.current) {
      const { resliceCursorWidget, resliceCursorWidgetInstanceZ, interactorZ } =
        context.current
      setZSlice(Number(e.target.value))

      const normal = resliceCursorWidget.getWidgetState().getPlanes()[
        xyzToViewType[2]
      ].normal
      const planeExtremities = resliceCursorWidget.getPlaneExtremities(
        xyzToViewType[2]
      )
      const newCenter = vtkMath.multiplyAccumulate(
        planeExtremities[0],
        normal,
        Number(e.target.value),
        []
      )

      resliceCursorWidget.setCenter(newCenter)
      // resliceCursorWidgetInstanceZ.invokeInternalInteractionEvent()
      resliceCursorWidgetInstanceZ.invokeInteractionEvent(
        resliceCursorWidgetInstanceZ.getActiveInteraction()
      )
      interactorZ.render()
    }
  }

  useHandleRightButtonMenu({
    plane: 'z',
    measuresData,
    sliceContainer: sliceContainerZ,
    selectedWidgetIndex,
    setMenuPosition,
    setIsRightButtonMenuVisible,
  })

  return (
    <>
      <div
        className={clsx('relative w-full h-full', {
          hidden:
            (view === 'Morpho' &&
              patientData &&
              patientData.aid === 'CTscan') ||
            !patientData ||
            view === 'Functional' ||
            view === 'Morpho-Functional' ||
            view === 'Morpho-Coronal' ||
            view === 'Morpho-Sagittal',
        })}
      >
        {patientData && patientData.aid === 'CTscan' && !isPdfProcessing && (
          <input
            type="range"
            min="0"
            max={Math.floor(sliderMaxZ)}
            step="1"
            value={ZSlice}
            className="w-full px-[5px]"
            onChange={(e) => changeSliderZ(e)}
          />
        )}
        <div
          className={clsx(
            'canvas-rounded relative border-[5px] rounded-[15px]',
            {
              'border-transparent': activeWindow !== 'z',
              'border-blue': activeWindow === 'z',
            }
          )}
          ref={sliceContainerZ}
        >
          <div className="absolute left-1/2 translate-x-[-50%] w-[calc(100%-10px)] flex justify-between text-sm laptop:text-base desktop:text-xl font-light tracking-wide">
            <p>Axial-XY plane</p>
            {patientData && patientData.aid === 'CTscan' && (
              <p>
                {Math.round(ZSlice / spacings.Z)}/
                {Math.round(sliderMaxZ / spacings.Z)}
              </p>
            )}
          </div>
          <div className="absolute bottom-0 right-1/2 translate-x-[50%] w-[calc(100%-10px)] flex flex-col items-end text-sm laptop:text-base desktop:text-xl font-light tracking-wide">
            {patientData &&
              patientData.aid === 'CTscan' &&
              (coordinates.z.onImage ? (
                <>
                  <p>
                    Loc: ({coordinates.z.loc[0]} {coordinates.z.loc[1]}{' '}
                    {Math.round(ZSlice / spacings.Z)})
                  </p>
                  <p>Contrast: {coordinates.z.contrast}</p>
                </>
              ) : (
                <>
                  <p>
                    Pos: ({coordinates.z.pos[0]} {coordinates.z.pos[1]}{' '}
                    {coordinates.z.pos[2]})
                  </p>
                  <p>Off image</p>
                  <p>Contrast: {coordinates.z.contrast}</p>
                </>
              ))}
            <p>
              L:{Math.round(color.level)}, W:{Math.round(color.window)}
            </p>
          </div>
        </div>
      </div>
      {isRightButtonMenuVisible.z && (
        <RightButtonMenu
          plane="z"
          measuresWidgetManager={context.current.measuresWidgetManagerZ}
        />
      )}
    </>
  )
}

export default WindowSliceZ
