import React, { useState, createContext } from 'react'

export const FileContext = createContext(null)

const FileContextProvider = ({ children }) => {
  const [fileData, setFileData] = useState(null)
  const [fileName, setFileName] = useState('No file chosen')
  const [isLoading, setIsLoading] = useState(false)
  const [toasts, setToasts] = useState([])

  return (
    <FileContext.Provider
      value={{
        fileData,
        setFileData,
        fileName,
        setFileName,
        isLoading,
        setIsLoading,
        toasts,
        setToasts,
      }}
    >
      {children}
    </FileContext.Provider>
  )
}

export default FileContextProvider
