import React, { useState } from 'react'

/**
 * Slider for selecting the thickness of the every slice.
 *
 * @component
 * @param {Object | null} props.context - main storage for all vtk.js components. If the image was not loaded in the application, than equal to null.
 * @returns {JSX.Element} The rendered MIP slider component.
 */
const MIP = ({ context }) => {
  const [MIP, setMIP] = useState(1)

  const handleMIP = (e) => {
    if (context.current) {
      const {
        resliceX,
        resliceY,
        resliceZ,
        renderWindowX,
        renderWindowY,
        renderWindowZ,
        renderWindow,
      } = context.current

      setMIP(e.target.value)

      resliceX.setSlabNumberOfSlices(e.target.value)
      resliceY.setSlabNumberOfSlices(e.target.value)
      resliceZ.setSlabNumberOfSlices(e.target.value)

      renderWindow.render()
      renderWindowX.render()
      renderWindowY.render()
      renderWindowZ.render()
    }
  }

  return (
    <li className="relative">
      <div className="text-sm laptop:text-base desktop:text-lg font-bold text-center truncate">
        MIP Thickness
      </div>
      <input
        className="w-full"
        type="range"
        min="1"
        max="10"
        step="1"
        value={MIP}
        onChange={(e) => handleMIP(e)}
      />
      <p className="text-sm laptop:text-md desktop:text-lg text-center leading-[18px] truncate">
        {MIP}, {MIP}, {MIP} [mm]
      </p>
    </li>
  )
}

export default MIP
