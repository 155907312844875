import React, { useContext } from 'react'

import { FileContext } from '../../../contexts/FileContext'

/**
 * "Button for downloading a current file to the pc's file system from the application."
 *
 * @component
 * @returns {JSX.Element} The rendered file save button component.
 */
const FileSave = () => {
  const { fileData, fileName } = useContext(FileContext)

  const handleFileSave = () => {
    if (fileData) {
      const blob = new Blob([fileData])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    }
  }

  return (
    <li className="h-full">
      <button
        className="h-full flex flex-col items-center gap-px"
        onClick={handleFileSave}
      >
        <div className="h-1/2 flex items-center">
          <img
            className="w-[20px] laptop:w-auto"
            src="/assets/images/save.svg"
            alt=""
          />
        </div>
        <p className="max-h-1/2 text-xs laptop:text-sm desktop:text-base leading-[18px] laptop:leading-[18px] desktop:leading-[18px]">
          File Save
        </p>
      </button>
    </li>
  )
}

export default FileSave
