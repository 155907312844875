import React, { useRef, useContext } from 'react'

import { FileContext } from '../../../contexts/FileContext'
import { GViewContext } from '../../../contexts/GViewContext'
import { ViewContext } from '../../../contexts/ViewContext'
import { MessengerContext } from '../../../contexts/MessengerContext'
import { MeasuresContext } from '../../../contexts/MeasuresContext'
import { ActionsTreeContext } from '../../../contexts/ActionsTreeContext'
import { renderImageStructure } from '../../../utils/imageRendering'

/**
 * Button for uploading a file to the application from the pc's file system.
 *
 * @component
 * @returns {JSX.Element} The rendered file load button component.
 */
const FileLoad = () => {
  const filePicker = useRef(null)

  const {
    context,
    vtkContainerRef,
    vtkFunctionalContainerRef,
    vtkParentContainerRef,
    sliceContainerX,
    sliceContainerY,
    sliceContainerZ,
    setXSlice,
    setYSlice,
    setZSlice,
    setSliderMaxX,
    setSliderMaxY,
    setSliderMaxZ,
    setCurrentWidgetPosition,
    color,
    setColor,
    setSpacings,
  } = useContext(GViewContext)
  const { setView } = useContext(ViewContext)

  const { setFileData, fileName, setFileName } = useContext(FileContext)
  const { setMainActorData } = useContext(ActionsTreeContext)
  const { messages, setMessages } = useContext(MessengerContext)
  const { measuresData } = useContext(MeasuresContext)

  const handlePick = () => {
    filePicker.current.click()
  }

  const handleFile = async (e) => {
    e.preventDefault()
    console.log(e.target.files[0])
    if (e.target.files[0]) {
      const file = e.target.files[0]
      console.log(file)
      setFileName(file.name)
      setMessages((prev) => [...prev, `Loading file: ${file.name}`])
      setMessages((prev) => [
        ...prev,
        `File size: ${Math.round(file.size / 1000000)}MB`,
      ])

      const reader = new FileReader()
      reader.onload = async function onLoad() {
        setFileData(reader.result)
        await renderImageStructure({
          context,
          imageData: reader.result,
          imageName: file.name,
          vtkContainerRef,
          vtkFunctionalContainerRef,
          vtkParentContainerRef,
          sliceContainerX,
          sliceContainerY,
          sliceContainerZ,
          setXSlice,
          setYSlice,
          setZSlice,
          setSliderMaxX,
          setSliderMaxY,
          setSliderMaxZ,
          setCurrentWidgetPosition,
          color,
          setColor,
          setSpacings,
          setMainActorData,
          measuresData,
          hasFunctional: false,
          setView,
        })
      }
      reader.readAsArrayBuffer(file)
    }
  }

  return (
    <li className="h-full">
      <button
        className="h-full flex flex-col items-center gap-px"
        onClick={handlePick}
      >
        <div className="h-1/2 flex items-center">
          <img
            className="w-[20px] laptop:w-auto"
            src="/assets/images/load.svg"
            alt=""
          />
        </div>
        <p className="max-h-1/2 text-xs laptop:text-sm desktop:text-base leading-[18px] laptop:leading-[18px] desktop:leading-[18px]">
          File Load
        </p>
      </button>
      <input
        ref={filePicker}
        type="file"
        accept=".vti"
        onChange={handleFile}
        hidden
      />
    </li>
  )
}

export default FileLoad
