import React, { useContext } from 'react'

import ToolbarFiltersButton from '../ui/ToolbarFiltersButton'
import { ActionsTreeContext } from '../../../contexts/ActionsTreeContext'
import { PatientContext } from '../../../contexts/PatientContext'

import ScissorsModal from './ScissorsModal'

/**
 * A button to trigger the scissors modal window for cut operations with 3D image
 *
 * @component
 * @param {string | null} props.activeFilter - variable holds an active filter or null if there are no active filters. Determines whether the button is active and, accordingly, whether the modal window is invoked.
 * @param {string} props.activeScreen – variable holds an active screen.
 * @param {function} props.setActiveFilter - function that is triggered by pressing a button
 * @returns {JSX.Element} The rendered scissors button component with modal.
 */
const ScissorsButton = ({ activeScreen, activeFilter, setActiveFilter }) => {
  const { mainActorData } = useContext(ActionsTreeContext)
  const { patientData } = useContext(PatientContext)

  const handleClick = () => {
    if (activeFilter !== 'Scissors') {
      setActiveFilter('Scissors')
    } else {
      setActiveFilter(null)
    }
  }

  return (
    <>
      <ToolbarFiltersButton
        caption="Scissors"
        activeFilter={activeFilter}
        handleClick={handleClick}
        isDisabled={
          activeScreen !== 'GView' ||
          !mainActorData ||
          (patientData && patientData.aid !== 'CTscan')
        }
        activeImageLink="/assets/images/scissors_active.svg"
        inactiveImageLink="/assets/images/scissors.svg"
        disabledImageLink="/assets/images/scissors_disabled.svg"
        tooltipText="Digital surgery"
      />
      {activeFilter === 'Scissors' && (
        <ScissorsModal caption="Scissors" setActiveFilter={setActiveFilter} />
      )}
    </>
  )
}

export default ScissorsButton
