import React from 'react'
import clsx from 'clsx'

/**
 * Menubar item (Help) component
 *
 * @component
 * @returns {JSX.Element} The rendered menubar item component.
 */
const Help = ({ isActive, onClick, onMouseOver }) => {
  return (
    <li className="relative" onMouseOver={onMouseOver} onClick={onClick}>
      <button
        className={clsx('h-full px-4 rounded border border-transparent', {
          'bg-blue border-blue': isActive,
        })}
      >
        Help
      </button>
      {isActive && (
        <ul className="absolute top-full py-1 px-1 bg-mid-blue/80 backdrop-blur w-[160px] max-h-[260px] overflow-y-scroll border border-semi-transparent rounded z-10">
          <li className="relative py-1 px-3 rounded hover:bg-blue-hover">
            <button className="w-full text-left">Help1</button>
          </li>
          <li className="relative py-1 px-3 rounded hover:bg-blue-hover">
            <button className="w-full text-left">Help2</button>
          </li>
          <li className="relative py-1 px-3 rounded hover:bg-blue-hover">
            <button className="w-full text-left">Help3</button>
          </li>
        </ul>
      )}
    </li>
  )
}

export default Help
