import React from 'react'

/**
 * Anatomy item for PDF report
 *
 * @component
 * @param {Array} props.anatomyData - anatomy data array
 * @returns {JSX.Element} The rendered anatomy item component for PDF report.
 */
const DiagnosticsItemAnatomyPdf = ({ anatomyData }) => {
  return (
    <div className="h-[247px]">
      <h3 className="font-bold text-center leading-tight text-lg text-black mb-[5px]">
        Anatomy
      </h3>
      <div className="gap-1 h-[calc(100%-20px)] ">
        <div className="w-full h-full">
          <div className="h-full border border-black">
            <table className="max-w-full w-full h-full divide-y divide-black">
              <thead className="bg-blue-hover">
                <tr>
                  <th
                    scope="row"
                    className="px-0.5 py-0.5 text-center text-sm leading-tight font-semibold tracking-wider"
                  >
                    Item
                  </th>
                  <th className="px-0.5 py-0.5 text-center text-sm leading-tight font-semibold tracking-wider">
                    Volume
                  </th>
                  <th className="px-0.5 py-0.5 text-center text-sm leading-tight font-semibold tracking-wider">
                    Fraction
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-black">
                {anatomyData.map((anatomyItem, index) => (
                  <tr key={index}>
                    <td className="px-0.5 py-0.5 text-center text-sm leading-tight font-semibold tracking-wider bg-blue-hover">
                      {anatomyItem.name}
                    </td>
                    <td className="px-0.5 py-0.5 text-center text-sm leading-tight text-black">
                      {anatomyItem.volume ? anatomyItem.volume.toFixed(1) : '–'}
                    </td>
                    <td className="px-0.5 py-0.5 text-center text-sm leading-tight text-black">
                      {anatomyItem.volumeFraction
                        ? anatomyItem.volumeFraction.toFixed(1)
                        : '–'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiagnosticsItemAnatomyPdf
