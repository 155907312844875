import React, { useState } from 'react'

import DraggableModalMd from '../../../../modals/DraggableModalMd'

import LineChart from '../../../../charts/LineChart'

/**
 * Modal window with information about about the 3D image
 *
 * @component
 * @param {Object} props.mainActorData - object with data corresponding to a 3D image
 * @param {function} props.setMainActorData - function that sets data to this object
 * @returns {JSX.Element} The rendered modal window component.
 */
const InfoModal = ({ mainActorData, setMainActorData }) => {
  const [shiftCoordinates, setShiftCoordinates] = useState({ x: 0, y: 0 })

  const [chartDimensions] = useState({
    width: 518,
    height: 280,
    margin: {
      top: 15,
      right: 30,
      bottom: 50,
      left: 60,
    },
  })

  const handleClose = () => {
    setMainActorData((prevState) => ({
      ...prevState,
      activeModal: null,
    }))
  }

  const handleStopDragging = (e, data) => {
    setShiftCoordinates({ x: data.x, y: data.y })
  }

  return (
    <DraggableModalMd
      handleClose={handleClose}
      handleStopDragging={handleStopDragging}
    >
      <h2 className="h-[36px] py-2 px-4 leading-tight text-md bg-blue rounded-t-2xl cursor-default shadow-[4px_4px_10px_0px_#00000040]">
        Info: {mainActorData.name}
      </h2>
      <div className="flex flex-col p-4 w-full h-[calc(100%-36px)] overflow-y-scroll">
        <div>Extent: {mainActorData.info.extent}</div>
        <div>Bounds: {mainActorData.info.bounds}</div>
        <div>Spacing: {mainActorData.info.spacing}</div>
        <div>Range: {mainActorData.info.range}</div>
        <div>Visual contrast: {mainActorData.info.visualContrast}</div>
        <div className="flex gap-3">
          <p>Preset level: {mainActorData.info.presetLevel}</p>
          <p>Window: {mainActorData.info.window}</p>
        </div>
        <LineChart
          chartId="info"
          chartTitle="Image Histogram"
          chart={mainActorData.info.chart}
          chartDimensions={chartDimensions}
          shiftCoordinates={shiftCoordinates}
        />
      </div>
    </DraggableModalMd>
  )
}

export default InfoModal
