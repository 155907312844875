import React, { useState, useEffect, useRef } from 'react'
import * as d3 from 'd3'

import Checkbox from '../ui/Checkbox'

/**
 * Line chart
 *
 * @component
 * @param {string} props.chartId - needed to correctly connect the graph to the bounding container of the graph
 * @param {string} props.chartTitle - chart title
 * @param {Object} props.chart - object with data, axes names and conditions (isLoading, error)
 * @param {Object} props.chartDimensions - object with width, height and margin values
 * @param {Object} props.shiftCoordinates - if the chart is in a draggable modal window, then it is necessary to get the shift coordinates from this modal window for correct display of tooltips; if not, then it is necessary to set { x: 0, y: 0 }
 * @returns {JSX.Element} The line chart component.
 */
const LineChart = ({
  chartId,
  chartTitle,
  chart,
  chartDimensions,
  shiftCoordinates,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [tooltipData, setTooltipData] = useState({ x: 0, y: 0 })
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })

  const [chartSettings, setChartSettings] = useState({
    isPointsVisible: false,
    isGridVisible: true,
    isLineDashed: false,
  })

  const chartRef = useRef(null)
  const chartContext = useRef(null)

  const initializeSVG = () => {
    const svg = d3
      .select(chartRef.current)
      .append('svg')
      .attr('width', chartDimensions.width)
      .attr('height', chartDimensions.height)
      .attr(
        'style',
        'max-width: 100%; height: auto; color: #000; margin: 0 auto'
      )

    svg
      .append('clipPath')
      .attr('id', `${chartId}ClipChart`)
      .append('rect')
      .attr('x', chartDimensions.margin.left)
      .attr('y', chartDimensions.margin.top)
      .attr(
        'width',
        chartDimensions.width -
          chartDimensions.margin.left -
          chartDimensions.margin.right
      )
      .attr(
        'height',
        chartDimensions.height -
          chartDimensions.margin.top -
          chartDimensions.margin.bottom
      )

    return svg
  }

  // Create the horizontal and vertical scales.
  const initializeScales = () => {
    const x = d3
      .scaleLinear()
      .domain(d3.extent(chart.data, (d) => d.x))
      .range([
        chartDimensions.margin.left,
        chartDimensions.width - chartDimensions.margin.right,
      ])

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(chart.data, (d) => d.y)])
      .nice()
      .range([
        chartDimensions.height - chartDimensions.margin.bottom,
        chartDimensions.margin.top,
      ])

    return { x, y }
  }

  const initializeAxes = (svg, scales) => {
    // Create the horizontal axis generator, called at startup and when zooming.
    const xAxis = (g, x) =>
      g.attr('class', 'xAxis').call(
        d3
          .axisBottom(x)
          .ticks(chartDimensions.width / 80)
          .tickSizeOuter(0)
      )

    // Append the horizontal axis.
    const gx = svg
      .append('g')
      .attr(
        'transform',
        `translate(0,${chartDimensions.height - chartDimensions.margin.bottom})`
      )
      .call(xAxis, scales.x)
      .call((g) =>
        g
          .append('text')
          .attr('x', chartDimensions.width / 2)
          .attr('y', 35)
          .attr('fill', 'currentColor')
          .attr('text-anchor', 'middle')
          .attr('font-size', '13px')
          .attr('font-weight', '600')
          .text(chart.axesNames.x)
      )

    // Append the vertical axis.
    svg
      .append('g')
      .attr('class', 'yAxis')
      .attr('transform', `translate(${chartDimensions.margin.left},0)`)
      .call(d3.axisLeft(scales.y).ticks(null, 's'))
      .call((g) =>
        g
          .append('text')
          .attr('x', -(chartDimensions.height - 24) / 2)
          .attr('y', -40)
          .attr('fill', 'currentColor')
          .attr('text-anchor', 'middle')
          .attr('font-size', '13px')
          .attr('font-weight', '600')
          .attr('transform', 'rotate(-90)')
          .text(chart.axesNames.y)
      )

    return { xAxis, gx }
  }

  const drawGrid = (svg) => {
    svg
      .selectAll('g.yAxis g.tick')
      .append('line')
      .attr('class', 'gridline')
      .attr(
        'x1',
        chartDimensions.width -
          chartDimensions.margin.left -
          chartDimensions.margin.right
      )
      .attr('stroke', '#00000020')
      .attr('stroke-dasharray', 'none')

    svg
      .selectAll('g.xAxis g.tick')
      .append('line')
      .attr('class', 'gridline')
      .attr(
        'y1',
        -chartDimensions.height +
          chartDimensions.margin.bottom +
          chartDimensions.margin.top
      )
      .attr('stroke', '#00000020')
      .attr('stroke-dasharray', 'none')
  }

  const drawLine = (svg, scales, data) => {
    // Create the line generator.
    const line = d3
      .line()
      .x((d) => scales.x(d.x))
      .y((d) => scales.y(d.y))

    // Create the line.
    svg
      .append('path')
      .datum(data)
      .attr('clip-path', `url(#${chartId}ClipChart)`)
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-dasharray', chartSettings.isLineDashed ? '3,2' : 'none')
      .attr('stroke-width', 2)
      .attr('d', line)
      .attr('class', 'line')

    return line
  }

  const initializePointsWithTooltips = (svg, scales) => {
    const mouseover = (e, d) => {
      setIsTooltipVisible(true)
      setTooltipPosition({
        x: e.layerX + 10,
        y: e.layerY - 40,
      })
      setTooltipData({ x: d.x, y: d.y })
      d3.select(this).attr('r', 6)
    }

    const mouseleave = () => {
      setIsTooltipVisible(false)
      d3.select(this).attr('r', 4)
    }

    svg
      .append('g')
      .selectAll('dot')
      .data(chart.data)
      .join('circle')
      .attr('class', 'linePoint')
      .style('visibility', chartSettings.isPointsVisible ? 'visible' : 'hidden')
      .attr('cx', (d) => scales.x(d.x))
      .attr('cy', (d) => scales.y(d.y))
      .attr('r', 4)
      .attr('fill', 'steelblue')
      .attr('clip-path', `url(#${chartId}ClipChart)`)
      .on('mouseover', mouseover)
      .on('mouseleave', mouseleave)
  }

  // When zooming, redraw the line and the x axis.
  const handleZoom = (event, grid) => {
    const { line, scales, gx, xAxis } = chartContext.current

    const xz = event.transform.rescaleX(scales.x)
    d3.select(chartRef.current)
      .select('svg')
      .select('path.line')
      .attr(
        'd',
        line.x((d) => xz(d.x))
      )
    gx.call(xAxis, xz)

    const xTicks = d3.selectAll('g.xAxis g.tick')

    xTicks.each(function () {
      const tick = d3.select(this)
      const gridline = tick.select('line.gridline')

      if (gridline.empty()) {
        tick
          .append('line')
          .attr('class', 'gridline')
          .attr('x1', 0)
          .attr(
            'y1',
            -chartDimensions.height +
              chartDimensions.margin.bottom +
              chartDimensions.margin.top
          )
          .attr('x2', 0)
          .attr('y2', 0)
          .attr('stroke', '#00000020')
          .style('visibility', grid ? 'visible' : 'hidden')
      }
    })

    d3.select(chartRef.current)
      .select('svg')
      .selectAll('circle.linePoint')
      .attr('cx', (d) => xz(d.x))
      .attr('cy', (d) => scales.y(d.y))
  }

  // Create the zoom behavior.
  const initializeZoomBehavior = () => {
    const zoom = d3
      .zoom()
      .scaleExtent([1, 20])
      .extent([
        [chartDimensions.margin.left, 0],
        [
          chartDimensions.width - chartDimensions.margin.right,
          chartDimensions.height,
        ],
      ])
      .translateExtent([
        [chartDimensions.margin.left, -Infinity],
        [chartDimensions.width - chartDimensions.margin.right, Infinity],
      ])
      .on('zoom', (event) => handleZoom(event, chartSettings.isGridVisible))

    return zoom
  }

  const renderChart = () => {
    const svg = initializeSVG()
    const scales = initializeScales()
    const { xAxis, gx } = initializeAxes(svg, scales)
    drawGrid(svg)
    const line = drawLine(svg, scales, chart.data)
    const zoom = initializeZoomBehavior()
    initializePointsWithTooltips(svg, scales)

    chartContext.current = {
      scales,
      xAxis,
      gx,
      line,
      zoom,
    }

    // Initial zoom.
    svg.call(zoom)
  }

  const handlePointsVisibility = (e) => {
    setChartSettings((prev) => ({
      ...prev,
      isPointsVisible: e.target.checked,
    }))
    const circles = d3
      .select(chartRef.current)
      .select('svg')
      .selectAll('circle.linePoint')
    if (e.target.checked) {
      circles.style('visibility', 'visible')
    } else {
      circles.style('visibility', 'hidden')
    }
  }

  const handleGridVisibility = (e) => {
    setChartSettings((prev) => ({
      ...prev,
      isGridVisible: e.target.checked,
    }))
    const gridlines = d3
      .select(chartRef.current)
      .select('svg')
      .selectAll('line.gridline')

    chartContext.current.zoom.on('zoom', (event) =>
      handleZoom(event, e.target.checked)
    )

    if (e.target.checked) {
      gridlines.style('visibility', 'visible')
    } else {
      gridlines.style('visibility', 'hidden')
    }
  }

  const handleLineDashed = (e) => {
    setChartSettings((prev) => ({
      ...prev,
      isLineDashed: e.target.checked,
    }))
    const pathLine = d3
      .select(chartRef.current)
      .select('svg')
      .select('path.line')

    if (e.target.checked) {
      pathLine.attr('stroke-dasharray', '3,2')
    } else {
      pathLine.attr('stroke-dasharray', 'none')
    }
  }

  const handleReset = () => {
    d3.select(chartRef.current)
      .select('svg')
      .transition()
      .duration(500)
      .call(chartContext.current.zoom.transform, d3.zoomIdentity)
  }

  useEffect(() => {
    if (
      !chart.conditions.isLoading &&
      !chart.conditions.error &&
      !chartRef.current.children.length
    ) {
      renderChart()
    }
  }, [chart.conditions.isLoading])

  return (
    <>
      <div className="bg-white rounded-md mt-[10px]">
        <h4 className="flex justify-center mt-[10px] text-black font-bold">
          {chartTitle}
        </h4>
        {!chart.conditions.isLoading && !chart.conditions.error ? (
          <>
            <div ref={chartRef} />
            {isTooltipVisible && (
              <div
                style={{
                  left: tooltipPosition.x - shiftCoordinates.x - 10,
                  top: tooltipPosition.y - shiftCoordinates.y - 20,
                }}
                className="absolute bg-black opacity-85 rounded-lg p-2 pointer-events-none text-sm whitespace-nowrap"
              >
                <p>
                  <span className="mr-1">x:</span>
                  {tooltipData.x}
                </p>
                <p>
                  <span className="mr-1">y:</span>
                  {tooltipData.y}
                </p>
              </div>
            )}
          </>
        ) : (
          <div className="p-3">
            {chart.conditions.error ? (
              <p className="text-red text-center">
                Error: {chart.conditions.error}
              </p>
            ) : (
              <img className="mx-auto" src="/assets/images/loader.svg" alt="" />
            )}
          </div>
        )}
      </div>
      {!chart.conditions.isLoading && !chart.conditions.error && (
        <div className="flex items-center justify-between gap-3 mt-3">
          <div className="flex gap-3">
            <Checkbox
              id={chartId + 'Points'}
              isChecked={chartSettings.isPointsVisible}
              setIsChecked={handlePointsVisibility}
            >
              Points
            </Checkbox>
            <Checkbox
              id={chartId + 'Grid'}
              isChecked={chartSettings.isGridVisible}
              setIsChecked={handleGridVisibility}
            >
              Grid
            </Checkbox>
            <Checkbox
              id={chartId + 'Dashed'}
              isChecked={chartSettings.isLineDashed}
              setIsChecked={handleLineDashed}
            >
              Dashed
            </Checkbox>
          </div>
          <button
            className="w-1/5 px-2 py-1 bg-blue transition duration-100 hover:bg-mid-blue-hover rounded-md"
            onClick={handleReset}
          >
            Reset
          </button>
        </div>
      )}
    </>
  )
}

export default LineChart
